import { makeCollectionCreator, makeConfigApiCreator } from "../../creators";
import history from "../../script/history";
import { ListResponse } from "../../script/types";
import { Device } from "./detail/attributes/creator";
import { Gateway } from "./detail/creator";
import { ErrorAction } from "../../creators/AjaxCreator";

const catalogCreator = makeConfigApiCreator<ListResponse<Device>>("v3_base", {
    errorCallout: true,
    errorRedirect: () => history.push("/devices/list"),
})("devices_catalog", (untypedResponse: unknown) => {
    const response = untypedResponse as ListResponse<Device>;
    response.data = response.data.map((device) => Object.assign(device, { selected: false }));
    if (response.data?.length > 1) {
        response.last = response.data[response.data.length - 1].id;
    }
    return response;
});

const insightsCreator = makeConfigApiCreator<ListResponse<Device>>("v3_preview1", {
    errorCallout: true,
    errorRedirect: () => history.push("/devices/insights"),
})("devices_catalog");

const { createThunk: createDevicesThunk, reducer: devicesReducer } = makeCollectionCreator(catalogCreator);
const { createThunk: createInsightsThunk } = makeCollectionCreator(insightsCreator);

export interface DevicesParameters {
    id?: string;
    groupId?: string;
    insightSessionId?: string;
    pageSize?: number;
    last?: string;
    order?: string;
    query?: string;
    errorAction?: ErrorAction;
    errorCallout?: boolean;
}

const getDevices = ({
    id,
    groupId,
    insightSessionId,
    pageSize = 50,
    last,
    order = "DESC",
    query,
    errorAction,
    errorCallout = true,
}: DevicesParameters) => {
    const queryParameters = {
        order,
        limit: pageSize?.toString(),
        include: "total_count",
        after: last,
        ...(query && { filter: query }),
    };

    if (groupId) {
        return createDevicesThunk({
            id,
            param: `device-groups/${groupId}/devices`,
            queryParameters,
            errorAction,
            errorCallout,
        });
    }

    if (insightSessionId) {
        return createInsightsThunk({
            id,
            param: `insights-sessions/${insightSessionId}/devices`,
            queryParameters,
            errorAction,
            errorCallout,
        });
    }

    return createDevicesThunk({
        id,
        param: "devices",
        queryParameters,
        errorAction,
        errorCallout,
    });
};

// update device details
const { createThunk: createUpdateDeviceThunk, reducer: updateDeviceReducer } = makeConfigApiCreator<Device>("v3_base", {
    errorRedirect: false,
    errorCallout: true,
})("update_devices_catalog");

const updateDetail = (id: string, data: Device) =>
    createUpdateDeviceThunk({
        param: "devices/" + id,
        data,
        method: "PUT",
    });

// delete device
const {
    createThunk: createDeleteDeviceThunk,
    reducer: deleteDeviceReducer,
    createResetAction: createDeleteActionReset,
} = makeConfigApiCreator<any>("v3_base")("delete_devices_catalog");

const deleteDeviceAction = (id: string) =>
    createDeleteDeviceThunk({
        errorCallout: false,
        param: `devices/${id}`,
        method: "DELETE",
        id,
    });

const gatewayDevicesCreator = makeConfigApiCreator<ListResponse<Device>>("v3_base", {
    errorCallout: true,
    errorRedirect: () => history.push("/devices/list"),
})("devices_catalog-gateways_devices", (untypedResponse: unknown) => {
    const response = untypedResponse as ListResponse<Device>;
    response.data = response.data.map((device) => Object.assign(device, { selected: false }));
    if (response.data && response.data.length > 1) {
        response.last = response.data[response.data.length - 1].id;
    }
    return response;
});

const { createThunk: createGatewayDevicesThunk, reducer: getGatewayDevicesReducer } = makeCollectionCreator(
    gatewayDevicesCreator
);

const getGatewayDevices = (id: string) =>
    createGatewayDevicesThunk({
        param: "devices",
        queryParameters: {
            limit: "1000",
            include: "total_count",
            order: "DESC",
            filter: encodeURIComponent(`host_gateway=${id}`),
        },
        errorCallout: false,
        id,
    });

// Device block categories

interface DeviceBlockCategory {
    id?: string;
    reference?: string;
    description?: string;
    updated_at?: string;
}

const { createThunk: createBlockCategoriesThunk, reducer: deviceBlockCategoriesReducer } = makeConfigApiCreator<
    ListResponse<DeviceBlockCategory>
>("v3_base", {
    errorCallout: true,
})("device_block_categories", (untypedResponse) => {
    const response = untypedResponse as ListResponse<DeviceBlockCategory>;
    if (response.data && response.data.length > 1) {
        response.last = response.data[response.data.length - 1].id;
    }

    return response;
});

interface BlockCategoriesParameters {
    pageSize?: number;
    last?: string;
    order?: string;
    query?: string;
}

const getBlockCategories = ({ pageSize = 50, last, order = "DESC", query }: BlockCategoriesParameters) => {
    const queryParameters = {
        order,
        limit: pageSize?.toString(),
        include: "total_count",
        after: last,
        filter: query,
    };

    return createBlockCategoriesThunk({
        param: "device-block-categories",
        queryParameters,
    });
};

interface DeviceSuspensionDetails {
    category: string;
    description?: string;
}

interface SuspendDeviceParameters {
    id: string;
    deviceId: string;
    data: DeviceSuspensionDetails;
    errorAction?: () => void;
    errorCallout?: boolean;
}

// Suspend device
const suspendDeviceCreator = makeConfigApiCreator<void>("v3_base", {
    errorCallout: true,
    errorRedirect: () => history.push("/devices/list"),
})("device_suspend");

const { createThunk: createSuspendDeviceThunk, reducer: suspendDeviceReducer } = makeCollectionCreator(
    suspendDeviceCreator
);

const suspendDevice = ({
    id,
    deviceId,
    data = {} as DeviceSuspensionDetails,
    errorAction,
    errorCallout = true,
}: SuspendDeviceParameters) =>
    createSuspendDeviceThunk({
        id,
        method: "POST",
        param: `devices/${deviceId}/suspend`,
        data,
        errorAction,
        errorCallout,
    });

// Resume device
const resumeDeviceCreator = makeConfigApiCreator<void>("v3_base", {
    errorCallout: true,
    errorRedirect: () => history.push("/devices/list"),
})("device_resume");

const { createThunk: createResumeDeviceThunk, reducer: resumeDeviceReducer } = makeCollectionCreator(
    resumeDeviceCreator
);

const resumeDevice = ({
    id,
    deviceId,
    data = {} as DeviceSuspensionDetails,
    errorAction,
    errorCallout = true,
}: SuspendDeviceParameters) => {
    return createResumeDeviceThunk({
        id,
        method: "POST",
        param: `devices/${deviceId}/resume`,
        data,
        errorAction,
        errorCallout,
    });
};

const { createThunk: createGatewaysThunk, reducer: gatewaysReducer } = makeConfigApiCreator<ListResponse<Gateway>>(
    "v3_alpha"
)("gateways");

const getGateways = (errorCallout: boolean) => {
    return createGatewaysThunk({
        param: "gateways",
        queryParameters: { include: "total_count" },
        errorCallout,
    });
};

export {
    getDevices,
    devicesReducer,
    updateDetail,
    updateDeviceReducer,
    deleteDeviceAction,
    deleteDeviceReducer,
    createDeleteActionReset,
    getGatewayDevices,
    getGatewayDevicesReducer,
    getBlockCategories,
    deviceBlockCategoriesReducer,
    suspendDevice,
    suspendDeviceReducer,
    resumeDevice,
    resumeDeviceReducer,
    getGateways,
    gatewaysReducer,
};
