import { Icon, Icons } from "portal-components";
import React from "react";

export interface SearchBarProps {
    value: string;
    placeholder?: string;
    autofocus?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface State {
    value: string;
}

export class SearchBar extends React.Component<SearchBarProps, State> {
    constructor(props: SearchBarProps) {
        super(props);

        this.handleValueChanged = this.handleValueChanged.bind(this);

        this.state = {
            value: props.value,
        };
    }

    componentDidUpdate(prevProps: Partial<SearchBarProps>) {
        if (prevProps.value !== this.props.value) {
            setTimeout(() => this.setState({ value: this.props.value }), 0);
        }
    }

    handleValueChanged(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ value: e.target.value });
        this.props.onChange?.(e);
    }

    render() {
        return (
            <div className="search-bar">
                <Icon name={Icons.Search} className="search-bar-icon" />
                <input
                    placeholder={this.props.placeholder}
                    value={this.state.value}
                    onChange={this.handleValueChanged}
                    autoFocus={this.props.autofocus}
                />
            </div>
        );
    }
}

export default SearchBar;
