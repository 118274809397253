import { Config } from "../typings/interfaces";

export interface AuthorizationRule {
    feature?: string;
    admin?: boolean;
    tierFree?: boolean;
    tierCommercial?: boolean;
    tenant?: boolean;
}

export interface Access {
    admin: boolean;
    tier?: string;
    tenant?: boolean;
}

export function isAuthorized(config: Config | undefined, access: Access, rule: AuthorizationRule): boolean {
    return (
        config !== undefined &&
        hasProperty(config.featureToggle, rule.feature) &&
        hasRequiredValue(access.admin, rule.admin) &&
        hasRequiredValue(access.tier === "0", rule.tierFree) &&
        hasRequiredValue(access.tier === "1", rule.tierCommercial) &&
        hasRequiredValue(access.tenant || false, rule.tenant)
    );
}

function hasProperty(obj?: any, propertyName?: string): boolean {
    return !obj || !propertyName || !!obj[propertyName];
}

function hasRequiredValue(currentValue: boolean, expectedValue?: boolean): boolean {
    return expectedValue === undefined || expectedValue === currentValue;
}
