import { If, Orientation, Panel, StackPanel, Button, Icons } from "portal-components";
import React, { ErrorInfo } from "react";
import { connect } from "react-redux";
import { Config } from "../typings/interfaces";
import translate, { DefaultStrings } from "../i18n/translate";
import { AppState } from "../creators/reducers";
import { redirectTo } from "../script/routing";

export const defaultStrings = {
    somethingWrong: "Something went wrong.",
    tryAgain: "Try refreshing the page and try again.  If errors persist, please contact support.",
    tryAgainButton: "Click to reload this component",
};

export interface ErrorBoundaryProps {
    config: Config;
    strings: DefaultStrings<typeof defaultStrings>;
    children: React.ReactElement | React.ReactElement[];
}

interface ErrorBoundaryState {
    hasError: boolean;
    error?: Error;
    info?: ErrorInfo;
}

export class ErrorBoundary extends React.PureComponent<ErrorBoundaryProps, ErrorBoundaryState> {
    public static defaultProps = {
        strings: defaultStrings,
    };

    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false, error: undefined, info: undefined };
    }

    componentDidCatch(error: Error, info: React.ErrorInfo) {
        this.setState({
            hasError: true,
            error,
            info,
        });
    }

    // note, for tsconfig, params that starts with _ will be excluded from the --noUnusedParameters flag.
    componentDidUpdate(_prevProps: Readonly<ErrorBoundaryProps>, prevState: Readonly<ErrorBoundaryState>) {
        // Detect ChunkLoadError and redirect back home when we're not in the the home page.
        if (prevState.error !== this.state.error && this.state.error !== undefined) {
            if (this.state.error.toString().indexOf("ChunkLoadError") !== -1 && window.location.pathname !== "/") {
                redirectTo({
                    path: "/",
                    replace: true,
                });
            }
        }
    }

    render() {
        const {
            config: { i18nPseudo },
            strings,
        } = this.props;
        const { error } = this.state;
        const { name, stack } = error || {};
        if (this.state.hasError) {
            return (
                <StackPanel orientation={Orientation.Vertical} gutter>
                    <Panel>
                        <h1>{strings.somethingWrong}</h1>
                        <Button
                            icon={Icons.Refresh}
                            onClick={() => this.setState({ hasError: false })}
                            text={strings.tryAgainButton}
                        />
                    </Panel>
                    <If condition={i18nPseudo}>
                        <h2>{name}</h2>
                        <Panel>
                            <pre>{stack}</pre>
                        </Panel>
                    </If>
                    <Panel>{strings.tryAgain}</Panel>
                </StackPanel>
            );
        }
        return this.props.children;
    }
}

const mapStateToProps = (state: AppState) => {
    return { config: state.config };
};

export default connect(mapStateToProps)(translate("ErrorBoundary")(ErrorBoundary));
