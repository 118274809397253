import { makeConfigApiCreator } from "../../../../creators/AjaxApiCreator";
import { ListResponse } from "../../../../script";
import history from "../../../../script/history";
import { CampaignDeviceMetadata, UpdateCampaign, UpdateCampaignMetrics, UpdateCampaignStatistics } from "./types";

// Get a single campaign details
const { reducer, createThunk: getUpdateDetailThunk } = makeConfigApiCreator<UpdateCampaign>("v3_base")(
    "update_detail",
    (updateCampaign: any) => ({
        ...updateCampaign,
        device_filter: updateCampaign.device_filter ? updateCampaign.device_filter.replace("filter=", "") : "",
    })
);
const getAction = (id: string) => getUpdateDetailThunk({ param: `update-campaigns/${id}`, errorRedirect: true });

// Get metrics for a single campaign
const { reducer: metricReducer, createThunk: getMetricsThunk } = makeConfigApiCreator<UpdateCampaignMetrics>("v3_base")(
    "update_metrics"
);
const getMetrics = (id: string) =>
    getMetricsThunk({
        param: `update-campaigns/${id}/metrics`,
        errorRedirect: () => history.push("/firmware/deployments/list"),
    });

// Statistics - failed
const { reducer: failedUpdatesStatisticsReducer, createThunk: getFailedUpdateStatisticsThunk } = makeConfigApiCreator<
    ListResponse<UpdateCampaignStatistics>
>("v3_base")("update_statistics_failed");

const getFailedUpdatesStatistics = (campaignId: string) =>
    getFailedUpdateStatisticsThunk({
        param: `update-campaigns/${campaignId}/statistics/fail/event_types`,
        queryParameters: { include: "total_count" },
        errorCallout: false,
        errorRedirect: false,
    });

// Statistics - failed
const { reducer: skippedUpdatesStatisticsReducer, createThunk: getSkippedUpdateStatisticsThunk } = makeConfigApiCreator<
    ListResponse<UpdateCampaignStatistics>
>("v3_base")("update_statistics_skipped");

const getSkippedUpdatesStatistics = (campaignId: string) =>
    getSkippedUpdateStatisticsThunk({
        param: `update-campaigns/${campaignId}/statistics/skipped/event_types`,
        queryParameters: { include: "total_count" },
        errorCallout: false,
        errorRedirect: false,
    });

// Statistics - info
const { reducer: infoUpdatesStatisticsReducer, createThunk: getInfoUpdateStatisticsThunk } = makeConfigApiCreator<
    ListResponse<UpdateCampaignStatistics>
>("v3_base")("update_statistics_info");

const getInfoUpdatesStatistics = (campaignId: string) =>
    getInfoUpdateStatisticsThunk({
        param: `update-campaigns/${campaignId}/statistics/info/event_types`,
        queryParameters: { include: "total_count" },
        errorCallout: false,
        errorRedirect: false,
    });

// Metadata
const { reducer: metadataReducer, createThunk: getMetadataThunk } = makeConfigApiCreator<
    ListResponse<CampaignDeviceMetadata>
>("v3_base")("update_metadata", (response: any) => {
    response = response as ListResponse<CampaignDeviceMetadata>;
    const data = response.data.map((device: CampaignDeviceMetadata) => ({
        ...device,
        id: device.device_id,
        metadata_id: device.id,
    }));
    if (response.data && response.data.length > 1) {
        response.last = response.data[response.data.length - 1].id;
    }
    return { ...response, data };
});

const getMetadata = ({ id, pageSize = 1000, last }: { id: string; pageSize?: number; last?: string }) => {
    const queryParameters: { [query: string]: string } = {
        limit: `${pageSize}`,
        include: "total_count",
    };
    if (last) {
        queryParameters.after = last;
    }
    return getMetadataThunk({
        param: `update-campaigns/${id}/campaign-device-metadata`,
        queryParameters,
        errorRedirect: () => history.push("/firmware/deployments/list"),
    });
};

// Archive phase change
const { reducer: archiveReducer, createThunk: getArchive } = makeConfigApiCreator<void>("v3_base")(
    "update_phase_change_archive"
);
const archiveAction = (id: string) =>
    getArchive({
        param: `update-campaigns/${id}/archive`,
        method: "POST",
        errorCallout: true,
        errorRedirect: () => history.push("/firmware/deployments/list"),
    });

// Start phase change
const { reducer: startReducer, createThunk: getStart } = makeConfigApiCreator<void>("v3_base")(
    "update_phase_change_start"
);
const startAction = (id: string, startDateTime?: Date) =>
    getStart({
        param: `update-campaigns/${id}/start`,
        method: "POST",
        data: startDateTime ? { start_time: startDateTime } : {},
        errorCallout: true,
        errorRedirect: () => history.push("/firmware/deployments/list"),
    });

// Stop phase change
const { reducer: stopReducer, createThunk: getStop } = makeConfigApiCreator<void>("v3_base")(
    "update_phase_change_stop"
);
const stopAction = (id: string) =>
    getStop({
        param: `update-campaigns/${id}/stop`,
        method: "POST",
        errorCallout: true,
        errorRedirect: () => history.push("/firmware/deployments/list"),
    });

export {
    archiveAction,
    archiveReducer,
    startAction,
    startReducer,
    stopAction,
    stopReducer,
    getAction,
    reducer,
    getMetrics,
    metricReducer,
    getMetadata,
    metadataReducer,
    getFailedUpdatesStatistics,
    failedUpdatesStatisticsReducer,
    getSkippedUpdatesStatistics,
    skippedUpdatesStatisticsReducer,
    getInfoUpdatesStatistics,
    infoUpdatesStatisticsReducer,
};
