import { ReactText } from "react";
export { sortDirs } from "./creator";
interface RowOriginal<T = {}> {
    original: { id: string } & T;
    [key: string]: unknown;
}

export type Row<T = {}> = T & RowOriginal<T>;

export interface Column<T = { [key: string]: unknown }> {
    ellipsis?: string;
    id?: number;
    index?: number;
    link?: string | ((row: Row<T>) => string);
    linkName?: string;
    minWidth?: number;
    name: keyof T | string;
    onClick?: (e: React.MouseEvent, data: any) => void;
    rawDate?: string;
    dateFormat?: string;
    disableOnEmpty?: boolean;
    renderFunction?:
        | ((
              value: ReactText | string | number | unknown,
              row?: Row<T>
          ) => JSX.Element | JSX.Element[] | JSX.Element[][] | string | null)
        | ((data: { [index: string]: unknown }) => JSX.Element[] | null | unknown);
    replaceEmptyCell?: string;
    resizable?: boolean;
    show?: boolean;
    sortable?: boolean;
    sortMethod?: (a: ReactText, b: ReactText) => number;
    title?: string;
    trim?: string;
    type?: string;
    width?: number;
    isSortable?: boolean;
}

interface ExtraActionButton {
    title: string;
    link?: string;
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>, selected: any[]) => void;
}

type DropdownButton<T = {}> = {
    disabled?: boolean;
    name?: string;
    hidden?: boolean;
    validSelectionFilter?: (device: T) => boolean | undefined;
    validSelectionsFilter?: (device: T[]) => boolean | undefined;
    multipleSelection?: boolean;
} & ExtraActionButton;

type IconButton = ExtraActionButton;

export type SingleButton = { id?: string; disabled?: boolean } & ExtraActionButton;

export interface ExtraActions<T = {}> {
    dropdownButtons?: DropdownButton<T>[];
    iconButtons?: IconButton[];
    singleButtons?: SingleButton[];
}

export interface GridPageSize {
    text?: string;
    value: number;
}

export interface GridWrapProps<T = { [key: string]: unknown }> {
    columns?: Column<T>[];
    defaultPageSize?: number;
    defaultSort?: { id: string | number; dir?: number };
    extraActions?: ExtraActions<T>;
    filterBy?: number[] | number | string;
    filterLevel?: number;
    loading?: boolean;
    noBottomPaging?: boolean;
    onSelection?: (selected: T[], selectedAll?: boolean) => void;
    pagingPageSizeLarge?: GridPageSize[];
    pagingPageSizeSmall?: GridPageSize[];
    selectedAll?: boolean;
    showFilter?: boolean;
    showPageSizeSelector?: boolean;
    onSortChange?: (newSort: any) => void;
}

export { default as GridWrap } from "./grid-wrap";
