import { makeConfigApiCreator, ParseFunction } from "../../../creators";
import { ListResponse, ListRequestParameters } from "../../../script";
import { AccessPolicy } from "./create/creator";

const makePoliciesCreator = <T, D = undefined>(type: string, parseFunction?: ParseFunction<T, D>) =>
    makeConfigApiCreator<T, D>("v3_base", {
        errorCallout: false,
    })(type, parseFunction);

const { reducer, createThunk } = makePoliciesCreator<ListResponse<AccessPolicy>>("policies", (resp) => {
    const response = resp as ListResponse<AccessPolicy>;
    response.data = response.data
        .map((item) => ({ ...item, selected: false }))
        .sort((a, b) => a.name.localeCompare(b.name));
    if (response.data && response.data.length > 1) {
        response.last = response.data[response.data.length - 1].id;
    }
    return response;
});

interface PoliciesRequestParameters extends ListRequestParameters {
    query?: string;
}

const getAction = (
    {
        pageSize = 1000,
        last = undefined,
        order = "DESC",
        query = undefined,
        errorAction = undefined,
        errorCallout = true,
    } = {} as PoliciesRequestParameters
) =>
    createThunk({
        param: `policies`,
        queryParameters: {
            order,
            limit: `${pageSize}`,
            include: "total_count",
            filter: query,
            after: last,
        },
        errorAction,
        errorCallout,
    });

const { reducer: countReducer, createThunk: countThunk } = makePoliciesCreator<number, ListResponse<unknown>>(
    "count_policies",
    (response) => (response as ListResponse<unknown>).total_count
);
const getCountAction = () => countThunk({ param: `policies?limit=2&include=total_count`, errorCallout: true });

export { getAction, getCountAction, reducer, countReducer };
