import { Strings } from "portal-components";
import React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import { Config } from "../../../../typings/interfaces";
import { AjaxState, isLoaded } from "../../../../creators/AjaxCreator";
import { ListResponse } from "../../../../script";
import history from "../../../../script/history";
import { AppState, AppDispatch } from "../../../../creators/reducers";
import { concatData } from "../../../../script/utility";
import DeviceEventDetailComponent from "./component";
import { DeviceEvent, getDeviceEventsForDevice, GetDeviceEventsParameters, DeviceEventDetailFilter } from "./creator";

interface DeviceEventDetailContainerProps extends RouteComponentProps {
    id: string;
    config: Config;
    deviceLog: AjaxState<ListResponse<DeviceEvent>>;
    dispatch: AppDispatch;
}

interface DeviceEventDetailContainerState {
    loading: boolean;
    data: DeviceEvent[];
    info: ListResponse<DeviceEvent>;
    filter: DeviceEventDetailFilter;
    query: string;
}

export class DeviceEventDetail extends React.PureComponent<
    DeviceEventDetailContainerProps,
    DeviceEventDetailContainerState
> {
    constructor(props: DeviceEventDetailContainerProps) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            info: {} as ListResponse<DeviceEvent>,
            filter: {} as DeviceEventDetailFilter,
            query: "",
        };
        this.getData = this.getData.bind(this);
        this.handleLoadMoreItems = this.handleLoadMoreItems.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleResetPartialList = this.handleResetPartialList.bind(this);
    }

    componentDidMount() {
        const query = Strings.convertSearchStringToObject(this.props.location.search);
        if (query.eventsFilter) {
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({ query: decodeURIComponent(query.eventsFilter) }, () => {
                history.replace({ ...history.location, search: "" });
                this.handleRefresh();
            });
        } else {
            this.handleRefresh();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: DeviceEventDetailContainerProps) {
        if (!isLoaded(this.props.deviceLog) && isLoaded(nextProps.deviceLog)) {
            const data = nextProps.deviceLog.data;
            this.setState({
                data: concatData(this.state.data, data.data).map((log: DeviceEvent) => {
                    return Object.assign({}, log, {
                        highlighted: log.id === this.props.id,
                    });
                }),
                loading: false,
                info: data,
            });
        }
    }

    handleLoadMoreItems(props: { last: string | undefined; pageSize?: 50 }) {
        this.getData({ last: this.state.info.last, pageSize: props.pageSize });
    }

    getData({ deviceId = this.props.id, pageSize = 50, last }: GetDeviceEventsParameters = {}) {
        this.props.dispatch(getDeviceEventsForDevice({ deviceId, pageSize, last, query: this.state.query }));
        this.setState({
            loading: true,
        });
    }

    handleRefresh() {
        this.setState({
            data: [],
            loading: true,
            filter: {} as DeviceEventDetailFilter,
            info: {} as ListResponse<DeviceEvent>,
        });
        this.getData();
    }

    handleFilterChange(filter: DeviceEventDetailFilter) {
        const query = filter && filter.type ? `event_type_description__eq=${filter.type.toUpperCase()}` : undefined;
        this.props.dispatch(
            getDeviceEventsForDevice({
                deviceId: this.props.id,
                query,
            })
        );
        this.setState({
            loading: true,
            filter,
        });
    }

    handleResetPartialList() {
        this.setState({ query: "" }, this.handleRefresh);
    }

    render() {
        const { id } = this.props;
        const { data, filter, info, loading } = this.state;

        const filterIndex = filter?.index ?? 0;
        const gridData =
            filterIndex > 0
                ? data.filter(
                      (event) =>
                          event.event_type_description && event.event_type_description.toLowerCase() === filter.type
                  )
                : data;

        return (
            <DeviceEventDetailComponent
                id={id}
                gridData={gridData}
                loading={loading}
                onRefresh={this.handleRefresh}
                onGridLoadMore={this.handleLoadMoreItems}
                gridInfo={filterIndex > 0 ? ({} as ListResponse<DeviceEvent>) : info}
                filterIndex={filterIndex}
                onFilterChanged={this.handleFilterChange}
                onResetPartialList={this.state.query !== "" ? this.handleResetPartialList : undefined}
            />
        );
    }
}

// map Redux store state to React props
export const mapStateToProps = (state: AppState) => {
    return {
        config: state.config,
        deviceLog: state.deviceEventsForDevice,
    };
};

export default withRouter(connect(mapStateToProps)(DeviceEventDetail));
