import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import NotFound from "../layout/404";
import ErrorBoundary from "../layout/errorBoundary";
import { importLazy, LazilyLoadFactory } from "../layout/lazilyload";
import { PropagatedProps } from "../layout/main/component";
import { asyncRender } from "./BaseRoute";

export interface BillingRouteProps extends RouteComponentProps {
    MyBilling: React.ComponentType<PropagatedProps>;
    ServicePackageHistory: React.ComponentType<PropagatedProps>;
    UsageReport: React.ComponentType<PropagatedProps>;
    UsageReportDetails: React.ComponentType<PropagatedProps>;
    componentProps: PropagatedProps;
}

export const BillingRoute: React.FunctionComponent<BillingRouteProps> = (props) => {
    const { componentProps, UsageReport, UsageReportDetails, MyBilling, ServicePackageHistory } = props;
    const asyncRenderWithProps = asyncRender(componentProps);

    return (
        <ErrorBoundary>
            <Switch>
                <Route path="/billing/me" render={asyncRenderWithProps(MyBilling, "myBilling")} />
                <Route
                    path="/billing/history"
                    render={asyncRenderWithProps(ServicePackageHistory, "servicePackageHistory")}
                />
                <Route path="/billing/usage/list" render={asyncRenderWithProps(UsageReport, "billing")} />
                <Route
                    path="/billing/usage/:month/:id"
                    render={asyncRenderWithProps(UsageReportDetails, "billingDetails")}
                />
                <Redirect exact from="/billing/usage" to="/billing/usage/list" />
                <Redirect exact from="/billing" to="/billing/me" />
                <Route component={NotFound} />
            </Switch>
        </ErrorBoundary>
    );
};

export default LazilyLoadFactory(withRouter(BillingRoute), {
    MyBilling: () => importLazy(import("../features/billing/container")),
    ServicePackageHistory: () => importLazy(import("../features/billing/servicePackageHistory/container")),
    UsageReport: () => importLazy(import("../features/billing/usage/container")),
    UsageReportDetails: () => importLazy(import("../features/billing/usage/detail/container")),
});
