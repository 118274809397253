import { AUTH_TOKEN_STORAGE_KEY } from "portal-components";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { Response } from "superagent";
import { Config } from "../typings/interfaces";
import history from "../script/history";
import { loadCookie } from "../script/utility";
import { AjaxCreator, AjaxCreatorOptions, ErrorAction, makeAjaxCreator, ParseFunction } from "./AjaxCreator";
import CalloutCreator from "./CalloutCreator";

const UNAUTHORIZED = 401;
const NOT_FOUND = 404;
const newLogin = window.appConfig.featureToggle ? window.appConfig.featureToggle.newLogin : false;

export const addAuth = (headers: { [header: string]: string } | undefined) => {
    let token = loadCookie("token");
    if (newLogin) {
        token = JSON.parse(window.sessionStorage.getItem(AUTH_TOKEN_STORAGE_KEY) || "{}").token;
    }

    return {
        ...(headers || {}),
        Authorization: `Bearer ${token}`,
    };
};

export type ApiCreatorOptions<D> = Omit<AjaxCreatorOptions<D>, "base"> & {
    errorRedirect?: boolean | (() => void);
    useAuth?: boolean;
};

export const logoutAction = (
    response: Response,
    errorAction: ErrorAction | undefined,
    errorRedirect: boolean | (() => void) = true
): ThunkAction<void, unknown, never, Action> => {
    return (dispatch) => {
        const responseCode = response.status;

        if (responseCode === UNAUTHORIZED) {
            // WARNING: Added as patch to certificate creation reauth errors;
            // To be removed when API is changed
            if (
                response.body.type === "reauth_required" ||
                response.body.message === "Reauthentication required for administrative actions"
            ) {
                dispatch({
                    type: "REAUTH_REQUEST",
                    data: {},
                });
            } else if (errorRedirect) {
                // Unauthorized error, which means the token has probably timed out on the server.
                // In this case, IAM will delete the token so we don't need to POST logout request to the server.
                // Just dispatch a LOGOUT_REQUEST action and redirect to login page
                dispatch({
                    type: "LOGOUT_REQUEST",
                    data: {
                        overlayMessage: {
                            description:
                                "You have been logged out due to inactivity. Please log in again to resume your session.",
                        },
                    },
                });
                // Clear all callouts when calling logout action, to prevent irrelevant callout errors being shown after logging in
                dispatch({ type: CalloutCreator.actions.clearCallout });
            }
        }

        if (responseCode === NOT_FOUND && errorRedirect) {
            // Not found error, which we handle by redirecting the user to the dedicated 404 page (unless otherwise specified).
            if (typeof errorRedirect === "function") {
                errorRedirect();
            } else {
                history.push("/404");
            }
        }

        if (typeof errorAction === "function") {
            errorAction(response);
        }
    };
};

export type APICreator<A, D> = Omit<AjaxCreator<A, D>, "createThunk"> & {
    createThunk: (options: ApiCreatorOptions<D>) => ThunkAction<Promise<void>, { config: Config }, undefined, Action>;
};

/**
 * Replacement for APICreator.
 * Extends makeAjaxCreator by reading the base URL out of the config and adding the Authorization header.
 * @param getBase Given a config, return the base URL for this creator
 * @param defaults Optional defaults to override AjaxCreator's defaults
 */
export const makeApiCreator = <A, D = undefined>(
    getBase: (config: Config) => string,
    defaults?: Partial<ApiCreatorOptions<D>>
) => (type: string, parseFunction?: ParseFunction<A, D>): APICreator<A, D> => {
    const ajaxCreator = makeAjaxCreator<A, D>(type, parseFunction);

    const createThunk = (
        inputOptions: ApiCreatorOptions<D>
    ): ThunkAction<Promise<void>, { config: Config }, undefined, Action> => (dispatch, getState) => {
        const { config } = getState();
        const options = { ...defaults, ...inputOptions };
        const { errorRedirect, headers, useAuth = true, ...baseOptions } = options;

        const ajaxCreatorOptions: AjaxCreatorOptions<D> = {
            ...baseOptions,
            base: getBase(config),
            headers: useAuth ? addAuth(headers) : headers,
            errorAction: (response) => {
                return logoutAction(response, baseOptions.errorAction, errorRedirect);
            },
        };

        return dispatch(ajaxCreator.createThunk(ajaxCreatorOptions));
    };

    return { ...ajaxCreator, createThunk };
};

/**
 * Shorthand for makeApiCreator where the base URL is in the "api" part of the config.
 */
export const makeConfigApiCreator = <A, D = undefined>(
    baseKey: keyof Config["api"],
    defaults?: ApiCreatorOptions<D>
): ((type: string, parseFunction?: ParseFunction<A, D>) => APICreator<A, D>) =>
    makeApiCreator(({ api }: Config) => api[baseKey], defaults);
