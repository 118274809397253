import { Icons, LinkButton } from "portal-components";
import React from "react";
import translate from "../i18n/translate";

const defaultStrings = {
    documentation: "Documentation",
};

interface DocumentationLinkProps {
    strings?: typeof defaultStrings;
    to?: string;
}

export const DocumentationLink: React.FunctionComponent<DocumentationLinkProps> = (props) => {
    const { to, strings = defaultStrings } = props;
    return to ? <LinkButton icon={Icons.Documentation} text={strings.documentation} to={to} /> : null;
};

DocumentationLink.defaultProps = {
    strings: defaultStrings,
};

export default translate("DocumentationLink")(DocumentationLink);
