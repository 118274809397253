import React from "react";
import LogsFullTableComponent from "./logsFullTable";
import LogsSimpleTableComponent from "./logsSimpleTable";
import { GatewayLog, GatewayLogsFilterOption } from "./creator";
import { ListResponse } from "../../../../script";
import { ValueEvent } from "portal-components";

interface GatewayLogsComponentProps {
    id?: string;
    onGridLoadMore: () => void;
    logs: GatewayLog[];
    info: ListResponse<GatewayLog>;
    loading: boolean;
    filter?: GatewayLogsFilterOption;
    filterOptions: GatewayLogsFilterOption[];
    onFilterChanged: (filter: ValueEvent<string>) => void;
    onRefresh: () => void;
}

export const GatewayLogsComponent = (props: GatewayLogsComponentProps) => {
    const { id } = props;
    return id ? <LogsFullTableComponent {...props} id={id} /> : <LogsSimpleTableComponent {...props} />;
};

GatewayLogsComponent.displayName = "GatewayLogsComponent";

export default GatewayLogsComponent;
