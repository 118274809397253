import { Strings, Theme } from "portal-components";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Config, UserStatus } from "../../typings/interfaces";
import { resolveErrorMessage } from "../../controls/errorHandler";
import {
    AjaxState,
    ErrorAndUrl,
    isLoading,
    onGroupStateLoaded,
    onStateChange,
    SuccessState,
} from "../../creators/AjaxCreator";
import translate, { DefaultStrings } from "../../i18n/translate";
import { AuthAccount, AuthState, LoginState } from "../../layout/reducer";
import { ListResponse } from "../../script";
import { AppDispatch, AppState } from "../../creators/reducers";
import { redirectTo } from "../../script/routing";
import { AccountStatus, convertSearchToQuery, formValidate, getFeatureToggle, loadCookie } from "../../script/utility";
import { CurrentAccount, CurrentUser, getAccount, getUser } from "../account";
import AccountExpired from "./accountExpiredComponent";
import LoginComponent, { LoginStep } from "./component";
import { firstLogin, getLoginCookie, LAST_LOGIN_COOKIE, login, setLoginCookie } from "./creator";
import { TEAM_STORAGE_KEY } from "./federated/auth/container";
import { IdentityProvider, identityProviderAction } from "./federated/creator";
import { logout } from "./logout/creator";
import { OidcErrorComponent } from "./oidcErrorComponent";
import { LoginCredentials } from "./team/api";
import UserInactive from "./userInactiveComponent";
import UserSuspendedComponent from "./userSuspendedComponent";
import { setStyling } from "../../script/branding";

const { INACTIVE, SUSPENDED, EXPIRED } = AccountStatus;

const { SELECT_TEAM } = LoginState;

const DEFAULT_MBED_IDP_ISSUER = "https://test-mbed.eu.auth0.com/";

const defaultStrings = {
    login: "Login",
    mainTitle: "Izuma Device Management Portal",
    mainTitleOnPrem: "Device Management Portal",
    passwordReset: "Your password has been reset. Please log in again to resume your session.",
    signedInRedirecting: "Logged in. Redirecting ...",
    signingIn: "Logging in ...",
    stateParameterMismatch: "State parameter did not match",
    authenticationCodeMissing: "Authentication code is missing.",
    authenticationCodeIncorrect: "Authentication code is incorrect.",
    captchaIsIncorrect: "Captcha is incorrect.",
};

export interface LoginProps extends RouteComponentProps {
    strings: DefaultStrings<typeof defaultStrings>;
    auth: AuthState;
    config: Config;
    currentAccount: AjaxState<CurrentAccount>;
    currentUser: AjaxState<CurrentUser>;
    identityProvider: AjaxState<ListResponse<IdentityProvider>>;
    dispatch: AppDispatch;
}

interface State {
    message: string;
    errorMessage: JSX.Element | string;
    headerFromCookie?: string;
    credentials: LoginCredentials;
    identityProviders: IdentityProvider[];
    isCaptcha: boolean;
    step: unknown;
    submitting: boolean;
    suspended: boolean;
    suspendedMessage: string;
    lastLogin: { time?: string; cloud?: boolean };
    loginAttempts: number;
    defaultIdentityProvider: { type: string };
    accounts?: AuthAccount[];
}

export class Login extends React.PureComponent<LoginProps, State> {
    public static readonly defaultProps = {
        strings: defaultStrings,
    };

    private initialLoad: { currentUser: boolean; currentAccount: boolean };

    constructor(props: LoginProps) {
        super(props);
        const loginCookie = getLoginCookie() || {};
        const { time, issuer: lastLoginIssuer } = loadCookie(LAST_LOGIN_COOKIE) || {};
        const queryParameters = convertSearchToQuery(props.location.search);
        const account = sessionStorage.getItem(TEAM_STORAGE_KEY) ?? "";

        // Choose OIDC flow if we have an issuer and either a code or error from the IdP
        const credentials =
            loginCookie.issuer && queryParameters.code
                ? { account, code: queryParameters.code, issuer: loginCookie.issuer, state: queryParameters.state }
                : { username: loginCookie.username };

        this.state = {
            message: (props.location.state as string) || "",
            errorMessage: "",
            headerFromCookie: loginCookie.message,
            credentials,
            identityProviders: [],
            isCaptcha: false,
            step: LoginStep.INITIAL_LOGIN,
            submitting: false,
            suspended: false,
            suspendedMessage: "",
            lastLogin: { time, cloud: !lastLoginIssuer },
            loginAttempts: 0,
            defaultIdentityProvider: { type: "NATIVE" },
        };

        this.initialLoad = {
            currentUser: props.auth.isAuthenticated,
            currentAccount: props.auth.isAuthenticated,
        };

        // Bind this to contextual functions
        this.doRedirect = this.doRedirect.bind(this);
        this.isLoggingIn = this.isLoggingIn.bind(this);
        this.isReceivedInitialToken = this.isReceivedInitialToken.bind(this);
        this.mfaNeeded = this.mfaNeeded.bind(this);
        this.handleErrorBackClick = this.handleErrorBackClick.bind(this);
        this.handleInitialLoginSubmit = this.handleInitialLoginSubmit.bind(this);
        this.handleOtpSubmit = this.handleOtpSubmit.bind(this);
        this.handleTeamSelect = this.handleTeamSelect.bind(this);
    }

    componentDidMount() {
        const { auth, dispatch, location, config } = this.props;
        const { credentials } = this.state;
        dispatch(identityProviderAction());

        // Fix an issue where we logout with a dark theme, we need to set it up to be 'light'.
        const { defaultTheme } = config;
        if (defaultTheme === Theme.Dark) {
            setStyling({}, true);
        }

        // check if we have a next query parameter after re-mounting the component
        // this can happen once we have selected a team
        const next = convertSearchToQuery(location.search)?.next ?? "/";

        if (credentials.issuer) {
            if (credentials.code) {
                // Log in to IAM immediately if we are using OIDC and have successfully logged in to the IdP.
                setLoginCookie({ ...getLoginCookie(), issuer: credentials.issuer });
                dispatch(login(credentials));
            } else {
                redirectTo({ path: next, replace: true });
            }
        } else if (auth.isAuthenticated) {
            dispatch(getUser);
            dispatch(getAccount);

            if (!this.mfaNeeded() && auth.userStatus === UserStatus.Active) {
                // The user directly accessed /#/login whilst authenticated, which doesn't make
                // sense - so let's just redirect to the dashboard.
                redirectTo({ path: next, replace: true });
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: LoginProps) {
        const { auth, currentAccount, currentUser, dispatch, identityProvider, strings } = this.props;

        if (nextProps.auth.state === SELECT_TEAM && this.props.auth.state !== nextProps.auth.state) {
            const { accounts } = nextProps.auth.info ?? { accounts: [] };
            const selections = accounts
                .filter((acct) => acct.alias !== "/")
                .sort((a, b) => {
                    const aComp = a.alias || a.display_name || a.id;
                    const bComp = b.alias || b.display_name || b.id;
                    return aComp.localeCompare(bComp);
                });
            const singleTeam = selections.length === 1;
            this.setState({
                accounts: selections,
                step: !singleTeam ? LoginStep.TEAM_SELECTION : this.state.step,
                message: "",
                errorMessage: "",
                submitting: singleTeam,
            });
            if (singleTeam) {
                this.handleTeamSelect(selections[0].id);
            }
            return;
        }

        // First stage: we've received some authentication details and we've dispatched the request
        if (this.isLoggingIn(nextProps)) {
            this.setState({ message: strings.signingIn, submitting: true });
            return;
        }

        // Second stage: We've received a token from the auth service, we now get user STATUS
        if (this.isReceivedInitialToken(nextProps)) {
            this.setState({ message: strings.signedInRedirecting });
            dispatch(getUser);
            dispatch(getAccount);
            return;
        }

        onStateChange(identityProvider, nextProps.identityProvider, {
            loaded: ({ data = { data: [] } }) => {
                const autoLogin = getFeatureToggle("autoLogin", nextProps);
                const identityProviders = data.data;
                const defaultIdentityProvider = identityProviders.find((f) => f.is_default) || { type: "NATIVE" };
                if (
                    !autoLogin ||
                    nextProps.auth.isLoggedOut ||
                    defaultIdentityProvider.type === "NATIVE" ||
                    !defaultIdentityProvider.issuer
                ) {
                    this.setState({ identityProviders: data.data, defaultIdentityProvider });
                } else {
                    redirectTo({
                        path: `/federated-login?issuer=${defaultIdentityProvider.issuer}`,
                        replace: true,
                    });
                }
            },
        });

        // Final stage: We've received the token, we can now redirect the user
        // to the dashboard.
        onGroupStateLoaded([currentAccount, currentUser], [nextProps.currentAccount, nextProps.currentUser], () => {
            // For effect, we just put half a second timeout on the redirect.
            if (nextProps.auth.userStatus === UserStatus.Active) {
                const next = convertSearchToQuery(nextProps.location.search)?.next ?? "/";
                this.doRedirect(next, true);
                return;
            }

            if (nextProps.auth.userStatus === UserStatus.Reset) {
                dispatch(firstLogin(this.state.credentials.password ?? ""));
                this.doRedirect("/welcome", true);
                return;
            }
        });

        // Capture errors: Check if two-factor Authentication code is needed
        if (
            this.state.submitting &&
            auth.isAuthenticating !== nextProps.auth.isAuthenticating &&
            nextProps.auth.authErrorMessage
        ) {
            this.setState({
                submitting: false,
                message: "",
                errorMessage: nextProps.auth.authErrorMessage,
            });
            // Get the error response
            const { fields, message } = (nextProps?.auth?.info?.response?.body as {
                fields: { name: string; message: string }[];
                message: string;
            }) ?? {
                fields: [],
                message: "",
            };

            // Parse the error type
            if (fields) {
                if (
                    fields.find((field) => {
                        return field.name === "account_status" && field.message === SUSPENDED;
                    })
                ) {
                    const reason = fields.find((field) => {
                        return field.name === "reason";
                    });
                    const suspendedMessage = (reason && reason.message) ?? "";
                    this.setState({ suspended: true, suspendedMessage });
                } else if (
                    fields.find((field) => {
                        return field.name === "status" && field.message === INACTIVE;
                    })
                ) {
                    this.setState({ errorMessage: <UserInactive /> });
                } else if (
                    fields.find((field) => {
                        return field.name === "account_status" && field.message === EXPIRED;
                    })
                ) {
                    this.setState({
                        errorMessage: <AccountExpired config={this.props.config} />,
                    });
                } else {
                    const otpField = fields.find((field) => field.name === "otp");
                    const isOtp = !!otpField;
                    const captchaField = fields.find((field) => field.name === "captcha");
                    const isCaptcha = !!captchaField;

                    // Check if user has two factor activated and take them to the Authentication code page
                    // or if it's a problem with captcha.
                    if (isOtp) {
                        const mapMessage =
                            this.state.step === LoginStep.MFA
                                ? otpField?.message === "null"
                                    ? strings.authenticationCodeMissing
                                    : strings.authenticationCodeIncorrect
                                : "";

                        this.setState({
                            isCaptcha,
                            step: LoginStep.MFA,
                            errorMessage: mapMessage,
                        });
                    } else if (isCaptcha) {
                        this.setState({
                            isCaptcha,
                            step: this.state.step,
                            errorMessage: captchaField?.message !== "null" ? strings.captchaIsIncorrect : message,
                        });
                    } else {
                        this.setState({
                            isCaptcha,
                            step: this.state.step,
                            errorMessage:
                                resolveErrorMessage(nextProps.auth.info ?? {}) ||
                                nextProps.auth.authErrorMessage ||
                                message,
                        });
                    }
                }
            }
        }

        // If the organization has MFA enforced but the user doens't have it turned on,
        // we need to stop the login and step them through the setup
        if (this.mfaNeeded(nextProps)) {
            this.handleSetupMfa();
        }

        onStateChange(currentUser, nextProps.currentUser, {
            failed: (error) => {
                if (!this.initialLoad.currentUser) {
                    const message = (error as ErrorAndUrl)?.response?.body?.message ?? "";
                    this.setState({
                        submitting: false,
                        errorMessage: message ? message.toString() : "",
                    });
                    this.props.dispatch(logout());
                }
            },
        });

        onStateChange(currentAccount, nextProps.currentAccount, {
            failed: (error) => {
                if (!this.initialLoad.currentAccount) {
                    const message = (error as ErrorAndUrl)?.response?.body?.message ?? "";
                    this.setState({
                        submitting: false,
                        errorMessage: message ? message.toString() : "",
                    });
                    this.props.dispatch(logout());
                }
            },
        });

        if (
            this.props.currentUser.requestState !== nextProps.currentUser.requestState &&
            !isLoading(nextProps.currentUser) &&
            this.initialLoad.currentUser
        ) {
            this.initialLoad.currentUser = false;
        }

        if (
            this.props.currentAccount.requestState !== nextProps.currentAccount.requestState &&
            !isLoading(nextProps.currentAccount) &&
            this.initialLoad.currentAccount
        ) {
            this.initialLoad.currentAccount = false;
        }
    }

    isLoggingIn(nextProps: LoginProps) {
        return !this.props.auth.isAuthenticating && nextProps.auth.isAuthenticating;
    }

    isReceivedInitialToken(nextProps: LoginProps) {
        return this.props.auth.isAuthenticating && nextProps.auth.info && nextProps.auth.info.token;
    }

    mfaNeeded(props = this.props) {
        // Check if MFA is enabled for the environment
        const mfaEnabled = getFeatureToggle("mfa", props);
        // Check if organization has two factor enforcement activated but user doens't have it setup
        const mfaEnforced =
            ((props?.currentAccount as SuccessState<CurrentAccount>)?.data?.mfa_status ?? "") === "enforced";
        const mfaConfigured = (props?.currentUser as SuccessState<CurrentUser>)?.data?.is_totp_enabled ?? false;
        const authenticated = props?.auth?.isAuthenticated ?? false;
        return mfaEnabled && authenticated && mfaEnforced && !mfaConfigured;
    }

    doRedirect(path: string, replace = false, query = {}) {
        const callback = () => this.handleErrorBackClick();
        return redirectTo({ path, replace, cb: callback, query });
    }

    handleOtpSubmit({ otp, captcha_id, captcha_answer }: { otp: string; captcha_id: string; captcha_answer: string }) {
        const credentials = {
            ...this.state.credentials,
            captcha: captcha_answer,
            captcha_id,
            otp: formValidate(otp, "otp") as string,
        };
        this.setState({ credentials, isCaptcha: false, submitting: true });
        this.props.dispatch(login(credentials));
    }

    handleInitialLoginSubmit({
        email,
        password,
        captcha_id,
        captcha_answer,
    }: {
        email: string;
        password: string;
        captcha_id: string;
        captcha_answer: string;
    }) {
        // Completely reset credentials on initial login - changing username should change account ID
        const credentials = {
            captcha_id,
            captcha: captcha_answer,
            username: email.trim(),
            password,
        };
        this.setState(
            { credentials, isCaptcha: false, submitting: true, loginAttempts: this.state.loginAttempts + 1 },
            () => {
                setLoginCookie(credentials);
                this.props.dispatch(login(credentials));
            }
        );
    }

    handleTeamSelect(accountId: string) {
        // Remove any captcha data from credentials - they have been used already by this point
        const { username, issuer, code, password, otp } = this.state.credentials;
        const credentials = {
            issuer,
            code,
            username,
            password,
            otp,
            account: accountId,
        };
        this.setState({ credentials, submitting: true });
        this.props.dispatch(login(credentials));
    }

    handleSetupMfa() {
        this.doRedirect("/login-mfasetup", true);
    }

    handleErrorBackClick() {
        // Reset state to try logging in again
        this.setState({
            credentials: {},
            message: "",
            errorMessage: "",
            step: LoginStep.INITIAL_LOGIN,
            submitting: false,
        });
    }

    render() {
        const { auth, config, location, strings } = this.props;
        const { externalLinks: { supportEmailAddress } = {} } = config;
        const {
            accounts,
            credentials,
            headerFromCookie,
            isCaptcha,
            identityProviders,
            step,
            message,
            submitting,
            suspended,
            suspendedMessage,
            lastLogin,
            errorMessage,
            loginAttempts,
            defaultIdentityProvider,
        } = this.state;

        const oidcError = credentials.issuer && step === LoginStep.INITIAL_LOGIN && errorMessage;

        if (suspended) {
            return (
                <UserSuspendedComponent
                    suspendedMessage={suspendedMessage}
                    supportEmailAddress={supportEmailAddress ?? ""}
                />
            );
        } else if (!submitting && oidcError) {
            // Render error message for non-recoverable OIDC errors
            return <OidcErrorComponent message={oidcError} onBackClick={this.handleErrorBackClick} />;
        } else {
            const { next, passwordReset } = Strings.convertSearchStringToObject(location.search);

            const showNativeLogin =
                (lastLogin && lastLogin.cloud && !config.mbedReferred && defaultIdentityProvider.type === "NATIVE") ??
                false;

            const loginHeader =
                passwordReset === "true"
                    ? strings.passwordReset
                    : auth.info && auth.info.overlayMessage
                    ? auth.info.overlayMessage
                    : headerFromCookie ?? "";

            return (
                <LoginComponent
                    accounts={accounts}
                    message={message}
                    loginHeader={loginHeader}
                    initialCredentials={credentials}
                    identityProviders={identityProviders}
                    next={next || "/"}
                    onInitialLogin={this.handleInitialLoginSubmit}
                    onOtpSubmit={this.handleOtpSubmit}
                    onTeamSelect={this.handleTeamSelect}
                    submitting={submitting}
                    isCaptcha={isCaptcha}
                    step={step}
                    isOnPrem={config.onPremises}
                    lastLogin={lastLogin}
                    showNativeLogin={showNativeLogin}
                    errorMessage={errorMessage}
                    loginAttempts={loginAttempts}
                />
            );
        }
    }
}

// map Redux store state to React props
function mapStateToProps(state: AppState) {
    return {
        auth: state.auth,
        config: state.config,
        currentAccount: state.currentaccount,
        currentUser: state.currentuser,
        identityProvider: state.identityProvider,
        routing: state.routing,
    };
}

export default connect(mapStateToProps)(translate("Login")(Login));
export { DEFAULT_MBED_IDP_ISSUER };
