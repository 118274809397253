import { Account, AccountRepository, AccountUpdateRequest, Config } from "mbed-cloud-sdk";
import { Objects } from "portal-components";
import { sdkCreator } from "../../../creators/SdkCreator";
import { makeConfigApiCreator } from "../../../creators";

let repository: AccountRepository;

const getRepository = (sdkConfig: Config) => {
    if (!repository) {
        repository = new AccountRepository(sdkConfig);
    }

    return repository;
};

// Convert user custom prop values to an object (comes as a string)
export const parseCustomFields = (customFields: { [key: string]: string } | undefined): { [key: string]: string } => {
    return Object.entries(customFields || {}).reduce((acc, [key, value]) => {
        let parsed: string;
        try {
            parsed = JSON.parse(value);
        } catch (err) {
            parsed = value;
        }
        return { ...acc, [key]: parsed };
    }, {});
};

const { reducer: accountReducer, createThunk: createAccountThunk } = sdkCreator<{}, Account>("CURRENT_ACCOUNT", {
    sdkMethod: (_input = {}, sdkConfig) => getRepository(sdkConfig).me({ include: "limits,policies,sub_accounts" }),
    parseFunction: (response) => {
        const { customFields } = response;
        return { ...response, customFields: parseCustomFields(customFields) };
    },
});

const getAccount = () => createAccountThunk({});

const {
    reducer: updateAccountReducer,
    createThunk: updateAccountThunk,
    createResetAction: updateAccountResetThunk,
} = sdkCreator("IAM_UPDATE_CURRENT_ACCOUNT", {
    sdkMethod: (request: { id: string; data: AccountUpdateRequest }, sdkConfig) => {
        return getRepository(sdkConfig).update(request.data, request.id);
    },
});

// `data` is declared as `AccountUpdateRequest` (which contains only the fields that we actually
// need/should update) but the object itself comes from "accounts/me" and it contains much more stuff.
// It's not a problem but the minimum length for the alias name changed in IAM and if we send an "old"
// and short alias name we now get an error. Skipping this property we can happily update the account.
const updateAccount = (id: string, data: AccountUpdateRequest) =>
    updateAccountThunk({ id, data: Objects.omit(["aliases"], data) });

const updateAccountReset = () => updateAccountResetThunk();

// delete from account
const { reducer: leaveTeam, createThunk: leaveTeamThunk } = makeConfigApiCreator<void>("v3_base")("team_leave");

const removeMeFromAccount = () => leaveTeamThunk({ param: "users/me/remove", method: "POST" });

export {
    accountReducer,
    getAccount,
    updateAccountReducer,
    updateAccount,
    updateAccountReset,
    leaveTeam,
    removeMeFromAccount,
};
