import { Icon, Icons, Span, Tooltip } from "portal-components";
import React from "react";
import { Link } from "react-router-dom";
import { CellContext } from "../../../controls/grid/cellContext";
import translate, { DefaultStrings } from "../../../i18n/translate";
import { DeviceEvent } from "../detail/events/creator";

const defaultStrings = {
    campaign: "Campaign: {0}",
    certificates: "certificates",
    filter: "filter",
};

interface EventLinkComponentProps {
    strings: DefaultStrings<typeof defaultStrings>;
    event: DeviceEvent;
}

export class EventLink extends React.PureComponent<EventLinkComponentProps> {
    public static defaultProps = {
        strings: defaultStrings,
    };

    render() {
        const { event: { data: { campaign_id, campaign_name } = {} } = {}, strings } = this.props;
        const links = [];
        if (campaign_id) {
            links.push(
                <div key={campaign_id}>
                    <Link to={"/firmware/deployments/{0}".format(campaign_id)}>
                        <Span
                            text={
                                campaign_name
                                    ? strings.campaign.format(campaign_name)
                                    : strings.campaign.format(campaign_id)
                            }
                            id={campaign_id}
                        />
                    </Link>
                </div>
            );
        }

        const content =
            links.length > 1 ? (
                <div className="text-center">
                    <Tooltip content={<Icon name={Icons.MoreOptionsHorizontalSymbol} className="clickable" />}>
                        {links}
                    </Tooltip>
                </div>
            ) : (
                links[0] || null
            );

        return (
            <CellContext.Consumer>
                {(context) => {
                    if (context && context.key && context.row && campaign_name) {
                        context.row[context.key] = `campaign_name ${campaign_id}`;
                    }

                    return content;
                }}
            </CellContext.Consumer>
        );
    }
}

export default translate("EventLink")(EventLink);
