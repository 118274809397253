import { Icon, Icons } from "portal-components";
import React from "react";
import ErrorHandler from "../controls/errorHandler";
import { CalloutState, CalloutBodyType, CalloutStateBody } from "../creators/CalloutCreator";

export enum CalloutSeverity {
    alert = "alert",
    info = "info",
    success = "success",
    warning = "warning",
}

export interface CalloutComponentProps {
    callout?: CalloutState;
    onChanged?: () => boolean | undefined;
}

interface State {
    visible: boolean;
}

export default class CalloutComponent extends React.PureComponent<CalloutComponentProps, State> {
    public static readonly severities = {
        alert: CalloutSeverity.alert,
        info: CalloutSeverity.info,
        success: CalloutSeverity.success,
        warning: CalloutSeverity.warning,
    };

    constructor(props: CalloutComponentProps) {
        super(props);
        this.state = { visible: true };
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidUpdate(prevProps: Partial<CalloutComponentProps>) {
        const isHeaderChanged = prevProps.callout?.header !== this.props.callout?.header;
        const isBodyChanged = prevProps.callout?.body !== this.props.callout?.body;
        if (isHeaderChanged || isBodyChanged) {
            this.setState({ visible: this.props.onChanged?.() ?? true });
        }
    }

    handleClose() {
        this.setState({ visible: false });
    }

    parseBody(body: CalloutBodyType | null) {
        if (Array.isArray(body)) {
            const arrayOfResponses = body as CalloutStateBody[];
            if (arrayOfResponses.find((elem) => elem.isError)) {
                return arrayOfResponses
                    .filter((elem) => elem.isError)
                    .map((elem, index) => (
                        <ErrorHandler
                            key={`errorCompo${index}`}
                            responseBody={elem.response ?? undefined}
                            message={elem.message}
                        />
                    ));
            }
        }

        return Array.isArray(body)
            ? (body as React.ReactNodeArray).map((text, index) => <div key={`message-${index}`}>{text}</div>)
            : body;
    }

    render() {
        if (!this.state.visible || this.props.callout === undefined) {
            return null;
        }

        const { severity, body, header } = this.props.callout;
        if (!(severity && (body || header))) {
            return false;
        }
        if (!(severity in CalloutComponent.severities)) {
            return false;
        }
        return (
            <div className={`callout ${severity}`} id={`callout-${severity}`}>
                <h5>{header}</h5>
                <div>{this.parseBody(body)}</div>
                <button
                    id="dismiss-callout-button"
                    className="close-button"
                    aria-label="Dismiss alert"
                    type="button"
                    data-close
                    onClick={this.handleClose}
                >
                    <Icon name={Icons.Close} />
                </button>
            </div>
        );
    }
}
