import {
    ButtonSize,
    DateTime,
    DateTimeOutputFormat,
    DateTimePicker,
    DateTimePickerVariant,
    ValueEvent,
} from "portal-components";
import React from "react";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const classes = require("./style.scss");

export interface DateTimeFilterProps {
    label: string;
    value?: Date;
    minimum?: Date;
    maximum?: Date;
    reference?: Date;
    autoclose?: boolean;
    timeFormat?: boolean;
    dateFormat?: DateTimeOutputFormat;
    onChange: (event: ValueEvent<Date | undefined>) => void;
}

export class DateTimeFilter extends React.PureComponent<DateTimeFilterProps> {
    constructor(props: Readonly<DateTimeFilterProps>) {
        super(props);

        this.isValid = this.isValid.bind(this);

        this.state = {
            value: props.value,
        };
    }

    public componentDidUpdate(prevProps: Readonly<DateTimeFilterProps>) {
        // When range changes and we already have a value we need to go back to a valid state if
        // the current value is outside the current range. This works well when range properties are updated
        // in one batch, if there is a delay then we might reset a valid value.
        const isRangeChanged = this.props.minimum !== prevProps.minimum || this.props.maximum !== prevProps.maximum;
        if (isRangeChanged && this.props.value && !this.isValid(this.props.value)) {
            this.props.onChange({ value: undefined });
        }
    }

    public render() {
        const { label, value } = this.props;

        return (
            <DateTimePicker
                text={value === undefined ? label : `${label}: ${this.formatDate()}`}
                variant={DateTimePickerVariant.Control}
                size={ButtonSize.Small}
                className={classes.dateTimeFilter}
                timeFormat={this.props.timeFormat === undefined ? !!value : this.props.timeFormat && !!value}
                value={value}
                onValidate={(e) => this.isValid(e.value)}
                onChange={this.props.onChange}
            />
        );
    }

    private formatDate() {
        const { value, reference, dateFormat } = this.props;

        if (value === undefined) {
            return "";
        }
        if (dateFormat) {
            return DateTime.format(value, dateFormat);
        } else {
            const isSameDayAsReference = value && reference && value.toDateString() === reference.toDateString();
            return DateTime.format(
                value,
                isSameDayAsReference ? DateTimeOutputFormat.Time : DateTimeOutputFormat.DateTime
            );
        }
    }

    private isValid(value: Date | undefined) {
        function datePart(date?: Date) {
            if (date === undefined) {
                return undefined;
            }

            const result = new Date(date);
            result.setHours(0, 0, 0, 0);

            return result;
        }

        // Note that we ignore the time part
        const minimum = datePart(this.props.minimum);
        const maximum = datePart(this.props.maximum);
        const adjustedValue = datePart(value);

        if (adjustedValue !== undefined) {
            if (minimum !== undefined && adjustedValue < minimum) {
                return false;
            }

            if (maximum !== undefined && adjustedValue > maximum) {
                return false;
            }
        }

        return true;
    }
}
