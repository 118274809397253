import { Arrays, GroupPanel, LinkButton, Paragraph, Text, Toolbar } from "portal-components";
import React from "react";
import Markup from "../../controls/markup";
import translate, { DefaultStrings } from "../../i18n/translate";

const defaultStrings = {
    onlineHeader: "Online",
    submitTicket: "Use our online support system to make a request, report an issue, or track your open tickets:",
    submitTicketLink: "Request support",
    phoneHeader: "Phone",
    phoneDescription: "We do not provide phone support right now, contact us via online or email (see below).",
    emailHeader: "Email",
    emailLink: "You can email us at [{0}](mailto:{0})",
    phoneLocal: "Local",
    phoneLongDistance: "International",
};

export interface TenantProps {
    strings: DefaultStrings<typeof defaultStrings>;
    supportLink: string;
    supportEmailAddress: string;
    phoneNumbers: any[];
}

export const Tenant: React.FunctionComponent<TenantProps> = (props) => {
    const { strings, supportLink, supportEmailAddress, phoneNumbers } = props;

    const phoneNumberList = [];
    const phoneNumbersByCountry = Arrays.groupBy(phoneNumbers, (x) => x.iso3166CountryCode);

    // tslint:disable-next-line:forin
    for (const countryCode in phoneNumbersByCountry) {
        const countryName = countryCode; //have to use country code until we sort out export of translated strings

        const perCountryPhoneNumberList = [];
        for (const phoneNumber of (phoneNumbersByCountry as any)[countryCode]) {
            const label = phoneNumber.local ? strings.phoneLocal.format(countryCode) : strings.phoneLongDistance;
            const callableNumber = phoneNumber.number
                .replace("(0)", "")
                .match(/(^\+)?\d+/gi)
                .join("");

            perCountryPhoneNumberList.push(
                <React.Fragment key={`${countryName}-${perCountryPhoneNumberList.length}`}>
                    <Text>
                        <span>{label}:&nbsp;</span>
                        <a href={`tel:${callableNumber}`}>{phoneNumber.number}</a>
                    </Text>
                </React.Fragment>
            );
        }

        phoneNumberList.push(
            <React.Fragment key={countryName}>
                <h3>{countryName}</h3>
                {perCountryPhoneNumberList}
                <br />
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <GroupPanel title={strings.onlineHeader}>
                <div>{strings.submitTicket}</div>
                <br />
                <Toolbar>
                    <LinkButton to={supportLink} text={strings.submitTicketLink} primary />
                </Toolbar>
            </GroupPanel>
            <GroupPanel title={strings.phoneHeader}>
                <Paragraph>{strings.phoneDescription}</Paragraph>
                {phoneNumberList}
            </GroupPanel>
            <GroupPanel title={strings.emailHeader}>
                <Markup string={strings.emailLink.format(supportEmailAddress)} />
            </GroupPanel>
        </React.Fragment>
    );
};

Tenant.defaultProps = {
    strings: defaultStrings,
};

export default translate("Tenant")(Tenant);
