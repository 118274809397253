import React from "react";
import { connect } from "react-redux";
import { AppState } from "../creators/reducers";
import { AuthState } from "../layout/reducer";

export interface ApiReauthMonitorProps {
    auth: AuthState;
    onCancel?: (auth: AuthState) => void;
    onSuccess: (auth: AuthState) => void;
}

export class ApiReauthMonitor extends React.PureComponent<ApiReauthMonitorProps> {
    componentDidUpdate(prevProps: Partial<ApiReauthMonitorProps>) {
        const { auth, onSuccess, onCancel } = this.props;

        if (auth.type !== prevProps.auth?.type && auth.type === "REAUTH_SUCCESS") {
            onSuccess(auth);
        }
        if (auth.type !== prevProps.auth?.type && auth.type === "REAUTH_CANCEL") {
            onCancel?.(auth);
        }
    }

    render() {
        return null;
    }
}

function mapStateToProps(state: AppState) {
    return {
        auth: state.auth,
    };
}

export default connect(mapStateToProps)(ApiReauthMonitor);
