import { SDKError } from "mbed-cloud-sdk";
import { useSelector } from "react-redux";
import { Action, Reducer } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { Response } from "superagent";
import { Config } from "../typings/interfaces";
import { ajax, AjaxOptions } from "../script/ajax";
import { AppState } from "./reducers";
import { evalBoolean, formatErrorResponse, isObject } from "../script/utility";
import CalloutCreator from "./CalloutCreator";

export enum RequestState {
    INITIALIZING = "INITIALIZING",
    LOADING = "LOADING",
    LOADED = "LOADED",
    FAILED = "FAILED",
}

export interface ErrorAndUrl {
    response: Response;
    url: string;
}
export interface FailedState {
    requestState: RequestState.FAILED;
    id: string | undefined;
    error: ErrorAndUrl;
}

export interface SuccessState<A> {
    requestState: RequestState.LOADED;
    id: string | undefined;
    text: string;
    data: A;
}

export interface InitializingState {
    requestState: RequestState.INITIALIZING;
    id: string | undefined;
}

export interface LoadingState {
    requestState: RequestState.LOADING;
    id: string | undefined;
}
/**
 * Redux state type representing one HTTP request.
 */
export type AjaxState<A> = InitializingState | LoadingState | SuccessState<A> | FailedState;

const initialState: AjaxState<unknown> = { requestState: RequestState.INITIALIZING, id: "" };

export interface RequestAction<D> {
    type: string;
    id: string | undefined;
    data: D | undefined;
}
export interface FailureAction {
    type: string;
    id: string | undefined;
    error: ErrorAndUrl;
}
export interface ResetAction {
    type: string;
    id: string | undefined;
}
export interface SuccessAction<D> {
    type: string;
    id: string | undefined;
    text: string;
    info: unknown;
    data: D | undefined;
}

/**
 * Type of redux actions that update the state for one HTTP request.
 */
export type AjaxAction<D> = RequestAction<D> | FailureAction | ResetAction | SuccessAction<D>;

export type ErrorAction = (response: Response) => ThunkAction<void, unknown, never, Action> | void;
export type ParseFunction<A, D = undefined> = (body: unknown, id: string | undefined, requestData?: D) => A;

export type AjaxCreatorOptions<D> = Omit<AjaxOptions, "url"> & {
    base: string;
    param?: string;
    id?: string;
    errorCallout?: boolean | ((param: unknown) => boolean);
    requestData?: D;
    errorAction?: ErrorAction;
    preventCache?: boolean;
};

export interface ActionTypes {
    requestType: string;
    successType: string;
    resetType: string;
    failureType: string;
}

/**
 * The reducer manage the state for one HTTP request. The thunk returned from createThunk starts the HTTP request,
 * building the url out of the base and param options.
 */
export interface AjaxCreator<A, D = undefined> {
    actionTypes: ActionTypes;
    createResetAction: (id?: string) => AjaxAction<D>;
    createThunk: (options: AjaxCreatorOptions<D>) => ThunkAction<Promise<void>, { config: Config }, undefined, Action>;
    reducer: Reducer<AjaxState<A>, Action>;
    prefix: string;
}

/**
 * Replacement for BaseCreator that works with TypeScript. Returns a reducer and a createThunk function.
 * @param type Prefix for the types of actions dispatched by this creator
 * @param parseFunction Parse the response
 */
export const makeAjaxCreator = <A, D = undefined>(
    type: string,
    parseFunction?: ParseFunction<A, D>
): AjaxCreator<A, D> => {
    const prefix = type.toUpperCase();

    const actionTypes = {
        requestType: `${prefix}_REQUEST`,
        successType: `${prefix}_SUCCESS`,
        failureType: `${prefix}_FAILURE`,
        resetType: `${prefix}_RESET`,
    };

    const isRequest = (action: Action): action is RequestAction<D> => action.type === actionTypes.requestType;
    const isSuccess = (action: Action): action is SuccessAction<D> => action.type === actionTypes.successType;
    const isFailure = (action: Action): action is FailureAction => action.type === actionTypes.failureType;
    const isReset = (action: Action): action is ResetAction => action.type === actionTypes.resetType;

    const createThunk = (options: AjaxCreatorOptions<D>) => {
        const {
            id,
            requestData,
            errorCallout: errorCalloutOption = true,
            errorAction,
            base,
            param = "",
            preventCache = false,
            ...ajaxOptions
        } = options;
        let errorCallout = errorCalloutOption;

        return (dispatch: ThunkDispatch<{ config: Config }, never, Action>) => {
            const requestAction: RequestAction<D> = { type: actionTypes.requestType, id, data: requestData };
            dispatch(requestAction);

            const url = `${base}${param}${
                preventCache ? `${!param.includes("?") ? "?" : "&"}_=${Date.now().valueOf()}` : ""
            }`;

            return ajax({ url, ...ajaxOptions, action: type }).then(
                (response) => {
                    const successAction: SuccessAction<D> = {
                        type: actionTypes.successType,
                        info: response.body,
                        text: response.text,
                        data: requestData,
                        id,
                    };

                    dispatch(successAction);
                },
                (error) => {
                    let response = error.response;
                    let errorMsg = error;

                    if (response) {
                        errorMsg = formatErrorResponse(response, null, url);
                    } else {
                        response = { status: 500, body: { message: error } };

                        // If error is an object and it contains a status property
                        // with value "undefined" then we had a local network error,
                        // do not show any error for this because this function
                        // is invoked in background by all AJAX polling requests.
                        // Most common cause in Chrome is ERR_NETWORK_CHANGED but
                        // unfortunately we do not have access to any detail.
                        // Error is also reported in the Console then we can ignore it here.
                        // Note that this does NOT stop error messages generated server-side
                        // to be displayed! Also local connectivity issues when navigating
                        // between pages aren't silenced.
                        if (
                            error !== undefined &&
                            Object.hasOwnProperty.call(error, "status") &&
                            error.status === undefined
                        ) {
                            errorCallout = false;
                        }
                    }

                    // Reauth requests are handled entirely by code, no need to inform the user.
                    if (
                        error !== undefined &&
                        error.status === 401 &&
                        response.body &&
                        response.body.type === "reauth_required"
                    ) {
                        errorCallout = false;
                    }

                    // Dispatch failure type, in order to set the failed state on the request
                    const failureAction: FailureAction = {
                        type: actionTypes.failureType,
                        error: { response: response, url },
                        id,
                    };
                    dispatch(failureAction);

                    if (evalBoolean(errorCallout, response)) {
                        // Dispatch new callout alert
                        dispatch({
                            type: CalloutCreator.actions.newCallout,
                            header: "",
                            body: [{ isError: true, response: response.body, message: errorMsg }],
                            severity: "warning",
                            acked: true,
                            showToast: false,
                        });
                    }

                    // Optionally call errorAction, if defined
                    if (errorAction) {
                        const action = errorAction(response);
                        action && dispatch(action);
                    }
                }
            );
        };
    };

    const reducer = (state: AjaxState<A> = initialState, action: Action): AjaxState<A> => {
        if (isRequest(action)) {
            return { requestState: RequestState.LOADING, id: action.id };
        } else if (isSuccess(action)) {
            return {
                requestState: RequestState.LOADED,
                id: action.id,
                data: parseFunction ? parseFunction(action.info || {}, action.id, action.data) : (action.info as A),
                text: action.text || "",
            };
        } else if (isFailure(action)) {
            return {
                requestState: RequestState.FAILED,
                id: action.id,
                error: action.error,
            };
        } else if (isReset(action)) {
            return { requestState: RequestState.INITIALIZING, id: action.id };
        } else {
            return state;
        }
    };

    const createResetAction = (id?: string) => ({ type: actionTypes.resetType, id });
    return { actionTypes, createThunk, reducer, createResetAction, prefix };
};

export interface StateChangeCallbacks<A> {
    loading?: () => void;
    loaded?: (result: { data: A; text: string }) => void;
    failed?: (error?: string | ErrorAndUrl | SDKError) => void;
    messageParser?: (state: FailedState) => string;
    supportsConcurrentRequests?: boolean;
}

export const onStateChange = <A>(
    propAction: AjaxState<A> = initialState,
    nextPropAction: AjaxState<A> = initialState,
    cb: StateChangeCallbacks<A> = {}
): void => {
    const { loading, loaded, failed, messageParser, supportsConcurrentRequests = false } = cb;

    if (!supportsConcurrentRequests && propAction.requestState === nextPropAction.requestState) {
        return;
    }

    switch (nextPropAction.requestState) {
        case RequestState.LOADING:
            loading && loading();
            return;
        case RequestState.LOADED:
            loaded && loaded({ data: nextPropAction.data, text: nextPropAction.text });
            return;
        case RequestState.FAILED:
            failed &&
                (messageParser
                    ? failed(messageParser(nextPropAction))
                    : failed((nextPropAction.error as unknown) as SDKError));
            return;
    }
};

export const onAnyStateChange = <A>(
    propAction: { [name: string]: AjaxState<A> },
    nextPropAction: { [name: string]: AjaxState<A> },
    cb: StateChangeCallbacks<A> = {}
): void => {
    if (!propAction || !nextPropAction) {
        return;
    }

    for (const key of Object.keys(nextPropAction)) {
        if (!Object.hasOwnProperty.call(propAction, key)) {
            continue;
        }

        onStateChange(propAction[key], nextPropAction[key], cb);
    }
};

export function onGroupStateLoaded<A>(
    propActions: [AjaxState<A>],
    nextPropActions: [AjaxState<A>],
    cb?: (data: A) => void
): void;
export function onGroupStateLoaded<A, B>(
    propActions: [AjaxState<A>, AjaxState<B>],
    nextPropActions: [AjaxState<A>, AjaxState<B>],
    cb?: (data1: A, data2: B) => void
): void;
export function onGroupStateLoaded<A, B, C>(
    propActions: [AjaxState<A>, AjaxState<B>, AjaxState<C>],
    nextPropActions: [AjaxState<A>, AjaxState<B>, AjaxState<C>],
    cb?: (data1: A, data2: B, data3: C) => void
): void;
export function onGroupStateLoaded(
    propActions?: AjaxState<unknown>[],
    nextPropActions?: AjaxState<unknown>[],
    cb?: () => void
): void;
export function onGroupStateLoaded(
    propActions: AjaxState<unknown>[] = [],
    nextPropActions: AjaxState<unknown>[] = [],
    cb = (..._args: unknown[]) => {}
): void {
    // Validate
    const validPropActions = Array.isArray(propActions) && propActions.length > 0;
    const validNextPropActions = Array.isArray(nextPropActions) && nextPropActions.length > 0;
    const valid = validPropActions && validNextPropActions && propActions.length === nextPropActions.length;

    if (!valid) return;

    // Check that at least one action was not loaded previously
    const wasNotLoaded = propActions.some((action) => {
        return isObject(action) && action.requestState !== RequestState.LOADED;
    });

    // Check all actions are now loaded, and collect the results
    const data = nextPropActions.reduce(
        (result: unknown[] | undefined, action) =>
            result
                ? isObject(action) && action.requestState === RequestState.LOADED
                    ? [...result, action.data]
                    : undefined
                : undefined,
        []
    );

    // Execute call back
    wasNotLoaded && data && cb(...data);
}

export const isLoading = (stateObject?: AjaxState<unknown>): stateObject is LoadingState =>
    stateObject?.requestState === RequestState.LOADING;
export const isInitializing = (stateObject?: AjaxState<unknown>): stateObject is InitializingState =>
    stateObject?.requestState === RequestState.INITIALIZING;
export const isLoaded = (stateObject?: AjaxState<unknown>): stateObject is SuccessState<unknown> =>
    stateObject?.requestState === RequestState.LOADED;
export const isFailed = (stateObject?: AjaxState<unknown>): stateObject is FailedState =>
    stateObject?.requestState === RequestState.FAILED;
export const isReauthError = (error?: ErrorAndUrl): boolean => !!(error?.response.body.type === "reauth_required");

/**
 * Selects a reducer from the AppState and process the API calls using the AjaxState different transitions.
 * Works in a similar way as the OnStateChange without the need for callbacks. Implements Redux's useSelector under the hood.
 * @param selector The Redux selector to be passed onto useSelector
 * @param id optional - access the state of a collectionReducer that is nested under an Id
 * @type TSelected The type of object to be returned as data
 * @type TError The type of error, defaults to ErrorAndUrl. API calls will return ErrorAndUrl, SDK calls return SDKError
 * @returns { result { data: TSelected | null, loading: boolean, failed: boolean, error: string } The current result of the selector
 */
export const useAjaxStateSelector = <TSelected, TError extends ErrorAndUrl = ErrorAndUrl>(
    selector: (state: AppState) => AjaxState<TSelected>,
    id?: string
) => {
    const state = useSelector(selector);
    const reducer: AjaxState<TSelected> = (id && (state as any)[id]) || state;

    return getSelectorResult<TSelected, TError>(reducer);
};

/**
 * Selects a dynamic reducer from the AppDynamicState and process the API calls using the AjaxState different transitions.
 * Works in a similar way as the OnStateChange without the need for callbacks. Implements Redux's useSelector under the hood.
 * @param selector The key of the reducer to get the state value from
 * @param id optional - access the state of a collectionReducer that is nested under an Id
 * @type TSelected The type of object to be returned as data
 * @type TLookup The reducer object type that has been registered for the specific feature
 * @type TError The type of error, defaults to ErrorAndUrl. API calls will return ErrorAndUrl, SDK calls return SDKError
 * @returns { result { data: TSelected | null, loading: boolean, failed: boolean, error: string } The current result of the selector
 */
export const useDynamicStateSelector = <
    TSelected,
    TLookup extends { [key: string]: Reducer },
    TError extends ErrorAndUrl = ErrorAndUrl
>(
    selector: keyof TLookup,
    id?: string
) => {
    const state = useSelector((state: AppState) => state[selector as string]);
    const reducer: AjaxState<TSelected> = (id && (state as any)[id]) || state;

    return getSelectorResult<TSelected, TError>(reducer);
};

export const useDynamicSelector = <TSelected, TLookup extends { [key: string]: Reducer }>(
    selector: keyof TLookup,
    id?: string
) => {
    const state = useSelector((state: AppState) => state[selector as string]);
    const reducer: AjaxState<TSelected> = (id && (state as any)[id]) || state;

    return reducer;
};

const getSelectorResult = <TSelected, TError extends ErrorAndUrl = ErrorAndUrl>(reducer: AjaxState<TSelected>) => {
    const loaded = isLoaded(reducer);
    const data = isLoaded(reducer) ? reducer.data : null;
    const loading = isLoading(reducer);
    const failed = isFailed(reducer);
    const error: TError | null = isFailed(reducer) ? (reducer.error as TError) : null;

    return { data, loading, loaded, failed, error };
};
