import { Button, ButtonSeverity, Dialog, Paragraph } from "portal-components";
import React from "react";
import { connect } from "react-redux";
import { AjaxState, RequestState } from "../../creators/AjaxCreator";
import { CurrentAccount } from "../../features/account";
import translate from "../../i18n/translate";
import { AppState } from "../../creators/reducers";
import { AccountStatus } from "../../script/utility";

const defaultStrings = {
    restrictedAccount: "Restricted account",
    dialogTitle: "This account has been restricted",
    theReasonIs: "The reason for this restriction is:",
    unknownReason: "The reason is unknown.",
    someFeaturesUnavailabe:
        "Some features will be unavailable. You will be able to view all data but unable to modify anything.",
    ticketHasBeenOpenedContactUs:
        "A ticket has been opened and is awaiting a response from your team.  If the contact for this account has not received an email about this, please contact us.",
    contactUs: "Contact us",
    close: "Close",
};

interface RestrictedAccountWarningProps {
    account?: AjaxState<CurrentAccount>;
    strings: typeof defaultStrings;
}

interface RestrictedAccountWarningState {
    expanded: boolean;
}

export class RestrictedAccountWarning extends React.PureComponent<
    RestrictedAccountWarningProps,
    RestrictedAccountWarningState
> {
    public static readonly defaultProps = {
        strings: defaultStrings,
    };

    public readonly state: RestrictedAccountWarningState = {
        expanded: false,
    };

    public constructor(props: Readonly<RestrictedAccountWarningProps>) {
        super(props);

        this.handleToggleDialog = this.handleToggleDialog.bind(this);
    }

    public render() {
        const { strings, account } = this.props;

        if (
            !account ||
            account.requestState !== RequestState.LOADED ||
            account.data.status !== AccountStatus.RESTRICTED
        ) {
            return null;
        }

        return (
            <React.Fragment>
                <Button
                    text={strings.restrictedAccount}
                    severity={ButtonSeverity.Warning}
                    onClick={this.handleToggleDialog}
                />
                <Dialog
                    title={strings.dialogTitle}
                    open={this.state.expanded}
                    buttons={[
                        { text: strings.contactUs, to: "/contact", primary: true },
                        { text: strings.close, onClick: this.handleToggleDialog },
                    ]}
                    onCloseRequest={this.handleToggleDialog}
                >
                    <Paragraph title={strings.theReasonIs}>{account.data.reason || strings.unknownReason}</Paragraph>
                    <Paragraph title={strings.someFeaturesUnavailabe}>{strings.ticketHasBeenOpenedContactUs}</Paragraph>
                </Dialog>
            </React.Fragment>
        );
    }

    private handleToggleDialog() {
        this.setState({ expanded: !this.state.expanded });
    }
}

export function mapStateToProps(state: AppState) {
    return {
        account: state.currentaccount,
    };
}

export default connect(mapStateToProps)(translate("RestrictedAccountWarning")(RestrictedAccountWarning));
