import React from "react";
import { Link } from "react-router-dom";
import translate from "../../i18n/translate";

const defaultStrings = {
    inactiveUser: "Your user account is currently inactive.",
    resetPassword: "Reset your password to reactivate.",
};

interface UserInactiveProps {
    strings: typeof defaultStrings;
}

export const UserInactive: React.FunctionComponent<UserInactiveProps> = ({ strings }) => (
    <React.Fragment>
        {strings.inactiveUser}&nbsp;
        <Link id="login-recovery-inactive-user" to="/login-recovery">
            {strings.resetPassword}
        </Link>
    </React.Fragment>
);

UserInactive.defaultProps = { strings: defaultStrings };

export default translate("UserInactive")(UserInactive);
