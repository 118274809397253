import { makeConfigApiCreator, ParseFunction } from "../../..//creators";
import { ListResponse } from "../../../script";
import { Application } from "./create/creator";
import { AccessKey } from "../accessKeys/create/creator";
import { ListRequestParameters } from "../../../script/types";
import { reducerRegistry } from "../../../script";
import { VerificationKey } from "../verificationKeys/create/creator";

const makeApplicationsCreator = <T, D = undefined>(type: string, parseFunction?: ParseFunction<T, D>) =>
    makeConfigApiCreator<T, D>("v3_base", {
        errorCallout: false,
    })(type, parseFunction);

// get applications
const { reducer, createThunk } = makeApplicationsCreator<ListResponse<Application>>("applications", (resp) => {
    const response = resp as ListResponse<Application>;
    response.data = response.data
        .map((item) => ({ ...item, selected: false }))
        .sort((a, b) => a.name.localeCompare(b.name));
    if (response.data && response.data.length > 1) {
        response.last = response.data[response.data.length - 1].id;
    }
    return response;
});

interface ApplicationsListParameters extends ListRequestParameters {
    query?: string;
}

const getAction = (
    {
        pageSize = 50,
        last = undefined,
        order = "DESC",
        query = undefined,
        errorAction = undefined,
        errorCallout = true,
    } = {} as ApplicationsListParameters
) =>
    createThunk({
        param: `applications`,
        queryParameters: {
            order,
            limit: `${pageSize}`,
            include: "total_count",
            filter: query,
            after: last,
        },
        errorAction,
        errorCallout,
    });

// get total count
const { reducer: countReducer, createThunk: countThunk } = makeApplicationsCreator<number, ListResponse<unknown>>(
    "count_applications",
    (response) => (response as ListResponse<unknown>).total_count
);
const getCountAction = () => countThunk({ param: `applications?limit=2&include=total_count`, errorCallout: true });

// get access key of an application
const { reducer: applicationAccessKeyReducer, createThunk: applicationAccessKeyThunk } = makeConfigApiCreator<
    ListResponse<AccessKey>
>("v3_base")("access-key-detail");
const getApplicationAccessKey = (id: string) =>
    applicationAccessKeyThunk({
        param: `applications/${id}/access-keys`,
        method: "GET",
    });

// get verification key of an application
const { reducer: applicationVerificationReducer, createThunk: applicationVerificationThunk } = makeConfigApiCreator<
    ListResponse<VerificationKey>
>("v3_base")("verification-key-detail");
const getApplicationVerificationKey = (id: string) =>
    applicationVerificationThunk({
        param: `applications/${id}/verification-keys`,
        method: "GET",
    });

// delete applications
const { reducer: deleteApplicationsReducer, createThunk: deleteApplicationsThunk } = makeConfigApiCreator<void>(
    "v3_base"
)("application_delete");

const deleteApplications = (id: string) => deleteApplicationsThunk({ param: `applications/${id}`, method: "DELETE" });

const reducers = {
    applications: reducer,
    applicationAccessKeys: applicationAccessKeyReducer,
    applicationVerificationKeys: applicationVerificationReducer,
    applicationTotalCount: countReducer,
};

reducerRegistry.register(reducers);

type ApplicationReducers = typeof reducers;

export {
    getAction,
    getCountAction,
    reducer,
    countReducer,
    deleteApplications,
    deleteApplicationsReducer,
    getApplicationAccessKey,
    getApplicationVerificationKey,
    ApplicationReducers,
};
