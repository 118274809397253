import { Strings } from "portal-components";
import React from "react";
import { connect } from "react-redux";
import { Config } from "../typings/interfaces";
import { ErrorAndUrl } from "../creators/AjaxCreator";
import translate from "../i18n/translate";
import { AppState } from "../creators/reducers";
import Markup from "./markup";

export function createMessageFromResponse(
    fields: any[] = [],
    respType: string,
    strings: { [key: string]: string } = {}
) {
    const errorMessages = fields.map((field) => {
        // Try to localize the name, if we have that string otherwise just output the unlocalized one. We (probably)
        // won't ever map all possible fields here then, at least, we convert snake_case used in API fields to
        // a more user-friendly sentence case.
        const name = field.name || field.field;
        let displayName = strings && strings[`${respType}_${name}`];
        if (!displayName) {
            if (name) {
                displayName = name.split("_").join(" ");
            } else {
                return field.message;
            }
        }

        return `${displayName}: ${field.message}`;
    });

    return errorMessages.join("\n");
}

export function resolveErrorMessage({ response }: Partial<ErrorAndUrl>, defaultMessage = "") {
    if (!response) {
        return defaultMessage;
    }

    const { message, fields, type } = response.body;

    if (!fields && !type && !message) {
        return "";
    }

    if (fields && fields.length) {
        return createMessageFromResponse(fields, type);
    }

    return typeof message === "string" ? message : defaultMessage;
}

const defaultStrings = {
    validation_error_name: "name",
    validation_error_description: "description",
    validation_error_minimum_length: "Please select a minimum password length between 8 and 512 characters.",
    tail:
        "An error has occurred. If this problem persists, please feel free to [send us an error report](mailto:{0}). Thanks!",
};

interface Props {
    responseBody?: {
        type?: string;
        fields?: string[];
    };
    message?: any;
    strings: typeof defaultStrings;
    config: Config;
}

export const ErrorHandler = (props: Props) => {
    const isKnownErrorType = () => {
        const type = props?.responseBody?.type ?? null;
        return type && !!ErrorHandler.supportedErrorTypes.find((x) => x === type);
    };

    const resolveMessageAsString = (message: any) => {
        if (typeof message === "string") {
            return message;
        }

        if (typeof message === "object" && message.message) {
            return message.message;
        }

        if (React.isValidElement(message)) {
            return message;
        }

        return Strings.toString(message);
    };

    const { responseBody, strings, message, config: { externalLinks: { supportEmailAddress = "" } = {} } = {} } = props;
    if (!responseBody && !message) {
        return null;
    }
    const fields = responseBody?.fields;
    const type = responseBody?.type ?? "";

    if (!(fields || type) && !message) {
        return null;
    }

    const displayMessage =
        isKnownErrorType() && fields
            ? createMessageFromResponse(fields, type, strings)
            : resolveMessageAsString(message);
    return (
        <div>
            <Markup string={strings.tail.format(supportEmailAddress)} />
            <code>{displayMessage || ""}</code>
        </div>
    );
};

ErrorHandler.defaultProps = {
    strings: defaultStrings,
};

ErrorHandler.supportedErrorTypes = ["validation_error", "duplicate"];

// map Redux store state to React props
function mapStateToProps(state: AppState) {
    return {
        config: state.config,
    };
}

export default connect(mapStateToProps)(translate("ErrorHandler")(ErrorHandler));
