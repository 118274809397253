import { Strings } from "portal-components";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { AppDispatch } from "../creators/reducers";

export interface FeatureToggleOverwriteProps extends RouteComponentProps {
    actions: {
        setFeatureOverwrite: (feature: string, value: boolean) => void;
    };
}

export class FeatureToggleOverwrite extends React.Component<FeatureToggleOverwriteProps> {
    constructor(props: FeatureToggleOverwriteProps) {
        super(props);
    }

    componentDidMount() {
        const query = Strings.convertSearchStringToObject(this.props?.location?.search ?? "");
        if (query && Object.getOwnPropertyNames(query).length > 0) {
            Object.entries(query).forEach(([feature, value]) =>
                this.props.actions.setFeatureOverwrite(feature, value === "true")
            );
        }
    }

    render() {
        return this.props.children;
    }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    actions: bindActionCreators(
        {
            setFeatureOverwrite: (feature: string, value: boolean) => ({
                type: "FEATURE_TOGGLE_OVERWRITE",
                feature,
                value,
            }),
        },
        dispatch
    ),
});

export default withRouter(connect(null, mapDispatchToProps)(FeatureToggleOverwrite));
