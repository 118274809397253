import { makeConfigApiCreator } from "../../../creators";
import { ListResponse } from "../../../script";
import { CampaignMeshNetworkMetadata, UpdateCampaign } from "./detail/types";

const { reducer, createThunk: getCampaignsThunk } = makeConfigApiCreator<ListResponse<UpdateCampaign>>("v3_base")(
    "updates",
    (response: any) => {
        response = response as ListResponse<UpdateCampaign>;

        const data = response.data.map((item: UpdateCampaign) => ({ ...item, selected: false }));
        if (response.data && response.data.length > 1) {
            response.last = response.data[response.data.length - 1].id;
        }
        response.data = response.data.sort((a: UpdateCampaign, b: UpdateCampaign) =>
            a.name?.localeCompare(b.name ?? "")
        );
        response.data = data;
        return response;
    }
);

const { reducer: meshReducer, createThunk: getMeshThunk } = makeConfigApiCreator<
    ListResponse<CampaignMeshNetworkMetadata>
>("v3_base")("campaign_mesh", (response: any) => {
    response = response as ListResponse<CampaignMeshNetworkMetadata>;

    const data = response.data.map((meshData: CampaignMeshNetworkMetadata) => {
        const totalDevices = meshData.success + meshData.skipped + meshData.failed + meshData.pending;
        return { ...meshData, selected: false, totalDevices };
    });

    if (response.data && response.data.length > 1) {
        response.last = response.data[response.data.length - 1].id;
    }
    return { ...response, data };
});

const getAction = ({
    pageSize = 1000,
    last,
    order = "DESC",
    query,
    errorCallout = true,
    getStats = false,
}: {
    pageSize?: number;
    last?: string;
    order?: string;
    query?: string;
    errorAction?: () => void;
    errorCallout?: boolean;
    getStats?: boolean;
}) => {
    const queryParameters: { [query: string]: string } = {
        order: order,
        limit: `${pageSize}`,
        include: "total_count",
    };
    if (last) {
        queryParameters.after = last;
    }
    if (query) {
        queryParameters.filter = query;
    }
    if (getStats) {
        queryParameters.stats = "true";
    }
    return getCampaignsThunk({
        param: `update-campaigns`,
        queryParameters,
        errorCallout,
    });
};

const getMeshDataAction = ({
    id,
    pageSize = 50,
    last,
    order = "DESC",
    errorCallout = true,
}: {
    id: string;
    pageSize?: number;
    last?: string;
    order?: string;
    errorAction?: () => void;
    errorCallout?: boolean;
}) => {
    const queryParameters: { [query: string]: string } = {
        order: order,
        limit: `${pageSize}`,
        include: "total_count",
    };
    if (last) {
        queryParameters.after = last;
    }
    return getMeshThunk({
        param: `update-campaigns/${id}/mesh-statistics`,
        queryParameters,
        errorCallout,
    });
};

// delete update campaign
const { reducer: deleteReducer, createThunk: deleteCampaignThunk } = makeConfigApiCreator<any>("v3_base")(
    "update_delete"
);

const deleteAction = (id: string) =>
    deleteCampaignThunk({
        param: `update-campaigns/${id}`,
        method: "DELETE",
        id,
    });

export { getAction, reducer, deleteAction, deleteReducer, getMeshDataAction, meshReducer };
