import { Branding, detectIE } from "portal-components";
import { Config } from "../typings/interfaces";
import { Theme } from "../features/organization";
import DevicePortalSplash from "../images/device-portal-splash.png";
import IzumaLogo from "../images/izuma-logo-horizontal-210-90.png";
import Logo from "../images/izuma-logo-horizontal-210-90.png";
import { iif, mergeDeep } from "../script/utility";
import { AppState } from "../creators/reducers";
import storeProvider from "./storeProvider";
import Agriculture from "../images/agriculture.png";
import City from "../images/city.png";
import Factory from "../images/factory.png";
import Industrial from "../images/industrial.png";
import Warehouse from "../images/warehouse.png";

// Use default Themes Pack
let darkTheme = Branding.DarkTheme;
let lightTheme = Branding.LightTheme;
let commonTheme = Branding.CommonTheme;
let Backgrounds = [City, Industrial, Warehouse, Factory, Agriculture];

// Use new menu branding when we enable it in our setting.js
if (window.appConfig.featureToggle?.useNewMenu) {
    darkTheme = Branding.PelionDarkTheme;
    lightTheme = Branding.PelionLightTheme;
    commonTheme = Branding.PelionCommonTheme;
    Backgrounds = [DevicePortalSplash];
}

const DefaultLoginBackground = Backgrounds[Math.floor(Math.random() * 100) % Backgrounds.length];

function getAppState() {
    return (storeProvider.getStore() && storeProvider.getStore().getState
        ? storeProvider.getStore().getState() || {}
        : {}) as AppState;
}

function allowTeamBrandedImage() {
    const { auth, teamLogin } = getAppState();
    return (teamLogin && teamLogin.state !== "INITIAL") || (auth && (auth.isAuthenticating || auth.isAuthenticated));
}

function getConfig(configOverride?: Partial<Config>): Partial<Config> {
    const { config } = getAppState();
    return mergeDeep(config || {}, configOverride || {});
}

// Inlining the interface as output causes a bug with Prettier
// https://github.com/prettier/prettier/issues/4327
interface GenericObject {
    [key: string]: string;
}

export function getColors(): GenericObject {
    return {
        empty: "#",
        black: "#333E48",
        blue: "#00C1DE",
        darkBlue: "#0091BD",
        green: "#95D600",
        orange: "#FF6B00",
        red: "#FF3C52",
        yellow: "#FFC700",
        white: "#FFF",
        gray: "#6F777C",
        lightGrey: "#E5ECEB",
        ...(detectIE(false) ? {} : getBrandColors()),
    };
}

export function graphColors(value: string | number) {
    const unlimited = typeof value === "string" || value === -1;
    const colorOptions = getColors();
    let color = colorOptions.empty;
    if (value < 25 || unlimited) {
        color = colorOptions.blue;
    } else if (value >= 25 && value < 75) {
        color = colorOptions.yellow;
    } else if (value >= 75) {
        color = colorOptions.red;
    }

    return color;
}

export function getTheme(config = getConfig(), includeTeamBranding = true) {
    const { onPremises = false, brandingBase = "" } = config || {};

    // Default theme is Light! Get on-premises override
    const baseTheme = iif(onPremises && brandingBase, brandingBase, Theme.Light);

    if (!includeTeamBranding) {
        return baseTheme;
    }

    const userTheme = getUserTheme(config);
    return iif(!!userTheme, userTheme, baseTheme);
}

// Get user theme but fall back to base team theme
export function getUserTheme(config = getConfig()) {
    const { allowUserTheme = false, defaultTheme = "", userTheme = "" } = config || {};
    return iif(allowUserTheme && userTheme, userTheme, defaultTheme);
}

export function getBrandedLogo(config = getConfig(), initialize: boolean | null = null): string {
    const { onPremises = false, brandLogo = "", brandLogos = { application: "", login: "" } } = config || {};

    if (initialize === null) {
        initialize = onPremises;
    }

    // check if we're using branding image
    const isBranded = isBrandedTeamLogo(config);

    let logo = iif(initialize && !isBranded, Logo);

    if (onPremises) {
        if (brandLogos && brandLogos.login) {
            logo = iif(initialize && !isBranded, brandLogos.login, logo);
        } else {
            logo = iif(brandLogo && initialize && !isBranded, brandLogo, logo);
        }
    }

    if (isBranded) {
        const theme = getTheme(config);
        const teamLogo =
            theme === Theme.Dark
                ? config?.brandingImagesDark?.brand_logo_landscape ?? ""
                : config?.brandingImagesLight?.brand_logo_landscape ?? "";
        logo = iif(teamLogo, teamLogo);
    }

    return logo;
}

export function getBrandedApplicationLogo(config = getConfig(), initialize: boolean | null = null): string {
    const { onPremises = false, brandLogos = { application: "", login: "" } } = config || {};
    const isOnLoginMenu = initialize;
    const isBranded = isBrandedApplicationTeamLogo(config); // check if we're using application branding image

    let logo = iif(initialize && !isBranded, IzumaLogo);

    if (onPremises) {
        logo = iif(brandLogos.application && initialize && !isBranded, brandLogos.application, logo);
        if (isOnLoginMenu) {
            logo = iif(brandLogos.login && initialize && !isBranded, brandLogos.login, logo);
        }
    }

    // If Branding image exists, it overrides any previous image selected by the system.
    if (isBranded) {
        const theme = getTheme(config);
        const teamLogo =
            theme === Theme.Dark
                ? config?.brandingImagesDark?.app_logo_landscape ?? ""
                : config?.brandingImagesLight?.app_logo_landscape ?? "";
        logo = iif(teamLogo, teamLogo);
    }

    return logo;
}

// Apply login background
// Assume branding is allowed if images are configured
export function getBrandedLoginBackground(config = getConfig()): string {
    const {
        onPremises = false,
        // tslint:disable-next-line:no-unnecessary-initializer
        brandLoginBackground = undefined,
        featureToggle: { uiCustomization = true } = {},
    } = config || {};

    let background = DefaultLoginBackground;

    if (onPremises) {
        // Assume any configured value overwrites the native background, checks for truthy values; ie. empty string or false sets no image.
        background = iif(
            brandLoginBackground === undefined,
            background,
            iif(!!brandLoginBackground, brandLoginBackground)
        );
    }

    // Override background only if those values are configured in team branding; Must be set in both light and dark theme.
    const fields = ["desktop_background_landscape", "desktop_background_portrait", "desktop_background_square"];
    const brandedDark = isConfiguredBrandingObject(config?.brandingImagesDark ?? fields);
    const brandedLight = isConfiguredBrandingObject(config?.brandingImagesLight ?? fields);

    if (allowTeamBrandedImage() && uiCustomization && (brandedDark || brandedLight)) {
        const theme = getTheme(config);
        const brandingImages = theme === Theme.Dark ? config?.brandingImagesDark : config?.brandingImagesLight;
        const { desktop_background_landscape = "", desktop_background_portrait = "", desktop_background_square = "" } =
            brandingImages || {};
        const teamLoginBackground =
            desktop_background_square || desktop_background_portrait || desktop_background_landscape;
        background = iif(teamLoginBackground, teamLoginBackground, background);
    }

    return iif(background, `url('${background}')`, "none");
}

// Checks for branding prop to have at least one field configured to be considered branded
// If we need a particular subset of fields, only expects one of those fields to be available, used for getting fallback values
function isConfiguredBrandingObject(prop: any, fields?: string[]) {
    return (
        prop &&
        typeof prop === "object" &&
        !Array.isArray(prop) &&
        Object.keys(prop).length > 0 &&
        (!fields || fields.some((field) => Object.keys(prop).includes(field)))
    );
}

export function isBradedOnPremColors(config = getConfig()) {
    const { onPremises = false, brandingColors = {}, brandingBase = "" } = config || {};

    return onPremises && brandingColors && (!!brandingBase || isConfiguredBrandingObject(brandingColors));
}

export function isBrandedTeamColors(config = getConfig()) {
    const {
        brandingColorsDark = {},
        brandingColorsLight = {},
        defaultTheme = "",
        featureToggle: { uiCustomization = false } = {},
    } = config;

    return (
        uiCustomization &&
        (!!defaultTheme ||
            isConfiguredBrandingObject(brandingColorsDark) ||
            isConfiguredBrandingObject(brandingColorsLight))
    );
}

export function isBrandedColors(configOverride?: Partial<Config>): boolean {
    const config = getConfig(configOverride);
    return isBradedOnPremColors(config) || isBrandedTeamColors(config);
}

// Assume branding (uiCustomization) is allowed if images are configured
export function isBrandedTeamLogo(config = getConfig()) {
    const {
        brandingImagesDark = {},
        brandingImagesLight = {},
        featureToggle: { uiCustomization = true } = {},
    } = config;

    const fields = ["brand_logo_landscape", "brand_logo_portrait", "brand_logo_square"];
    const brandedDark = isConfiguredBrandingObject(brandingImagesDark, fields);
    const brandedLight = isConfiguredBrandingObject(brandingImagesLight, fields);

    return allowTeamBrandedImage() && uiCustomization && (brandedDark || brandedLight);
}

export function isBrandedApplicationTeamLogo(config = getConfig()) {
    const {
        brandingImagesDark = {},
        brandingImagesLight = {},
        featureToggle: { uiCustomization = true } = {},
    } = config;

    const fields = ["app_logo_landscape", "app_logo_portrait", "app_logo_square"];
    const brandedDark = isConfiguredBrandingObject(brandingImagesDark, fields);
    const brandedLight = isConfiguredBrandingObject(brandingImagesLight, fields);

    return allowTeamBrandedImage() && uiCustomization && (brandedDark || brandedLight);
}

// Returns key-value pairs as with RGBA string values
export function getBrandColors(configOverride: Partial<Config> = {}): GenericObject {
    return Branding.getBrandColors(getBranding(configOverride));
}

export function setStyling(configOverride: Partial<Config> = {}, reset = false) {
    if (reset || isBrandedColors(configOverride)) {
        return Branding.setStyling(getBranding(configOverride, reset));
    }
    return false;
}

export function getBranding(configOverride: Partial<Config> = {}, reset = false) {
    if (!reset && !isBrandedColors(configOverride)) {
        return {};
    }

    const config = getConfig(configOverride);

    const {
        onPremises = false,
        brandingColors = {},
        brandingColorsDark = {},
        brandingColorsLight = {},
        featureToggle: { uiCustomization = false } = {},
    } = config;

    // Get base theme, ignoring team and user branding theme
    const baseTheme = getTheme(config, false);
    const teamTheme = getUserTheme(config);

    const brandingBaseConfiguration = iif(
        !reset && uiCustomization && !!teamTheme,
        iif(teamTheme === Theme.Dark, darkTheme, lightTheme),
        iif(baseTheme === Theme.Dark, darkTheme, lightTheme)
    );

    const brandingOnPremisesConfiguration = iif(onPremises, brandingColors, {});

    // Overrides for on-premises menu active color
    if (
        onPremises &&
        brandingOnPremisesConfiguration.primary &&
        !brandingOnPremisesConfiguration.menuItemSelectedColor
    ) {
        brandingOnPremisesConfiguration.menuItemSelectedColor = brandingOnPremisesConfiguration.primary;
    }

    const teamBrandingBaseTheme = getTheme(config);
    const applyTeamBranding = !reset && uiCustomization;
    const teamBrandingConfiguration = Object.entries(
        iif(applyTeamBranding, iif(teamBrandingBaseTheme === Theme.Dark, brandingColorsDark, brandingColorsLight), {})
    ).reduce((acc, [reference, color]) => {
        return { ...acc, [referenceNormalize(reference)]: color };
    }, {}) as GenericObject;

    // Overrides for team branding menu active color
    if (applyTeamBranding && teamBrandingConfiguration.primary && !teamBrandingConfiguration.menuItemSelectedColor) {
        teamBrandingConfiguration.menuItemSelectedColor = teamBrandingConfiguration.primary;
    }

    return Object.assign(
        {},
        commonTheme,
        brandingBaseConfiguration,
        brandingOnPremisesConfiguration,
        teamBrandingConfiguration
    );
}

// Get default branding for specified theme, include on-prem overwrites if enabled
export function getDefaultBranding(theme = Theme.Light, configOverride: Partial<Config> = {}) {
    const config = getConfig(configOverride);

    const { onPremises = false, brandingColors = {} } = config;
    const brandingBaseConfiguration = iif(theme === Theme.Dark, darkTheme, lightTheme);
    const brandingOnPremisesConfiguration = iif(onPremises, brandingColors, {});
    // Overrides for on-premises menu active color
    if (
        onPremises &&
        brandingOnPremisesConfiguration.primary &&
        !brandingOnPremisesConfiguration.menuItemSelectedColor
    ) {
        brandingOnPremisesConfiguration.menuItemSelectedColor = brandingOnPremisesConfiguration.primary;
    }

    return Object.assign({}, commonTheme, brandingBaseConfiguration, brandingOnPremisesConfiguration);
}

export const referenceNormalize = (reference: string) => {
    const references = {
        nav_menu_background: "navMenuBackground",
        nav_menu_background_font_color: "navMenuFontColor",
        nav_menu_active_background: "navMenuActiveBackground",
        nav_menu_active_background_font_color: "navMenuActiveFontColor",
        nav_menu_highlight: "navMenuHighlight",
        nav_menu_highlight_font_color: "navMenuHighlightFontColor",
        canvas_background: "workspace",
        canvas_background_font_color: "workspaceFontColor",
        workspace_background: "body",
        workspace_background_font_color: "bodyFontColor",
        primary: "primary",
        primary_font_color: "primaryFontColor",
        secondary: "secondary",
        secondary_font_color: "secondaryFontColor",
        error_color: "errorColor",
        error_font_color: "errorColorFontColor",
        info_color: "infoColor",
        info_font_color: "infoColorFontColor",
        success_color: "successColor",
        success_font_color: "successColorFontColor",
        warning_color: "warningColor",
        warning_font_color: "warningColorFontColor",
    } as GenericObject;
    return reference in references ? references[reference] : reference;
};

export { darkTheme, lightTheme, commonTheme };
