import React from "react";
import { Config } from "../../typings/interfaces";
import translate from "../../i18n/translate";

const defaultStrings = {
    expiredAccount: "Your account has expired.",
    expiredAccountContactUs: "Please contact us.",
};

export interface AccountExpiredComponent {
    config: Config;
    strings: typeof defaultStrings;
}

export const AccountExpired: React.FunctionComponent<AccountExpiredComponent> = ({ config, strings }) => {
    const link = config.externalLinks.genericSupport;

    return (
        <React.Fragment>
            {strings.expiredAccount}&nbsp;
            <a href={link} rel="external noopener noreferrer">
                {strings.expiredAccountContactUs}
            </a>
        </React.Fragment>
    );
};

AccountExpired.defaultProps = {
    strings: defaultStrings,
};

export default translate("AccountExpired")(AccountExpired);
