import React from "react";
import { GridWrapProps } from ".";
const Grid = React.lazy(() => import("./grid-wrap-load"));

interface GridWrapComponentProps<T = { [key: string]: unknown }> extends GridWrapProps<T> {
    [key: string]: unknown;
}

export const GridWrapComponent = <T extends {}>(props: GridWrapComponentProps<T>) => (
    <React.Suspense fallback={null}>
        <Grid {...props} />
    </React.Suspense>
);

GridWrapComponent.displayName = "GridWrapComponent";
export default GridWrapComponent;
