import { ListResponse } from "mbed-cloud-sdk/types/legacy/common/listResponse";
import { ValueEvent } from "portal-components";
import React from "react";
import { GridWrapProps } from "../../../controls/grid";
import { DropdownFilter, FilterBar } from "../../../controls/filterBar";
import GridWrap from "../../../controls/grid/grid-wrap";
import LogLevelComponent from "../../../controls/logLevelComponent";
import translate, { DefaultStrings } from "../../../i18n/translate";
import { combinePathNameWithCurrentSearch } from "../../../script/routing";
import { DeviceEvent } from "../detail/events/creator";
import EventDetail from "./eventDetail";
import EventLink from "./eventLink";

const defaultStrings = {
    all: "All types",
    deviceDeleted: "Device is no longer in device directory",
    deviceId: "Device ID",
    eventAt: "Time",
    fail: "Fail",
    info: "Info",
    message: "Message",
    ok: "OK",
    pending: "Pending",
    refresh: "Refresh results",
    related: "Related",
    success: "Success",
    type: "Message type",
};

interface EventTableComponentProps {
    strings: DefaultStrings<typeof defaultStrings>;
    id?: string;
    onRefresh: () => void;
    onFilterChanged: (e: { index: any; type: string }) => void;
    gridData: DeviceEvent[];
    gridInfo: ListResponse<DeviceEvent> | null;
    filterIndex: number;
    loading: boolean;
    onGridLoadMore: (props: { last: string | undefined; pageSize?: undefined }) => void;
}

interface EventTableComponentState extends GridWrapProps<DeviceEvent> {
    typeFilterElements: { value: string | undefined; text: string; default?: boolean }[];
}

export class EventTable extends React.PureComponent<EventTableComponentProps, EventTableComponentState> {
    public static defaultProps = {
        strings: defaultStrings,
    };

    constructor(props: EventTableComponentProps) {
        super(props);
        this.handleRefresh = this.handleRefresh.bind(this);
        this.handleFilterChanged = this.handleFilterChanged.bind(this);

        this.state = {
            columns: [
                {
                    name: "device_id",
                    title: props.strings.deviceId,
                    type: "link",
                    link: (row: { original: DeviceEvent }) =>
                        combinePathNameWithCurrentSearch(`/devices/logs/${row.original.device_id}/events`),
                    replaceEmptyCell: props.strings.deviceDeleted,
                    disableOnEmpty: true,
                    ellipsis: "middle",
                },
                {
                    name: "date_time",
                    title: props.strings.eventAt,
                    type: "date",
                    dateFormat: "LTS ll",
                    ellipsis: "end",
                },
                {
                    name: "event_type_description",
                    title: props.strings.type,
                    renderFunction: (eventType: unknown) => <LogLevelComponent level={eventType as string} />,
                },
                {
                    name: "event_type",
                    title: props.strings.message,
                    renderFunction: (_: any, event: unknown) => <EventDetail event={event as DeviceEvent} />,
                },
                {
                    name: "data",
                    title: props.strings.related,
                    sortable: false,
                    resizable: false,
                    renderFunction: (_: any, event: unknown) => <EventLink event={event as DeviceEvent} />,
                },
            ]
                .filter((c) => !!c)
                .map((c, index) => ({ ...c, id: index })),

            typeFilterElements: [
                {
                    value: undefined,
                    text: props.strings.all,
                    default: true,
                },
                {
                    value: "fail",
                    text: props.strings.fail,
                },
                {
                    value: "pending",
                    text: props.strings.pending,
                },
                {
                    value: "ok",
                    text: props.strings.ok,
                },
                {
                    value: "success",
                    text: props.strings.success,
                },
                {
                    value: "info",
                    text: props.strings.info,
                },
            ],
            extraActions: {
                iconButtons: [
                    {
                        title: props.strings.refresh,
                        onClick: this.handleRefresh,
                    },
                ],
            },
        };
    }

    handleRefresh(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();
        this.props.onRefresh();
    }

    handleFilterChanged(e: ValueEvent<string>) {
        this.props?.onFilterChanged({
            index: this.state.typeFilterElements.findIndex((x) => x.value === e.value),
            type: e.value,
        });
    }

    render() {
        const { id, gridData, gridInfo, filterIndex = 0, loading, onGridLoadMore, strings } = this.props;
        const { columns, extraActions, selectedAll, typeFilterElements } = this.state;

        return (
            <GridWrap
                id={id ? `device_${id}-events` : "device-events"}
                columns={columns}
                data={gridData}
                extraActions={extraActions}
                filterBy={id ? [0, 1, 2, 3] : [0, 1, 2, 3, 4]}
                info={gridInfo}
                loading={loading}
                noSelection
                onGridLoadMore={onGridLoadMore}
                selectedAll={selectedAll}
                downloadData
                showFilter
                inlineFilters={
                    <FilterBar>
                        {this.props.children}
                        <DropdownFilter
                            items={typeFilterElements}
                            label={strings.type}
                            value={typeFilterElements[filterIndex].value as string}
                            onChange={this.handleFilterChanged}
                        />
                    </FilterBar>
                }
            />
        );
    }
}

export default translate("EventTable")(EventTable);
