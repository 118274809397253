import { Strings, Icons } from "portal-components";
import React from "react";
import { SearchBar } from "../searchBar";
import { CheckedListItem, CheckedListItemProps } from "./checkedListItem";

export interface CheckedListProps {
    style?: object;
    className?: string;
    filter?: string;
    placeholderForFilter?: string;
    titleForCreate?: string;
    descriptionForCreate?: string;
    canFilter?: boolean;
    canCreate?: boolean;
    onCreate?: (item: { name: string }) => void;
}

interface CheckedListState {
    filter?: string;
}

export class CheckedList extends React.Component<CheckedListProps, CheckedListState> {
    constructor(props: CheckedListProps) {
        super(props);

        this.handleFilterChanged = this.handleFilterChanged.bind(this);
        this.handleCreate = this.handleCreate.bind(this);

        this.state = {
            filter: props.filter,
        };
    }

    handleFilterChanged(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            filter: e.target.value,
        });
    }

    handleCreate() {
        this.props.onCreate?.({ name: this.state.filter ?? "" });
        this.setState({ filter: "" });
    }

    renderFilterBar() {
        if (!this.props.canFilter) {
            return null;
        }

        return (
            <div className="checked-list-filter">
                <SearchBar
                    placeholder={this.props.placeholderForFilter}
                    value={this.state.filter ?? ""}
                    autofocus
                    onChange={this.handleFilterChanged}
                />
            </div>
        );
    }

    renderChildren() {
        const { filter } = this.state;
        return React.Children.map(this.props.children as any, (element: React.ReactElement<CheckedListItemProps>) => {
            // Ignores null children (in case they come from someArray.filter()).
            if (!element) {
                return null;
            }
            const { type, props } = element;
            const { title, description, searchText, unfilterable } = props;

            // After this we apply filtering but we know how to filter only CheckedListItem.
            if (type !== CheckedListItem) {
                return element;
            }

            let searchableText = searchText ?? "";
            if (title && typeof title === "string") {
                searchableText += "\n" + title;
            }
            if (description && typeof description === "string") {
                searchableText += "\n" + description;
            }
            // Includes all "unfilterble" children with the specified filter in their title. Note that
            // an empty string includes everything.
            if (!unfilterable && !Strings.contains(searchableText, filter)) {
                return null;
            }

            return element;
        });
    }

    renderAdditionalMenuItems() {
        if (!this.props.canCreate || !this.props.canFilter || !this.state.filter) {
            return null;
        }

        return (
            <CheckedListItem
                key="add-new-list-item"
                title={this.props.titleForCreate?.format(this.state.filter) ?? ""}
                icon={Icons.Add}
                description={this.props.descriptionForCreate}
                onClick={this.handleCreate}
                strong
                unselectable
                unfilterable
            />
        );
    }

    render() {
        const { style, className } = this.props;

        return (
            <div className={`checked-list ${className ? className : ""}`} style={style}>
                {this.renderFilterBar()}
                <ul className="checked-list-body">
                    {this.renderChildren()}
                    {this.renderAdditionalMenuItems()}
                </ul>
            </div>
        );
    }
}
