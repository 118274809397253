import { Button, Form, TextBox, ValueEvent } from "portal-components";
import React, { useEffect, useState } from "react";
import { Response } from "superagent";
import translate from "../../../i18n/translate";
import { LoadIdpError } from "./reducer";
import { AppState } from "../../../creators/reducers";
import { connect } from "react-redux";
import { Config } from "../../../typings/interfaces";
import { getFeatureToggle } from "../../../script/utility";

const defaultStrings = {
    email: "Email",
    next: "Next",
    loadIdpFailed: "Failed to load identity provider.",
    notFound: "Email not found.",
    unknownIdpType: "Unknown identity provider type.",
};

export interface EnterEmailComponentProps {
    defaultEmail: string;
    strings: typeof defaultStrings;
    onEmailSubmit: (email: string) => void;
    error?: LoadIdpError;
    response?: Response;
    submitting: boolean;
    genericErrorMsg?: string;
    config: Config;
}

export const EnterEmailComponent: React.FunctionComponent<EnterEmailComponentProps> = (props) => {
    const { defaultEmail, error, genericErrorMsg, onEmailSubmit, strings, submitting } = props;
    const [email, setEmail] = useState(defaultEmail);
    const useNewMenu = getFeatureToggle("useNewMenu", props);

    useEffect(() => {
        if (email !== "") {
            setEmail(defaultEmail);
        }
    }, [defaultEmail]);
    const handleButtonClick = () => {
        onEmailSubmit(email);
    };

    const handleEmailChange = ({ value }: ValueEvent<string>): void => {
        setEmail(value);
    };

    const getErrorMessage = (error: LoadIdpError): React.ReactNode => {
        switch (error) {
            case LoadIdpError.UNKNOWN_IDP_TYPE:
                return strings.unknownIdpType;
            case LoadIdpError.LOAD_IDP_FAILED:
                return strings.loadIdpFailed;
            case LoadIdpError.NOT_FOUND:
                // Adding generic error message as current api returns an empty array when a team does not exist.
                // So, "Email does not exist" is an incorrect message under those conditions. (IOTSPOR-2615)
                return genericErrorMsg || strings.notFound;
        }
    };

    return (
        <Form onSubmit={handleButtonClick}>
            <TextBox
                name="email"
                value={email}
                label={strings.email}
                onValueChange={handleEmailChange}
                disabled={submitting}
                autoComplete="email"
                type="email"
                required
                autoFocus
            />
            <Button
                id="next-button"
                className={useNewMenu ? "full-size" : "full-size"}
                submit
                primary
                stretched
                text={strings.next}
                busy={submitting}
            />
            {error && <p className="help-text red">{getErrorMessage(error)}</p>}
        </Form>
    );
};
EnterEmailComponent.defaultProps = {
    strings: defaultStrings,
};

const mapStateToProps = (state: AppState) => {
    return {
        config: state.config,
    };
};

export default connect(mapStateToProps)(translate("EnterEmailComponent")(EnterEmailComponent));
