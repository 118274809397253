import React from "react";
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from "react-router";
import { PropagatedProps } from "../layout/main/component";
import { asyncRender } from "./BaseRoute";
import ErrorBoundary from "../layout/errorBoundary";
import { importLazy, LazilyLoadFactory } from "../layout/lazilyload";
import { connect } from "react-redux";
import { Config } from "../typings/interfaces";
import { AppState } from "../creators/reducers";

export interface FleetRouteProps extends RouteComponentProps {
    FactoryMetrics: React.ComponentType<PropagatedProps>;
    componentProps: PropagatedProps;
    config: Config;
}

export const FleetRoute: React.FunctionComponent<FleetRouteProps> = (props) => {
    const { FactoryMetrics, componentProps, config } = props;

    const asyncRenderWithProps = asyncRender(componentProps);

    return (
        <ErrorBoundary>
            <Switch>
                {config.featureToggle.fleetManagement && config.featureToggle.manufacturing_statistics && (
                    <Route
                        exact
                        path="/fleet/factory"
                        render={asyncRenderWithProps(FactoryMetrics, "factoryMetrics")}
                    />
                )}
                <Redirect exact from="/fleet" to="/fleet/factory" />
            </Switch>
        </ErrorBoundary>
    );
};

const mapStateToProps = ({ config }: AppState) => ({ config });

export default LazilyLoadFactory(withRouter(connect(mapStateToProps)(FleetRoute)), {
    FactoryMetrics: () => importLazy(import("../features/fleet/container")),
});
