import { Location } from "history";
import { Else, If, Page, PageButtons, PageSubtitle, Then } from "portal-components";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { bindActionCreators } from "redux";
import { Config } from "../../../../typings/interfaces";
import DocumentationLink from "../../../../controls/documentationLink";
import { AjaxState, FailedState, isLoaded, onStateChange } from "../../../../creators/AjaxCreator";
import translate, { DefaultStrings } from "../../../../i18n/translate";
import { AuthState } from "../../../../layout/reducer";
import { AppDispatch, AppState } from "../../../../creators/reducers";
import { redirectTo } from "../../../../script/routing";
import { Documentation, formValidate, sendEvent } from "../../../../script/utility";
import { logout } from "../../../login/logout/creator"; // importing from the creator to avoid a circular dependency
import { totpActivate, totpGenerateScratchCodes, updateUser } from "../../action/creator";
import { CurrentUser, getUser } from "../../creator";
import Component, { Totp } from "./component";

const defaultStrings = {
    mbedCloud: "Izuma Device Management - {0}",
    mbedCloudOnPrem: "Device Management - {0}",
    notActive: "Two-factor authentication is not active on this account",
    alreadyActive: "Two-factor authentication is already active on this account",
    title: "Two-factor authentication",
    description: "Set up two-factor authentication",
};

export interface TotpSetupContainerProps extends Partial<RouteComponentProps> {
    // partial because container is imported by mfa setup
    strings: DefaultStrings<typeof defaultStrings>;
    auth: AuthState;
    config: Config;
    cancelButton: JSX.Element;
    currentUser?: AjaxState<CurrentUser>;
    enableSetup: boolean;
    loginFlow: boolean;
    totpActivate?: AjaxState<CurrentUser>;
    totpNewCodes?: AjaxState<CurrentUser>;
    updateUser?: AjaxState<CurrentUser>;
    onComplete?: () => void;
    dispatch: AppDispatch;
    actions: {
        totpActivate: typeof totpActivate;
        totpGenerateScratchCodes: typeof totpGenerateScratchCodes;
        updateUser: typeof updateUser;
        logout: typeof logout;
    };
}

interface TotpSetupContainerState {
    actionDisabled: boolean;
    errorMessage: string;
    recovery: string[];
    step: StepName;
    stepState?: { totp_code: string; didCopy: string };
    totp?: Totp;
    working: boolean;
}

interface AuthStep {
    action: (state?: { totp_code: string }) => void;
    location?: string;
    name: StepName;
}

type StepName = "start" | "scan" | "code" | "recovery" | "deactivate" | "regenerate";

export class TotpSetupContainer extends React.PureComponent<TotpSetupContainerProps, TotpSetupContainerState> {
    public static readonly defaultProps = {
        strings: defaultStrings,
    };

    authSteps: {
        start: AuthStep;
        scan: AuthStep;
        code: AuthStep;
        recovery: AuthStep;
        deactivate: AuthStep;
        regenerate: AuthStep;
    };

    constructor(props: TotpSetupContainerProps) {
        super(props);

        this.handleStepSubmit = this.handleStepSubmit.bind(this);
        this.handleStep1Submit = this.handleStep1Submit.bind(this);
        this.handleStep2Submit = this.handleStep2Submit.bind(this);
        this.handleStep3Submit = this.handleStep3Submit.bind(this);
        this.handleStep4Submit = this.handleStep4Submit.bind(this);
        this.handleDeactivate = this.handleDeactivate.bind(this);
        this.handleGenerateRecoveryCodesSubmit = this.handleGenerateRecoveryCodesSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.mapPathToStep = this.mapPathToStep.bind(this);

        this.authSteps = {
            start: {
                location: "/account/update/auth",
                action: this.handleStep1Submit,
                name: "start",
            },
            scan: { action: this.handleStep2Submit, name: "scan" },
            code: { action: this.handleStep3Submit, name: "code" },
            recovery: { action: this.handleStep4Submit, name: "recovery" },
            deactivate: {
                location: "/account/update/auth/deactivate",
                action: this.handleDeactivate,
                name: "deactivate",
            },
            regenerate: {
                location: "/account/update/auth/generaterecoverycodes",
                action: this.handleGenerateRecoveryCodesSubmit,
                name: "regenerate",
            },
        };

        this.state = {
            actionDisabled: this.props.enableSetup ? false : true,
            errorMessage: "",
            recovery: [],
            step: this.authSteps.start.name,
            working: false,
        };
    }

    componentDidMount() {
        // Get users profile
        this.props.dispatch(getUser);
    }

    UNSAFE_componentWillReceiveProps(nextProps: TotpSetupContainerProps) {
        const { auth, currentUser, location, totpActivate, totpNewCodes, updateUser } = nextProps;
        const errorMessageParser = ({ error }: FailedState) => error.response.body.message;

        const onFailed = (error: unknown) => {
            this.setState({
                errorMessage: error as string,
                working: false,
            });
        };

        onStateChange(this.props.currentUser, currentUser, {
            loaded: ({ data }) => {
                const { start, deactivate, regenerate } = this.authSteps;
                const { step } = this.state;

                if (step === start.name && data.is_totp_enabled) {
                    // User has already turned on MFA and is trying to activate it
                    this.setState({
                        errorMessage: this.props.strings.alreadyActive,
                    });
                } else if ((step === deactivate.name || step === regenerate.name) && !data.is_totp_enabled) {
                    // User doesn't have MFA on and is trying to deactivate it/generate codes
                    this.setState({
                        errorMessage: this.props.strings.notActive,
                    });
                } else {
                    // Cotinue
                    this.setState({
                        working: false,
                        errorMessage: "",
                        actionDisabled: false,
                    });
                }
            },
            failed: onFailed,
            messageParser: errorMessageParser,
        });

        // Step 2: Turn on two-factor/Deactivate
        onStateChange(this.props.updateUser, updateUser, {
            loaded: ({ data: user }) => {
                const { start, scan, deactivate } = this.authSteps;
                const { step } = this.state;

                if (step === start.name) {
                    this.setState({
                        step: scan.name,
                        totp: this.generateTotpObject(user),
                        working: false,
                    });
                    sendEvent("Account", "TOTP acctivated");
                    // Deactivate
                } else if (step === deactivate.name) {
                    sendEvent("Account", "TOTP deactivated");
                    redirectTo({ path: "/account" });
                }
            },
            failed: onFailed,
            messageParser: errorMessageParser,
        });

        // Step 3: Set TOTP code to activate two-factor
        onStateChange(this.props.totpActivate, totpActivate, {
            loaded: () => {
                if (this.state.step === this.authSteps.code.name) {
                    const recovery = isLoaded(updateUser) ? [...updateUser?.data.totp_scratch_codes] : [];
                    this.setState({
                        recovery,
                        step: this.authSteps.recovery.name,
                        working: false,
                    });
                }
            },
            failed: onFailed,
            messageParser: errorMessageParser,
        });

        // Step 4: Generate new codes
        onStateChange(this.props.totpNewCodes, totpNewCodes, {
            loaded: ({ data: user }) => {
                if (this.state.step === this.authSteps.regenerate.name) {
                    this.setState({
                        recovery: user.totp_scratch_codes || [],
                        step: this.authSteps.recovery.name,
                        totp: this.generateTotpObject(user),
                        working: false,
                    });
                }
            },
            failed: onFailed,
            messageParser: errorMessageParser,
        });

        // Handle reauth requirement and try action again
        if (this.props.auth.type !== auth.type && auth.type === "REAUTH_SUCCESS") {
            this.handleStepSubmit();
        }

        // Validate path
        this.mapPathToStep(location);
    }

    generateTotpObject(user: CurrentUser): Totp {
        const { config, strings = {} } = this.props;
        const host = window.location.hostname;
        const isOnPrem = config?.onPremises ?? false;
        const issuer = isOnPrem ? strings.mbedCloudOnPrem.format(host) : strings.mbedCloud.format(host);
        return {
            email: user.email ?? "",
            host,
            issuer,
            url: window.location.origin,
            secret: user.totp_secret ?? "",
        };
    }

    mapPathToStep(location: Location | undefined) {
        let redirectToStep = this.state.step;
        const { deactivate, regenerate } = this.authSteps;

        switch (location?.pathname) {
            case deactivate.location:
                redirectToStep = deactivate.name;
                break;
            case regenerate.location:
                redirectToStep = regenerate.name;
                break;
        }

        if (redirectToStep !== this.state.step) {
            this.setState({ step: redirectToStep });
        }
    }

    handleStepSubmit(stepState = this.state.stepState) {
        this.setState({ working: true, errorMessage: "", stepState });
        this.authSteps[this.state.step].action(stepState);
    }

    handleDeactivate() {
        this.props.actions.updateUser({ is_totp_enabled: false });
    }

    handleStep1Submit() {
        // Turn on two factor
        const { currentUser } = this.props;
        if (!currentUser) return;

        this.props.actions.updateUser({ is_totp_enabled: true });
    }

    handleStep2Submit() {
        // Send user to next step, no processing needed
        this.setState({
            step: this.authSteps.code.name,
            working: false,
        });
    }

    handleStep3Submit(state?: { totp_code: string }) {
        if (state?.totp_code) {
            this.props.actions.totpActivate({
                code: formValidate(state.totp_code, "otp"),
            });
        } else {
            this.setState({
                working: false,
            });
        }
    }

    handleStep4Submit() {
        const { onComplete } = this.props;
        if (onComplete) {
            onComplete();
            return;
        }

        // Send user back to account page
        redirectTo({ path: "/account" });
    }

    handleGenerateRecoveryCodesSubmit() {
        // Get users profile to generate codes
        this.props.actions.totpGenerateScratchCodes();
    }

    handleCancel() {
        if (this.props.loginFlow) {
            this.props.actions.logout({ endIdpSession: true });
            redirectTo({ path: "/login" });
        } else {
            redirectTo({ path: "/account" });
        }
    }

    render() {
        const { cancelButton, config, loginFlow, strings } = this.props;
        const { errorMessage, recovery, step, totp, working, actionDisabled } = this.state;

        const mfaActive = config.featureToggle.mfa ?? false;
        if (!mfaActive) {
            return null;
        }

        const component = (
            <Component
                cancelButton={cancelButton}
                config={config}
                errorMessage={errorMessage}
                onStepSubmit={this.handleStepSubmit}
                onCancel={this.handleCancel}
                step={step}
                totp={totp}
                recovery={recovery}
                working={working}
                actionDisabled={actionDisabled}
                loginFlow={loginFlow}
            />
        );

        const totpDocsLink = config ? config.docs_base + config.docs_version + Documentation.accountSecurity : "#";
        return (
            <If condition={loginFlow}>
                <Then>
                    <PageSubtitle title={strings.description} />
                    {component}
                </Then>
                <Else>
                    <Page title={strings.title} id="totp-setup-component" description={strings.description}>
                        <PageButtons>
                            <DocumentationLink to={totpDocsLink} />
                        </PageButtons>
                        {component}
                    </Page>
                </Else>
            </If>
        );
    }
}

// map Redux store state to React props
const mapStateToProps = (state: AppState) => ({
    auth: state.auth,
    config: state.config,
    currentUser: state.currentuser,
    updateUser: state.updateuser,
    totpActivate: state.totpactivate,
    totpNewCodes: state.totpNewCodes,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    actions: bindActionCreators({ totpActivate, totpGenerateScratchCodes, updateUser, logout }, dispatch),
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(translate("TotpSetupContainer")(TotpSetupContainer));
