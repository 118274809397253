import { makeConfigApiCreator } from "../../../creators";
import { ListResponse } from "../../../script";
import { FirmwareImage } from "./detail/types";

// firmware image list
const { reducer, createThunk: getImagesThunk } = makeConfigApiCreator<ListResponse<FirmwareImage>>("v3_base")(
    "firmware_image",
    (untypedResponse: unknown) => {
        const response = untypedResponse as ListResponse<FirmwareImage>;
        response.data = response.data.map((device) => ({ ...device, selected: false }));
        if (response.data && response.data.length > 1) {
            response.last = response.data[response.data.length - 1].id;
        }
        return response;
    }
);

const getAction = ({
    pageSize = 1000,
    last,
    order = "DESC",
    query,
    errorAction,
    errorCallout = true,
}: {
    pageSize?: number;
    last?: string;
    order?: string;
    query?: string;
    errorAction?: () => void;
    errorCallout?: boolean;
}) => {
    const queryParameters: { [query: string]: string } = {
        order,
        limit: `${pageSize}`,
        include: `total_count`,
    };
    if (last) {
        queryParameters.after = last;
    }
    if (query) {
        queryParameters.filter = query;
    }
    return getImagesThunk({ param: `firmware-images`, queryParameters, errorAction, errorCallout });
};

// firmware image detail
const { reducer: detailReducer, createThunk: getImageDetailThunk } = makeConfigApiCreator<FirmwareImage>("v3_base")(
    "firmware_image_detail"
);
const detailAction = (id: string) => getImageDetailThunk({ param: `firmware-images/${id}`, method: "GET" });

// delete firmware image
const { reducer: deleteReducer, createThunk: deleteImageThunk } = makeConfigApiCreator<void>("v3_base")(
    "firmware_image_delete"
);
const deleteAction = (id: string) =>
    deleteImageThunk({
        param: `firmware-images/${id}`,
        method: "DELETE",
    });

export { getAction, reducer, deleteAction, deleteReducer, detailAction, detailReducer };
