import { makeConfigApiCreator } from "../../creators";
import { redirectTo } from "../../script/routing";
import { loadCookie, saveCookie } from "../../script/utility";
import { LoginCredentials } from "./team/api";

const { createThunk: loginThunk } = makeConfigApiCreator<unknown, { issuer?: string }>("auth_base", {
    useAuth: false,
    errorCallout: false,
    errorRedirect: false,
})("LOGIN");

const login = (credentials: LoginCredentials, errorRedirect = true) => {
    const issuer = credentials.issuer;
    localStorage.setItem("login-event", "login" + Math.random());
    return loginThunk({
        param: "login",
        data: Object.entries(credentials)
            .filter(([, b]) => !!b)
            .reduce((a, [k, v]) => ({ ...a, [k]: v }), {}),
        method: "POST",
        requestData: { issuer },
        errorRedirect,
    });
};

const LOGIN_COOKIE = "credentials";
const LAST_LOGIN_COOKIE = "lastlogin";

interface LoginCookie {
    idpId?: string;
    username?: string;
    issuer?: string;
    message?: string;
}
const setLoginCookie = ({ idpId, username, issuer, message = "" }: LoginCookie) => {
    // Store the username & organisation in cookie for 30 days
    const thirtyDaysFromNow = new Date(new Date().getTime() + 30 * 86400000);
    saveCookie(LOGIN_COOKIE, { username, issuer, message, idpId }, { expires: thirtyDaysFromNow });
};

const getLoginCookie = (): LoginCookie => loadCookie(LOGIN_COOKIE) || {};

const firstLogin = (oldPassword: string) => ({
    type: "FIRST_LOGIN",
    oldPassword,
});

export interface SwitchTeamResponse {
    redirect_uri: string;
    roles: string[];
    token?: string;
}

const parseSwitchTeam = (body: any) => {
    const response = body as SwitchTeamResponse;
    const { token } = response;
    const threeDaysFromNow = new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000);
    if (token) {
        saveCookie("token", token, { expires: threeDaysFromNow });
        // set login cookie
        localStorage.setItem("login-event", "login" + Math.random());
        // reload page
        redirectTo({ path: "/", cb: () => window.location.reload() });
    }
    return response;
};

const { reducer: switchTeamReducer, createThunk: switchTeamThunk } = makeConfigApiCreator<SwitchTeamResponse>(
    "auth_base",
    {
        useAuth: false,
        errorCallout: false,
        errorRedirect: false,
    }
)("SWITCH_TEAM_SESSION", parseSwitchTeam);

interface SwitchTeamOptions {
    account_id: string;
}

const switchTeams = ({ account_id }: SwitchTeamOptions) =>
    switchTeamThunk({
        param: "impersonate",
        data: { account_id },
        method: "POST",
        useAuth: true,
        errorCallout: false,
    });

export { LAST_LOGIN_COOKIE, firstLogin, login, setLoginCookie, getLoginCookie, switchTeams, switchTeamReducer };
