// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2DZpRsD-4OpwrG60Fj_JEc>*{margin:1rem}.yzmZntSHxk8SvK8Cvriav p,.yzmZntSHxk8SvK8Cvriav p>strong{word-break:break-all}\n", "",{"version":3,"sources":["login.scss"],"names":[],"mappings":"AAAA,2BAAuB,WAAW,CAAC,yDAAuD,oBAAoB","file":"login.scss","sourcesContent":[":local(.loginOption)>*{margin:1rem}:local(.loginContent) p,:local(.loginContent) p>strong{word-break:break-all}\n"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginOption": "_2DZpRsD-4OpwrG60Fj_JEc",
	"loginContent": "yzmZntSHxk8SvK8Cvriav"
};
export default ___CSS_LOADER_EXPORT___;
