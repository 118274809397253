import { makeConfigApiCreator } from "../../../creators";

const { reducer, createThunk: emailVerifyThunk } = makeConfigApiCreator("auth_base", {
    useAuth: false,
    errorCallout: false,
    errorRedirect: false,
})("email_verify");

const verifyAction = ({ code, email }: { code: string; email: string }) =>
    emailVerifyThunk({
        data: { code, email },
        errorCallout: false,
        method: "POST",
        param: "validate-email",
    });

export { verifyAction, reducer };
