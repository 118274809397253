import { makeConfigApiCreator } from "../../creators/AjaxApiCreator";

interface Version {
    tag: string;
}

const { reducer: versionCheckerReducer, createThunk: versionCheckerThunk } = makeConfigApiCreator<Version>("version", {
    errorCallout: false,
    errorRedirect: false,
})("version_checker");

const getVersion = () => versionCheckerThunk({});

export { getVersion, versionCheckerReducer, Version };
