import React from "react";
import { CalloutState } from "../../creators/CalloutCreator";

export const MainContext = React.createContext({
    enableCallout: () => {},
    disableCallout: () => {},
    resetCallout: () => {},
} as MainContextProps);

export interface MainContextProps {
    enableCallout: () => void;
    disableCallout: () => void;
    resetCallout: (callout: CalloutState) => void;
}
