import React from "react";
import GridWrap from "../../../../controls/grid/grid-wrap";
import LogLevelComponent from "../../../../controls/logLevelComponent";
import translate, { DefaultStrings } from "../../../../i18n/translate";
import LogDetails from "./logDetails";
import { Column } from "../../../../controls/grid";
import { GatewayLog } from "./creator";
import { ListResponse } from "mbed-cloud-sdk/types/legacy/common/listResponse";

const PAGESIZE = 10;

const defaultStrings = {
    level: "Level",
    message: "Message",
};

interface LogsSimpleTableProps {
    id?: string;
    strings: DefaultStrings<typeof defaultStrings>;
    onGridLoadMore: () => void;
    info: ListResponse<GatewayLog>;
    loading: boolean;
    logs: GatewayLog[];
}

interface LogsSimpleTableState {
    columns: Column<GatewayLog>[];
}

export class LogsSimpleTable extends React.PureComponent<LogsSimpleTableProps, LogsSimpleTableState> {
    public static defaultProps = {
        strings: defaultStrings,
    };

    constructor(props: LogsSimpleTableProps) {
        super(props);

        this.handleGridLoadMore = this.handleGridLoadMore.bind(this);

        const { strings } = props;
        this.state = {
            columns: [
                {
                    index: 0,
                    name: "level",
                    title: strings.level,
                    renderFunction: (level: unknown) => <LogLevelComponent level={level as string} />,
                    isSortable: false,
                    width: 110,
                },
                {
                    index: 1,
                    name: "message",
                    title: strings.message,
                    renderFunction: (_, log) => <LogDetails log={log as GatewayLog} />,
                    isSortable: false,
                },
            ],
        };
    }

    handleGridLoadMore() {
        this.props?.onGridLoadMore();
    }

    render() {
        const { info, loading, logs } = this.props;
        const { columns } = this.state;

        return (
            <GridWrap
                id={`gateway__logs`}
                columns={columns}
                data={logs}
                loading={loading}
                onGridLoadMore={this.handleGridLoadMore}
                info={info}
                downloadData={false}
                showPageSizeSelector={false}
                defaultPageSize={PAGESIZE}
                pagingPageSizeSmall={[{ value: PAGESIZE }]}
                noBottomPaging
                noSelection
            />
        );
    }
}

export default translate("LogsSimpleTable")(LogsSimpleTable);
