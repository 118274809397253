import React from "react";
import { connect } from "react-redux";
import { Column, GridWrap, GridWrapProps, Row } from "../../../../controls/grid";
import { AjaxState, ErrorAction, isLoading, onStateChange } from "../../../../creators/AjaxCreator";
import translate, { DefaultStrings } from "../../../../i18n/translate";
import { buildQuery, Filter } from "../../../../script/deviceQueries";
import { AppDispatch, AppState } from "../../../../creators/reducers";
import { combinePathNameWithCurrentSearch } from "../../../../script/routing";
import { concatData } from "../../../../script/typedUtilities";
import { ListResponse } from "../../../../script/types";
import { Device, getDevices } from "../../../devices";

const DEFAULT_OPERATION_ID = "DEVICE_LIST_CONTROL";

const defaultStrings = {
    deviceId: "Device ID",
    endpointName: "Endpoint name",
    name: "Name",
    deviceClass: "Class ID",
    bootstrapDate: "Bootstrap date",
};

interface DeviceListProps {
    catalog?: { [key: string]: AjaxState<ListResponse<Device>> };
    compact?: boolean;
    dispatch?: AppDispatch;
    extraActions?: {};
    extraColumns?: Column[];
    filter?: Filter[];
    filterable?: boolean;
    groupId?: string;
    id: string;
    insightSessionId?: string;
    linkToDeviceDetails?: boolean;
    onError?: ErrorAction;
    onRowClick?: (r: Row) => void;
    onTotalReceived?: (n: number) => void;
    selectable?: boolean;
    strings: DefaultStrings<typeof defaultStrings>;
    title?: string;
    initialFetch?: boolean;
    selection?: string[];
}

interface DeviceListState {
    groupId: string;
    insightSessionId: string;
    data: Device[];
    response: ListResponse<Device>;
    query: string;
    columns: Column[];
}

export class DeviceList extends React.PureComponent<DeviceListProps, DeviceListState> {
    public static defaultProps = {
        strings: defaultStrings,
        id: "device_directory",
        selectable: false,
        compact: true,
        filterable: true,
        dispatch: () => {},
        initialFetch: true,
    };

    constructor(props: DeviceListProps) {
        super(props);

        this.getData = this.getData.bind(this);
        this.handleLoadMoreItems = this.handleLoadMoreItems.bind(this);

        const extraColumns = props.extraColumns ?? [];
        const firstColumnIndex = extraColumns.length;
        const columns: Column[] = [
            {
                id: firstColumnIndex,
                name: "id",
                title: props.strings.deviceId,
                ellipsis: "middle",
                type: props.linkToDeviceDetails ? "link" : "text",
                link: props.linkToDeviceDetails
                    ? (row) => combinePathNameWithCurrentSearch(`/devices/list/${row.original.id}`)
                    : undefined,
            },
            {
                id: firstColumnIndex + 1,
                name: "endpoint_name",
                title: props.strings.endpointName,
                ellipsis: "middle",
            },
            {
                id: firstColumnIndex + 2,
                name: "name",
                title: props.strings.name,
                ellipsis: "front",
            },
            {
                id: firstColumnIndex + 3,
                name: "device_class",
                title: props.strings.deviceClass,
                ellipsis: "end",
            },
            {
                id: firstColumnIndex + 4,
                name: "bootstrapped_timestamp",
                title: props.strings.bootstrapDate,
                type: "date",
                ellipsis: "end",
            },
        ];

        this.state = {
            groupId: props.groupId ?? "",
            insightSessionId: props.insightSessionId ?? "",
            query: props.filter && props.filter.length > 0 ? buildQuery(props.filter) : "",
            response: {
                has_more: false,
                limit: 1000,
                total_count: 0,
                data: [],
            },
            data: [],
            columns: [...extraColumns, ...columns],
        };
    }

    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps: DeviceListProps) {
        const id = this.state.groupId || this.state.insightSessionId || DEFAULT_OPERATION_ID;

        const newQuery = this.props.filter ? buildQuery(this.props.filter) : "";
        if (this.state.query !== newQuery) {
            this.setState(
                {
                    query: newQuery,
                    data: [],
                },
                () => {
                    this.getData();
                }
            );
        }

        if (
            (this.props.groupId ?? "") !== this.state.groupId ||
            (this.props.insightSessionId ?? "") !== this.state.insightSessionId
        ) {
            this.setState(
                {
                    groupId: this.props.groupId ?? "",
                    insightSessionId: this.props.insightSessionId ?? "",
                    data: [],
                },
                () => {
                    this.getData();
                }
            );
        }

        onStateChange(prevProps.catalog?.[id], this.props.catalog?.[id], {
            loaded: (catalogData) => {
                const data = catalogData.data;
                this.setState({
                    data: concatData(this.state.data, data.data),
                    response: data,
                });

                if (this.props.onTotalReceived) {
                    this.props.onTotalReceived(data.total_count);
                }
            },
        });
    }

    getData(last?: string, filter = this.state.query) {
        if (!this.props.initialFetch && !last) return;
        const id = this.state.groupId || this.state.insightSessionId || DEFAULT_OPERATION_ID;
        const query = filter.replace(/&+$/g, "");
        this.props.dispatch?.(
            getDevices({
                id,
                groupId: this.state.groupId,
                insightSessionId: this.state.insightSessionId,
                query,
                pageSize: this.state.response.limit,
                last,
                errorAction: this.props.onError,
            })
        );
    }

    handleLoadMoreItems() {
        this.getData(this.state.response.last);
    }

    render() {
        const pagingPageSize = [
            { text: "10", value: 10 },
            { text: "50", value: 50 },
            { text: "100", value: 100 },
        ];
        const id = this.state.groupId || this.state.insightSessionId || DEFAULT_OPERATION_ID;

        const loading = isLoading(this.props.catalog?.[id]);

        const props: Partial<GridWrapProps> = {};

        if (this.props.compact) {
            props.showPageSizeSelector = false;
            props.defaultPageSize = pagingPageSize[0].value;
            props.pagingPageSizeSmall = pagingPageSize;
            props.pagingPageSizeLarge = pagingPageSize;
            props.noBottomPaging = true;
        }

        if (this.props.filterable) {
            props.showFilter = true;
            props.filterBy = [0, 2];
        }

        return (
            <React.Fragment>
                {this.props.title && <h4>{this.props.title}</h4>}
                <GridWrap
                    {...props}
                    id={this.props.id}
                    columns={this.state.columns}
                    data={this.state.data}
                    info={this.state.response}
                    loading={loading}
                    noSelection={!this.props.selectable}
                    wrapperClass="gridContainer"
                    tableClass="gridComponent"
                    extraActions={this.props.extraActions}
                    onGridLoadMore={this.handleLoadMoreItems}
                    onRowClick={this.props.onRowClick}
                    showFilter={false}
                />
            </React.Fragment>
        );
    }
}

export const mapStateToProps = (state: AppState) => ({
    catalog: state.devices,
});

export default connect(mapStateToProps)(translate("DeviceList")(DeviceList));
