import React from "react";
import { createPortal } from "react-dom";
import { CalloutState } from "../../creators/CalloutCreator";
import ToastCalloutComponent from "../toastCalloutComponent";

interface NotificationsProps {
    hidden: boolean;
    callout: CalloutState;
}

export default class Notifications extends React.PureComponent<NotificationsProps> {
    public render() {
        const getNesting = (item: HTMLElement) => (item.dataset.nesting !== undefined ? +item.dataset.nesting : 0);
        const mostNested = (a: HTMLElement | null, b: HTMLElement) => (!a || getNesting(b) > getNesting(a) ? b : a);

        const possibleContainers = Array.from(document.getElementsByClassName("page-extra-header-content"));
        const mostSpecificContainer = possibleContainers
            .map((x) => x as HTMLElement)
            .reduce((acc, item) => mostNested(acc, item), null as HTMLElement | null);

        return mostSpecificContainer ? createPortal(this.renderImpl(), mostSpecificContainer) : this.renderImpl();
    }

    private renderImpl() {
        const { hidden, callout } = this.props;

        if (hidden) {
            return null;
        }

        return <ToastCalloutComponent callout={callout} />;
    }
}
