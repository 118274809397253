// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* global __VERSION__ */
import { Icon, Icons, If, MediaQueries, MediaQuery } from "portal-components";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Config } from "../../typings/interfaces";
import { AppState } from "../../creators/reducers";
import CookieConsent from "../../controls/cookieConsent";
import translate, { DefaultStrings } from "../../i18n/translate";
import LoadingComponent from "../../layout/loadingComponent";
import { getBrandedLoginBackground, getBrandedLogo, isBrandedTeamLogo } from "../../script/branding";

declare const __VERSION__: string;

const defaultStrings = {
    back: "back",
    leadHeader: "Izuma Device Management is available to evaluate as a free trial.",
    leadLink: "Activate account",
    leadTagline: "Click the button to find instructions on how to activate your account.",
    poweredBy: "Powered by Izuma",
};

export interface BaseLoginComponentProps {
    children?: React.ReactNode | React.ReactElement | React.ReactElement[] | null;
    strings: DefaultStrings<typeof defaultStrings>;
    config: Config;
    id?: string;
    returnUrl?: string;
    loading?: boolean;
    showBackButton?: boolean;
    onBackClick?: () => void;
}

interface BaseLoginComponentState {
    small?: boolean;
}

export class BaseLoginComponent extends React.PureComponent<BaseLoginComponentProps, BaseLoginComponentState> {
    public static readonly defaultProps = {
        strings: defaultStrings,
        loading: false,
        showBackButton: false,
    };

    constructor(props: BaseLoginComponentProps) {
        super(props);
        this.state = {
            small: false,
        };
        this.handleBackClick = this.handleBackClick.bind(this);
    }

    handleBackClick(e: React.MouseEvent) {
        e.preventDefault();
        this.props.onBackClick && this.props.onBackClick();
    }

    render() {
        const { strings, id, loading, config, onBackClick, showBackButton } = this.props;
        const { onPremises = false, externalLinks = {} as Config["externalLinks"] } = config;
        const { cookiePolicy, registration } = externalLinks;

        const cookieMessage = loading ? null : (
            <CookieConsent type="callout" className="callout" buttonType="click" link={cookiePolicy} />
        );

        const registrationLink = registration || "https://os.mbed.com/pelion-free-tier";

        const registerNoticeEnabled = false; // Disable the registration notice text as free trial isn't provided.
        const registerNotice = !registerNoticeEnabled ? null : (
            <div>
                <p className="lead">{strings.leadHeader}</p>
                <p>{strings.leadTagline}</p>
                <a className="button" href={registrationLink} rel="external noopener noreferrer" target="_blank">
                    {strings.leadLink}
                </a>
            </div>
        );

        const lead = onPremises ? null : (
            <div className="register-notice">
                {registerNotice}
                <div>{!this.state.small && cookieMessage}</div>
            </div>
        );

        let backButton = null;

        if (showBackButton) {
            const backContent = (
                <React.Fragment>
                    <Icon name={Icons.GoBack} />
                    &nbsp;{this.props.strings.back}
                </React.Fragment>
            );

            backButton = onBackClick ? (
                <a onClick={this.handleBackClick}>{backContent}</a>
            ) : (
                <Link to={this.props.returnUrl || "/login"} className="back">
                    {backContent}
                </Link>
            );
        }

        const logoSrc = getBrandedLogo(config, true);
        const showPoweredBy = config.showPoweredBy || (!config.onPremises && isBrandedTeamLogo(config));

        const content = loading ? (
            <LoadingComponent random />
        ) : (
            <React.Fragment>
                {backButton}
                <div className="mbedLogoContainer">
                    <img src={logoSrc} alt="" className="mbedLogo" />
                </div>
                <If condition={showPoweredBy}>
                    <div className="powered-by">{strings.poweredBy}</div>
                </If>
                {this.props.children}
                {this.state.small && cookieMessage}
            </React.Fragment>
        );

        return (
            <div id={id} className="blue">
                <MediaQuery query={MediaQueries.SmallOnly} onChanged={(small) => this.setState({ small })} />
                <div id="not-authenticated" />
                <div className="left-pane">
                    <div className="left-pane-scroll login">{content}</div>
                </div>
                <div
                    className="right-pane hide-for-small-only"
                    style={{ backgroundImage: getBrandedLoginBackground(config) }}
                />
                {lead}
                <div className="version">{__VERSION__}</div>
            </div>
        );
    }
}

function mapStateToProps(state: AppState) {
    return {
        config: state.config,
    };
}

export default connect(mapStateToProps)(translate("BaseLoginComponent")(BaseLoginComponent));
