import React from "react";
import ErrorHandler from "../../controls/errorHandler";
import BaseLoginComponent from "./loginComponent";

interface Props {
    message: string | object;
    onBackClick: () => void;
}
export const OidcErrorComponent = (props: Props) => {
    const { message, onBackClick } = props;

    return (
        <BaseLoginComponent showBackButton onBackClick={onBackClick}>
            <ErrorHandler message={message} />
        </BaseLoginComponent>
    );
};
