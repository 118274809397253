import { makeApiCreator, makeConfigApiCreator } from "../../../creators";

export interface ArchiveInfoResponse {
    client_version: string;
    download_size_bytes: number;
    download_url: string;
    license_type: string;
    license_version: string;
    os: string;
    release_notes_url: string;
    version: string;
}

export interface FactoryReleaseNote {
    text: string;
}

const { reducer: downloadReducer, createThunk: downloadActionThunk } = makeConfigApiCreator<ArchiveInfoResponse>(
    "download_base"
)("factory_tool_download");

const downloadAction = downloadActionThunk({
    param: "downloads/fcu/info",
    errorCallout: false,
});

const getApiUrl = () => "";

const { reducer: releaseReducer, createThunk: releaseActionThunk } = makeApiCreator<FactoryReleaseNote>(getApiUrl)(
    "factory_tool_release_note"
);
const releaseAction = (url: string) =>
    releaseActionThunk({
        param: url,
    });

export { downloadAction, downloadReducer, releaseAction, releaseReducer };
