import React from "react";
import translate, { DefaultStrings } from "../../i18n/translate";

const defaultStrings = {
    blocked: "Blocked (suspended)",
    enabled: "Enabled",
};

export interface DeviceLifecycleStatusProps {
    strings: DefaultStrings<typeof defaultStrings>;
    status: string;
}

export class DeviceLifecycleStatus extends React.PureComponent<DeviceLifecycleStatusProps> {
    public static readonly defaultProps = {
        strings: defaultStrings,
    };

    public render() {
        const { status, strings } = this.props;
        return <option value={status}>{(strings as any)[status] || status}</option>;
    }
}

export default translate("DeviceLifecycleStatus")(DeviceLifecycleStatus);
