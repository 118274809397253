import moment from "moment";
import { Button, Icon, Icons, LinkButton, TextBox, ValueEvent } from "portal-components";
import React from "react";
import { Link } from "react-router-dom";
import translate from "../../i18n/translate";
import { IdentityProvider } from "./federated/creator";
import { getFeatureToggle } from "../../script/utility";
import { Config } from "../../typings/interfaces";
import { AppState } from "../../creators/reducers";
import { connect } from "react-redux";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const classes = require("./login.scss");

const SUGGEST_FEDERATED_AFTER_FAILED = 1;

const defaultStrings = {
    forgottonPassword: "Forgot your password?",
    loginHeader: "Log in to use Izuma (formerly Pelion) Device Management services.",
    loginHeaderOnPrem: "Log in to use Device Management services.",
    mbedLogin: "Use your Mbed.com account to log in",
    oidcLoginButton: "Log in with {0}",
    cloudLogin: "Use your Izuma account to log in",
    password: "Password",
    username: "Email",
    lastLogin: "Last login {0}",
    loginButton: "Log in",
    cloudLoginButton: "Log in with email & password",
    or: "or",
    loginWithFederated: "Log in with another provider",
    loginWithFederatedSuggest: "Try using another login method",
    loginWithNative: "Log in with email & password",
    loginWithTeam: "Log in with Team SSO",
};

export interface InitialLoginComponentProps {
    enableMbedLogin?: boolean;
    mbedIdpIssuer?: string;
    loginHeader?: string;
    isOnPrem?: boolean;
    identityProviders: IdentityProvider[];
    email: string;
    password: string;
    onEmailChange: (email: string) => void;
    onPasswordChange: (password: string) => void;
    submitting?: boolean;
    captcha?: React.ReactNode;
    message?: string;
    errorMessage: string | JSX.Element;
    next: string;
    onChangeLoginType: () => void;
    onLoginWithTeam: () => void;
    showNativeLogin?: boolean;
    loginAttempts?: number;
    lastLogin?: { time?: string; cloud?: boolean };
    strings: typeof defaultStrings;
    config: Config;
}

export class InitialLoginComponent extends React.Component<InitialLoginComponentProps> {
    public static readonly defaultProps = {
        lastLogin: {},
        loginAttempts: 0,
        onEmailChange: () => {},
        onPasswordChange: () => {},
        onChangeLoginType: () => {},
        showNativeLogin: true,
        strings: defaultStrings,
    };

    constructor(props: InitialLoginComponentProps) {
        super(props);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleLoginSwitch = this.handleLoginSwitch.bind(this);
        this.handleLoginTeam = this.handleLoginTeam.bind(this);
    }

    handleEmailChange({ value }: ValueEvent<string>) {
        this.props.onEmailChange(value);
    }

    handlePasswordChange({ value }: ValueEvent<string>) {
        this.props.onPasswordChange(value);
    }

    handleLoginSwitch() {
        this.props.onChangeLoginType();
    }

    handleLoginTeam() {
        this.props.onLoginWithTeam();
    }

    render() {
        const {
            email,
            password,
            loginHeader,
            strings,
            isOnPrem,
            identityProviders,
            next,
            lastLogin: { time = "", cloud = false } = {},
            submitting,
            captcha,
            message,
            errorMessage,
            showNativeLogin,
            loginAttempts = 0,
        } = this.props;

        const suggestFederated =
            showNativeLogin && errorMessage && loginAttempts >= SUGGEST_FEDERATED_AFTER_FAILED && !captcha;

        const lastLoginElement = time ? <span>{strings.lastLogin.format(moment(time).calendar())}</span> : null;
        const enableMbedLogin = identityProviders.some((p) => p.type === "MBED");
        const useNewMenu = getFeatureToggle("useNewMenu", this.props);

        interface OtherIdp {
            type: "NATIVE" | "TEAMSSO";
            onClick: () => void;
            id: string;
            name: string;
        }
        const ssoLoginOption = (idp: IdentityProvider) => {
            const { name, id, issuer } = idp;
            const issuerUri = encodeURIComponent(issuer as string);
            const nextUri = encodeURIComponent(next);
            const link = `/federated-login?issuer=${issuerUri}&next=${nextUri}`;
            return (
                <div key={`federated-provider-${id}`} className="federated-login">
                    <LinkButton
                        id="login-with-mbed"
                        to={link}
                        className={["button full-size primary", classes.loginOption]}
                        icon={Icons.LoginFederated}
                        text={strings.oidcLoginButton.format(name)}
                    />
                </div>
            );
        };
        const iamLoginOption = ({ id, onClick, name }: OtherIdp) => {
            return (
                <div key={`federated-provider-${id}`} className="federated-login">
                    <Button
                        id={`login-with-${id}`}
                        className={["button full-size primary", classes.loginOption]}
                        icon={Icons.LoginFederated}
                        text={name}
                        onClick={onClick}
                    />
                </div>
            );
        };
        const nativeIdp: OtherIdp[] = [
            {
                type: "NATIVE",
                id: "iam",
                name: strings.loginWithNative,
                onClick: this.handleLoginSwitch,
            },
            { type: "TEAMSSO", id: "team", name: strings.loginWithTeam, onClick: this.handleLoginTeam },
        ];
        const loginOptions = identityProviders.filter(({ type }) => type !== "NATIVE").map((p) => ssoLoginOption(p));
        const nativeOptions = nativeIdp.map((p) => iamLoginOption(p));

        const federatedLogin = (
            <React.Fragment>
                {loginOptions}
                {nativeOptions}
            </React.Fragment>
        );
        const loginTypeSwitch =
            identityProviders.length === 0 ? null : (
                <div id="login-switch-button" className="clickable login-switch" onClick={this.handleLoginSwitch}>
                    <span className="fa-layers fa-fw">
                        <Icon name={Icons.Badge} className="fa-lightGrey" transform="grow-10" />
                        <Icon
                            name={suggestFederated ? Icons.LoginSuggest : Icons.LoginFederated}
                            transform="shrink-3"
                        />
                    </span>
                    &emsp;
                    <span className="inlineBlock">
                        {suggestFederated ? strings.loginWithFederatedSuggest : strings.loginWithFederated}
                    </span>
                </div>
            );

        const nativeLogin = (
            <React.Fragment>
                <div className="cloud-login">
                    <TextBox
                        id="email"
                        name="email"
                        type="email"
                        label={strings.username}
                        autoComplete="email"
                        autoFocus={!email}
                        required
                        value={email}
                        onValueChange={this.handleEmailChange}
                    />
                    <TextBox
                        id="password"
                        name="password"
                        type="password"
                        label={strings.password}
                        autoComplete="password"
                        autoFocus={!!email}
                        required
                        value={password}
                        onValueChange={this.handlePasswordChange}
                    />
                    {captcha}
                    <div id="login-result-area" className={errorMessage && loginAttempts > 0 ? "red" : "help-text"}>
                        {(loginAttempts > 0 ? errorMessage : "") || message}
                        {suggestFederated ? loginTypeSwitch : null}
                    </div>
                    <Button
                        id="sign-in-button"
                        submit
                        primary
                        className={useNewMenu ? "full-size pelion-login" : "full-size"}
                        text={enableMbedLogin ? strings.cloudLoginButton : strings.loginButton}
                        busy={submitting}
                    />
                    <br />
                    <div className="grid-x">
                        <span className="cell small-12 hide-for-medium text-right">
                            {cloud ? lastLoginElement : null}
                        </span>
                        <span className="cell medium-6 hide-for-small-only">{cloud ? lastLoginElement : null}</span>
                        <span className="cell small-12 medium-6 text-right">
                            <Link id="login-recovery" to={{ pathname: "/login-recovery", state: { email } }}>
                                {strings.forgottonPassword}
                            </Link>
                        </span>
                    </div>
                </div>
                <p className="or-separator">&mdash;&nbsp;{strings.or}&nbsp;&mdash;</p>
                {suggestFederated ? null : loginTypeSwitch}
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <p className="lead">{loginHeader || (isOnPrem ? strings.loginHeaderOnPrem : strings.loginHeader)}</p>
                {showNativeLogin ? nativeLogin : federatedLogin}
            </React.Fragment>
        );
    }
}

// map Redux store state to React props
function mapStateToProps(state: AppState) {
    return {
        config: state.config,
    };
}

export default connect(mapStateToProps)(translate("InitialLoginComponent")(InitialLoginComponent));
