import { makeConfigApiCreator } from "../../../creators/AjaxApiCreator";
import { ListResponse } from "../../../script/types";

interface CertificateEnrollment {
    id: string;
    created_at: string;
    enroll_status: "completed" | "new" | "pending";
    enroll_result: "success" | "failure";
    enroll_result_detail?: string;
    device_id: string;
    certificate_name: string;
    updated_at?: string;
}

// Renew a device certificate
const { createThunk: createRenewThunk, reducer: deviceRenewReducer } = makeConfigApiCreator<CertificateEnrollment>(
    "v3_base",
    {
        errorRedirect: false,
        errorCallout: false,
    }
)("catalog_renew_device_certificate");

const renewAction = (deviceId: string, certificateName: string) =>
    createRenewThunk({
        param: `devices/${deviceId}/certificates/${certificateName}/renew`,
        method: "POST",
    });

// Gets all certificate enrollment requests
const { createThunk: createCertificateEnrollmentsThunk, reducer: certificateEnrollmentsReducer } = makeConfigApiCreator<
    ListResponse<CertificateEnrollment>
>("v3_base", {
    errorCallout: true,
})("certificate_enrollments", (untypedResponse) => {
    const response = untypedResponse as ListResponse<CertificateEnrollment>;
    if (response.data && response.data.length > 1) {
        response.last = response.data[response.data.length - 1].id;
    }

    return response;
});

interface CertificateEnrollmentsParameters {
    deviceId?: string;
    last?: string;
    pageSize?: number;
    order?: string;
}

const getCertificateEnrollments = ({
    deviceId,
    last,
    pageSize = 50,
    order = "DESC",
}: CertificateEnrollmentsParameters) => {
    const queryParameters = {
        order,
        limit: pageSize?.toString(),
        include: "total_count",
        after: last,
        device_id__eq: deviceId,
    };

    return createCertificateEnrollmentsThunk({
        param: "certificate-enrollments",
        queryParameters,
        errorRedirect: false,
    });
};

const EdgeGateway = "MBED_GW";

interface GatewayCoordinates {
    latitude: number;
    longitude: number;
}

interface Gateway {
    description?: string;
    name?: string;
    accountID?: string;
    siteID?: string;
    coordinates?: GatewayCoordinates;
    pairingCode?: string;
    id?: string;
    devicedbConnected?: boolean;
    devicejsConnected?: boolean;
}

const {
    createThunk: createGatewayThunk,
    reducer: gatewayReducer,
    createResetAction: createGatewayResetAction,
} = makeConfigApiCreator<Gateway>("v3_alpha")("gateway"); // v3_alpha is now deprecated

const getGateway = (id: string) =>
    createGatewayThunk({
        param: `devices/${id}/gateway`,
        errorRedirect: false,
    });
const resetGateway = createGatewayResetAction();

const { createThunk: createUpdateGatewayThunk, reducer: updateGatewayReducer } = makeConfigApiCreator<Gateway>(
    "v3_alpha"
)("gateway_update");

const updateGateway = ({ id, data }: { id: string; data: GatewayCoordinates }) => {
    return createUpdateGatewayThunk({
        param: `devices/${id}/gateway`,
        data: { coordinates: data },
        method: "PUT",
    });
};

const { createThunk: resetDeviceSessionThunk, reducer: resetDeviceSessionReducer } = makeConfigApiCreator<void>(
    "v3_base"
)("reset_session");

const resetDeviceSession = (id: string) => {
    return resetDeviceSessionThunk({
        param: `devices/${id}/reset-session`,
        method: "POST",
    });
};

export {
    renewAction,
    deviceRenewReducer,
    getCertificateEnrollments,
    certificateEnrollmentsReducer,
    CertificateEnrollment,
    getGateway,
    gatewayReducer,
    EdgeGateway,
    resetGateway,
    updateGatewayReducer,
    resetDeviceSessionReducer,
    updateGateway,
    resetDeviceSession,
    Gateway,
    GatewayCoordinates,
};
