import React from "react";
import { connect } from "react-redux";
import { AjaxState, isLoaded, onStateChange } from "../../creators/AjaxCreator";
import { Config } from "../../typings/interfaces";
import { AppState } from "../../creators/reducers";
import { mergeBuiltInListWithConfiguration } from "../../script/utility";
import { CurrentAccount } from "../account";
import SupportComponent from "./component";

interface SupportContainerProps {
    currentAccount: AjaxState<CurrentAccount>;
    config: Config;
}

interface SupportContainerState {
    isFreeAccount: boolean;
}

export class SupportContainer extends React.PureComponent<SupportContainerProps, SupportContainerState> {
    constructor(props: SupportContainerProps) {
        super(props);
        const isFreeAccount = isLoaded(props.currentAccount)
            ? parseInt(props.currentAccount.data.tier as string) === 0
            : true;
        this.state = {
            isFreeAccount,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: SupportContainerProps) {
        onStateChange(this.props.currentAccount, nextProps.currentAccount, {
            loaded: ({ data }) => {
                this.setState({
                    isFreeAccount: parseInt(data.tier as string) === 0,
                });
            },
        });
    }

    shouldComponentUpdate(nextProps: SupportContainerProps) {
        return isLoaded(nextProps.currentAccount);
    }

    render() {
        const { config } = this.props;
        const { documentation, mbedForum, supportEmailAddress, supportLink, upgradeLink } = config.externalLinks || {};
        const { onPremises, contactUsMarkup } = config;
        const { isFreeAccount } = this.state;

        const phoneNumbers = mergeBuiltInListWithConfiguration({}, this.props.config.phoneNumbers);
        const parentAccount =
            (isLoaded(this.props.currentAccount) && this.props.currentAccount.data?.parent_account) || undefined;

        return (
            <SupportComponent
                {...{
                    documentation,
                    mbedForum,
                    supportEmailAddress,
                    supportLink,
                    phoneNumbers,
                    isFreeAccount,
                    upgradeLink,
                    onPremises,
                    contactUsMarkup,
                    parentAccount,
                }}
            />
        );
    }
}

export const mapStateToProps = (state: AppState) => {
    return {
        currentAccount: state.currentaccount,
        config: state.config,
    };
};

export default connect(mapStateToProps)(SupportContainer);
