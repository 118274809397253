import { SDKError } from "mbed-cloud-sdk";
import {
    Button,
    Dialog,
    DialogButtons,
    Else,
    ErrorMessage,
    Form,
    If,
    TextBox,
    ValueEvent,
    ButtonSeverity,
} from "portal-components";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { Config } from "../../../typings/interfaces";
import Markup from "../../../controls/markup";
import { AjaxState, isLoaded, onStateChange } from "../../../creators/AjaxCreator";
import CalloutCreator, { CalloutState, CreateCalloutReturn } from "../../../creators/CalloutCreator";
import translate, { DefaultStrings } from "../../../i18n/translate";
import CalloutComponent from "../../../layout/calloutComponent";
import { AuthState } from "../../../layout/reducer";
import { AppDispatch, AppState } from "../../../creators/reducers";
import { redirectTo } from "../../../script/routing";
import { ListResponse } from "../../../script/types";
import { formValidate } from "../../../script/utility";
import { CurrentAccount, CurrentUser } from "../../account";
import Captcha from "../../captcha";
import { getLoginCookie, login } from "../creator";
import { IdentityProvider, identityProviderAction } from "../federated/creator";
import { LoginCredentials } from "../team/api";
import { postInitialSamlLogin, SamlLoginResponse } from "./creator";

const oidcEntryUrl = `${window.location.origin}/federated-login/reauthenticate?issuer={0}`;
const samlEntryUrl = `${window.location.origin}/saml-login/reauthenticate?redirect={0}`;

const defaultStrings = {
    anErrorOccurred: "An error occurred",
    captchaPrompt: "Type the characters from the image above",
    enterButton: "Enter",
    password: "Password",
    enterPassword: "Please enter your password to continue with this action.",
    enterPasswordAndTotp:
        "Please enter your password and authentication code generated by your Authenticator App to continue with this action.",
    enterTotp:
        "Please enter your authentication code generated by your Authenticator App to continue with this action.",
    errorLoadingIdP: "An error has occurred",
    reauthenticateButton: "Reauthenticate",
    federatedReauthRequired:
        "Please log in again to continue with this action.\n Note that this window will close after 1 minute of inactivity.",
    federatedReauthLoggingIn: "Complete the login in the new window.",
    mbedReauthTimeout: "Login window closed due to inactivity. Your changes have not been saved.",
    modalTitle: "Authentication required",
    passwordNotSet: "Please enter your password",
    totp: "Six-digit authentication code",
    totpNotSet: "Please enter your authentication code",
    signedIn: "Success.",
    unknownIdentityProvider: "Unknown identity provider",
};

export interface ReAuthenticateProps extends RouteComponentProps {
    auth: AuthState;
    identityProvider: AjaxState<ListResponse<IdentityProvider>>;
    currentAccount: AjaxState<CurrentAccount>;
    currentUser: AjaxState<CurrentUser>;
    callout: CalloutState;
    config: Config;
    initialSamlLogin: AjaxState<SamlLoginResponse>;
    onClose: () => void;
    strings: DefaultStrings<typeof defaultStrings>;
    openWindow?: (url?: string) => Window | null;
    actions: {
        createCallout: (header: string) => CreateCalloutReturn;
        identityProviderAction: typeof identityProviderAction;
        login: typeof login;
        fireAction: (type: string, args?: { [key: string]: unknown }) => unknown;
        postInitialSamlLogin: typeof postInitialSamlLogin;
    };
}

export interface ReAuthenticateState {
    username: string;
    submitting: boolean;
    password: string;
    message: string;
    isTotpEnabled: boolean;
    totp: string;
    isCaptcha: boolean;
    captchaId: string;
    captchaAnswer: string;
    federatedCredentials: LoginCredentials | undefined;
    loginFailed: boolean;
    federatedTimeout?: number;
}

interface AuthInfoBody {
    fields: { name: string; message: string }[];
    message: string;
    type: string;
}

export class ReAuthenticate extends React.PureComponent<ReAuthenticateProps, ReAuthenticateState> {
    public static defaultProps = {
        onClose: () => {},
        openWindow: (url?: string) => window.open(url),
        strings: defaultStrings,
    };

    private externalAuthWindow?: Window | null;

    constructor(props: ReAuthenticateProps) {
        super(props);

        const { username = "" } = getLoginCookie();
        this.state = {
            username,
            submitting: false,
            password: "",
            message: "",
            isTotpEnabled: isLoaded(props.currentUser) && (props.currentUser?.data?.is_totp_enabled ?? false),
            totp: "",
            isCaptcha: false,
            captchaId: "",
            captchaAnswer: "",
            federatedCredentials: undefined,
            loginFailed: false,
        };

        this.handleCancel = this.handleCancel.bind(this);
        this.handleCaptchaChange = this.handleCaptchaChange.bind(this);
        this.handleCaptchaFieldChange = this.handleCaptchaFieldChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleOidcLoginClick = this.handleOidcLoginClick.bind(this);
        this.handleSamlLoginClick = this.handleSamlLoginClick.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.handleTotpChange = this.handleTotpChange.bind(this);
        this.handleFederatedCallback = this.handleFederatedCallback.bind(this);
        this.handleFederatedTotp = this.handleFederatedTotp.bind(this);
        this.handleFederatedTimeout = this.handleFederatedTimeout.bind(this);
        this.componentCleanup = this.componentCleanup.bind(this);
    }

    componentDidMount() {
        // set the listener for the re-auth new tab
        window.addEventListener("message", this.handleFederatedCallback, false);
        // set the before unload so we can also clean up in case of refresh
        window.addEventListener("beforeunload", this.componentCleanup);
    }

    componentWillUnmount() {
        // only clear the callout when we dont have a timeout defined so we can
        // potentially show the timeout message
        if (!this.state.federatedTimeout) {
            this.props.actions.fireAction(CalloutCreator.actions.clearCallout);
        }
        this.componentCleanup();
    }

    componentDidUpdate(prevProps: ReAuthenticateProps) {
        const { identityProvider, auth, initialSamlLogin } = prevProps;
        // flag for errors
        let loginFailed = false;

        if (this.props.auth.isAuthenticating && !this.state.submitting) {
            this.setState({ submitting: true, loginFailed: false });
        } else if (!this.props.auth.isAuthenticating && this.state.submitting) {
            this.setState({ submitting: false, password: "", message: this.props.auth.authErrorMessage });
        }

        if (auth.type !== this.props.auth.type && this.props.auth.type === "LOGIN_SUCCESS") {
            this.setState({ message: this.props.strings.signedIn, loginFailed });
            this.handleSuccess();
            return;
        }

        if (!this.props.auth.isAuthenticating && this.props.auth.authErrorMessage && !this.state.loginFailed) {
            // Check account status
            let isInactive = false;
            // Get the error message
            let errorMessage = this.props.auth.authErrorMessage;
            // Get more error info
            let isCaptcha = this.state.isCaptcha;

            if (this.props.auth.info && this.props.auth.info.response && this.props.auth.info.response.body) {
                // Check if account is inactive
                const { fields = [], message, type }: AuthInfoBody = this.props.auth.info.response.body;
                const captchaField = fields.find((field) => field.name === "captcha");
                isCaptcha = !!captchaField;
                isInactive =
                    fields && !!fields.find((field) => field.name === "status" && field.message === "INACTIVE");
                // Get the body if there are more details
                errorMessage += type !== "invalid_token" && message ? " " + message : "";
                // for federated dont append the authErrorMessage and show all errors
                if (this.props.auth.federated) {
                    errorMessage = message;
                }
                loginFailed = true;
            }

            // Should account be rerouted
            if (isInactive) {
                redirectTo({ path: "/login-recovery", replace: true, query: { locked: "true" } });
            } else {
                this.setState({
                    message: errorMessage,
                    password: "",
                    captchaAnswer: "",
                    isCaptcha,
                    loginFailed,
                });
            }
        }

        onStateChange(identityProvider, this.props.identityProvider, {
            loading: () => {
                this.setState({ submitting: true });
            },
            loaded: ({ data = { data: [] } }) => {
                const idp = data.data.find((i) => i.issuer === auth.issuer);
                if (idp) {
                    if (this.externalAuthWindow) {
                        this.externalAuthWindow.focus();
                        this.externalAuthWindow.location.assign(oidcEntryUrl.format(idp.issuer));
                    } else {
                        // This will probably be blocked by the popup blocker but it's the last chance...
                        this.props.openWindow?.(oidcEntryUrl.format(idp.issuer));
                    }
                    this.setState({ federatedTimeout: window.setTimeout(this.handleFederatedTimeout, 60000) });
                } else {
                    this.setState({ message: this.props.strings.unknownIdentityProvider, submitting: false });
                }
            },
            failed: (error) => {
                if (this.externalAuthWindow) {
                    this.externalAuthWindow.close();
                }
                this.setState({
                    message: (error as SDKError)?.response?.body?.message ?? this.props.strings.anErrorOccurred,
                    submitting: false,
                });
            },
        });

        onStateChange(initialSamlLogin, this.props.initialSamlLogin, {
            loading: () => {
                this.setState({ submitting: true });
            },
            messageParser: ({ error: { response } }) => {
                return response?.body?.message;
            },
            failed: (message) => {
                if (this.externalAuthWindow) {
                    this.externalAuthWindow.close();
                }

                if (typeof message === "string") {
                    this.setState({
                        message: message || this.props.strings.anErrorOccurred,
                        submitting: false,
                    });
                }
            },
            loaded: ({ data: { redirect_uri: redirectUri } }) => {
                // We've retrieved the SAML login URL, now authenticate in a new tab
                if (this.externalAuthWindow) {
                    this.externalAuthWindow.focus();
                    this.externalAuthWindow.location.assign(samlEntryUrl.format(encodeURIComponent(redirectUri)));
                } else {
                    // This will probably be blocked by the popup blocker but it's the last chance...
                    this.props.openWindow?.(samlEntryUrl.format(encodeURIComponent(redirectUri)));
                }

                this.setState({
                    federatedTimeout: window.setTimeout(this.handleFederatedTimeout, 60000),
                });
            },
        });
    }

    componentCleanup() {
        window.removeEventListener("message", this.handleFederatedCallback);
        window.removeEventListener("beforeunload", this.componentCleanup);
    }

    handleFederatedCallback(message: MessageEvent) {
        // first check that the callback is from the correct origin and we have a string code
        if (message.origin !== window.location.origin || typeof message.data !== "object") {
            return;
        }

        window.clearTimeout(this.state.federatedTimeout);
        const { currentAccount, actions } = this.props;
        const { isTotpEnabled } = this.state;
        if (isLoaded(currentAccount)) {
            const account = currentAccount?.data?.id ?? null;
            const issuer = this.props.auth.issuer;

            if (isTotpEnabled) {
                this.setState({
                    federatedCredentials: message.data,
                    submitting: false,
                });
            } else {
                const formProps = {
                    ...message.data,
                    ...(issuer ? { account, issuer } : {}),
                };

                this.setState({ submitting: true, message: "" });

                actions.login(formProps, false);
            }
        }
    }

    handleFederatedTotp(event: React.MouseEvent<EventTarget> | React.FormEvent<HTMLElement>) {
        event.preventDefault();
        const { auth, currentAccount, actions, strings } = this.props;
        const { isTotpEnabled, totp, federatedCredentials } = this.state;
        const account = isLoaded(currentAccount) ? currentAccount?.data?.id : "";
        const issuer = auth.issuer;

        // Check if we need to validate the TOTP
        if (isTotpEnabled && totp.length === 0) {
            this.setState({
                message: strings.totpNotSet,
            });
            return;
        }

        const formProps = {
            ...federatedCredentials,
            ...(issuer ? { account, issuer } : {}),
            otp: formValidate(totp, "otp", isTotpEnabled) as string,
        };

        this.setState({ submitting: true, message: "", loginFailed: false });

        actions.login(formProps, false);
    }

    handleFederatedTimeout() {
        if (this.externalAuthWindow) {
            this.externalAuthWindow.close();
        }

        this.props.actions.fireAction("REAUTH_CANCEL");
        this.props.actions.createCallout(this.props.strings.mbedReauthTimeout);
        this.props.onClose();
    }

    handlePasswordChange(event: ValueEvent<string>) {
        this.setState({ password: event.value });
    }

    handleTotpChange(event: ValueEvent<string>) {
        this.setState({ totp: event.value });
    }

    handleOidcLoginClick() {
        const { issuer } = this.props.auth;

        // We pre-open the reauth window here in response to the user click,
        // in this way it's not blocked by the Popup Blocker (for example in FF).
        this.externalAuthWindow = this.props.openWindow?.();
        this.externalAuthWindow?.blur();

        this.props.actions.identityProviderAction({ accountId: "", issuer });
    }

    handleSamlLoginClick() {
        const { actions, auth, currentAccount } = this.props;
        const team = isLoaded(currentAccount) ? currentAccount?.data?.id : "";

        // We pre-open the reauth window here in response to the user click,
        // in this way it's not blocked by the Popup Blocker (for example in FF).
        this.externalAuthWindow = this.props.openWindow?.();
        this.externalAuthWindow?.blur();

        // Start the SAML login by retrieving a redirect_uri from the /auth-login endpoint
        actions.postInitialSamlLogin({ idpId: auth.idpId || "", team });
    }

    handleClick(event: React.MouseEvent<EventTarget> | React.FormEvent<HTMLElement>) {
        event.preventDefault();
        const { currentAccount, actions, strings } = this.props;
        const { username, password, isTotpEnabled, totp, captchaId, captchaAnswer, isCaptcha } = this.state;
        const account = isLoaded(currentAccount) ? currentAccount?.data?.id : "";

        if (password.length === 0) {
            this.setState({
                message: strings.passwordNotSet,
            });
            return;
        }
        // Check if we need to validate the TOTP
        if (isTotpEnabled && totp.length === 0) {
            this.setState({
                message: strings.totpNotSet,
            });
            return;
        }
        let formProps: { [key: string]: string } = {
            username,
            account,
            password,
            otp: formValidate(totp, "otp", isTotpEnabled) as string,
        };

        if (isCaptcha) {
            formProps = {
                ...formProps,
                captcha_id: captchaId,
                captcha: captchaAnswer,
            };
        }

        actions.login(formProps, false);
    }

    handleSuccess() {
        this.props.actions.fireAction("REAUTH_SUCCESS");
        this.props.onClose();
    }

    handleCancel(event: Event) {
        event.preventDefault();
        this.props.actions.fireAction("REAUTH_CANCEL");
        this.props.onClose();
    }

    handleCaptchaChange(captchaId: string) {
        this.setState({ captchaId });
    }

    handleCaptchaFieldChange(event: ValueEvent<string>) {
        this.setState({
            captchaAnswer: event.value,
        });
    }

    render() {
        const { auth, strings } = this.props;
        const { submitting, message, isTotpEnabled, isCaptcha, federatedCredentials } = this.state;

        const isFederated = !!(auth.idpId || auth.issuer);
        const isOidcLogin = !!auth.issuer;

        const totpInput = isTotpEnabled ? (
            <TextBox
                type={"text"}
                label={strings.totp}
                id={"totp"}
                name={"totp"}
                value={this.state.totp}
                onValueChange={this.handleTotpChange}
                required
                autoComplete={"one-time-cod"}
            ></TextBox>
        ) : (
            <React.Fragment />
        );

        const captcha = isCaptcha && (
            <Captcha
                inputName="captcha_answer"
                captchaPrompt={strings.captchaPrompt}
                onChange={this.handleCaptchaFieldChange}
                onCaptchaIdChange={this.handleCaptchaChange}
            />
        );

        const explanationText = isFederated
            ? strings.federatedReauthRequired
            : isTotpEnabled
            ? strings.enterPasswordAndTotp
            : strings.enterPassword;

        return (
            <Dialog title={strings.modalTitle} onCloseRequest={this.handleCancel}>
                <div id="reauth-container" className="inner-wrap">
                    <If condition={!submitting && explanationText && !federatedCredentials}>
                        <p>{explanationText}</p>
                    </If>
                    <If condition={submitting}>
                        <If condition={isFederated}>
                            <React.Fragment>
                                <If condition={!federatedCredentials}>
                                    <Markup string={strings.federatedReauthLoggingIn} />
                                </If>
                            </React.Fragment>
                        </If>
                    </If>
                    <If condition={!submitting && isFederated}>
                        <React.Fragment>
                            <p className="reauth-error-message">{message}</p>
                            <If condition={!federatedCredentials}>
                                <If condition={isOidcLogin}>
                                    <Button
                                        id="oidc-login"
                                        severity={ButtonSeverity.Warning}
                                        onClick={this.handleOidcLoginClick}
                                    >
                                        <Markup string={strings.reauthenticateButton} />
                                    </Button>
                                    <Else>
                                        <Button
                                            id="saml-login"
                                            severity={ButtonSeverity.Warning}
                                            onClick={this.handleSamlLoginClick}
                                        >
                                            {strings.reauthenticateButton}
                                        </Button>
                                    </Else>
                                </If>
                            </If>
                            <If condition={isTotpEnabled && federatedCredentials}>
                                {totpInput}
                                <Button
                                    id="reauth-button"
                                    onClick={this.handleFederatedTotp}
                                    busy={submitting}
                                    submit
                                    text={strings.enterTotp}
                                />
                            </If>
                        </React.Fragment>
                    </If>
                    <If condition={!isFederated}>
                        <Form id="reauth-form" onSubmit={this.handleClick} submitting={submitting}>
                            <TextBox
                                type={"password"}
                                label={strings.password}
                                id={"password"}
                                name={"password"}
                                value={this.state.password}
                                onValueChange={this.handlePasswordChange}
                                required
                                autoComplete={"current-password"}
                            ></TextBox>
                            {totpInput}
                            {captcha}
                            <ErrorMessage value={message} />
                            <DialogButtons confirmTitle={strings.enterButton} submitting={submitting} hideCancel />
                        </Form>
                    </If>
                </div>
            </Dialog>
        );
    }
}

export const mapStateToProps = (state: AppState) => {
    const { auth, config, currentaccount, currentuser, initialSamlLogin, identityProvider, callout } = state;
    return {
        auth,
        config,
        currentAccount: currentaccount,
        currentUser: currentuser,
        identityProvider,
        initialSamlLogin,
        callout,
    };
};

export const mapDispatchToProps = (dispatch: AppDispatch) => ({
    actions: bindActionCreators(
        {
            createCallout: (header: string) =>
                CalloutCreator.createCallout(CalloutComponent.severities.warning, header, []),
            identityProviderAction,
            login,
            fireAction: (type: string, args?: { [key: string]: unknown }) => ({ ...(args || {}), type }),
            postInitialSamlLogin,
        },
        dispatch
    ),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(translate("ReAuthenticate")(ReAuthenticate)));
