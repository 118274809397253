import { makeConfigApiCreator } from "../../creators";

interface Captcha {
    captcha_id: string;
    captcha_bytes: string;
}

//GET /auth/captcha
const { reducer: captchaReducer, createThunk: captchaThunk } = makeConfigApiCreator<Captcha>("auth_base", {
    useAuth: false,
    errorCallout: false,
    errorRedirect: false,
})("captcha");

const getCaptcha = () => captchaThunk({ param: "captcha", method: "GET" });

export { captchaReducer, getCaptcha, Captcha };
