import { ButtonVariant, DropdownButton, evalBoolean, List, ValueEvent } from "portal-components";
import React from "react";
import { DropdownFilterItem, DropdownFilterItemProps } from "./dropdownFilterItem";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const classes = require("./style.scss");

export interface DropdownFilterProps<T> {
    when?: boolean;
    id?: string;
    label: string;
    value: T;
    items: DropdownFilterItemProps[];
    selectionMustExist: boolean;
    onChange: (event: ValueEvent<T>) => void;
}

interface DropDownFilterContextProps<T> {
    value?: T;
    select: (value: T) => void;
    selectionExists: boolean;
}

export const DropdownFilterContext = React.createContext<DropDownFilterContextProps<unknown>>({
    select: () => {},
    selectionExists: false,
});

export class DropdownFilter<T> extends React.PureComponent<DropdownFilterProps<T>> {
    public static readonly defaultProps = {
        selectionMustExist: true,
    };

    constructor(props: Readonly<DropdownFilterProps<T>>) {
        super(props);

        this.select = this.select.bind(this);
        this.state = {
            value: props.value,
        };
    }

    public render() {
        const { when, id, items, selectionMustExist, value } = this.props;

        if (when !== undefined && !evalBoolean(when)) {
            return null;
        }

        const selectedItem = items.find((x) => x.value === value);
        if (selectedItem === undefined && selectionMustExist) {
            throw Error(`There must be one selected item in the filter ${id} (no items with value '${value}').`);
        }

        const context = { value, selectionExists: !!selectedItem, select: this.select };

        return (
            <DropdownFilterContext.Provider value={context}>
                <DropdownButton
                    id={id}
                    variant={ButtonVariant.Light}
                    text={this.resolveText(selectedItem)}
                    className={classes.dropdownFilter}
                    indicator
                >
                    <List>
                        {this.props.items.map((item) => (
                            <DropdownFilterItem key={`${item.text}-${item.value}`} {...item} />
                        ))}
                    </List>
                </DropdownButton>
            </DropdownFilterContext.Provider>
        );
    }

    private resolveText(selectedItem: DropdownFilterItemProps | undefined) {
        const { label } = this.props;

        if (!selectedItem || selectedItem.default) {
            return label || "";
        }

        if (label === undefined) {
            return selectedItem.text;
        }

        return `${label}: ${selectedItem.text}`;
    }

    private select(value: unknown) {
        if (this.props.onChange) {
            this.props.onChange({ value: value as T });
        }
    }
}
