import React from "react";
import Markup from "../../controls/markup";

export interface OnPremisesCustomMarkUpProps {
    translatedContent: { [key: string]: string };
    language: string;
}

export const OnPremisesCustomMarkUp: React.FunctionComponent<OnPremisesCustomMarkUpProps> = (props) => {
    const content =
        props.translatedContent[props.language] ||
        Object.entries(props.translatedContent).filter((entry) => !!entry[1])[0][1];

    return <Markup className="markup contactus" string={content} full />;
};

export default OnPremisesCustomMarkUp;
