import { ErrorAction, makeConfigApiCreator } from "../../../creators";

interface InviteStatus {
    id: string;
    email: string;
    team: string;
    account_id: string;
    flow: "acceptance" | "enrollment" | "forbidden";
    created_at: string;
    updated_at: string;
}

const { reducer: inviteStatusReducer, createThunk: getInviteStatusThunk } = makeConfigApiCreator<InviteStatus>(
    "auth_base",
    { useAuth: false }
)("invite_status");

const getInviteStatus = (id: string, errorAction?: ErrorAction) => {
    return getInviteStatusThunk({
        param: `invitations/${id}`,
        errorAction,
        errorRedirect: false,
    });
};

interface InviteAccept {
    password?: string;
    address?: string;
    phone_number?: string;
    full_name?: string;
    is_marketing_accepted?: boolean;
    is_gtc_accepted?: boolean;
    username?: string;
    code?: string;
}

const { reducer: inviteAcceptReducer, createThunk: inviteAcceptThunk } = makeConfigApiCreator<void>("auth_base", {
    useAuth: false,
})("invite_accept");

const acceptInvite = (id: string, data?: InviteAccept) => {
    return inviteAcceptThunk({
        param: `invitations/${id}`,
        data,
        method: "PUT",
    });
};

export { getInviteStatus, acceptInvite, inviteStatusReducer, inviteAcceptReducer, InviteStatus, InviteAccept };
