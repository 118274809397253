import { GroupPanel } from "portal-components";
import React from "react";
import Markup from "../../controls/markup";
import translate from "../../i18n/translate";

const defaultStrings = {
    header: "Resources",
    docsLink: "[Izuma Device Management Documentation]({0}).",
};

export interface FooterProps {
    strings: typeof defaultStrings;
    documentation: string;
    mbedForum: string;
}

export const Footer: React.FunctionComponent<FooterProps> = (props) => {
    const { strings, documentation } = props;
    return (
        <GroupPanel title={strings.header}>
            <Markup string={strings.docsLink.format(documentation)} />
        </GroupPanel>
    );
};

Footer.defaultProps = {
    strings: defaultStrings,
};

export default translate(["Footer"])(Footer);
