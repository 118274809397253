import { Button, Icons, TextBox, ValueEvent } from "portal-components";
import React from "react";
import translate, { DefaultStrings } from "../../i18n/translate";

const defaultStrings = {
    captchaNotEnteredPrompt: "Please complete captcha before submitting",
};

interface CaptchaComponentProps {
    strings: DefaultStrings<typeof defaultStrings>;
    imgSource: string;
    captchaPrompt: string;
    onChange: (e: ValueEvent<string>) => void;
    onRefresh: () => void;
    inputName: string;
    answerNotEntered: boolean;
    loading: boolean;
    autoFocus: boolean;
}

interface CaptchaComponentState {
    value: string;
}

export class CaptchaComponent extends React.PureComponent<CaptchaComponentProps, CaptchaComponentState> {
    public static defaultProps = {
        strings: defaultStrings,
    };

    constructor(props: CaptchaComponentProps) {
        super(props);
        this.handleRefresh = this.handleRefresh.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = { value: "" };
    }
    handleRefresh(e: React.MouseEvent<HTMLElement, MouseEvent>) {
        e.preventDefault();
        this.props.onRefresh();
    }

    handleChange(e: ValueEvent<string>) {
        this.setState({ value: e.value });
        this.props.onChange(e);
    }

    render() {
        const { imgSource, captchaPrompt, answerNotEntered, inputName, loading } = this.props;
        const captchaImage = imgSource ? "data:image/png;base64, " + imgSource : "";
        return (
            <div className="captcha">
                <img className="captcha-img" src={captchaImage} />
                <Button className="captcha-refresh" busy={loading} icon={Icons.Refresh} onClick={this.handleRefresh} />
                <p>{captchaPrompt}</p>
                <TextBox
                    className={answerNotEntered ? "is-invalid-input" : ""}
                    autoComplete="off"
                    required
                    autoFocus={this.props.autoFocus}
                    name={inputName}
                    value={this.state.value}
                    onValueChange={this.handleChange}
                />
                {this.props.answerNotEntered ? (
                    <p className="help-text alert-text">{this.props.strings.captchaNotEnteredPrompt}</p>
                ) : null}
            </div>
        );
    }
}

export default translate("CaptchaComponent")(CaptchaComponent);
