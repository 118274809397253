import { makeCollectionCreator, makeConfigApiCreator } from "../../../creators";
import { ListResponse, reducerRegistry } from "../../../script";

export interface PolicyGroup {
    id: string;
    name: string;
    account_id: string;
    creation_time: number;
    last_update_time: number;
    user_count: number;
    apikey_count: number;
    created_at: Date;
    updated_at: Date;
    index: number;
    selected: boolean;
}

const { reducer, createThunk } = makeConfigApiCreator<ListResponse<PolicyGroup>>("v3_base")(
    "iam_groups",
    (response) => ({
        ...(response as ListResponse<PolicyGroup>),
        data: (response as ListResponse<PolicyGroup>).data.map(
            (group: PolicyGroup, index: number): PolicyGroup => {
                return {
                    ...group,
                    index,
                    selected: false,
                };
            }
        ),
    })
);

const getGroups = () => createThunk({ param: "policy-groups" });

const { reducer: totalCountReducer, createThunk: createTotalCountThunk } = makeConfigApiCreator<
    ListResponse<PolicyGroup>
>("v3_base")("iam_groups_total_count");

const getGroupsCount = () =>
    createTotalCountThunk({ param: "policy-groups", queryParameters: { include: "total_count", limit: "2" } });

const deleteGroupCreator = makeConfigApiCreator<void>("v3_base", { errorCallout: false })("iam_delete_group");

const { createThunk: deleteCreateThunk, reducer: deleteGroupReducer } = makeCollectionCreator<void>(deleteGroupCreator);

const deleteGroup = (id: string) => deleteCreateThunk({ id, param: `policy-groups/${id}`, method: "DELETE" });

const reducers = {
    group: reducer,
    groupsTotalCount: totalCountReducer,
    groupdelete: deleteGroupReducer,
};

reducerRegistry.register(reducers);

type GroupReducers = typeof reducers;

export { deleteGroup, getGroups, getGroupsCount, GroupReducers };
