import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import { Config } from "../typings/interfaces";
import { AppState } from "../creators/reducers";
import NotFound from "../layout/404";
import ErrorBoundary from "../layout/errorBoundary";
import { importLazy, LazilyLoadFactory } from "../layout/lazilyload";
import { PropagatedProps } from "../layout/main/component";
import { asyncRender } from "./BaseRoute";

export interface IdentityRouteProps extends RouteComponentProps {
    Certificate: React.ComponentType<PropagatedProps>;
    CertificateDetail: React.ComponentType<PropagatedProps>;
    FactoryTool: React.ComponentType<PropagatedProps>;
    NewDeveloperCertificate: React.ComponentType<PropagatedProps>;
    ImportCertificate: React.ComponentType<PropagatedProps>;
    ServerCredentials: React.ComponentType<PropagatedProps>;
    TrustAnchor: React.ComponentType<PropagatedProps>;
    UploadCertificate: React.ComponentType<PropagatedProps>;
    ListPsk: React.ComponentType<PropagatedProps>;
    UploadPsk: React.ComponentType<PropagatedProps>;
    ExternalCA: React.ComponentType<PropagatedProps>;
    componentProps: PropagatedProps;
    config: Config;
}

export const IdentityRoute: React.FunctionComponent<IdentityRouteProps> = (props) => {
    const {
        Certificate,
        CertificateDetail,
        FactoryTool,
        NewDeveloperCertificate,
        ServerCredentials,
        TrustAnchor,
        ListPsk,
        UploadCertificate,
        UploadPsk,
        ExternalCA,
        ImportCertificate,
        config,
        componentProps,
    } = props;
    const asyncRenderWithProps = asyncRender(componentProps);
    return (
        <ErrorBoundary>
            <Switch>
                <Route
                    path="/identity/certificates/authorities"
                    render={asyncRenderWithProps(ExternalCA, "externalCA")}
                />
                <Route path="/identity/certificates/list" render={asyncRenderWithProps(Certificate, "certificates")} />
                <Route
                    path="/identity/certificates/list/:id"
                    render={asyncRenderWithProps(Certificate, "certificatesWithDetails")}
                />
                {config.featureToggle.externalCA && (
                    <Route
                        path="/identity/certificates/authorities"
                        render={asyncRenderWithProps(Certificate, "certificates")}
                    />
                )}
                <Route
                    exact
                    path="/identity/certificates/import"
                    render={asyncRenderWithProps(ImportCertificate, "importCertificate")}
                />
                <Route
                    exact
                    path="/identity/certificates/developer/new"
                    render={asyncRenderWithProps(NewDeveloperCertificate, "createDeveloperCertificate")}
                />
                <Route
                    exact
                    path="/identity/certificates/new"
                    render={asyncRenderWithProps(UploadCertificate, "uploadCertificate")}
                />
                <Route
                    exact
                    path="/identity/certificates/:id/developer/edit"
                    render={asyncRenderWithProps(NewDeveloperCertificate, "createDeveloperCertificate")}
                />
                <Route
                    exact
                    path="/identity/certificates/:id/edit"
                    render={asyncRenderWithProps(UploadCertificate, "editCertificate", { edit: true })}
                />
                <Route
                    exact
                    path="/identity/certificates/:id/replace"
                    render={asyncRenderWithProps(UploadCertificate, "editCertificate", { replace: true })}
                />
                <Route
                    path="/identity/certificates/:id"
                    render={asyncRenderWithProps(CertificateDetail, "certificateDetail")}
                />
                {config.featureToggle.factory_configurator_utility && (
                    <Route
                        exact
                        path="/identity/factorytools"
                        render={asyncRenderWithProps(FactoryTool, "factoryTools")}
                    />
                )}
                <Route
                    exact
                    path="/identity/server"
                    render={asyncRenderWithProps(ServerCredentials, "serverCredentials")}
                />
                <Route exact path="/identity/trust-anchor" render={asyncRenderWithProps(TrustAnchor, "trustAnchor")} />
                <Route
                    exact
                    path="/identity/pre-shared-keys/upload"
                    render={asyncRenderWithProps(UploadPsk, "pskUpload")}
                />
                <Route exact path="/identity/pre-shared-keys" render={asyncRenderWithProps(ListPsk, "pskList")} />
                <Redirect exact from="/identity" to="/identity/certificates/list" />
                <Redirect exact from="/identity/certificates" to="/identity/certificates/list" />
                <Route component={NotFound} />
            </Switch>
        </ErrorBoundary>
    );
};

const mapStateToProps = ({ config }: AppState) => ({ config });

export default LazilyLoadFactory(withRouter(connect(mapStateToProps)(IdentityRoute)), {
    Certificate: () => importLazy(import("../features/identity/certificates/container")),
    CertificateDetail: () => importLazy(import("../features/identity/certificates/detail/container")),
    FactoryTool: () => importLazy(import("../features/identity/factoryTool/container")),
    NewDeveloperCertificate: () => importLazy(import("../features/identity/certificates/create/container")),
    ImportCertificate: () => importLazy(import("../features/identity/certificates/import/container")),
    ServerCredentials: () => importLazy(import("../features/identity/serverCredentials/container")),
    TrustAnchor: () => importLazy(import("../features/identity/trustAnchor/container")),
    UploadCertificate: () => importLazy(import("../features/identity/certificates/upload/container")),
    ListPsk: () => importLazy(import("../features/identity/preSharedKeys/container")),
    UploadPsk: () => importLazy(import("../features/identity/preSharedKeys/upload/container")),
    ExternalCA: () => importLazy(import("../features/identity/certificates/authorities/container")),
});
