import { Config } from "../typings/interfaces";
import store from "./storeProvider";
import { isAuthorized } from "./permissions";
import { redirectTo } from "./routing";

export function isCurrentAdmin() {
    return getState().auth?.roles?.includes("Administrator") ?? false;
}

export function getAccess() {
    return {
        admin: isCurrentAdmin(),
    };
}

export function redirectIfFeatureIsNotAvailable(config: Config | undefined, feature: string, url: string) {
    if (!isAuthorized(config, getAccess(), { feature })) {
        redirectTo({ path: url });
    }
}

export function isFreeAccount() {
    return getAccount().tier === "0";
}

export function isCommercialAccount() {
    return getAccount().tier === "1";
}

export function isRootAdminTeam() {
    return getAccount().aliases?.some((alias: string) => alias === "/") ?? false;
}

function getState() {
    return store.getStore().getState();
}

function getAccount() {
    return getState().currentaccount ?? {};
}
