import { makeConfigApiCreator } from "../../../../creators";
import { User } from "../creator";

// Policy delete
const { reducer, createThunk, createResetAction } = makeConfigApiCreator<{}>("v3_base")("iam_newpassword");
const postAction = (id: string, data: User) =>
    createThunk({ param: `users/${id}/reset-password`, method: "POST", data, errorCallout: false });
const reset = createResetAction();

export { postAction, reset, reducer };
