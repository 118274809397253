import ReactGA from "react-ga";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";

function initialize() {
    if (window.appConfig?.featureToggle?.googleAnalytics) {
        ReactGA.initialize(window.appConfig.trackingID ?? "");
    }
}

function getGoogleAnalytics() {
    return window.appConfig?.featureToggle?.googleAnalytics ? ReactGA : null;
}

function getGA() {
    return (
        getGoogleAnalytics() || {
            event: () => {},
            pageview: (_) => {},
            set: (_) => {},
        }
    );
}

interface GoogleAnalyticsState {
    location?: string;
}

export class GoogleAnalytics extends React.PureComponent<RouteComponentProps<{}>, GoogleAnalyticsState> {
    public state: GoogleAnalyticsState = {};

    public static getDerivedStateFromProps(
        props: RouteComponentProps<{}>,
        state: GoogleAnalyticsState
    ): GoogleAnalyticsState | null {
        return props.history.location.pathname !== state.location
            ? { location: props.history.location.pathname }
            : null;
    }

    public componentDidUpdate(_: RouteComponentProps<{}>, prevState: GoogleAnalyticsState) {
        if (this.state.location !== prevState.location && this.state.location) {
            const ga = getGoogleAnalytics();
            if (ga) {
                ga.set({ page: this.state.location });
                ga.pageview(this.state.location);
            }
        }
    }

    public render() {
        return null;
    }
}

export default withRouter(GoogleAnalytics);

export { initialize, getGA, getGoogleAnalytics };
