/**
 * Place for newer utilities that should be typed. Overtime js utility file should be migrated.
 */

export const concatData = <T extends { id: string; [key: string]: any }>(currentData: T[], appendData: T[]): T[] => {
    currentData = currentData.map((item) => {
        const newItem = appendData.find((element) => {
            return item?.id === element?.id;
        });
        return newItem ? newItem : item;
    });
    let data = currentData.concat(
        appendData.filter((item) => {
            return !currentData.find((element) => {
                return item.id === element.id;
            });
        })
    );
    data = data.map((item, index) => {
        return Object.assign(item, { index });
    });
    return data;
};
