import { ListResponse } from "../../script";
import { ParseFunction } from "../../creators/AjaxCreator";
import { makeConfigApiCreator } from "../../creators";
import { FactoryMetricsProps } from "./types";

const makeFactoryStatisticsCreator = <T, D = undefined>(type: string, parseFunction?: ParseFunction<T, D>) =>
    makeConfigApiCreator<T, D>("v3_base", {
        errorCallout: false,
    })(type, parseFunction);

const { reducer, createThunk } = makeFactoryStatisticsCreator<ListResponse<FactoryMetricsProps>>(
    "manufacturingStatistics",
    (resp) => {
        const response = resp as ListResponse<FactoryMetricsProps>;
        if (response.data && response.data.length > 1) {
            response.last = response.data[response.data.length - 1].id;
        }
        return response;
    }
);

const getAction = ({
    start = "",
    end = "",
    pageSize = 50,
    after = "",
    order = "ASC",
    errorAction = undefined,
    errorCallout = true,
    errorRedirect = false,
} = {}) =>
    createThunk({
        param: `manufacturing/statistics`,
        queryParameters: {
            start,
            end,
            order,
            limit: `${pageSize}`,
            include: "total_count",
            after: after,
        },
        errorAction,
        errorCallout,
        errorRedirect,
    });

export { getAction, reducer };
