import { Icons, Page, TabItem, Tabs } from "portal-components";
import React from "react";
import { CalloutState } from "../../creators/CalloutCreator";
import translate, { DefaultStrings } from "../../i18n/translate";
import APICalls from "./apiCalls";
import { ApiActionsState } from "./creator";
import FeatureToggles from "./featureToggles";
import Notifications from "./notifications";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const classes = require("./console.scss");

export const defaultStrings = {
    api: "API Calls",
    notifications: "Notifications",
    toggles: "Feature Toggles",
};

export interface ConsoleComponentProps {
    apiActions: ApiActionsState[];
    enableAPI: boolean;
    enableNotifications: boolean;
    enableToggles: boolean;
    host: string;
    notifications: CalloutState[];
    onTabSelected: (tab: number) => void;
    strings: DefaultStrings<typeof defaultStrings>;
    tab: number;
}

export const ConsoleComponent: React.FunctionComponent<ConsoleComponentProps> = (props: ConsoleComponentProps) => {
    const {
        apiActions = [],
        enableAPI = false,
        enableNotifications = false,
        enableToggles = false,
        host = "",
        notifications = [],
        strings = defaultStrings,
        tab = 0,
    } = props;

    const handleTabSelected = (tab: number) => props.onTabSelected && props.onTabSelected(tab);

    const notificationsTab = enableNotifications ? (
        <TabItem
            key="notifications"
            id="console-notifications-tab"
            icon={Icons.DeveloperConsoleNotifications}
            text={strings.notifications}
        >
            <Notifications data={notifications} />
        </TabItem>
    ) : undefined;
    const apiTab = enableAPI ? (
        <TabItem key="api" id="console-api-tab" icon={Icons.DeveloperConsoleAPI} text={strings.api}>
            <APICalls data={apiActions} host={host} />
        </TabItem>
    ) : undefined;

    const togglesTab = enableToggles ? (
        <TabItem key="toggles" id="console-toggles-tab" icon={Icons.DeveloperConsoleAPI} text={strings.toggles}>
            <FeatureToggles />
        </TabItem>
    ) : undefined;

    const tabs = [notificationsTab, apiTab, togglesTab].filter((x) => !!x);

    return (
        <Page id="developer-console" className={classes.developerConsole}>
            <Tabs defaultSelectedIndex={tab} onTabSelected={handleTabSelected}>
                {tabs}
            </Tabs>
        </Page>
    );
};

ConsoleComponent.defaultProps = {
    apiActions: [],
    host: "",
    notifications: [],
    strings: defaultStrings,
    tab: 0,
};

export default translate("ConsoleComponent")(ConsoleComponent);
