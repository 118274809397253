import { Config, TrustedCertificateRepository } from "mbed-cloud-sdk";
import { makeConfigApiCreator, ParseFunction, sdkCreator } from "../../../../creators";
import { Device } from "../../../../features/devices";
import { ListResponse } from "../../../../script";
import history from "../../../../script/history";
import { TrustedCertificate } from "../creator";

let repository: TrustedCertificateRepository;

const getRepository = (sdkConfig: Config) => {
    if (!repository) {
        repository = new TrustedCertificateRepository(sdkConfig);
    }

    return repository;
};

const {
    reducer: getCertificateReducer,
    createThunk: getCertificateThunk,
    createResetAction: getCertificateResetThunk,
} = makeConfigApiCreator<TrustedCertificate>("v3_base")("GET_TRUSTED_CERTIFICATE");
const getCertificate = (id: string) => getCertificateThunk({ param: `trusted-certificates/${id}`, method: "GET" });
const getCertificateReset = getCertificateResetThunk();

const {
    reducer: deleteCertificateReducer,
    createThunk: deleteCertificateThunk,
    createResetAction: deleteCertificateResetAction,
} = sdkCreator("DELETE_TRUSTED_CERTIFICATE", {
    sdkMethod: (id: string, sdkConfig) => getRepository(sdkConfig).delete(id),
});
const deleteCertificate = (id: string) => deleteCertificateThunk(id);
const deleteCertificateReset = deleteCertificateResetAction();

const makeCertificateCreator = <T>(type: string, parseFunction?: ParseFunction<T>) =>
    makeConfigApiCreator<T>("v3_base", {
        errorCallout: true,
        errorRedirect: () => history.push("/certificates/list"),
    })(type, parseFunction);

// Get devices
const { createThunk: createGetDevicesThunk, reducer: getDevicesReducer } = makeCertificateCreator<ListResponse<Device>>(
    "trusted-certificate-devices",
    (untypedResponse) => {
        const response = untypedResponse as ListResponse<Device>;
        const data = response.data.map((device) => Object.assign(device, { selected: false }));
        if (response.data && response.data.length > 1) {
            response.last = response.data[response.data.length - 1].id;
        }

        // Temp workaround because total_count is undefined for this endpoint:
        // if this is the only page (!after && !has_more) then can use the number of devices we received in this page.
        if (!response.has_more && !(response as any).after && response.total_count === undefined && data) {
            response.total_count = data.length;
        }

        return response;
    }
);

interface CertificateIssuerDevicesParameters {
    certificateId: string;
    pageSize?: number;
    last?: string;
    order?: string;
}

const getDevicesAction = ({
    certificateId,
    pageSize = 50,
    last,
    order = "DESC",
}: CertificateIssuerDevicesParameters) => {
    const queryParameters = {
        order,
        limit: pageSize?.toString(),
        include: "total_count",
        after: last,
    };

    return createGetDevicesThunk({
        param: `trusted-certificates/${certificateId}/devices`,
        queryParameters,
    });
};

interface DeveloperCertificate {
    id?: string;
    account_id?: string;
    certificate?: string;
    created_at?: Date;
    description?: string;
    developer_private_key?: string;
    name: string;
    security_file_content?: string;
}

const {
    createThunk: createGetDeveloperCertificateThunk,
    reducer: getDeveloperCertificateReducer,
    createResetAction: getDeveloperCertificateReset,
} = makeCertificateCreator<DeveloperCertificate>("developer_certificate_details");
const getDeveloperCertificate = (id: string) =>
    createGetDeveloperCertificateThunk({ param: `developer-certificates/${id}` });

export {
    getCertificate,
    getCertificateReset,
    getCertificateReducer,
    getDeveloperCertificate,
    getDeveloperCertificateReset,
    getDeveloperCertificateReducer,
    deleteCertificate,
    deleteCertificateReset,
    deleteCertificateReducer,
    getDevicesAction,
    getDevicesReducer,
};
