import { Icons, ListItem } from "portal-components";
import React from "react";
import { DropdownFilterContext } from "./dropdownFilter";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const classes = require("./style.scss");

export interface DropdownFilterItemProps {
    text: string;
    description?: string;
    value: unknown;
    default?: boolean;
}

export const DropdownFilterItem: React.FunctionComponent<DropdownFilterItemProps> = (props) => (
    <DropdownFilterContext.Consumer>
        {(context) => {
            const isSelected = context.value === props.value || (!context.selectionExists && props.default);
            return (
                <ListItem
                    className={classes.dropdownFilterItem}
                    icon={Icons.SelectedMark}
                    active={isSelected}
                    text={props.text}
                    description={props.description}
                    onClick={() => {
                        if (!isSelected) {
                            context.select(props.value);
                        }
                    }}
                />
            );
        }}
    </DropdownFilterContext.Consumer>
);
