import React, { ComponentType } from "react";
import { GetProps, useSelector } from "react-redux";
import { AppState } from "../creators/reducers";
import { getUserUiLanguage } from "../script/utility";

// Wrapper translate component props
export interface TranslateProps {
    strings: { [key: string]: string };
}

export interface Translation {
    [component: string]: { [key: string]: string };
}

export interface Translations {
    [lang: string]: Translation;
}

export const languages = { ja: true, zh: true, pseudo: true };

// Type of props for generated component (i.e. without the strings).
// LibraryManagedAttributes propagates defaultProps from the component type C.
type OutputProps<C, P extends TranslateProps> = JSX.LibraryManagedAttributes<C, Omit<P, "strings">>;

/**
 * Utility for deducing type of strings prop from default strings. E.g.:
 * ```typescript
 * const defaultStrings = {
 *     name: "Name"
 * };
 *
 * interface MyProps {
 *     strings: DefaultStrings<typeof defaultStrings>;
 * }
 * ```
 */
export type DefaultStrings<S extends { [key: string]: string }> = { [K in keyof S | string]: string };

/**
 * Higher-order component that reads the current language from the redux store and passes translated
 * strings to the wrapped component.
 * @param key Key of wrapped component in the translation bundles
 */
const translate = (key: string | string[]) => {
    return <C extends ComponentType<GetProps<C>>>(WrappedComponent: C): ComponentType<OutputProps<C, any>> => {
        const TranslationComponent = <P extends TranslateProps>(props: P) => {
            const [currentLanguage, translations] = useSelector(
                ({ i18n }: AppState) => [i18n?.lang ?? getUserUiLanguage(), i18n?.strings ?? {}],
                ([a], [b]) => a === b
            );
            const defaultProps = WrappedComponent.defaultProps;
            const defaultStrings = (defaultProps && (defaultProps as Partial<TranslateProps>).strings) || {};
            let languageStrings;
            const language = translations[currentLanguage];

            if (language) {
                if (Array.isArray(key)) {
                    languageStrings = key.map((objectKey) => language[objectKey]);
                    languageStrings = Object.assign({}, ...languageStrings);
                } else {
                    languageStrings = language[key];
                }
            }

            const merged = { ...defaultStrings, ...languageStrings };
            const mergedProps = { ...props, strings: merged };

            const AnyComponent = WrappedComponent as ComponentType<any>;
            return <AnyComponent {...mergedProps} key={currentLanguage} />;
        };

        return TranslationComponent as any;
    };
};

export default translate;
