import { toast } from "react-toastify";
import { AnyAction, Action } from "redux";

type JobActionType = "ENQUEUE_JOB_ACTION" | "UPDATE_JOB_ACTION" | "CLEAR_COMPLETED_JOBS";

export interface Job {
    id: string;
    canRetry?: boolean;
    component?: any;
    description?: string;
    endTime?: Date;
    invisible?: boolean;
    progress?: number;
    props?: any;
    report?: string;
    startTime?: Date;
    status?: "pending" | "succeeded" | "failed";
    title?: string;
    onRetry?: () => void;
}

interface JobAction extends Action<JobActionType> {
    job: Job;
}

type ClearJobsAction = Action<JobActionType>;

let nextJobId = new Date().getTime();

const enqueueJobAction = (jobData: Omit<Job, "id" | "status" | "startTime" | "endTime">): JobAction => ({
    type: "ENQUEUE_JOB_ACTION",
    job: {
        id: `job-${nextJobId++}`,
        status: "pending",
        startTime: new Date(),
        ...jobData,
    },
});

const updateJobAction = (job: Job): JobAction => ({
    type: "UPDATE_JOB_ACTION",
    job,
});

const clearCompletedJobsAction = (): ClearJobsAction => ({
    type: "CLEAR_COMPLETED_JOBS",
});

const enqueueJob = (state: { [jobId: string]: Job }, job: Job) => ({
    ...state,
    [job.id]: job,
});

const clearCompletedJobs = (state: { [jobId: string]: Job }) =>
    Object.entries(state).reduce(
        (result, [jobId, job]) => (job.status === "pending" ? { ...result, [jobId]: job } : result),
        {}
    );

const updateJob = (state: { [jobId: string]: Job }, updatedJobData: Job) => {
    if (!state[updatedJobData.id]) {
        return state;
    }

    const job = { ...state[updatedJobData.id] };

    // CHECK: should we simply apply all updatedJobData properties?
    // Some properties MUST not be changed (for example ID and initialization properties)
    // but this might be tedious if we'll have more and more properties to copy.
    if (updatedJobData.status !== undefined) {
        if (job.status !== updatedJobData.status && updatedJobData.status !== "pending") {
            if (updatedJobData.status === "failed") {
                toast.error(job.title);
            }

            job.endTime = new Date();
        }

        job.status = updatedJobData.status;
    }

    job.description = updatedJobData.description ?? job.description;
    job.report = updatedJobData.report ?? job.report;

    return { ...state, [updatedJobData.id]: job };
};

const reducer = (state: { [jobId: string]: Job } = {}, action: AnyAction) => {
    switch (action.type) {
        case "ENQUEUE_JOB_ACTION":
            return enqueueJob(state, action.job);
        case "UPDATE_JOB_ACTION":
            return updateJob(state, action.job);
        case "CLEAR_COMPLETED_JOBS":
            return clearCompletedJobs(state);
    }

    return state;
};

export { enqueueJobAction, updateJobAction, clearCompletedJobsAction, reducer };
