import {
    AppBar,
    Application,
    AppSearch,
    AuthenticationBarrier as NewAuthenticationBarrier,
    Box,
    Button,
    ButtonVariant,
    Configuration,
    Icons,
    IconSize,
    LinkButton,
    MediaQueries,
    MediaQuery,
} from "portal-components";
import React from "react";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import { Config } from "../../typings/interfaces";
import CookieConsent from "../../controls/cookieConsent";
import { AjaxState } from "../../creators/AjaxCreator";
import { CurrentAccount } from "../../features/account";
import AsyncJobList from "../../features/asyncJobList/container";
import Console from "../../features/console/container";
import translate from "../../i18n/translate";
import { getBrandedLoginBackground, getBrandedLogo } from "../../script/branding";
import { AppState, AppDispatch } from "../../creators/reducers";
import { getFeatureToggle, getUserUiLanguage } from "../../script/utility";
import { isFreeAccount } from "../../script/roles";
import ErrorBoundary from "../errorBoundary";
import VersionChecker from "../versionChecker/container";
import WithReflexContainer from "../withReflexContainer";
import AuthChecker from "./authChecker";
import AuthenticationBarrier from "./authenticationBarrier";
import MainComponent from "./component";
import GoogleAnalytics from "../../script/googleAnalytics";
import SiteMap, { MenuComponentSlots } from "./menu";
import RestrictedAccountWarning from "./restrictedAccountWarning";
import { SystemState } from "./systemState";
import TeamMenu from "./teamMenu";
import { createLanguageAction } from "../../i18n/creator";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const classes = require("./container.scss");

const defaultStrings = {
    console: "Console",
    productName: "Izuma Device Management Portal",
    productNameOnPrem: "Device Management Portal",
    typeToSearch: "Search features",
    upgradeAccount: "Upgrade account",
    noSearchResults: "No results",
};

interface MainContainerProps {
    language?: string;
    dispatch: AppDispatch;
    config: Config;
    account?: AjaxState<CurrentAccount>;
    strings: typeof defaultStrings;
}

interface MainContainerState {
    compact: boolean;
    enableConsole: boolean;
    siteMapIsHidden: boolean;
    configuration: Partial<Configuration> & { endpoint: string };
}

const themeConfiguration = {
    images: {
        loginBackground: () => getBrandedLoginBackground(),
        loginLogo: () => `url(${getBrandedLogo(undefined, true)})`,
    },
};

export class MainContainer extends React.PureComponent<MainContainerProps, MainContainerState> {
    public static readonly defaultProps = {
        strings: defaultStrings,
    };

    public state: MainContainerState = {
        siteMapIsHidden: false,
        enableConsole: false,
        compact: false,
        configuration: { endpoint: "" },
    };

    public constructor(props: Readonly<MainContainerProps>) {
        super(props);

        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.handleApplicationContentClick = this.handleApplicationContentClick.bind(this);
        this.handleViewportSizeChanged = this.handleViewportSizeChanged.bind(this);
        this.enableConsole = this.enableConsole.bind(this);

        this.state.configuration = { ...this.props.config, theme: themeConfiguration };
    }

    public componentDidMount() {
        document.title = this.productName;
        this.setState({ enableConsole: this.enableConsole() });

        if (this.props.language) {
            this.props.dispatch(createLanguageAction(this.props.language));
        }
    }

    public componentDidUpdate(prevProps: MainContainerProps) {
        if (this.enableConsole() !== this.enableConsole(prevProps)) {
            this.setState({ enableConsole: this.enableConsole() });
        }

        if (prevProps.config !== this.props.config) {
            this.setState({
                configuration: { ...this.props.config, theme: themeConfiguration },
            });
        }
    }

    public render() {
        return (
            <React.Fragment>
                <MediaQuery query={MediaQueries.MediumOrSmaller} onChanged={this.handleViewportSizeChanged} />
                <Application
                    config={this.state.configuration}
                    title={this.productName}
                    language={this.props.language}
                    className={[classes.mainContainer, this.state.compact ? "compact" : ""]}
                >
                    {this.renderContent()}
                </Application>
            </React.Fragment>
        );
    }

    private renderContent() {
        const { compact, enableConsole } = this.state;
        const { config, account, strings } = this.props;
        const { externalLinks = { cookiePolicy: "" }, featureToggle: { newLogin: useNewLogin } = { newLogin: false } } =
            config || {};
        const { cookiePolicy } = externalLinks;
        const useNewMenu = getFeatureToggle("useNewMenu", this.props);

        // Keep in sync MediaQueries.MediumOrSmaller with what defined in the CSS for .application-content
        const appBar = (
            <AppBar>
                <Button
                    when={this.state.compact}
                    icon={Icons.MenuSymbol}
                    iconSize={IconSize.Large}
                    variant={ButtonVariant.Compact}
                    onClick={this.handleMenuClick}
                    className="toggle-site-map"
                />
                <LinkButton when={account && isFreeAccount()} text={strings.upgradeAccount} to="/contact" primary />
                <RestrictedAccountWarning />
            </AppBar>
        );

        const appHeader = !this.state.compact ? <React.Fragment /> : <Application.Header>{appBar}</Application.Header>;
        const siteMapProps = {
            hidden: this.state.siteMapIsHidden,
            withLogo: true,
        };

        const innerArea = (
            <React.Fragment>
                <SiteMap {...siteMapProps}>
                    <TeamMenu />
                    <br />
                    <AppSearch
                        placeholder={strings.typeToSearch}
                        noResultsText={strings.noSearchResults}
                        iconSize={IconSize.Large}
                        expandable={false}
                        {...(useNewMenu && { className: "branded-search" })}
                        {...(!useNewMenu && { dimmed: true })}
                    />
                    <br />
                    <Box style={MenuComponentSlots.Bottom}>
                        <LinkButton
                            when={account && isFreeAccount()}
                            text={strings.upgradeAccount}
                            to="/contact"
                            primary
                        />
                        <RestrictedAccountWarning />
                    </Box>
                </SiteMap>
                <Application.Content id="authenticated" onClick={this.handleApplicationContentClick} branded>
                    <SystemState />
                    <ErrorBoundary>
                        <WithReflexContainer
                            enable={enableConsole}
                            customHandleLabel={strings.console}
                            drawerChildren={<Console />}
                            compact={compact}
                        >
                            <main id="app">
                                <MainComponent />
                            </main>
                        </WithReflexContainer>
                        <AsyncJobList />
                        <CookieConsent
                            {...(useNewMenu && { className: "cookieconsent-container callout branded" })}
                            link={cookiePolicy}
                        />
                    </ErrorBoundary>
                </Application.Content>
            </React.Fragment>
        );

        const appContent = (
            <React.Fragment>
                <ToastContainer pauseOnFocusLoss={false} />
                <GoogleAnalytics />
                <VersionChecker />
                {appHeader}
            </React.Fragment>
        );

        if (useNewLogin) {
            return (
                <NewAuthenticationBarrier>
                    <AuthChecker />
                    {appContent}
                    {innerArea}
                </NewAuthenticationBarrier>
            );
        }

        return (
            <React.Fragment>
                {appContent}
                <AuthenticationBarrier>{innerArea}</AuthenticationBarrier>
            </React.Fragment>
        );
    }

    private get productName() {
        const { config, strings } = this.props;

        const isOnPremises = config && config.onPremises;
        return isOnPremises ? strings.productNameOnPrem : strings.productName;
    }

    private enableConsole(props = this.props) {
        return (
            getFeatureToggle("consoleAPI", props) ||
            getFeatureToggle("consoleNotifications", props) ||
            getFeatureToggle("consoleFeatureToggles", props)
        );
    }

    private handleMenuClick() {
        this.setState({ siteMapIsHidden: !this.state.siteMapIsHidden });
    }

    private handleApplicationContentClick() {
        if (this.state.compact && !this.state.siteMapIsHidden) {
            this.setState({ siteMapIsHidden: true });
        }
    }

    private handleViewportSizeChanged(matches: boolean) {
        this.setState({ compact: matches, siteMapIsHidden: matches });
    }
}

export function mapStateToProps(state: AppState) {
    return {
        language: state.i18n ? state.i18n.lang : getUserUiLanguage(),
        config: state.config,
        account: state.currentaccount,
    };
}

export default connect(mapStateToProps)(translate("MainContainer")(MainContainer));
