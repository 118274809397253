import { DeveloperCertificateCreateRequest, DeveloperCertificateRepository } from "mbed-cloud-sdk";
import { sdkCreator } from "../../../../creators/SdkCreator";

const {
    reducer: createDeveloperCertificateReducer,
    createThunk: createDeveloperCertificateThunk,
    createResetAction: createDeveloperCertificateResetThunk,
} = sdkCreator("CREATE_DEVELOPER_CERTIFICATE", {
    sdkMethod: (request: DeveloperCertificateCreateRequest, sdkConfig) =>
        new DeveloperCertificateRepository(sdkConfig).create(request),
});
const createDeveloperCertificate = (request: DeveloperCertificateCreateRequest) =>
    createDeveloperCertificateThunk(request);
const createDeveloperCertificateReset = createDeveloperCertificateResetThunk();

export { createDeveloperCertificate, createDeveloperCertificateReducer, createDeveloperCertificateReset };
