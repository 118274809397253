import { Config as SdkConfig, ListOptions, Paginator } from "mbed-cloud-sdk";
import { Action, AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { Config } from "../typings/interfaces";
import { AjaxState, RequestState } from "./AjaxCreator";
import { errorHandler, getSdkConfig, SdkCreatorOptions } from "./SdkCreator";

export type PaginationDirection = "NEXT" | "PREVIOUS" | "ALL";

// Creator that can be used for any SDK API call
export const sdkPaginatorCreator = <TReturns, TOptions extends ListOptions>(
    actionPrefix: string,
    paginatorOptions: {
        paginator: (parameters: TOptions, config: SdkConfig, id?: string) => Paginator<TReturns, TOptions>;
        mapResults?: <TReturns>(item: TReturns, index: number) => TReturns;
    },
    creatorOptions: SdkCreatorOptions = { id: "", errorCallout: true }
) => {
    const actionState = (
        action: Action,
        state: AjaxState<Paginator<TReturns, TOptions> | number>
    ): AjaxState<Paginator<TReturns, TOptions> | number> => {
        switch (action.type) {
            case `${actionPrefix}_LOADING`:
                return { requestState: RequestState.LOADING, id: creatorOptions.id };
            case `${actionPrefix}_SUCCESS`:
                return {
                    requestState: RequestState.LOADED,
                    id: creatorOptions.id,
                    data: (action as AnyAction).data,
                    text: "",
                };
            case `${actionPrefix}_ERROR`:
                return { requestState: RequestState.FAILED, id: creatorOptions.id, error: (action as AnyAction).error };
            case `${actionPrefix}_RESET`:
                return { requestState: RequestState.INITIALIZING, id: "" };
            default:
                return state;
        }
    };

    return {
        reducer: (
            state: AjaxState<Paginator<TReturns, TOptions>> = {
                requestState: RequestState.INITIALIZING,
                id: creatorOptions.id,
            },
            action: Action
        ): AjaxState<Paginator<TReturns, TOptions>> => {
            return actionState(action, state) as AjaxState<Paginator<TReturns, TOptions>>;
        },
        createListThunk: (
            paginator?: Paginator<TReturns, TOptions>,
            parameters?: TOptions,
            options?: SdkCreatorOptions,
            direction: PaginationDirection = "NEXT",
            id?: string
        ): ThunkAction<Promise<void>, { config: Config }, never, Action> => async (dispatch, getState) => {
            parameters = parameters || ({} as TOptions);
            options = options || {};
            dispatch({ type: `${actionPrefix}_LOADING`, id: options.id });
            if (!paginator) {
                const sdkConfig = getSdkConfig(getState);

                if (paginatorOptions.mapResults) {
                    parameters.mapResults = paginatorOptions.mapResults;
                }

                try {
                    paginator = paginatorOptions.paginator(parameters, sdkConfig, id);
                } catch (error) {
                    return errorHandler(error, options, dispatch, actionPrefix);
                }
            }

            try {
                let data: TReturns[] = [];
                switch (direction) {
                    case "NEXT":
                        await paginator.nextPage();
                        break;
                    case "PREVIOUS":
                        await paginator.previousPage();
                        break;
                    case "ALL":
                        data = await paginator.all();
                        break;
                    default:
                        break;
                }

                dispatch({
                    type: `${actionPrefix}_SUCCESS`,
                    data: direction === "ALL" ? data : paginator,
                    id: options.id,
                });
            } catch (error) {
                errorHandler(error, options, dispatch, actionPrefix);
            }
        },
        totalCountReducer: (
            state: AjaxState<number> = {
                requestState: RequestState.INITIALIZING,
                id: creatorOptions.id,
            },
            action: Action
        ): AjaxState<number> => {
            return actionState(action, state) as AjaxState<number>;
        },
        createTotalCountThunk: (
            paginator?: Paginator<TReturns, TOptions>,
            options?: SdkCreatorOptions,
            parameters?: TOptions,
            id?: string
        ): ThunkAction<Promise<void>, { config: Config }, never, Action> => async (dispatch, getState) => {
            options = options || {};
            dispatch({ type: `${actionPrefix}_LOADING` });

            if (!paginator) {
                const sdkConfig = getSdkConfig(getState);

                try {
                    paginator = paginatorOptions.paginator({ ...parameters, pageSize: 2 } as TOptions, sdkConfig, id);
                } catch (error) {
                    return errorHandler(error, options, dispatch, actionPrefix);
                }
            }

            try {
                const totalCount = await paginator.totalCount();
                dispatch({ type: `${actionPrefix}_SUCCESS`, data: totalCount });
            } catch (error) {
                errorHandler(error, options, dispatch, actionPrefix);
            }
        },
        createResetAction: () => ({ type: `${actionPrefix}_RESET` }),
    };
};
