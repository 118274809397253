import { makeConfigApiCreator } from "../../creators";
import { ListResponse } from "../../script";

export interface CurrentUser {
    custom_fields: { [key: string]: any };
    email?: string;
    full_name: any;
    id: string;
    is_totp_enabled?: boolean;
    login_history?: LoginHistory[];
    team?: string;
    totp_scratch_codes?: string[];
    totp_secret?: string;
}

interface LoginHistory {
    date: string;
    ip_address: string;
    success: boolean;
    user_agent: string;
}

export interface TeamLoginProfile {
    id: string;
    name: string;
    type: string;
}

export interface Team {
    id: string;
    display_name?: string;
    alias?: string;
    parent_id: string;
    status: string;
    login_profiles?: TeamLoginProfile[];
}

export interface SubAccount {
    id: string;
    display_name?: string;
}

export interface ParentAccount {
    admin_name: string;
    admin_email: string;
}

export type BusinessModel = "api_calls_1_business_model" | "active_device_business_model";

// Convert user custom prop values to an object (comes as a string)
export const parseCustomFields = (customFields: { [key: string]: string } | null): { [key: string]: unknown } => {
    return Object.entries(customFields || {}).reduce((acc, [key, value]) => {
        let parsed = null;
        try {
            parsed = JSON.parse(value);
        } catch (err) {
            parsed = value;
        }
        return { ...acc, [key]: parsed };
    }, {});
};

const { reducer: userReducer, createThunk: createUserThunk } = makeConfigApiCreator<CurrentUser>("v3_base")(
    "iam_current_user",
    (response) => {
        const untypedResponse = (response || {}) as any;
        const { custom_fields } = untypedResponse;
        return { ...untypedResponse, custom_fields: parseCustomFields(custom_fields) };
    }
);

const getUser = createUserThunk({ param: "users/me" });

const { reducer: userTeamsReducer, createThunk: createUserTeamsThunk } = makeConfigApiCreator<ListResponse<Team>>(
    "v3_base"
)("iam_current_user_available_teams");
const getUserTeams = createUserTeamsThunk({ param: "users/me/team-accounts" });

export interface CurrentAccountLimitations {
    billing_period: number;
    created_at: string;
    description: boolean;
    etag: string;
    id: string;
    inherited: boolean;
    inherited_from: string;
    inherited_type: string;
    limit: number;
    name: string;
    object: string;
    quota: number;
    updated_at: string;
}

export interface CurrentAccount {
    display_name: string;
    id: string;
    status?: "ACTIVE" | "EXPIRED" | "INACTIVE" | "RESET" | "RESTRICTED" | "SUSPENDED";
    idle_timeout: string;
    limits: { [limit: string]: string };
    mfa_status: "enforced" | "optional";
    password_policy: { minimum_length: number };
    policies: { action: string; allow: boolean; feature: string; inherited: boolean; resource: string }[];
    limitations: CurrentAccountLimitations[];
    aliases: string[];
    reason?: string;
    custom_fields: { [key: string]: any };
    tier?: string;
    contact?: string;
    company?: string;
    contract_number?: string;
    customer_number?: string;
    address_line1?: string;
    address_line2?: string;
    city?: string;
    state?: string;
    country?: string;
    postal_code?: string;
    phone_number?: string;
    email?: string;
    notification_emails?: string[];
    sub_accounts?: SubAccount[];
    business_model: BusinessModel;
    parent_id?: string;
    parent_account?: ParentAccount;
    is_oem?: boolean;
}

const { reducer: accountReducer, createThunk: createAccountThunk } = makeConfigApiCreator<CurrentAccount>("v3_base")(
    "iam_current_account",
    (untypedResponse) => {
        const response = (untypedResponse || {}) as CurrentAccount;
        const { custom_fields } = response;
        return { ...response, custom_fields: parseCustomFields(custom_fields) };
    }
);

const getAccount = createAccountThunk({
    param: "accounts/me",
    queryParameters: { include: "limits,policies,sub_accounts" },
});

const { reducer: servicePackageReducer, createThunk: createServicePackageThunk } = makeConfigApiCreator<unknown>(
    "v3_base"
)("service_package");
const getServicePackage = createServicePackageThunk({ param: "service-packages", errorCallout: false });

// Update User custom fields
const { reducer: updateUserSettingsReducer, createThunk: createUpdateUserSettingsThunk } = makeConfigApiCreator<
    CurrentUser
>("v3_base")("iam_update_user_custom_field", (response) => {
    const untypedResponse = (response || {}) as any;
    const { custom_fields } = untypedResponse;
    return { ...untypedResponse, custom_fields: parseCustomFields(custom_fields) };
});

const updateUserSettings = (data: { [key: string]: unknown }) => {
    const customFields = Object.entries(data).reduce((acc, [key, value]) => {
        return { ...acc, [key]: JSON.stringify(value) };
    }, {});

    return createUpdateUserSettingsThunk({
        param: `users/me`,
        data: { custom_fields: customFields },
        method: "PUT",
        errorCallout: false,
    });
};

// Update Account custom fields
const { reducer: updateAccountSettingsReducer, createThunk: createUpdateAccountSettingsThunk } = makeConfigApiCreator<{
    custom_fields: { [key: string]: unknown };
}>("v3_base")("iam_update_account_custom_field", (response) => {
    const untypedResponse = (response || {}) as {
        custom_fields: { [key: string]: string };
    };
    const { custom_fields } = untypedResponse;
    return { ...untypedResponse, custom_fields: parseCustomFields(custom_fields) };
});

const updateAccountSettings = (data: { [key: string]: unknown }) => {
    const customFields = Object.entries(data).reduce((acc, [key, value]) => {
        return { ...acc, [key]: JSON.stringify(value) };
    }, {});

    return createUpdateAccountSettingsThunk({
        param: `accounts/me`,
        data: { custom_fields: customFields },
        method: "PUT",
        errorCallout: false,
    });
};

export {
    getUser,
    userReducer,
    getUserTeams,
    userTeamsReducer,
    getAccount,
    accountReducer,
    getServicePackage,
    servicePackageReducer,
    updateUserSettings,
    updateUserSettingsReducer,
    updateAccountSettingsReducer,
    updateAccountSettings,
};
