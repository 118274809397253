import { Icon, Icons, Page } from "portal-components";
import React from "react";
import { Link } from "react-router-dom";
import translate, { DefaultStrings } from "../i18n/translate";
import { reloadPage } from "../script/utility";

const defaultStrings = {
    header: "This page has not loaded properly",
    line2: "Perhaps there is just a problem with the link you clicked on or the URL you entered.",
    goBack: "Go back to the main page.",
    reloadPage: "Refresh to see if a newer version of Portal is available",
};

export interface GoneProps {
    strings: DefaultStrings<typeof defaultStrings>;
}

export const Gone: React.FunctionComponent<GoneProps> = (props) => {
    const { strings } = props;
    return (
        <Page title={strings.header}>
            <p>{strings.line1}</p>
            <p>
                <a onClick={reloadPage}>
                    <Icon name={Icons.Refresh} />
                    &nbsp;{strings.reloadPage}
                </a>
            </p>
            <p>
                <Link to="/">
                    <Icon name={Icons.GoBack} />
                    &nbsp;{strings.goBack}
                </Link>
            </p>
        </Page>
    );
};

Gone.displayName = "Gone";
Gone.defaultProps = {
    strings: defaultStrings,
};

export default translate("Gone")(Gone);
