import LogoTemplateLandscape from "../../../images/LogoTemplateLandscape.png";
import LogoTemplatePortrait from "../../../images/LogoTemplatePortrait.png";
import LogoTemplateSquare from "../../../images/LogoTemplateSquare.png";

export enum Theme {
    Light = "light",
    Dark = "dark",
}

export interface BrandingImagesDefinition {
    object: string;
    reference: string;
    updated_at: string;
    static_uri: string;
}

export interface BrandingColorsDefinition {
    color: string;
    object: string;
    reference: string;
    updated_at: string;
}

export interface ValidationError {
    field: string;
    message: string;
}

export enum BrandingTypes {
    COLORS,
    IMAGES,
    CAROUSEL,
}

export interface ImageDefinition {
    static_uri?: string;
    file?: File;
}

export interface ImageDefinitions {
    [key: string]: ImageDefinition;
}

export enum ContrastRatingType {
    MINIMUM,
    ENHANCED,
    GOOD,
}

// As defined by AA ratings at https://www.w3.org/TR/WCAG21/#contrast-minimum
export enum ContrastRatingThresholds {
    MINIMUM = 4.5, // per success criterion (minimum) for AA at 4.5:1
    ENHANCED = 7, // per success criterion (enhanced) for AA at 7:1
    GOOD = 21, // per max success criterion for AA
}

export enum ContrastRatingCompare {
    "foreground",
    "workspace",
    "canvas",
}

export interface ContrastRating {
    message: string;
    type: ContrastRatingType;
    rating: number;
    ratingRounded: number;
    compare: ContrastRatingCompare;
}

export interface ThemeProps {
    colors: { [key: string]: string };
    previousColors: { [key: string]: string };
    defaultColors: { [key: string]: string };
    colorsValid: { [key: string]: boolean };
    images: ImageDefinitions;
    previousImages: ImageDefinitions;
    defaultImages: { [key: string]: string };
    imagesValid: { [key: string]: boolean };
    carousel: ImageDefinitions;
    previousCarousel: ImageDefinitions;
    defaultCarousel: { [key: string]: string };
    carouselValid: { [key: string]: boolean };
    contrast: { [key: string]: ContrastRating[] };
}

export interface ColorsOption {
    color: string;
    font: string;
    inverted?: boolean;
}

export const Colors: ColorsOption[] = [
    { color: "workspace_background", font: "workspace_background_font_color", inverted: false },
    { color: "canvas_background", font: "canvas_background_font_color", inverted: false },
    { color: "nav_menu_background", font: "nav_menu_background_font_color", inverted: false },
    { color: "nav_menu_active_background", font: "nav_menu_active_background_font_color", inverted: false },
    { color: "nav_menu_highlight", font: "nav_menu_highlight_font_color", inverted: false },
    { color: "primary", font: "primary_font_color" },
    { color: "secondary", font: "secondary_font_color" },
    { color: "error_color", font: "error_font_color" },
    { color: "info_color", font: "info_font_color" },
    { color: "success_color", font: "success_font_color" },
    { color: "warning_color", font: "warning_font_color" },
];

export interface ImageOption {
    reference: string;
    template?: string;
}
export interface ImagesOption {
    type: string;
    landscape?: ImageOption;
    portrait?: ImageOption;
    square?: ImageOption;
    contain?: boolean;
}

// Only define square if single value needed
export enum ImagesEntities {
    BRAND_LOGO_LANDSCAPE = "brand_logo_landscape",
    BRAND_LOGO_PORTRAIT = "brand_logo_portrait",
    BRAND_LOGO_SQUARE = "brand_logo_square",
    BRAND_LOGO_EMAIL = "brand_logo_email",
    DESKTOP_BACKGROUND_LANDSCAPE = "desktop_background_landscape",
    DESKTOP_BACKGROUND_PORTRAIT = "desktop_background_portrait",
    DESKTOP_BACKGROUND_SQUARE = "desktop_background_square",
    APP_LOGO_LANDSCAPE = "app_logo_landscape",
    APP_LOGO_PORTRAIT = "app_logo_portrait",
    APP_LOGO_SQUARE = "app_logo_square",
}

export const Images: ImagesOption[] = [
    {
        type: "app_logo",
        landscape: {
            reference: ImagesEntities.APP_LOGO_LANDSCAPE,
            template: LogoTemplateLandscape,
        },
        portrait: {
            reference: ImagesEntities.APP_LOGO_PORTRAIT,
            template: LogoTemplatePortrait,
        },
        square: {
            reference: ImagesEntities.APP_LOGO_SQUARE,
            template: LogoTemplateSquare,
        },
        contain: true,
    },
    {
        type: "brand_logo",
        landscape: {
            reference: ImagesEntities.BRAND_LOGO_LANDSCAPE,
            template: LogoTemplateLandscape,
        },
        portrait: {
            reference: ImagesEntities.BRAND_LOGO_PORTRAIT,
            template: LogoTemplatePortrait,
        },
        square: {
            reference: ImagesEntities.BRAND_LOGO_SQUARE,
            template: LogoTemplateSquare,
        },
        contain: true,
    },
    { type: "brand_logo_email", square: { reference: ImagesEntities.BRAND_LOGO_EMAIL }, contain: true },
    {
        type: "desktop_background",
        landscape: { reference: ImagesEntities.DESKTOP_BACKGROUND_LANDSCAPE },
        portrait: { reference: ImagesEntities.DESKTOP_BACKGROUND_PORTRAIT },
        square: { reference: ImagesEntities.DESKTOP_BACKGROUND_SQUARE },
    },
];

export enum CarouselEntities {
    CAROUSEL_00_PORTRAIT = "carousel_image_portrait_0",
    CAROUSEL_01_PORTRAIT = "carousel_image_portrait_1",
    CAROUSEL_02_PORTRAIT = "carousel_image_portrait_2",
    CAROUSEL_03_PORTRAIT = "carousel_image_portrait_3",
}

export const Carousel: ImagesOption[] = [
    { type: "carousel_00", portrait: { reference: CarouselEntities.CAROUSEL_00_PORTRAIT } },
    { type: "carousel_01", portrait: { reference: CarouselEntities.CAROUSEL_01_PORTRAIT } },
    { type: "carousel_02", portrait: { reference: CarouselEntities.CAROUSEL_02_PORTRAIT } },
    { type: "carousel_03", portrait: { reference: CarouselEntities.CAROUSEL_03_PORTRAIT } },
];

export const MAXFILESIZE = 2 * 1024 * 1024; // 2 MB

export interface SubtenantConfiguration {
    branding_inherited: boolean;
    branding_overwrite_allowed: boolean;
}
