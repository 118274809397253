import React from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../creators/reducers";
import { Config } from "../../../typings/interfaces";

const SystemStatePanel = React.lazy(() => import("./systemStatePanel"));

export const SystemState: React.FunctionComponent = () => {
    const config = useSelector<AppState, Config>(({ config }: AppState) => config);

    if (!config.systemState) {
        return null;
    }

    return (
        <React.Suspense fallback={null}>
            <SystemStatePanel />
        </React.Suspense>
    );
};

SystemState.displayName = "SystemState";
