import { GroupPanel, NameValueItem, NameValueTable, SwitchButton, TabItem, Tabs } from "portal-components";
import React, { ReactNode } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Config } from "../../typings/interfaces";
import { AjaxState, RequestState } from "../../creators/AjaxCreator";
import translate, { DefaultStrings } from "../../i18n/translate";
import { AppDispatch, AppState } from "../../creators/reducers";
import { CurrentAccount } from "../account";

export const defaultStrings = {
    account: "Account Configuration",
    accountFeatures: "Account Features",
    accountLimits: "Account Limits",
    features: "Feature Toggles",
    false: "False",
    true: "True",
};

export interface FeatureTogglesProps {
    strings: DefaultStrings<typeof defaultStrings>;
    config: Config;
    currentAccount: AjaxState<CurrentAccount>;
    actions: {
        setFeatureOverwrite: (feature: string, value: boolean) => void;
    };
}

export interface FeatureTogglesState {
    currentAccount: Partial<CurrentAccount>;
}

export class FeatureToggles extends React.Component<FeatureTogglesProps, FeatureTogglesState> {
    public static readonly defaultProps = {
        strings: defaultStrings,
    };

    constructor(props: FeatureTogglesProps) {
        super(props);
        this.state = {
            currentAccount: {
                limits: {},
                policies: [],
            },
        };
        this.handleToggleChange = this.handleToggleChange.bind(this);
    }

    static getDerivedStateFromProps(props: FeatureTogglesProps) {
        const { currentAccount } = props;
        if (currentAccount.requestState === RequestState.LOADED) {
            return { currentAccount: currentAccount.data };
        }
        return null;
    }

    handleToggleChange(feature: string, value: boolean) {
        this.props.actions.setFeatureOverwrite(feature, value);
    }

    public render(): ReactNode {
        const {
            config: { featureToggle = {} },
            strings,
        } = this.props;

        const { currentAccount: { limits = {}, policies = [] } = {} } = this.state;
        const accountFeatures = policies
            .sort((a, b) => (a.feature < b.feature ? -1 : 1))
            .map((policy) => {
                const { feature, allow } = policy;
                return (
                    <NameValueItem key={`accountfeature-${feature}`} text={feature}>
                        {allow ? strings.true : strings.false}
                    </NameValueItem>
                );
            });

        const accountLimits = Object.entries(limits)
            .sort((a, b) => (a[0] < b[0] ? -1 : 1))
            .map(([limit, value]) => {
                return (
                    <NameValueItem key={`accountlimit-${limit}`} text={limit}>
                        {value}
                    </NameValueItem>
                );
            });

        const features = Object.entries(featureToggle)
            .sort(([a], [b]) => (a < b ? -1 : 1))
            .map(([toggle, value]) => (
                <SwitchButton
                    key={toggle}
                    onChange={({ value }) => this.handleToggleChange(toggle, value)}
                    value={!!value}
                >
                    {strings[toggle] || toggle}
                </SwitchButton>
            ));

        return (
            <div className="grid-x small-12">
                <GroupPanel title={strings.features} className="cell small-12 medium-6">
                    {features}
                </GroupPanel>
                <GroupPanel title={strings.account} className="cell small-12 medium-6">
                    <Tabs>
                        <TabItem key="accountFeatures" text={strings.accountFeatures}>
                            <NameValueTable>{accountFeatures}</NameValueTable>
                        </TabItem>
                        <TabItem key="accountLimits" text={strings.accountLimits}>
                            <NameValueTable>{accountLimits}</NameValueTable>
                        </TabItem>
                    </Tabs>
                </GroupPanel>
            </div>
        );
    }
}

const mapStateToProps = ({ config, currentaccount }: AppState) => ({
    config,
    currentAccount: currentaccount,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    actions: bindActionCreators(
        {
            setFeatureOverwrite: (feature: string, value: boolean) => ({
                type: "FEATURE_TOGGLE_OVERWRITE",
                feature,
                value,
            }),
        },
        dispatch
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(translate("FeatureToggles")(FeatureToggles));
