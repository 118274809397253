import {
    Box,
    Button,
    ButtonVariant,
    Else,
    GroupPanel,
    Icon,
    Icons,
    If,
    Orientation,
    Panel,
    SizeMode,
    StackPanel,
    Then,
} from "portal-components";
import React from "react";
import { connect } from "react-redux";
import { AppDispatch, AppState } from "../../creators/reducers";
import LeavePageConfirmation from "../../controls/leavePageConfirmation";
import translate, { DefaultStrings } from "../../i18n/translate";
import { Job } from "./creator";
import { clearCompletedJobsAction } from "./creator";
import JobStatusIndicator from "./jobStatusIndicator";

enum PanelSize {
    MINIMIZED = "minimized",
    NORMAL = "normal",
    EXPANDED = "expanded",
}

interface Jobs {
    visiblePending: Job[];
    invisiblePending: Job[];
    completed: Job[];
}

const Text = (props: {
    children?: any;
    className?: string;
    commandLabel?: string;
    hidden?: boolean;
    variant: string;
    onCommandClick?: () => void;
}) => {
    if (props.hidden) {
        return null;
    }

    let content = props.children;
    if (props.variant === "note") {
        content = <em>{content}</em>;
    } else if (props.variant === "title") {
        content = <strong>{content}</strong>;
    }

    return (
        <StackPanel
            orientation={Orientation.Horizontal}
            className={`${props.className || ""} ${props.hidden ? "hidden" : ""}`}
        >
            <Panel size={SizeMode.Grow}>{content}</Panel>
            <Panel size={SizeMode.Shrink}>
                {props.commandLabel && (
                    <span>
                        &emsp;<a onClick={props.onCommandClick}>{props.commandLabel}</a>
                    </span>
                )}
            </Panel>
        </StackPanel>
    );
};

const byStartDateJobSorter = (a: Job, b: Job) => (b.startTime?.getTime?.() ?? 0) - (a.startTime?.getTime?.() ?? 0);

const defaultStrings = {
    summary: "{0} running jobs",
    title: "Background jobs",
    running: "Running",
    history: "History",
    noRunningJobs: "No jobs are running",
    noJobsHistory: "No jobs have been completed (yet)",
    leavePageConfirmation:
        "Closing the browser will cause not completed background jobs to be aborted, autonomous background jobs cannot be monitored on next session.",
    clearAll: "Clear all",
};

export interface AsyncJobListProps {
    strings: DefaultStrings<typeof defaultStrings>;
    jobs: object;
    dispatch: AppDispatch;
}

interface AsyncJobListState {
    size: PanelSize;
}

export class AsyncJobList extends React.PureComponent<AsyncJobListProps, AsyncJobListState> {
    public static readonly defaultProps = {
        strings: defaultStrings,
    };

    constructor(props: AsyncJobListProps) {
        super(props);

        this.handleClearCompletedJobs = this.handleClearCompletedJobs.bind(this);

        this.state = {
            size: PanelSize.MINIMIZED,
        };
    }

    groupJobsByStatus(): Jobs {
        const result: Jobs = {
            visiblePending: [],
            invisiblePending: [],
            completed: [],
        };

        if (!this.props.jobs) {
            return result;
        }

        // Running invisible jobs are displayed differently, completed invisible jobs are hidden only if they completed successfully.
        Array.prototype.sort.call(Object.values(this.props.jobs), byStartDateJobSorter).forEach((job: Job) => {
            if (job.status === "pending") {
                (job.invisible ? result.invisiblePending : result.visiblePending).push(job);
            } else if (!job.invisible || job.status === "failed") {
                result.completed.push(job);
            }
        });

        return result;
    }

    handleClearCompletedJobs() {
        this.props.dispatch(clearCompletedJobsAction());
    }

    renderSummary(sizeClass: string, pendingJobs: Job[]) {
        if (!pendingJobs.length) {
            return null;
        }

        // If we don't have running jobs then we expand directly to history
        const handleClick = () => {
            this.setState({
                size: pendingJobs.length ? PanelSize.NORMAL : PanelSize.EXPANDED,
            });
        };

        const summary =
            pendingJobs.length > 1 ? this.props.strings.summary.format(pendingJobs.length) : pendingJobs[0].title;

        return (
            <Panel className={`async-job-list-summary ${sizeClass}`} onClick={handleClick}>
                <If condition={pendingJobs.length > 0}>
                    <Then>
                        <Icon name={Icons.LoadingSymbol} spin />
                        <span>
                            &ensp;
                            {summary}
                        </span>
                    </Then>
                    <Else>
                        <Icon name={Icons.History} />
                    </Else>
                </If>
            </Panel>
        );
    }

    renderPendingJobs(pendingJobs: Job[]) {
        return pendingJobs.map((x) => {
            const Component = x.component;
            return <Component key={x.id} job={x} {...x.props} />;
        });
    }

    renderCompletedJobs(completedJobs: Job[]) {
        // Completed jobs does not render their own component
        return completedJobs.map((completedJob) => (
            <JobStatusIndicator
                key={completedJob.id}
                title={completedJob.title}
                description={completedJob.description}
                status={completedJob.status ?? "pending"}
                report={completedJob.report}
                startTime={completedJob.startTime}
                endTime={completedJob.endTime}
                canRetry={completedJob.canRetry ?? false}
                onRetry={completedJob.onRetry}
            />
        ));
    }

    renderVisibleJobs(jobs: Jobs) {
        if (!jobs.visiblePending.length && !jobs.completed.length) {
            return null;
        }

        const { strings } = this.props;
        const { size } = this.state;

        const sizeClass = `list-${size}`;
        const isExpanded = size === PanelSize.EXPANDED;

        const hasPendingJobs = jobs.visiblePending.length > 0 || jobs.invisiblePending.length > 0;

        let containerClassName = "async-job-list-container";
        if (!isExpanded) {
            containerClassName += ` seek-attention ${jobs.visiblePending.length ? "" : "no-pending-jobs"}`;
        }

        const handleMinimize = () => this.setState({ size: PanelSize.MINIMIZED });
        const handleToggle = () => {
            this.setState({
                size: isExpanded ? PanelSize.NORMAL : PanelSize.EXPANDED,
            });
        };

        return (
            <Box key="visible-jobs" className={containerClassName}>
                <LeavePageConfirmation when={hasPendingJobs} message={strings.leavePageConfirmation} ignoreRouting />

                {this.renderSummary(sizeClass, jobs.visiblePending)}

                <GroupPanel className={`async-job-list-content ${sizeClass}`} title={strings.title}>
                    <GroupPanel.Commands>
                        <Button variant={ButtonVariant.Inline} icon={Icons.ConsoleMinimize} onClick={handleMinimize} />
                        <Button
                            variant={ButtonVariant.Inline}
                            icon={isExpanded ? Icons.ConsoleRestore : Icons.ConsoleMaximize}
                            onClick={handleToggle}
                        />
                    </GroupPanel.Commands>
                    <Panel className="sections">
                        <section>
                            <Text variant="title" hidden={!isExpanded}>
                                {strings.running}
                            </Text>
                            <Text variant="note" hidden={jobs.visiblePending.length > 0}>
                                {strings.noRunningJobs}
                            </Text>
                            {this.renderPendingJobs(jobs.visiblePending)}
                        </section>
                        <section className={isExpanded ? "" : "hidden"}>
                            <Text
                                variant="title"
                                commandLabel={strings.clearAll}
                                onCommandClick={this.handleClearCompletedJobs}
                            >
                                {strings.history}
                            </Text>
                            <Text variant="note" hidden={jobs.completed.length > 0}>
                                {strings.noJobsHistory}
                            </Text>
                            {this.renderCompletedJobs(jobs.completed)}
                        </section>
                    </Panel>
                </GroupPanel>
            </Box>
        );
    }

    renderInvisibleJobs(jobs: Jobs) {
        if (!jobs.invisiblePending.length) {
            return null;
        }

        return (
            <div key="invisible-jobs" className="async-job-list-invisible-content">
                {this.renderPendingJobs(jobs.invisiblePending)}
            </div>
        );
    }

    render() {
        const jobs = this.groupJobsByStatus();

        return (
            <React.Fragment>
                {this.renderInvisibleJobs(jobs)}
                {this.renderVisibleJobs(jobs)}
            </React.Fragment>
        );
    }
}

export const mapStateToProps = (state: AppState) => ({
    config: state.config,
    jobs: state.asyncJobs,
});

export default connect(mapStateToProps)(translate("AsyncJobList")(AsyncJobList));
