import React from "react";
import { connect } from "react-redux";
import { Config } from "../../typings/interfaces";
import { AjaxState, onStateChange } from "../../creators/AjaxCreator";
import { AppState, AppDispatch } from "../../creators/reducers";
import CaptchaComponent from "./component";
import { getCaptcha, Captcha } from "./creator";
import { ValueEvent } from "portal-components";

interface CaptchaContainerProps {
    config: Config;
    captcha: AjaxState<Captcha>;
    dispatch: AppDispatch;
    captchaPrompt: string;
    onChange: (e: ValueEvent<string>) => void;
    inputName: string;
    answerNotEntered: boolean;
    autoFocus: boolean;
    onCaptchaIdChange: (id: string) => void;
}

interface CaptchaContainerState {
    loading: boolean;
    captcha: Captcha;
}

export class CaptchaContainer extends React.PureComponent<CaptchaContainerProps, CaptchaContainerState> {
    constructor(props: CaptchaContainerProps) {
        super(props);
        this.state = {
            loading: true,
            captcha: {} as Captcha,
        };
        this.handleRefresh = this.handleRefresh.bind(this);
    }
    componentDidMount() {
        const { captcha } = this.props.config.featureToggle;
        if (!captcha) {
            return;
        }
        this.handleRefresh();
    }

    UNSAFE_componentWillReceiveProps(nextProps: CaptchaContainerProps) {
        const { captcha } = this.props.config.featureToggle;
        if (!captcha) {
            return;
        }

        onStateChange(this.props.captcha, nextProps.captcha, {
            loaded: ({ data }) => {
                this.props.onCaptchaIdChange(data.captcha_id);
                this.setState({
                    loading: false,
                    captcha: data,
                });
            },
        });
    }

    handleRefresh() {
        this.setState({
            loading: true,
        });
        const { dispatch } = this.props;
        dispatch(getCaptcha());
    }

    render() {
        const captchaBytes = this.state.captcha ? this.state.captcha.captcha_bytes : "";
        const { captcha } = this.props.config.featureToggle;
        if (!captcha) {
            return null;
        }
        return (
            <CaptchaComponent
                imgSource={captchaBytes}
                captchaPrompt={this.props.captchaPrompt}
                onChange={this.props.onChange}
                onRefresh={this.handleRefresh}
                inputName={this.props.inputName}
                answerNotEntered={this.props.answerNotEntered}
                loading={this.state.loading}
                autoFocus={this.props.autoFocus}
            />
        );
    }
}

// map Redux store state to React props
export const mapStateToProps = (state: AppState) => {
    return {
        captcha: state.captcha,
        config: state.config,
    };
};

export default connect(mapStateToProps)(CaptchaContainer);
