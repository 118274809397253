import { makeConfigApiCreator, ParseFunction } from "../../creators";

interface BillingReportParameters {
    month: string;
    errorCallout?: boolean | ((response: any) => boolean);
}

export interface QuotaUsageReport {
    index: number;
    amount: number;
    time: string;
    campaign_name: string;
    type: string;
}

interface ServicePackageReport {
    quota_usage: QuotaUsageReport[];
    metadata: ServicePackageMetadata;
    aggregated_quota_usage: QuotaUsageReport[];
}

interface ServicePackageMetadata {
    remaining_quota: number;
    reserved_quota: number;
    start_time: string;
    end_time: string;
}

interface AccountReport {
    id: string;
    company: string;
}

interface DeviceToPelion {
    total_count?: number;
    registrations?: number;
    registration_updates?: number;
    registration_deletes?: number;
    observations?: number;
    est_requests?: number;
    proxy_responses?: number;
    bootstrap_requests?: number;
}

interface PelionToDevice {
    total_count?: number;
    proxy_requests?: number;
    subscriptions?: number;
}

interface BillingDataReport {
    active_devices?: number;
    bootstraps?: number;
    device_to_pelion_messages?: DeviceToPelion;
    firmware_updates?: number;
    pelion_to_device_messages?: PelionToDevice;
    pelion_to_webapp_notifications?: number;
    rest_api_requests_with_api_key_token?: number;
    rest_api_requests_with_user_token?: number;
    terminal_bytes_from_device?: number;
    terminal_bytes_to_device?: number;
    tunneling_bytes_from_device?: number;
    tunneling_bytes_to_device?: number;
    logging_bytes_from_device?: number;
}

interface BillingItem {
    account: AccountReport;
    aggregated: BillingDataReport;
    billing_data: BillingDataReport;
    service_package: ServicePackageReport;
    subtenants?: BillingItem[];
}

interface ApiCallsProcessed {
    deviceToService?: number;
    registrations?: number;
    registrationUpdates?: number;
    registrationDeletes?: number;
    observations?: number;
    proxyResponses?: number;
    bootstrapRequests?: number;
    estRequests?: number;
    serviceToDevice?: number;
    proxyRequests?: number;
    subscriptions?: number;
    apiUserSessions?: number;
    apiApiKey?: number;
    resourceChangeNotifications?: number;
    terminal_bytes_from_device?: number;
    terminal_bytes_to_device?: number;
    tunneling_bytes_from_device?: number;
    tunneling_bytes_to_device?: number;
    logging_bytes_from_device?: number;
}

interface BillingDataAccount extends ApiCallsProcessed {
    activeDevices?: number;
    firmwareUpdates?: number;
    usedQuota?: number;
    totalQuota?: number;
    quotaHistory: QuotaUsageReport[];
}

interface BillingDataAggregated
    extends Omit<BillingDataAccount, "usedQuota" | "totalQuota" | "quotaHistory">,
        ApiCallsProcessed {}

interface SubtenantProcessed extends ApiCallsProcessed {
    id: string;
    company: string;
    teamName: string;
    startTime: string;
    endTime: string;
    activeDevices?: number;
    firmwareUpdates?: number;
    usedQuota?: number;
    quotaHistory: QuotaUsageReport[];
}

interface BillingDataProcessed {
    aggregated: BillingDataAggregated;
    account: BillingDataAccount;
    subtenants: SubtenantProcessed[];
}

type SumSelector<T> = (item: T) => number | undefined;
const sum = <T>(items: T[] | null | undefined, selector: SumSelector<T>) => {
    if (!items) {
        return undefined;
    }

    let hasValues = false;
    const result = items.reduce((runningSum: number, item: T) => {
        const value = selector(item);
        if (value === undefined) {
            return runningSum;
        }

        hasValues = true;
        return runningSum + value;
    }, 0);

    return hasValues ? result : undefined;
};

const fixMissingFields = (response: BillingItem) => {
    const fix = (item: BillingItem) => {
        if (!item.service_package) {
            item.service_package = {
                metadata: {
                    remaining_quota: 0,
                    reserved_quota: 0,
                    start_time: "",
                    end_time: "",
                },
                quota_usage: [],
                aggregated_quota_usage: [],
            };
        }

        if (!item.service_package.quota_usage) {
            item.service_package.quota_usage = [];
        }
    };

    fix(response);

    if (!response.service_package.aggregated_quota_usage) {
        response.service_package.aggregated_quota_usage = [];
    }

    if (!response.subtenants) {
        response.subtenants = [];
    }

    response.subtenants.forEach(fix);
};

const parseApiCalls = (data: BillingDataReport) => {
    const {
        device_to_pelion_messages = {} as DeviceToPelion,
        pelion_to_device_messages = {} as PelionToDevice,
        rest_api_requests_with_api_key_token,
        rest_api_requests_with_user_token,
        pelion_to_webapp_notifications,
        terminal_bytes_from_device,
        terminal_bytes_to_device,
        tunneling_bytes_from_device,
        tunneling_bytes_to_device,
        logging_bytes_from_device,
    } = data;
    const {
        total_count: deviceToService,
        bootstrap_requests,
        est_requests,
        observations,
        proxy_responses,
        registration_deletes,
        registration_updates,
        registrations,
    } = device_to_pelion_messages;

    const { total_count: serviceToDevice, proxy_requests, subscriptions } = pelion_to_device_messages;

    const response: ApiCallsProcessed = {
        deviceToService,
        registrations,
        registrationUpdates: registration_updates,
        registrationDeletes: registration_deletes,
        observations,
        proxyResponses: proxy_responses,
        bootstrapRequests: bootstrap_requests,
        estRequests: est_requests,
        serviceToDevice,
        proxyRequests: proxy_requests,
        subscriptions,
        apiUserSessions: rest_api_requests_with_user_token,
        apiApiKey: rest_api_requests_with_api_key_token,
        resourceChangeNotifications: pelion_to_webapp_notifications,
        terminal_bytes_from_device,
        terminal_bytes_to_device,
        tunneling_bytes_from_device,
        tunneling_bytes_to_device,
        logging_bytes_from_device,
    };

    return response;
};

const parseResponse = (untypedResponse: unknown) => {
    const response = untypedResponse as BillingItem;
    fixMissingFields(response);

    let subtenants: SubtenantProcessed[] = [];
    const toProcess: BillingItem[] = [response].concat(response.subtenants ? response.subtenants : []);
    subtenants = toProcess.map((subtenant: BillingItem, index: number) => {
        // We assign an arbitrary ID to each entry, it's valid and unique only within this collection.
        // We need it because many UI components need an ID field.
        subtenant.service_package?.quota_usage?.forEach(
            (item: QuotaUsageReport, index: number) => (item.index = index)
        );

        return {
            index,
            selected: false,
            id: subtenant.account.id,
            company: subtenant.account.company,
            teamName: subtenant.account.company,

            startTime: response.service_package?.metadata?.start_time,
            endTime: response.service_package?.metadata?.end_time,

            activeDevices: subtenant.billing_data.active_devices,
            firmwareUpdates: subtenant.billing_data.firmware_updates,
            usedQuota: sum(subtenant.service_package?.quota_usage, (x) => x.amount),
            quotaHistory: subtenant.service_package?.quota_usage,
            ...parseApiCalls(subtenant.billing_data),
        };
    });

    const aggregated: BillingDataAggregated = response.aggregated
        ? {
              activeDevices: response.aggregated.active_devices,
              firmwareUpdates: response.aggregated.firmware_updates,
              ...parseApiCalls(response.aggregated),
          }
        : ({} as BillingDataAggregated);

    const remainingQuota = response.service_package?.metadata?.remaining_quota;
    const reservedQuota = response.service_package?.metadata?.reserved_quota;
    const account: BillingDataAccount = {
        activeDevices: response.billing_data.active_devices,
        firmwareUpdates: response.billing_data.firmware_updates,
        usedQuota: sum(response.service_package?.quota_usage, (x) => x.amount),
        totalQuota:
            reservedQuota !== undefined && remainingQuota !== undefined ? reservedQuota + remainingQuota : undefined,
        quotaHistory: response.service_package?.quota_usage,
        ...parseApiCalls(response.billing_data),
    };

    return { subtenants, account, aggregated };
};

const makeCreator = <T>(type: string, parseResponse?: ParseFunction<T>) =>
    makeConfigApiCreator<T>("v3_base")(type, parseResponse);

const { createThunk, reducer } = makeCreator<BillingDataProcessed>("subtenants_billing", parseResponse);

// Service returns 404 when billing for the specified month is not available, we do not
// want to show an error for this (just let the list empty)
const showErrorCallout = (response: any) => !response.notFound;

const getBilling = ({ month, errorCallout = showErrorCallout }: BillingReportParameters) => {
    const queryParameters = { month: month };

    return createThunk({
        param: `billing-report`,
        queryParameters,
        errorCallout,
        errorRedirect: false,
    });
};

interface ServicePackageQuotaParameters {
    errorCallout?: boolean | ((response: any) => boolean);
}

interface ServicePackageQuota {
    quota?: number;
}

const { createThunk: servicePackageQuotaThunk, reducer: servicePackageQuotaReducer } = makeCreator<ServicePackageQuota>(
    "service_package_quota"
);

const getServicePackageQuotaAction = ({ errorCallout = showErrorCallout }: ServicePackageQuotaParameters = {}) => {
    return servicePackageQuotaThunk({
        param: "service-packages-quota",
        errorCallout,
        errorRedirect: false,
    });
};

interface ServicePackage {
    id?: string;
    previous_id: string;
    created?: string;
    modified?: string;
    start_time?: string;
    expires?: string;
    end_time?: string;
    firmware_update_count?: number;
    reason?: string;
}

interface ServicePackages {
    active?: ServicePackage;
    previous?: ServicePackage[];
}

const { createThunk: servicePackageThunk, reducer: servicePackageReducer } = makeCreator<ServicePackages>(
    "service_package"
);

const getServicePackage = () => {
    return servicePackageThunk({
        param: "service-packages",
        errorCallout: true,
        errorRedirect: false,
    });
};

interface BillingReport {
    url: string;
}

const { createThunk: activeDevicesThunk, reducer: activeDevicesReducer } = makeCreator<BillingReport>("active_devices");

const getActiveDevicesReport = (month: string) => {
    return activeDevicesThunk({
        param: "billing-report-active-devices",
        queryParameters: { month },
        errorRedirect: false,
        errorCallout: true,
    });
};

const { createThunk: firmwareUpdatesThunk, reducer: firmwareUpdatesReducer } = makeCreator<BillingReport>(
    "firmware-updates"
);

const getFirmwareUpdatesReport = (month: string) => {
    return firmwareUpdatesThunk({
        param: "billing-report-firmware-updates",
        queryParameters: { month },
        errorRedirect: false,
        errorCallout: true,
    });
};

export {
    ApiCallsProcessed,
    BillingDataAccount,
    BillingDataAggregated,
    BillingReport,
    ServicePackageQuota,
    BillingDataProcessed,
    ServicePackages,
    getBilling,
    reducer,
    getServicePackageQuotaAction,
    servicePackageQuotaReducer,
    getServicePackage,
    servicePackageReducer,
    getActiveDevicesReport,
    activeDevicesReducer,
    getFirmwareUpdatesReport,
    firmwareUpdatesReducer,
    SubtenantProcessed,
};
