import {
    ButtonVariant,
    DateTime,
    DropdownButton,
    GroupPanel,
    Icons,
    LinkButton,
    Span,
    Text,
    Tooltip,
    If,
} from "portal-components";
import React from "react";
import CopyCallout from "../../../../controls/copyCallout";
import LogLevelComponent from "../../../../controls/logLevelComponent";
import translate, { DefaultStrings } from "../../../../i18n/translate";
import { GatewayLog } from "./creator";

const defaultStrings = {
    origin: "Origin",
    appName: "Application Name",
    tag: "eTag",
    type: "Type",
};

interface LogDetailsProps {
    strings: DefaultStrings<typeof defaultStrings>;
    showLink?: boolean;
    log: GatewayLog;
}

export class LogDetails extends React.PureComponent<LogDetailsProps> {
    public static defaultProps = {
        strings: defaultStrings,
    };

    constructor(props: LogDetailsProps) {
        super(props);
        this.buildDetailsModal = this.buildDetailsModal.bind(this);
    }

    buildDetailsModal() {
        const {
            log: {
                device_id = null,
                level = "",
                app_name = "",
                type = "",
                etag = "",
                message = "",
                timestamp = null,
            } = {},
            showLink,
            strings,
        } = this.props;

        const linkUrl = `/devices/list/${device_id}/gatewayalerts`;
        const link = (
            <If condition={showLink && device_id}>
                <Tooltip
                    content={
                        <Text
                            copiable
                            copiableText={`${window.location.origin + linkUrl}`}
                            copyButtonSiblings={
                                <LinkButton to={linkUrl} icon={Icons.LinkInfo} variant={ButtonVariant.Inline} />
                            }
                        >
                            {linkUrl}
                        </Text>
                    }
                >
                    <LinkButton to={linkUrl} icon={Icons.LinkInfo} variant={ButtonVariant.Inline} />
                </Tooltip>
            </If>
        );

        return (
            <GroupPanel
                title={(<LogLevelComponent level={level} truncated={false} />) as any}
                description={DateTime.format(timestamp)}
            >
                {link}
                <span>
                    {strings.tag}: <strong>{etag}</strong>
                </span>
                <br />
                <span>
                    {strings.appName}: <strong>{app_name}</strong>
                </span>
                <br />
                <span>
                    {strings.type}: <strong>{type}</strong>
                </span>
                <CopyCallout text={message} />
            </GroupPanel>
        );
    }

    render() {
        const { log: { id, message } = {} } = this.props;
        return (
            <DropdownButton
                text={
                    <a>
                        <Span text={message} id={id} />
                    </a>
                }
                variant={ButtonVariant.Compact}
            >
                {() => this.buildDetailsModal()}
            </DropdownButton>
        );
    }
}

export default translate("LogDetails")(LogDetails);
