import React from "react";
import { QRCodeProps } from "./types";
const QRCodeComponent = React.lazy(() => import("./component"));

export const QrCode: React.FunctionComponent<QRCodeProps> = (props) => (
    <React.Suspense fallback={null}>
        <QRCodeComponent {...props} />
    </React.Suspense>
);

QrCode.displayName = "QrCode";
export default QrCode;
