import { AnyAction } from "redux";
import { notify } from "../features/devices";
import { AppDispatch } from "./reducers";

export default class PollingCreator {
    public static addData(body: object) {
        return (dispatch: AppDispatch) => {
            dispatch({
                type: PollingCreator.actions.newData,
                body,
            });
        };
    }

    public static clearData() {
        return (dispatch: AppDispatch) => {
            dispatch({ type: PollingCreator.actions.clearData });
        };
    }

    public static createReducer() {
        return (state = {}, action: AnyAction) => {
            switch (action.type) {
                case PollingCreator.actions.newData:
                    //notify the sdk
                    notify(action.body);
                    return Object.assign({}, state, {
                        data: action.body,
                        state: PollingCreator.actions.newData,
                    });
                case PollingCreator.actions.clearData:
                    return Object.assign({}, state, {
                        data: null,
                        state: PollingCreator.actions.clearData,
                    });
                default:
                    return state;
            }
        };
    }

    public static actions = {
        newData: "NEW_POLLING_DATA",
        clearData: "CLEAR_POLLING_DATA",
    };
}
