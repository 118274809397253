import React from "react";
import { connect } from "react-redux";
import { AjaxState } from "../../creators/AjaxCreator";
import { AppState } from "../../creators/reducers";
import ClientBatchOperation from "../asyncJob/clientBatchOperation";
import { Job } from "../asyncJobList/creator";
import { suspendDevice } from "./creator";
import { DeviceBlock } from "./types";

export interface SuspendDevicesJobProps {
    devices: { id: string }[];
    blockData: DeviceBlock;
    onCompleted: () => void;
    job: Job;
    deviceSuspend: {
        [key: string]: AjaxState<void>;
    };
}

export class SuspendDevicesJob extends React.PureComponent<SuspendDevicesJobProps> {
    constructor(props: Readonly<SuspendDevicesJobProps>) {
        super(props);

        this.handleSuspendDevice = this.handleSuspendDevice.bind(this);
        this.handleCompleted = this.handleCompleted.bind(this);
    }

    handleSuspendDevice(device: { id: string }) {
        return suspendDevice({
            id: this.props.job.id,
            deviceId: device.id,
            data: this.props.blockData,
        });
    }

    handleCompleted() {
        this.props.onCompleted && this.props.onCompleted();
    }

    render() {
        return (
            <ClientBatchOperation
                job={this.props.job}
                list={this.props.devices}
                response={(this.props.deviceSuspend as any)[this.props.job.id]} // Keep any for now, legacy component
                onProcess={this.handleSuspendDevice}
                onCompleted={this.handleCompleted}
            />
        );
    }
}

export function mapStateToProps(state: AppState) {
    return {
        deviceSuspend: state.suspendDevice,
    };
}

export default connect(mapStateToProps)(SuspendDevicesJob);
