import { Icon, Icons } from "portal-components";
import React from "react";

const REQUEST_STATUS = {
    LOADING: "loading",
    SUCCEEDED: "succeeded",
    FAILED: "failed",
};

const INDICATOR_VARIANT = {
    SPINNER: "spinner",
    ELLIPSIS: "ellipsis",
};

const centeredStyle = {
    width: "100%",
    margin: "0 auto",
};

export interface StatusIndicatorProps {
    className?: string;
    centered?: boolean;
    status: "loading" | "succeeded" | "failed";
    variant: "spinner" | "ellipsis";
}

interface State {
    tick: number;
}

class StatusIndicator extends React.Component<StatusIndicatorProps, State> {
    public static readonly defaultProps = {
        className: "",
        status: REQUEST_STATUS.LOADING,
        variant: INDICATOR_VARIANT.SPINNER,
    };

    private timer?: number;

    constructor(props: StatusIndicatorProps) {
        super(props);

        if (props.variant === INDICATOR_VARIANT.ELLIPSIS) {
            this.timer = window.setInterval(() => this.setState({ tick: this.state.tick + 1 }), 300);
        }

        this.state = { tick: 0 };
    }

    componentWillUnmount() {
        if (this.timer) {
            window.clearInterval(this.timer);
        }
    }

    render() {
        if (this.props.status !== REQUEST_STATUS.LOADING) {
            return null;
        }

        const style = this.props.centered ? centeredStyle : {};

        if (this.props.variant === INDICATOR_VARIANT.SPINNER) {
            return <Icon name={Icons.LoadingSymbol} spin className={this.props.className} style={style} />;
        }

        return (
            <span style={style} className={this.props.className}>
                {Array((this.state.tick % 4) + 1).join(". ")}
            </span>
        );
    }
}

export default StatusIndicator;
export { REQUEST_STATUS, INDICATOR_VARIANT, StatusIndicator };
