import { GroupPanel, LinkButton, Text } from "portal-components";
import React from "react";
// import Markup from "../../controls/markup";
import translate from "../../i18n/translate";

const defaultStrings = {
    header: "Upgrade your account",
    text:
        "Commercial accounts provide additional capabilities, suitable for organisations that manage devices in production environments. Contact us to learn more about the benefits, and how to upgrade your account.",
    button: "Upgrade account",
    emailHeader: "Email",
    emailLink: "You can email us at [{0}](mailto:{0})",
};

export interface FreeAccountProps {
    strings: typeof defaultStrings;
    link: string;
    supportEmailAddress: string;
}

export const FreeAccount: React.FunctionComponent<FreeAccountProps> = (props) => {
    const { link, strings /*supportEmailAddress*/ } = props;
    return (
        <React.Fragment>
            {/* TODO: reinstate when https://jira.arm.com/browse/IOTSPOR-3267
            <GroupPanel title={strings.emailHeader}>
                <Markup string={strings.emailLink.format(supportEmailAddress)} />
            </GroupPanel>
            */}
            <GroupPanel title={strings.header}>
                <GroupPanel.Commands>
                    <LinkButton to={link} text={strings.button} primary />
                </GroupPanel.Commands>
                <Text>{strings.text}</Text>
            </GroupPanel>
        </React.Fragment>
    );
};

FreeAccount.defaultProps = {
    strings: defaultStrings,
};

export default translate("FreeAccount")(FreeAccount);
