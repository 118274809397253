import moment from "moment";
import React from "react";
import DeviceLifecycleStatus from "../controls/filter/deviceLifecycleStatus";
import DeviceState from "../controls/filter/deviceState";
import DeviceSuspensionCategory from "../controls/filter/deviceSuspensionCategory";
import { EdgeGateway } from "../features/devices";
import { isoDate } from "./utility";
import { AuthorizationRule } from "../script/permissions";

export enum StandardOperators {
    EQUAL = "eq",
    NOT_EQUAL = "neq",
    IN = "in",
    NOT_IN = "nin",
}

enum OtherOperators {
    GREATER_THAN_EQUAL = "gte",
    LESS_THAN_EQUAL = "lte",
}
export type AllOperators = StandardOperators | OtherOperators;
export const dateFormat = "YYYY-MM-DD";

export const standardOperators = Object.entries({ ...StandardOperators }).map(([, a]) => a);
export const allOperators = Object.entries({ ...StandardOperators, ...OtherOperators }).map(([, a]) => a);

export type TagType = "dropdown" | "datetime" | "text" | "number" | "boolean" | "custom" | "component" | "date";

export const operatorsForTagType = (tagType: TagType) => (tagType?.includes("date") ? allOperators : standardOperators);

interface KnownTag {
    type: TagType;
    renderValue?: (value: string) => JSX.Element;
    renderValues?: (value: string) => JSX.Element;
    maxLength?: number;
    min?: number;
    max?: number;
    values?: string[];
    requires?: AuthorizationRule;
}
interface KnownTags {
    [index: string]: KnownTag;
}

export const knownTags: KnownTags = {
    state: {
        type: "dropdown",
        renderValue: (state: string) => <DeviceState key={state} state={state} />,
        values: ["unenrolled", "cloud_enrolling", "bootstrapped", "registered", "deregistered"],
    },
    device_class: {
        maxLength: 32,
        type: "text",
    },
    device_execution_mode: {
        min: 0,
        max: 5,
        type: "number",
    },
    serial_number: {
        maxLength: 64,
        type: "text",
    },
    id: {
        maxLength: 255,
        type: "text",
    },
    vendor_id: {
        maxLength: 255,
        type: "text",
    },
    description: {
        maxLength: 255,
        type: "text",
    },
    name: {
        maxLength: 128,
        type: "text",
    },
    created_at: {
        type: "datetime",
    },
    host_gateway: {
        maxLength: 64,
        type: "text",
    },
    ca_id: {
        maxLength: 255,
        type: "text",
    },
    endpoint_name: {
        maxLength: 255,
        type: "text",
    },
    endpoint_type: {
        maxLength: 255,
        type: "text",
    },
    bootstrap_expiration_date: {
        type: "date",
    },
    bootstrapped_timestamp: {
        type: "datetime",
    },
    connector_expiration_date: {
        type: "date",
    },
    deployed_state: {
        type: "text",
    },
    deployment: {
        type: "text",
    },
    device_key: {
        type: "text",
    },
    enrolment_list_timestamp: {
        type: "datetime",
    },
    etag: {
        type: "datetime",
    },
    firmware_checksum: {
        type: "text",
    },
    manifest_timestamp: {
        type: "datetime",
    },
    manifest: {
        type: "text",
    },
    mechanism_url: {
        type: "text",
    },
    mechanism: {
        type: "text",
    },
    net_id: {
        maxLength: 40,
        type: "text",
    },
    updated_at: {
        type: "datetime",
    },
    last_operator_suspended_category: {
        requires: { feature: "device_suspension" },
        renderValues: (name) => <DeviceSuspensionCategory key={name} />,
        type: "dropdown",
    },
    last_operator_suspended_description: {
        requires: { feature: "device_suspension" },
        maxLength: 255,
        type: "text",
    },
    last_operator_suspended_updated_at: {
        requires: { feature: "device_suspension" },
        type: "datetime",
    },
    last_system_suspended_category: {
        requires: { feature: "device_suspension" },
        renderValues: (name) => <DeviceSuspensionCategory key={name} />,
        type: "dropdown",
    },
    last_system_suspended_description: {
        requires: { feature: "device_suspension" },
        maxLength: 255,
        type: "text",
    },
    last_system_suspended_updated_at: {
        requires: { feature: "device_suspension" },
        type: "datetime",
    },
    operator_suspended: {
        requires: { feature: "device_suspension" },
        type: "boolean",
    },
    system_suspended: {
        requires: { feature: "device_suspension" },
        type: "boolean",
    },
    lifecycle_status: {
        requires: { feature: "device_suspension" },
        type: "dropdown",
        renderValue: (status) => <DeviceLifecycleStatus key={status} status={status} />,
        values: ["enabled", "blocked"],
    },
};

export const defaultValueForTag = (tagName: keyof typeof knownTags) => {
    const tagDefinition = knownTags[tagName];

    if (tagDefinition) {
        if (tagDefinition.type === "datetime") {
            return isoDate(new Date());
        } else if (tagDefinition.type === "date") {
            return isoDate(new Date(), dateFormat);
        } else if (tagDefinition.type === "dropdown") {
            return tagDefinition.values?.[0] ?? "";
        } else if (tagDefinition.type === "number") {
            return 0;
        } else if (tagDefinition.type === "boolean") {
            return "false";
        } else {
            return "";
        }
    } else {
        return "";
    }
};

export interface Rule {
    tagName?: keyof typeof knownTags;
    value?: string | number;
    operator: AllOperators;
}

export interface Filter extends Rule {
    tagDefinition?: KnownTag;
    type?: TagType;
    key?: string;
}

export interface SavedFilter {
    id: string;
    name: string;
    query: string;
}

export const filterTagsEqual = (tag1: Filter) => (tag2: Filter) => {
    if (tag1.type !== tag2.type || tag1.value !== tag2.value) {
        return false;
    }

    if (tag1.type === "custom") {
        return tag1.key === tag2.key;
    }

    return tag1.operator === tag2.operator && tag1.tagName === tag2.tagName;
};

export const filtersEqual = (filter1: Filter[]) => (filter2: Filter[]) =>
    filter1.length === filter2.length && filter1.every((tag) => filter2.findIndex(filterTagsEqual(tag)) >= 0);

// Does the value match the tag definition?
export const validateTagValue = ({ tagDefinition, value }: Pick<Filter, "tagDefinition" | "value">) => {
    // Regex for validating that datetime timestamp is in UTC RFC3339 format
    const datetimeRegex = RegExp(
        /^([0-9]+)-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])[Tt]([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9]|60)(\.[0-9]+)?(([Zz])|([+|-]([01][0-9]|2[0-3]):[0-5][0-9]))$/
    );
    switch (tagDefinition?.type) {
        case "datetime":
            return !!datetimeRegex.exec(value as string) && moment(value, moment.ISO_8601).isValid();
        case "date":
            return moment(value, dateFormat, true).isValid();
        case "dropdown":
            // If there isn't "values" then rendering and validation is responsibility of
            // the control returned by "getRenderValues()" (note the final "s", it's not "getRenderValue()").
            return !tagDefinition.values || tagDefinition.values.includes(value as string) || value === "";
        case "number":
            return !isNaN(value as number);
        case "text":
            return typeof value === "string";
        case "boolean":
            return value === "false" || value === "true" || typeof value === "boolean";
        default:
            return false;
    }
};

/**
 * Parse a url formencoded query to an array of tags as understood by Portal.
 * Null is returned if the query string could not be parsed. This could happen if a saved query
 * contains fields or operators that are not understood by Portal, or if the user is typing
 * into the "advanced" query text area.
 */
export const parseQuery = (query = "") => {
    return query
        .split(/[?&]/)
        .filter((pair) => pair !== "")
        .map((pair) => pair.split("="))
        .reduce<Filter[]>((acc, parts) => {
            // Propagate parsing failure
            if (acc === null) return acc;

            // Invalid query string if there is not a key and a value
            if (parts.length !== 2) return acc;

            const key = parts[0];
            let value;

            try {
                value = decodeURIComponent(parts[1]);
            } catch (e) {
                return acc;
            }

            const matchCustom = /^custom_attributes__(.*)$/.exec(key);
            const matchComponent = /^component_attributes__([a-zA-Z0-9]*)(?:__(\w*))?$/.exec(key);

            if (matchCustom) {
                return [...acc, { type: "custom", key: decodeURIComponent(matchCustom[1] || ""), value }] as Filter[];
            } else if (matchComponent) {
                return [
                    ...acc,
                    {
                        type: "component",
                        key: decodeURIComponent(matchComponent[1] || ""),
                        value,
                        operator: matchComponent[2],
                    },
                ] as Filter[];
            } else {
                const match = new RegExp(`^(.+?)(?:__(${allOperators.join("|")}))?$`).exec(key);

                // Key is not a format that Portal recognises
                if (!match || !match[1]) return acc;

                const tagName = match[1];
                const tagDefinition = knownTags[tagName];

                // This is not a field that Portal understands
                if (!tagDefinition) return acc;

                // The __eq operator is optional - normalise to "eq"
                const operator = (match[2] || "eq") as AllOperators;
                const allowedOperators = operatorsForTagType(tagDefinition.type);

                // Operator not allowed for given field
                if (!allowedOperators.includes(operator)) return acc;

                if (!validateTagValue({ tagDefinition, value } as Filter)) return acc;

                return [...acc, { type: "attribute", tagName, value, operator }] as Filter[];
            }
        }, []);
};

/**
 * Build a url formencoded query from an array of tags.
 */
export const buildQuery = (tags: Filter[]) =>
    tags
        .map(({ type, tagName, key, value, operator }) => {
            switch (type) {
                case "custom":
                    return [`custom_attributes__${key}`, value];
                case "component":
                    return [`component_attributes__${key}__${operator}`, value];
                default:
                    return [`${tagName}__${operator}`, value];
            }
        })
        .map((pair) => (pair as string[]).join("="))
        .join("&");

interface FilterOptions {
    [index: string]: Filter[];
}

const DeviceTypes: FilterOptions = {
    DIRECT: [
        { tagName: "host_gateway", operator: StandardOperators.EQUAL, value: "" },
        { tagName: "endpoint_type", operator: StandardOperators.NOT_EQUAL, value: EdgeGateway },
    ],
    GATEWAY_DEVICE: [{ tagName: "host_gateway", operator: StandardOperators.NOT_EQUAL, value: "" }],
    GATEWAY: [{ tagName: "endpoint_type", operator: StandardOperators.EQUAL, value: EdgeGateway }],
};

const DeviceExecutionModes = {
    DEVELOPMENT: [{ tagName: "device_execution_mode", operator: StandardOperators.EQUAL, value: "1" }],
    PRODUCTION: [{ tagName: "device_execution_mode", operator: StandardOperators.NOT_EQUAL, value: "1" }],
};

const RegisteredOnlyMode = [{ tagName: "state", operator: StandardOperators.EQUAL, value: "registered" }];

const LifecycleStatusModes = {
    ENABLED: [{ tagName: "lifecycle_status", operator: StandardOperators.EQUAL, value: "enabled" }],
    BLOCKED: [{ tagName: "lifecycle_status", operator: StandardOperators.EQUAL, value: "blocked" }],
};

export const QuickFilters = {
    DeviceTypes,
    DeviceExecutionModes,
    RegisteredOnlyMode,
    LifecycleStatusModes,
};
