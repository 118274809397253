import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Config, FeatureToggle } from "../../typings/interfaces";
import CalloutCreator from "../../creators/CalloutCreator";
import translate, { DefaultStrings } from "../../i18n/translate";
import { AppDispatch, AppState } from "../../creators/reducers";
import { isCurrentAdmin } from "../../script/roles";
import { redirectTo } from "../../script/routing";
import { logout } from "../login/logout/creator"; // importing from the creator to avoid a circular dependency

const defaultStrings = {
    cannotCarryOutRequest: "The page cannot carry out the request.",
    pleaseSignIn: "Please sign in again using a different account.",
    sendErrorReport:
        "You do not have the required privileges to access this page. Are you using a developer account to view an administrative page?",
    contactSupport: "Contact support team at: {0}",
    thanks: "Thanks!",
    notAuthorized: "Not authorized",
};

export interface AuthorizationComponentProps extends RouteComponentProps {
    strings: DefaultStrings<typeof defaultStrings>;
    config: Config;
    AuthorizationProps: { feature: string; Component: any };
    dispatch: AppDispatch;
}

interface AuthorizationComponentState {
    authChecked: boolean;
    isAuthorized: boolean;
}

export class AuthorizationComponent extends React.PureComponent<
    AuthorizationComponentProps,
    AuthorizationComponentState
> {
    public static readonly defaultProps = {
        strings: defaultStrings,
    };

    constructor(props: AuthorizationComponentProps) {
        super(props);
        this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.isAuthorized = this.isAuthorized.bind(this);
        this.checkAuthentication = this.checkAuthentication.bind(this);
        this.state = {
            authChecked: false,
            isAuthorized: false,
        };
    }

    componentDidMount() {
        this.checkAuthentication();
    }

    handleLogoutClick() {
        this.props.dispatch(logout({ endIdpSession: true }));
    }

    isAuthorized() {
        const {
            AuthorizationProps: { feature },
            config,
        } = this.props;

        const toggle =
            Object.prototype.hasOwnProperty.call(config.featureToggle, feature) &&
            !config.featureToggle[feature as keyof FeatureToggle];

        const access = {
            admin: isCurrentAdmin(),
        } as { [key: string]: boolean };

        const featureAccess = Object.prototype.hasOwnProperty.call(access, feature) ? access[feature] : true;
        return !(!featureAccess || toggle);
    }

    checkAuthentication() {
        const { strings, config: { externalLinks: { supportEmailAddress = "" } = {} } = {} } = this.props;

        if (!this.isAuthorized()) {
            this.props.dispatch({
                type: CalloutCreator.actions.newCallout,
                severity: "warning",
                header: strings.notAuthorized,
                body: [
                    <div key="access-denied">
                        <div>
                            {strings.cannotCarryOutRequest}&nbsp;
                            <a onClick={this.handleLogoutClick}>{strings.pleaseSignIn}</a>.
                        </div>
                        <div>
                            {strings.sendErrorReport}&nbsp;
                            <a href={"mailto:" + supportEmailAddress}>
                                {strings.contactSupport.format(supportEmailAddress)}
                            </a>
                            &nbsp;
                            {strings.thanks}
                        </div>
                    </div>,
                ],
                showToast: true,
                acked: true,
            });

            redirectTo({ path: "/" });
        }
    }

    render() {
        const {
            AuthorizationProps: { Component },
            ...rest
        } = this.props;
        const newProps = { ...rest, strings: undefined };
        return this.isAuthorized() ? <Component params={this.props.match.params} {...newProps} /> : null;
    }
}

// map Redux store state to React props
export const mapStateToProps = (state: AppState) => ({ config: state.config });

export default function requireAuthorization(feature: string, Component: any) {
    return connect(mapStateToProps, (dispatch) => ({ AuthorizationProps: { feature, Component }, dispatch }))(
        translate("AuthorizationComponent")(AuthorizationComponent)
    );
}
