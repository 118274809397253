import { ButtonVariant, DateTime, DropdownButton, GroupPanel, If } from "portal-components";
import React from "react";
import CopyCallout from "../../../controls/copyCallout";
import LogLevelComponent from "../../../controls/logLevelComponent";
import translate, { DefaultStrings } from "../../../i18n/translate";
import EventMessage from "./eventMessage";
import { DeviceEvent } from "../detail/events/creator";

const defaultStrings = {
    type: "Type",
};

interface EventDetailComponentProps {
    strings: DefaultStrings<typeof defaultStrings>;
    event: DeviceEvent;
}

export class EventDetail extends React.PureComponent<EventDetailComponentProps> {
    public static defaultProps = {
        strings: defaultStrings,
    };

    constructor(props: EventDetailComponentProps) {
        super(props);
        this.buildDetailsModal = this.buildDetailsModal.bind(this);
    }

    buildDetailsModal(event: DeviceEvent) {
        const { strings } = this.props;
        const { data, date_time = null, device_id, event_type, event_type_category, event_type_description } =
            event || {};
        const isValidEvent = event_type && event_type_category && event_type_description;

        const title = (
            <If condition={event_type_description}>
                <div>
                    {strings.type}: <LogLevelComponent level={event_type_description} truncated={false} />
                </div>
            </If>
        );

        const extra = isValidEvent ? { data } : {};
        const text = JSON.stringify(
            {
                device_id,
                event_type,
                event_type_category,
                ...extra,
            },
            null,
            2
        );

        return (
            <GroupPanel title={(title as unknown) as string} description={DateTime.format(date_time)}>
                <EventMessage event={event} truncated={false} />
                <CopyCallout text={text} />
            </GroupPanel>
        );
    }

    render() {
        const { event } = this.props;
        return (
            <DropdownButton
                text={
                    <a>
                        <EventMessage event={event} />
                    </a>
                }
                variant={ButtonVariant.Compact}
            >
                {() => this.buildDetailsModal(event)}
            </DropdownButton>
        );
    }
}

export default translate("EventDetail")(EventDetail);
