import React from "react";
import { connect } from "react-redux";
import { Config } from "../typings/interfaces";
import { isInitializing, isLoaded, isLoading } from "../creators/AjaxCreator";
import { Access, AuthorizationRule, isAuthorized } from "../script/permissions";
import { getAccess } from "../script/roles";
import { AppState } from "../creators/reducers";
import { redirectTo } from "../script/routing";

interface RequiresProps {
    accessLoading?: boolean;
    config?: Config;
    access?: Access;
    rule?: AuthorizationRule;
    redirectIfUnsupported?: boolean;
    children?: React.ReactElement;
}

export class Requires extends React.Component<RequiresProps> {
    public constructor(props: Readonly<RequiresProps>) {
        super(props);
    }

    public shouldComponentUpdate(nextProps: RequiresProps) {
        return nextProps.accessLoading === false;
    }

    public componentDidUpdate() {
        const { access = getAccess() as Access, rule, config, redirectIfUnsupported } = this.props;

        if (redirectIfUnsupported && !isAuthorized(config, access, rule || ({} as AuthorizationRule))) {
            redirectTo({ path: "/404", replace: true });
        }
    }

    public render() {
        const { access = getAccess() as Access, children, config, rule } = this.props;

        return isAuthorized(config, access, rule || ({} as AuthorizationRule)) ? children : null;
    }
}

export function mapStateToProps({ config, currentaccount }: AppState) {
    const access = getAccess() as Access;
    const accessLoading = isLoading(currentaccount) || isInitializing(currentaccount);
    if (isLoaded(currentaccount)) {
        const {
            data: { tier, parent_id },
        } = currentaccount;
        access.tier = tier;
        access.tenant = parent_id !== null;
    }

    return {
        access,
        accessLoading,
        config,
    };
}

export default connect(mapStateToProps)(Requires);
