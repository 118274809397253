import { Config as SdkConfig, SDKError } from "mbed-cloud-sdk";
import { Action, AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { Config } from "../typings/interfaces";
import history from "../script/history";
import { loadCookie } from "../script/utility";
import { AjaxState, RequestState, SuccessAction, RequestAction, FailureAction, ResetAction } from "./AjaxCreator";
import CalloutCreator from "./CalloutCreator";

export interface SdkCreatorOptions {
    id?: string;
    errorCallout?: boolean;
    errorAction?: (error?: SDKError) => void;
    errorRedirect?: boolean | (() => void);
}

// Creator that can be used for any SDK API call
export const sdkCreator = <TInput, TReturns, TParsed = TReturns>(
    type: string,
    creatorOptions: {
        sdkMethod?: (input: TInput, config: SdkConfig, options?: SdkCreatorOptions) => Promise<TReturns>;
        parseFunction?: (body: TReturns, id?: string, requestData?: TInput) => TParsed;
    }
) => {
    const prefix = type.toUpperCase();

    const actionTypes = {
        requestType: `${prefix}_REQUEST`,
        successType: `${prefix}_SUCCESS`,
        failureType: `${prefix}_FAILURE`,
        resetType: `${prefix}_RESET`,
    };

    const isRequest = (action: Action): action is RequestAction<TReturns> => action.type === actionTypes.requestType;
    const isSuccess = (action: Action): action is SuccessAction<TReturns> => action.type === actionTypes.successType;
    const isFailure = (action: Action): action is FailureAction => action.type === actionTypes.failureType;
    const isReset = (action: Action): action is ResetAction => action.type === actionTypes.resetType;

    const reducer = (
        state: AjaxState<TParsed> = { requestState: RequestState.INITIALIZING, id: "" },
        action: Action
    ): AjaxState<TParsed> => {
        if (isRequest(action)) {
            return { requestState: RequestState.LOADING, id: action.id };
        } else if (isSuccess(action)) {
            const { parseFunction } = creatorOptions;
            const data = parseFunction
                ? parseFunction(action.data as TReturns, action.id, action.info as TInput)
                : ((action.data as unknown) as TParsed);
            return {
                requestState: RequestState.LOADED,
                id: action.id,
                data,
                text: "",
            };
        } else if (isFailure(action)) {
            return { requestState: RequestState.FAILED, id: action.id, error: (action as AnyAction).error };
        } else if (isReset(action)) {
            return { requestState: RequestState.INITIALIZING, id: action.id };
        }

        return state;
    };

    const createThunk = (
        input: TInput,
        options: SdkCreatorOptions = {}
    ): ThunkAction<Promise<void>, { config: Config }, never, Action> => async (dispatch, getState) => {
        if (!creatorOptions.sdkMethod) {
            return Promise.reject(() =>
                dispatch({ type: actionTypes.failureType, error: "Single entity action is not defined" })
            );
        } else {
            dispatch({ type: actionTypes.requestType, id: options.id });

            const sdkConfig = getSdkConfig(getState);

            try {
                const data = await creatorOptions.sdkMethod(input, sdkConfig, options);
                dispatch({ type: actionTypes.successType, data, id: options.id });
            } catch (error) {
                errorHandler(error, options, dispatch, prefix);
            }
        }
    };

    const createResetAction = () => ({
        type: actionTypes.resetType,
    });

    return { reducer, createThunk, createResetAction };
};

let sdkConfig: SdkConfig;
export const getSdkConfig = (
    getState: () => {
        config: Config;
    }
) => {
    if (!sdkConfig) {
        const { config } = getState();
        sdkConfig = new SdkConfig({ apiKey: () => loadCookie("token"), host: config.apiHost });
        return sdkConfig;
    }

    return sdkConfig;
};

export const errorHandler = (
    error: SDKError,
    options: SdkCreatorOptions,
    dispatch: any,
    actionPrefix: string,
    errorRedirect: boolean | (() => void) = options.errorRedirect ?? true
) => {
    const { code, response } = error;
    const { errorAction, errorCallout } = options;

    // TODO: try to use the same error handler on both Ajax and SDK creator
    if (code === 401) {
        // To be removed when API is changed
        if (response.body.type === "reauth_required" || response.body.message.includes("Reauthentication required")) {
            dispatch({
                type: "REAUTH_REQUEST",
                data: {},
            });
        } else if (errorRedirect) {
            // Unauthorized error, which means the token has probably timed out on the server.
            // In this case, IAM will delete the token so we don't need to POST logout request to the server.
            // Just dispatch a LOGOUT_REQUEST action and redirect to login page
            dispatch({
                type: "LOGOUT_REQUEST",
                data: {
                    overlayMessage: {
                        description:
                            "You have been logged out due to inactivity. Please log in again to resume your session.",
                    },
                },
            });
        }
    }

    if (code === 404 && errorRedirect) {
        // Not found error, which we handle by redirecting the user to the dedicated 404 page (unless otherwise specified).
        if (typeof errorRedirect === "function") {
            errorRedirect();
        } else {
            history.push("/404");
        }
    } else if (typeof errorAction === "function") {
        errorAction(error);
    } else if (errorCallout) {
        dispatch({
            type: CalloutCreator.actions.newCallout,
            header: "",
            body: [{ isError: true, response: error.details, message: error.message }],
            severity: "warning",
            acked: true,
            showToast: false,
        });
    }

    dispatch({ type: `${actionPrefix}_FAILURE`, error, id: options.id });
};
