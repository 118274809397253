import { ThunkAction } from "redux-thunk";
import { AppState } from "../creators/reducers";
import { Translation } from "./translate";

export const CHANGE_LANG_ACTION = "CHANGE_LANG";
export const FETCH_LANG_ACTION = "FETCH_LANG";
export const RECEIVE_LANG_ACTION = "RECEIVE_LANG";

export interface LanguageAction {
    type: typeof CHANGE_LANG_ACTION | typeof FETCH_LANG_ACTION | typeof RECEIVE_LANG_ACTION;
    lang: string;
    strings?: Translation;
}

export const createLanguageAction = (lang: string): ThunkAction<void, AppState, never, LanguageAction> => {
    return (dispatch, getState) => {
        const {
            i18n: { strings },
        } = getState();
        if (strings && !strings[lang]) {
            return dispatch(fetchLanguage(lang));
        }
        return dispatch({
            type: CHANGE_LANG_ACTION,
            lang,
        });
    };
};

export const fetchLanguage = (lang: string): ThunkAction<void, AppState, never, LanguageAction> => {
    return (dispatch) => {
        dispatch({
            type: FETCH_LANG_ACTION,
            lang,
        });
        let newStrings;
        switch (lang) {
            case "zh":
                newStrings = import("./languages/zh");
                break;
            case "ja":
                newStrings = import("./languages/ja");
                break;
            case "pseudo":
                newStrings = import("./languages/test/pseudo");
                break;
            default:
                return dispatch({
                    type: CHANGE_LANG_ACTION,
                    lang,
                });
        }
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        if (newStrings) {
            return newStrings.then((module) => {
                dispatch({
                    type: RECEIVE_LANG_ACTION,
                    lang,
                    strings: module.default,
                });
            });
        }
        return Promise.resolve();
    };
};
