import React from "react";
import { connect } from "react-redux";
import { onStateChange, AjaxState } from "../../../../creators/AjaxCreator";
import translate, { DefaultStrings } from "../../../../i18n/translate";
import { getGatewayLogs, GatewayLog, GatewayLogsFilterOption } from "./creator";
import GatewayLogsComponent from "./component";
import { AppState, AppDispatch } from "../../../../creators/reducers";
import { ListResponse } from "../../../../script";
import { ValueEvent } from "portal-components";

const defaultStrings = {
    all: "All levels",
    critical: "Critical",
    debug: "Debug",
    error: "Error",
    info: "Info",
    trace: "Trace",
    warning: "Warning",
};

interface GatewayLogsContainerProps {
    id?: string;
    strings: DefaultStrings<typeof defaultStrings>;
    gatewayLogs: AjaxState<ListResponse<GatewayLog>>;
    dispatch: AppDispatch;
}

interface GatewayLogsContainerState {
    loading: boolean;
    logs: GatewayLog[];
    info: ListResponse<GatewayLog>;
    pageSize: number;
    filterOptions: GatewayLogsFilterOption[];
    filter?: GatewayLogsFilterOption;
}

export class GatewayLogsContainer extends React.PureComponent<GatewayLogsContainerProps, GatewayLogsContainerState> {
    public static defaultProps = {
        strings: defaultStrings,
    };

    constructor(props: GatewayLogsContainerProps) {
        super(props);

        this.handleFilterChanged = this.handleFilterChanged.bind(this);
        this.handleGridLoadMore = this.handleGridLoadMore.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);

        const { strings } = props;

        this.state = {
            loading: true,
            logs: [],
            info: {} as ListResponse<GatewayLog>,
            pageSize: props.id ? 1000 : 50,
            filterOptions: [
                {
                    value: "all",
                    text: strings.all,
                    default: true,
                },
                {
                    value: "critical",
                    text: strings.critical,
                },
                {
                    value: "error",
                    text: strings.error,
                },
                {
                    value: "warning",
                    text: strings.warning,
                },
                {
                    value: "info",
                    text: strings.info,
                },
                {
                    value: "trace",
                    text: strings.trace,
                },
                {
                    value: "debug",
                    text: strings.debug,
                },
            ],
            filter: {} as GatewayLogsFilterOption,
        };
    }

    componentDidMount() {
        this.handleRefresh();
    }

    UNSAFE_componentWillReceiveProps({ gatewayLogs }: GatewayLogsContainerProps) {
        onStateChange(this.props.gatewayLogs, gatewayLogs, {
            loading: () => {
                this.setState({ loading: true });
            },
            loaded: ({ data }) => {
                const logs = data.data || [];
                const info = {
                    has_more: logs.length > 0 && this.state.pageSize <= logs.length,
                    last: logs.length > 0 ? new Date(logs[logs.length - 1]["timestamp"] as number).toISOString() : null,
                    limit: this.state.pageSize,
                } as ListResponse<GatewayLog>;
                this.setState({
                    loading: false,
                    logs: [...this.state.logs, ...logs],
                    info,
                });
            },
            failed: () => {
                this.setState({ loading: false });
            },
        });
    }

    handleGridLoadMore() {
        const { dispatch, id } = this.props;
        const last = this.state.info?.last;
        dispatch(getGatewayLogs({ id, last, pageSize: this.state.pageSize }));
    }

    handleRefresh() {
        const { dispatch, id } = this.props;
        this.setState({ filter: undefined, logs: [], info: {} as ListResponse<GatewayLog> });
        dispatch(getGatewayLogs({ id, pageSize: this.state.pageSize }));
    }

    handleFilterChanged(e: ValueEvent<string>) {
        this.setState({ filter: this.state.filterOptions.find((x) => x.value === e.value) });
    }

    render() {
        const { id } = this.props;
        const { filter, filterOptions, info, loading, logs } = this.state;

        const gridData =
            filter && !filter.default ? logs.filter((log) => log.level?.toLowerCase() === filter.value) : logs;

        return (
            <div id="gateway-logs" className="page-content">
                <GatewayLogsComponent
                    filter={filter}
                    filterOptions={filterOptions}
                    id={id}
                    info={info}
                    loading={loading}
                    logs={gridData}
                    onFilterChanged={this.handleFilterChanged}
                    onGridLoadMore={this.handleGridLoadMore}
                    onRefresh={this.handleRefresh}
                />
            </div>
        );
    }
}

export const mapStateToProps = ({ gatewayLogs }: AppState) => ({
    gatewayLogs,
});

export default connect(mapStateToProps)(translate("GatewayLogsContainer")(GatewayLogsContainer));
