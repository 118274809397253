/// <reference types="webpack-env" />
import React from "react";
import { render, unmountComponentAtNode } from "react-dom";
import App from "../routes/router";

const container = document.getElementById("main") as HTMLElement;
render(<App />, container);

if (module.hot) {
    // Re-render the app on webpack hot module reload
    module.hot.accept("../routes/router", () => {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const App = require("../routes/router").default;
        unmountComponentAtNode(container);
        render(<App />, container);
    });
}
