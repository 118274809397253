import { makeConfigApiCreator } from "../../../../creators";
import { ListResponse } from "../../../../script/types";

const makeAccountCreator = <T>(type: string) =>
    makeConfigApiCreator<T>("v3_base", {
        errorCallout: false,
    })(type);

export interface AccountDetails {
    id: string;
    admin_details: string;
    address_line1: string;
    address_line2: string;
    admin_email?: string;
    aliases: string[];
    billing_details: string;
    city: string;
    company: string;
    contact: string;
    contract_number: string;
    country: string;
    customer_number: string;
    display_name: string;
    email: string;
    end_market: string;
    idle_timeout: string;
    mfa_status: string;
    phone_number: string;
    postal_code: string;
    status: string;
    state: string;
    password_policy: { minimum_length: string };
}

const { reducer: accountReducer, createThunk: accountCreateThunk } = makeAccountCreator<AccountDetails>(
    "iam_tenant_account"
);

const getAccount = (id: string) =>
    accountCreateThunk({
        param: `accounts/${id}`,
        queryParameters: { include: "limits,policies,sub_accounts" },
    });

export interface Agreement {
    id: string;
    accepted_at: string | null;
    title: string;
}

const { reducer: accountAgreementsReducer, createThunk: agreementCreateThunk } = makeAccountCreator<
    ListResponse<Agreement>
>("iam_tenant_agreements");

const getAccountAgreements = (id: string) =>
    agreementCreateThunk({
        param: `accounts/${id}/agreements?required__eq=true`,
    });

export { getAccount, accountReducer, getAccountAgreements, accountAgreementsReducer };
