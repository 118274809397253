import { Span, SpanCopyMode } from "portal-components";
import React from "react";
import { CellContext } from "../../../controls/grid/cellContext";
import translate, { DefaultStrings } from "../../../i18n/translate";
import { DeviceEvent } from "../detail/events/creator";

export const defaultStrings = {
    DD4_100: "Device added to Device Management",
    DD4_101: "Device bootstrapped",
    SYS_100: "Device could not be reached",
    SYS_101: "Device not registered",
    SYS_102: "Device Vendor ID does not match Manifest Vendor ID",
    SYS_103: "Device was not updated because the campaign was manually stopped",
    SYS_104: "Campaign was manually stopped. Device may still update",
    SYS_105: "Device deregistered",
    SYS_106: "Device registered",
    SYS_107: "Device bootstrapped",
    SYS_108: "Device added to campaign ",
    SYS_109: "Device removed from campaign ",
    SYS_110: "Device already at desired state",
    SYS_111: "Device failed to reach desired state",
    SYS_112: "Device reached desired state",
    SYS_113: "Device suspended by operator",
    SYS_114: "Device returned to service by operator",
    SYS_115: "Device suspended by system",
    SYS_116: "Device returned to service by system",
    SYS_117: "Update skipped because device is suspended.",
    SYS_118: "Device already at a later firmware version than specified in campaign",
    SYS_120: "Device registered and has been assigned to an active campaign",
    SYS_121: "Device registered and exists in another campaign",
    SYS_122: "Device already at a later firmware version than specified in campaign",
    SYS_123: "Failed to reserve additional billing quota for campaign",
    SYS_124: "Device sent unknown update result",
    SYS_125: "Device received manifest",
    SYS_126: "Device in mesh campaign is not node behind border router",
    SYS_127: "No border router found with net-id of node",
    SYS_128: "Node update aborted - border router threshold not met",
    SYS_129: "Node failed to complete session before timeout",
    SYS_130: "Device does not support this manifest schema",
    UPD1_FAIL_2: "Insufficient storage",
    UPD1_FAIL_3: "Insufficient memory",
    UPD1_FAIL_4: "Connection lost",
    UPD1_FAIL_5: "Integrity check failed",
    UPD1_FAIL_6: "Package type unsupported",
    UPD1_FAIL_7: "Download URI invalid",
    UPD1_FAIL_8: "Update failed",
    UPD1_FAIL_9: "Unsupported protocol",
    UPD1_OK_1: "Firmware successfully updated",
    UPD1_REPORT_HASH: "Firmware hash reported",
    UPD1_REPORT_VERSION: "Manifest version reported",
    UPD1_STATE_0: "Device idle",
    UPD1_STATE_1: "Downloading payload",
    UPD1_STATE_2: "Download complete",
    UPD1_STATE_3: "Installing payload",
    UPD2_OK_1: "Firmware successfully updated",
    UPD2_FAIL_2: "Manifest download timed out",
    UPD2_FAIL_3: "Manifest rejected, URI invalid",
    UPD2_FAIL_4: "Manifest rejected, integrity check failed",
    UPD2_FAIL_5: "Manifest rejected, attributes don't apply to this device",
    UPD2_FAIL_6: "Manifest rejected, certificate not found",
    UPD2_FAIL_7: "Manifest rejected, signature verification failed",
    UPD2_FAIL_8: "Manifest rejected, dependant manifest not found",
    UPD2_FAIL_9: "Manifest rejected, not enough flash",
    UPD2_FAIL_10: "Manifest rejected, not enough RAM",
    UPD2_FAIL_11: "Connection lost during download",
    UPD2_FAIL_12: "Asset failed integrity check",
    UPD2_FAIL_13: "Unsupported asset type",
    UPD2_FAIL_14: "Invalid asset URI",
    UPD2_FAIL_15: "Timed out downloading asset",
    UPD2_FAIL_16: "Unsupported delta format",
    UPD2_FAIL_17: "Unsupported encryption format",
    UPD2_OK_18: "Asset update successfully completed",
    UPD2_OK_19: "Asset updated after recovery",
    UPD2_FAIL_100: "Device could not be reached",
    UPD2_FAIL_101: "Device not registered",
    UPD2_FAIL_102: "Device Vendor ID does not match Manifest Vendor ID",
    UPD2_FAIL_103: "Device was not updated because the campaign was manually stopped",
    UPD2_FAIL_104: "Device at higher version",
    UPD2_FAIL_105: "Device at current version",
    UPD2_REPORT_HASH: "Asset hash reported",
    UPD2_REPORT_VERSION: "Manifest version reported",
    UPD2_STATE_0: "Uninitialised",
    UPD2_STATE_1: "Idle",
    UPD2_STATE_2: "Processing manifest",
    UPD2_STATE_3: "Awaiting download approval",
    UPD2_STATE_4: "Downloading",
    UPD2_STATE_5: "Downloaded",
    UPD2_STATE_6: "Awaiting installation approval",
    UPD2_STATE_7: "Installing asset",
    UPD2_STATE_8: "Rebooting",
    UPD4_400: "Device added to campaign",
    UPD4_401: "Device removed from campaign",
    UPD4_402: "Device is at desired state",
    UPD4_FAIL_21: "FW payload URI in manifest is too long",
    UPD4_FAIL_22: "Failure to parse an update manifest",
    UPD4_FAIL_23: "Signature verification failed",
    UPD4_FAIL_24: "Connection lost during download",
    UPD4_FAIL_25: "Payload format specified by manifest is unsupported",
    UPD4_FAIL_26: "Payload authenticity check failed",
    UPD4_FAIL_27: "FW candidate version is rejected (is older or equals to installed one)",
    UPD4_FAIL_28: "Manifest schema version unsupported (incompatible manifest-tool version)",
    UPD4_FAIL_29: "Vendor data specified in a manifest is too big.",
    UPD4_FAIL_30: "manifest with wrong Vendor id",
    UPD4_FAIL_31: "manifest with wrong Class id",
    UPD4_FAIL_32: "Installed FW digest differs from the one specified in manifest (precursor)",
    UPD4_FAIL_33: "Insufficient storage on a device for saving update candidate",
    UPD4_FAIL_34: "Not enough RAM",
    UPD4_FAIL_35: "Storage write error",
    UPD4_FAIL_36: "Storage read error",
    UPD4_FAIL_37: "Application rejected install authorization request",
    UPD4_FAIL_38: "Application rejected download authorization request",
    UPD4_FAIL_39: "Component name in manifest targets unknown component",
    UPD4_FAIL_40: "Update failed at installation phase",
    UPD4_FAIL_41: "Non-specific internal error",
    UPD4_FAIL_42: "Non-specific internal error - delta engine",
    UPD4_FAIL_43: "Non-specific internal error - crypto engine",
    UPD4_FAIL_44: "Expected asset is not found in NVM",
    UPD4_FAIL_45: "Multicast abort session",
    UPD4_FAIL_46: "Failure to parse a combined package",
    UPD4_FAIL_47: "Combined package with wrong number of images",
    UPD4_FAIL_48: "Combined image name specified in a package is too long",
    UPD4_FAIL_49: "Vendor data size specified in a package descriptor is too long",
    UPD4_OK_100: "Update succeeded event",
    UPD4_FAIL_101: "Update error, nonspecific network error",
    UPD4_FAIL_102: "Update error, nonspecific payload error",
    UPD4_FAIL_103: "Update error, nonspecific system error",
    UPD4_FAIL_200: "Manifest validation, nonspecific error",
    UPD4_FAIL_201: "Manifest network error, nonspecific network error",
    UPD4_FAIL_202: "Manifest network error, timeout",
    UPD4_FAIL_203: "Manifest network error, connect failed",
    UPD4_FAIL_204: "Manifest network error, DNS lookup failed",
    UPD4_FAIL_205: "Manifest network error, connection loss",
    UPD4_FAIL_206: "Manifest rejected, manifest not found",
    UPD4_FAIL_207: "Manifest rejected, integrity check failed (hash error)",
    UPD4_FAIL_208: "Manifest rejected, certificate not found",
    UPD4_FAIL_209: "Manifest rejected, invalid certificate",
    UPD4_FAIL_210: "Manifest rejected, signature invalid",
    UPD4_FAIL_211: "Manifest rejected, dependent manifest not found",
    UPD4_FAIL_212: "Manifest rejected, device already processing manifest",
    UPD4_FAIL_213: "Manifest rejected, resource already present",
    UPD4_FAIL_214: "Manifest rejected, unsupported manifest version",
    UPD4_FAIL_215: "Manifest rejected, wrong vendor ID",
    UPD4_FAIL_216: "Manifest rejected, wrong class ID",
    UPD4_FAIL_217: "Manifest rejected, invalid manifest size",
    UPD4_FAIL_218: "Manifest rejected, invalid manifest format (DER format)",
    UPD4_FAIL_219: "Manifest rejected, invalid crypto mode",
    UPD4_FAIL_220: "Manifest rejected, missing field",
    UPD4_FAIL_221: "Manifest rejected, rollback protection",
    UPD4_FAIL_222: "Manifest rejected, missing keytable",
    UPD4_FAIL_223: "Manifest rejected, corrupted keytable",
    UPD4_FAIL_224: "Manifest rejected, invalid keytable (wrong encoding of key table)",
    UPD4_FAIL_225: "Manifest processing error, insufficient storage",
    UPD4_FAIL_226: "Manifest processing error, insufficient memory",
    UPD4_FAIL_227: "Manifest processing error, write error",
    UPD4_FAIL_300: "Resource fetching, nonspecific error",
    UPD4_FAIL_301: "Resource network error, nonspecific network error",
    UPD4_FAIL_302: "Resource network error, no route available",
    UPD4_FAIL_303: "Resource network error, time out",
    UPD4_FAIL_304: "Resource network error, connect failed",
    UPD4_FAIL_305: "Resource network error, DNS lookup failed",
    UPD4_FAIL_306: "Resource network error, connection loss",
    UPD4_FAIL_307: "Resource fetching, resource URI invalid",
    UPD4_FAIL_308: "Resource fetching, request type invalid",
    UPD4_FAIL_309: "Resource fetching, resource integrity check failed",
    UPD4_FAIL_310: "Resource fetching, user-defined error 1",
    UPD4_FAIL_311: "Resource fetching, user-defined error 2",
    UPD4_FAIL_312: "Resource fetching, user-defined error 3",
    UPD4_FAIL_313: "Resource fetching, user-defined error 4",
    UPD4_FAIL_314: "Resource fetching, user-defined error 5",
    UPD4_FAIL_315: "Resource fetching permission denied by device",
    UPD4_FAIL_316: "Resource fetching permission could not be provided by device",
    UPD4_FAIL_400: "Payload processing, nonspecific error",
    UPD4_FAIL_401: "Payload processing, user-defined error 1",
    UPD4_FAIL_402: "Payload processing, user-defined error 2",
    UPD4_FAIL_403: "Payload processing, user-defined error 3",
    UPD4_FAIL_404: "Payload processing, user-defined error 4",
    UPD4_FAIL_405: "Payload processing, user-defined error 5",
    UPD4_FAIL_406: "Processed result does not match expected hash",
    UPD4_FAIL_407: "Payload processing parse error",
    UPD4_FAIL_408: "Manifest rejected, insufficient storage space for processing",
    UPD4_FAIL_409: "Manifest rejected, insufficient memory space for processing",
    UPD4_FAIL_500: "Payload writing error, nonspecific error",
    UPD4_FAIL_501: "Payload writing error, insufficient storage",
    UPD4_FAIL_502: "Payload writing error, insufficient memory",
    UPD4_FAIL_503: "Payload writing error, write error",
    UPD4_FAIL_504: "Payload writing error, hash error",
    UPD4_FAIL_505: "Payload writing error, activate failed",
    UPD4_FAIL_506: "Payload writing error, user-defined error 1",
    UPD4_FAIL_507: "Payload writing error, user-defined error 2",
    UPD4_FAIL_508: "Payload writing error, user-defined error 3",
    UPD4_FAIL_509: "Payload writing error, user-defined error 4",
    UPD4_FAIL_510: "Payload writing error, user-defined error 5",
    UPD4_FAIL_511: "Payload installation permission denied by device",
    UPD4_FAIL_512: "Payload installation permission could not be provided by device",
    UPD4_OK_M1: "Update initial value",
    UPD4_STATE_10: "Processing asset - REMOVE",
    UPD4_VERSION: "New reported manifest hash",
};

interface EventMessageComponentProps {
    strings: DefaultStrings<typeof defaultStrings>;
    truncated?: boolean;
    event: DeviceEvent;
    withId?: boolean;
}

export const EventMessage: React.FunctionComponent<EventMessageComponentProps> = (props) => {
    const { event: { event_type = "", description = "", count = 0 } = {}, strings, truncated } = props;
    let message = strings[event_type] || description || event_type;

    if (props.withId && message !== event_type) {
        message = `${message} (${event_type})`;
    }

    return (
        <CellContext.Consumer>
            {(context) => {
                if (context && context.key && context.row) {
                    context.row[context.key] = message;
                }

                if (truncated) {
                    return <Span text={message} tooltip={`${message}: ${count}`} copiable={SpanCopyMode.None} />;
                }

                return message;
            }}
        </CellContext.Consumer>
    );
};

// Event messages defined in https://github.com/ArmMbedCloud/update-service/tree/master/update-service-container/mccp/profiles
EventMessage.defaultProps = {
    strings: defaultStrings,
};

export default translate("EventMessage")(EventMessage);
