import { makeCollectionCreator, makeConfigApiCreator } from "../../../creators";
import { ParseFunction } from "../../../creators/AjaxCreator";
import history from "../../../script/history";
import { ListResponse } from "../../../script/types";
import { DeviceGroup } from "./create/creator";

const makeDeviceGroupsCreator = <T>(type: string, parseFunction?: ParseFunction<T>) =>
    makeConfigApiCreator<T>("v3_base", {
        errorRedirect: () => history.push("/devices/list"),
        errorCallout: false,
    })(type, parseFunction);

const { createThunk: createDeviceGroupsThunk, reducer: deviceGroupsReducer } = makeCollectionCreator(
    makeDeviceGroupsCreator<ListResponse<DeviceGroup>>("device_groups")
);

const getDeviceGroups = ({
    id,
    pageSize = 1000,
    last,
    order = "DESC",
    query,
    errorAction,
    errorCallout = true,
}: {
    id?: string;
    pageSize?: number;
    last?: string;
    order?: string;
    query?: string;
    errorAction?: () => void;
    errorCallout?: boolean;
}) => {
    const queryParameters = {
        order,
        limit: pageSize?.toString(),
        include: "total_count",
        after: last,
        filter: query,
    };

    return createDeviceGroupsThunk({
        id,
        param: "device-groups",
        queryParameters,
        errorAction,
        errorCallout,
    });
};

const { createThunk: createDeleteDeviceGroupsThunk, reducer: deleteGroupReducer } = makeCollectionCreator(
    makeDeviceGroupsCreator<void>("delete_device_group")
);
const deleteDeviceGroup = (id: string, groupId: string) =>
    createDeleteDeviceGroupsThunk({
        id,
        param: `device-groups/${groupId}/`,
        method: "DELETE",
    });

export { getDeviceGroups, deviceGroupsReducer, deleteDeviceGroup, deleteGroupReducer };
