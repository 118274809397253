import { Action } from "redux";
import { Response } from "superagent";
import { Idp, LoginCredentials, LoginResponse } from "./api";

// Errors that do not result from user input
export enum LoadError {
    LOGOUT_FAILED = "LOGOUT_FAILED",
    LOAD_ACCOUNT_FAILED = "LOAD_ACCOUNT_FAILED",
    LOGIN_FAILED = "LOGIN_FAILED",
}

// Errors occurring after the user enters their email
export enum LoadIdpError {
    LOAD_IDP_FAILED = "LOAD_IDP_FAILED",
    UNKNOWN_IDP_TYPE = "UNKNOWN_IDP_TYPE",
    NOT_FOUND = "NOT_FOUND",
}

// Errors resulting from user input on the login form
export enum LoginError {
    LOGIN_FAILED = "LOGIN_FAILED",
    OTP_INVALID = "OTP_INVALID",
    CAPTCHA_INVALID = "CAPTCHA_INVALID",
}

export type EnterCredentialsStep = "MFA" | "LOGIN";

interface EnterCredentialsState {
    step: EnterCredentialsStep;
    error?: LoginError;
    isCaptcha: boolean;
    submitting: boolean;
    credentials: LoginCredentials | undefined;
}

/**
 * Redux state type for the teamLogin key.
 */
export type LoginTeamState =
    | { state: "INITIAL" }
    | { state: "LOADING"; team: string; next: string }
    | {
          state: "LOAD_ERROR";
          team: string;
          error: LoadError;
          response?: Response;
      }
    | { state: "CONFIRM_SWITCH_TEAM"; team: string; next: string }
    | {
          state: "ENTER_EMAIL";
          team: string;
          next: string;
          submitting: boolean;
          email?: string;
          error?: LoadIdpError;
          response?: Response;
          idps?: Idp[];
      }
    | {
          state: "SELECT_IDP";
          team: string;
          email: string;
          next: string;
          idps: Idp[];
      }
    | ({
          state: "ENTER_CREDENTIALS";
          team: string;
          email: string;
      } & EnterCredentialsState)
    | { state: "SUSPENDED"; reason?: string }
    | { state: "INACTIVE" }
    | { state: "EXPIRED" }
    | { state: "SUCCESS" };

/**
 * Redux actions applicable to the teamLogin key.
 */
export type LoginTeamAction =
    | { type: "TEAM_LOGIN_RESET" }
    | { type: "TEAM_LOGIN_LOADING_START"; team: string; next: string }
    | {
          type: "TEAM_LOGIN_LOADING_ERROR";
          error: LoadError;
          response?: Response;
      }
    | { type: "TEAM_LOGIN_REQUEST_CONFIRM" }
    | { type: "TEAM_LOGIN_REQUEST_EMAIL" }
    | {
          type: "TEAM_LOGIN_LOAD_IDP_ERROR";
          error: LoadIdpError;
          response?: Response;
      }
    | { type: "TEAM_LOGIN_SUBMIT_EMAIL"; email: string }
    | { type: "TEAM_LOGIN_LIST_ALL_IDPS"; idps: Idp[] }
    | {
          type: "TEAM_LOGIN_REQUEST_CREDENTIALS";
          step: EnterCredentialsStep;
          isCaptcha: boolean;
          credentials?: LoginCredentials;
          error?: LoginError;
      }
    | { type: "TEAM_LOGIN_SELECT_IDP"; idps: Idp[] }
    | { type: "TEAM_LOGIN_SUBMIT_LOGIN"; credentials: LoginCredentials }
    | { type: "TEAM_LOGIN_SUSPENDED"; reason?: string }
    | { type: "TEAM_LOGIN_INACTIVE" }
    | { type: "TEAM_LOGIN_EXPIRED" }
    | { type: "TEAM_LOGIN_SUCCESS"; info?: LoginResponse };

// Required since we don't yet have a type for all actions in the app.
// Assume that all actions starting with TEAM_LOGIN_ are a LoginTeamAction.
const isTeamLoginAction = (action: Action): action is LoginTeamAction => {
    return action.type.startsWith("TEAM_LOGIN_");
};

const reduceTeamLoginAction = (state: LoginTeamState, action: LoginTeamAction): LoginTeamState => {
    const { team: currentTeam = "", next: currentNext = "", email: currentEmail = "" } = state as {
        [key: string]: string;
    };

    switch (action.type) {
        case "TEAM_LOGIN_RESET":
            return { state: "INITIAL" };
        case "TEAM_LOGIN_LOADING_START":
            // Waiting to see if the current token is valid, or waiting for an Idp for a team
            return { state: "LOADING", team: action.team, next: action.next };
        case "TEAM_LOGIN_REQUEST_EMAIL":
            return {
                state: "ENTER_EMAIL",
                team: currentTeam,
                next: currentNext,
                submitting: false,
                email: currentEmail,
            };
        case "TEAM_LOGIN_LOAD_IDP_ERROR":
            return {
                state: "ENTER_EMAIL",
                team: currentTeam,
                next: currentNext,
                submitting: false,
                email: currentEmail,
                error: action.error,
                response: action.response,
            };
        case "TEAM_LOGIN_LIST_ALL_IDPS":
            return {
                state: "ENTER_EMAIL",
                team: currentTeam,
                next: currentNext,
                submitting: false,
                email: currentEmail,
                idps: action.idps,
            };
        case "TEAM_LOGIN_SUBMIT_EMAIL":
            return {
                ...(state as Pick<LoginTeamState, "state">),
                state: "ENTER_EMAIL",
                team: currentTeam,
                next: currentNext,
                submitting: true,
                email: action.email,
            };
        case "TEAM_LOGIN_SELECT_IDP":
            return {
                state: "SELECT_IDP",
                team: currentTeam,
                next: currentNext,
                idps: action.idps,
                email: currentEmail,
            };
        case "TEAM_LOGIN_LOADING_ERROR":
            // Failed to load IdP for the team, or got a non-401 error testing the current token
            return {
                state: "LOAD_ERROR",
                team: currentTeam,
                error: action.error,
                response: action.response,
            };
        case "TEAM_LOGIN_REQUEST_CONFIRM":
            // Need confirmation from the user before switching team
            return {
                state: "CONFIRM_SWITCH_TEAM",
                team: currentTeam,
                next: currentNext,
            };
        case "TEAM_LOGIN_REQUEST_CREDENTIALS":
            // The user must submit credentials, or the credentials they entered before were invalid
            return {
                state: "ENTER_CREDENTIALS",
                team: currentTeam,
                email: currentEmail,
                step: action.step,
                error: action.error,
                isCaptcha: action.isCaptcha,
                credentials: action.credentials
                    ? action.credentials
                    : state.state === "ENTER_CREDENTIALS"
                    ? state.credentials
                    : {},
                submitting: false,
            };
        case "TEAM_LOGIN_SUBMIT_LOGIN":
            // Submitting user credentials
            return {
                state: "ENTER_CREDENTIALS",
                team: currentTeam,
                email: currentEmail,
                step: state.state === "ENTER_CREDENTIALS" ? state.step : "LOGIN",
                isCaptcha: state.state === "ENTER_CREDENTIALS" ? state.isCaptcha : false,
                credentials: action.credentials,
                submitting: true,
            };
        case "TEAM_LOGIN_SUSPENDED":
            return { state: "SUSPENDED", reason: action.reason };
        case "TEAM_LOGIN_INACTIVE":
            return { state: "INACTIVE" };
        case "TEAM_LOGIN_EXPIRED":
            return { state: "EXPIRED" };
        case "TEAM_LOGIN_SUCCESS":
            // Login success; the token for the team is stored in a cookie
            return { state: "SUCCESS" };
        default:
            // Exhaustiveness check
            return state;
    }
};

export const teamLoginReducer = (state: LoginTeamState = { state: "INITIAL" }, action: Action): LoginTeamState => {
    return isTeamLoginAction(action) ? reduceTeamLoginAction(state, action) : state;
};
