import {
    TrustedCertificateCreateRequest,
    TrustedCertificateRepository,
    TrustedCertificateUpdateRequest,
} from "mbed-cloud-sdk";
import { sdkCreator } from "../../../../creators/SdkCreator";

const {
    reducer: createCertificateReducer,
    createThunk: createCertificateThunk,
    createResetAction: createCertificateResetThunk,
} = sdkCreator("CREATE_CERTIFICATE", {
    sdkMethod: (request: TrustedCertificateCreateRequest, sdkConfig) =>
        new TrustedCertificateRepository(sdkConfig).create(request),
});
const createCertificate = (request: TrustedCertificateCreateRequest) => createCertificateThunk(request);
const createCertificateReset = createCertificateResetThunk();

const {
    reducer: updateCertificateReducer,
    createThunk: updateCertificateThunk,
    createResetAction: updateCertificateResetThunk,
} = sdkCreator("UPDATE_CERTIFICATE", {
    sdkMethod: (request: { id: string; data: TrustedCertificateUpdateRequest }, sdkConfig) =>
        new TrustedCertificateRepository(sdkConfig).update(request.data, request.id),
});
const updateCertificate = (id: string, data: TrustedCertificateUpdateRequest) => updateCertificateThunk({ id, data });
const updateCertificateReset = updateCertificateResetThunk();

export {
    createCertificate,
    createCertificateReducer,
    createCertificateReset,
    updateCertificate,
    updateCertificateReducer,
    updateCertificateReset,
};
