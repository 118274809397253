import request, { ProgressEvent, Response } from "superagent";
import { Config } from "../typings/interfaces";
import { PubSubConsoleAPI } from "../features/console/creator";
import PubSub from "./pubSub";
import { getNextUniqueId } from "./utility";

export type HttpMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export interface AjaxOptions {
    url: string;
    progress?: (event: ProgressEvent) => void;
    headers?: { [param: string]: string };
    method?: HttpMethod;
    data?: string | object;
    queryParameters?: { [param: string]: string | undefined };
    callback?: string;
    cookies?: boolean;
    type?: string | null;
    responseType?: string;
    action?: string;
}

/**
 * Make an HTTP request, returning a Promise.
 */
export const ajax = ({
    url,
    progress,
    headers = {},
    method = "GET",
    data = {},
    queryParameters = {},
    cookies = false,
    type = "application/json",
    responseType,
}: AjaxOptions): Promise<Response> => {
    // Log for developer console
    const consoleId = getNextUniqueId();
    PubSub.send(PubSubConsoleAPI.Request, { consoleId, url, method, data, queryParameters });

    return new Promise((resolve, reject) => {
        const req = request(method, url).query(queryParameters).send(data).set(headers);

        if (type) {
            req.type(type);
        }

        if (responseType) {
            req.responseType(responseType);
        }

        if (cookies) {
            req.withCredentials();
        }

        if (typeof progress === "function") {
            req.on("progress", progress);
        }

        req.end((error, response) => {
            if (error) {
                reject(error);
                PubSub.send(PubSubConsoleAPI.Error, { consoleId, response: error });
            } else {
                resolve(response);
                PubSub.send(PubSubConsoleAPI.Response, { consoleId, response });
            }
        });
    });
};

export type ConfigAjaxOptions = Pick<AjaxOptions, Exclude<keyof AjaxOptions, "url">> & {
    getState: () => { config: Config };
    param: string;
};

/**
 * Make an HTTP request. Looks up a base URL from the config, then makes an HTTP request.
 * Redux getState must be passed in options.
 */
export const configAjax = (baseKey: keyof Config["api"]) => (options: ConfigAjaxOptions) => {
    const { config } = options.getState();
    const url = `${config.api[baseKey]}${options.param}`;
    return ajax({ url: url, ...options });
};
