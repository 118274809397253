import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import NotFound from "../layout/404";
import ErrorBoundary from "../layout/errorBoundary";
import { importLazy, LazilyLoadFactory, updateAccess } from "../layout/lazilyload";
import { PropagatedProps } from "../layout/main/component";
import { asyncRender } from "./BaseRoute";

export interface FirmwareRouteProps extends RouteComponentProps {
    Deployment: React.ComponentType<PropagatedProps>;
    DeploymentCreation: React.ComponentType<PropagatedProps>;
    DeploymentDetail: React.ComponentType<PropagatedProps>;
    FirmwareHome: React.ComponentType<PropagatedProps>;
    FirmwareImages: React.ComponentType<PropagatedProps>;
    FirmwareImageDetails: React.ComponentType<PropagatedProps>;
    FirmwareManifests: React.ComponentType<PropagatedProps>;
    FirmwareManifestDetails: React.ComponentType<PropagatedProps>;
    NewFirmwareImage: React.ComponentType<PropagatedProps>;
    NewFirmwareManifest: React.ComponentType<PropagatedProps>;
    componentProps: PropagatedProps;
}

export const FirmwareRoute: React.FunctionComponent<FirmwareRouteProps> = (props) => {
    const {
        Deployment,
        DeploymentCreation,
        DeploymentDetail,
        FirmwareHome,
        FirmwareImages,
        FirmwareImageDetails,
        FirmwareManifests,
        FirmwareManifestDetails,
        NewFirmwareImage,
        NewFirmwareManifest,
        componentProps,
    } = props;
    const asyncRenderWithProps = asyncRender(componentProps);
    return (
        <ErrorBoundary>
            <Switch>
                <Route exact path="/firmware" render={asyncRenderWithProps(FirmwareHome, "firmware")} />
                <Route path="/firmware/images/list" render={asyncRenderWithProps(FirmwareImages, "images")} />
                <Route
                    exact
                    path="/firmware/images/new"
                    render={asyncRenderWithProps(NewFirmwareImage, "uploadImage")}
                />
                <Route path="/firmware/images/:id" render={asyncRenderWithProps(FirmwareImageDetails, "imageDetail")} />
                <Route
                    exact
                    path="/firmware/manifests/new"
                    render={asyncRenderWithProps(NewFirmwareManifest, "uploadManifest")}
                />
                <Route path="/firmware/manifests/list" render={asyncRenderWithProps(FirmwareManifests, "manifests")} />
                <Route
                    path="/firmware/manifests/:id"
                    render={asyncRenderWithProps(FirmwareManifestDetails, "manifestDetails")}
                />
                <Route path="/firmware/deployments/list" render={asyncRenderWithProps(Deployment, "deployments")} />
                <Route
                    path="/firmware/deployments/list/:id"
                    render={asyncRenderWithProps(Deployment, "deploymentsDetail")}
                />
                <Route
                    path="/firmware/deployments/new"
                    render={asyncRenderWithProps(DeploymentCreation, "createDeployment")}
                />
                <Route
                    path="/firmware/deployments/wizard"
                    render={asyncRenderWithProps(DeploymentCreation, "createDeployment", { wizard: true })}
                />
                <Route
                    path="/firmware/deployments/:id/edit"
                    render={asyncRenderWithProps(DeploymentCreation, "editDeployment")}
                />
                <Route
                    path="/firmware/deployments/:id"
                    render={asyncRenderWithProps(DeploymentDetail, "deploymentDetail")}
                />
                <Redirect from="/firmware/deployments" to="/firmware/deployments/list" />
                <Redirect from="/firmware/manifests" to="/firmware/manifests/list" />
                <Redirect from="/firmware/images" to="/firmware/images/list" />
                <Route component={NotFound} />
            </Switch>
        </ErrorBoundary>
    );
};

export default LazilyLoadFactory(withRouter(FirmwareRoute), {
    Deployment: () => importLazy(import("../features/firmware/campaigns/container"), updateAccess),
    DeploymentCreation: () => importLazy(import("../features/firmware/campaigns/create/container"), updateAccess),
    DeploymentDetail: () => importLazy(import("../features/firmware/campaigns/detail/container"), updateAccess),
    FirmwareHome: () => importLazy(import("../features/firmware/container")),
    FirmwareImages: () => importLazy(import("../features/firmware/images/container"), updateAccess),
    FirmwareImageDetails: () => importLazy(import("../features/firmware/images/detail/container"), updateAccess),
    FirmwareManifests: () => importLazy(import("../features/firmware/manifests/container"), updateAccess),
    FirmwareManifestDetails: () => importLazy(import("../features/firmware/manifests/detail/container"), updateAccess),
    NewFirmwareImage: () => importLazy(import("../features/firmware/images/create/container"), updateAccess),
    NewFirmwareManifest: () => importLazy(import("../features/firmware/manifests/create/container"), updateAccess),
});
