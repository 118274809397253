import { Icon, Icons, IconSize } from "portal-components";
import React from "react";
import Markup from "../../controls/markup";
import translate, { DefaultStrings } from "../../i18n/translate";
import BaseLoginComponent from "./loginComponent";

const defaultStrings = {
    accountSuspended: "This account has been suspended and you have been logged out.",
    suspendedContactUs: "Please contact us at [{0}](mailto:{0}) for help with resolving this issue",
    suspendedReason: "The reason for the suspension is:",
    suspendedUser: "Your user account is currently suspended.",
};

export interface UserSuspendedComponentProps {
    strings: DefaultStrings<typeof defaultStrings>;
    supportEmailAddress: string;
    suspendedMessage: string;
}

export const UserSuspendedComponent: React.FunctionComponent<UserSuspendedComponentProps> = (props) => {
    const { supportEmailAddress, strings, suspendedMessage } = props;

    return (
        <BaseLoginComponent>
            <div className="grid-x">
                <div className="cell small-2">
                    <Icon name={Icons.WarningSymbol} size={IconSize.Larger} className="alert-text" />
                    &nbsp;
                </div>
                <div className="cell small-10">
                    <p>{strings.accountSuspended}</p>
                </div>
            </div>
            <p>
                <strong>{strings.suspendedReason}</strong>
            </p>
            <p>{suspendedMessage || strings.suspendedUser}</p>
            <p>
                <Markup string={strings.suspendedContactUs.format(supportEmailAddress)} />
            </p>
        </BaseLoginComponent>
    );
};

UserSuspendedComponent.defaultProps = {
    supportEmailAddress: "support@izumanetworks.com",
    strings: defaultStrings,
};

export default translate("UserSuspendedComponent")(UserSuspendedComponent);
