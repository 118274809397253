import { makeConfigApiCreator } from "../../../../creators/AjaxApiCreator";
import { ListResponse } from "../../../../script/types";

interface GetDeviceEventsParameters {
    deviceId?: string;
    pageSize?: number;
    last?: string;
    order?: string;
    query?: string;
}

interface DeviceEventDetailFilter {
    index: number;
    type: string;
}

interface DeviceEvent {
    changes?: { [key: string]: string };
    created_at?: string;
    count?: number;
    date_time?: string;
    data?: { campaign_id: string; campaign_name: string };
    id?: string;
    description?: string;
    device_id?: string;
    event_type?: string;
    event_type_category?: string;
    event_type_description?: string;
    state_change?: boolean;
}

const { createThunk: createLogsThunk, reducer: deviceEventsForDeviceReducer } = makeConfigApiCreator<
    ListResponse<DeviceEvent>
>("v3_base", {
    errorRedirect: false,
})("device_log_detail", (untypedResponse) => {
    const response = untypedResponse as ListResponse<DeviceEvent>;
    response.data.map((item) => {
        return Object.assign(item, { selected: false });
    });

    if (response.data && response.data.length > 1) {
        response.last = response.data[response.data.length - 1].id;
    }

    return response;
});

const getDeviceEventsForDevice = ({
    deviceId,
    pageSize = 1000,
    order = "DESC",
    query,
    last,
}: GetDeviceEventsParameters) => {
    const queryParameters: { [key: string]: string } = {
        limit: pageSize?.toString(),
        order,
        include: "total_count",
        filter: [`device_id__eq=${deviceId}`, query].filter((f) => !!f).join("&"),
    };

    if (last) {
        queryParameters.after = last;
    }

    return createLogsThunk({
        param: "device-events",
        queryParameters,
    });
};

export {
    GetDeviceEventsParameters,
    DeviceEvent,
    getDeviceEventsForDevice,
    deviceEventsForDeviceReducer,
    DeviceEventDetailFilter,
};
