export { reducer as updateCreate, cloneReducer as updateClone } from "./create/creator";
export {
    deleteReducer as updateDelete,
    getAction as getUpdateList,
    reducer as updateList,
    meshReducer as updateMesh,
} from "./creator";
export {
    archiveReducer as updateArchive,
    failedUpdatesStatisticsReducer as updateFailedStatistics,
    infoUpdatesStatisticsReducer as updateInfoStatistics,
    metadataReducer as updateMetaDataDetail,
    metricReducer as updateMetrics,
    reducer as updateDetail,
    skippedUpdatesStatisticsReducer as updateSkippedStatistics,
    startReducer as updateStart,
    stopReducer as updateStop,
} from "./detail/creator";
import DeviceList from "./create/deviceList";
export { DeviceList };
