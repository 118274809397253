// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1Xhfenclb4mCpJ3JCvyiHV>section.master header.header{height:10px}._1Xhfenclb4mCpJ3JCvyiHV>section.master>.master-content>.tabs{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;height:100%}._1Xhfenclb4mCpJ3JCvyiHV>section.master>.master-content>.tabs>.tab-panels{margin-top:1em;overflow:auto;-webkit-flex:1;flex:1}\n", "",{"version":3,"sources":["console.scss"],"names":[],"mappings":"AAAA,sDAAuD,WAAW,CAAC,8DAA+D,oBAAY,CAAZ,YAAY,CAAC,6BAAqB,CAArB,qBAAqB,CAAC,WAAW,CAAC,0EAA2E,cAAc,CAAC,aAAa,CAAC,cAAK,CAAL,MAAM","file":"console.scss","sourcesContent":[":local(.developerConsole)>section.master header.header{height:10px}:local(.developerConsole)>section.master>.master-content>.tabs{display:flex;flex-direction:column;height:100%}:local(.developerConsole)>section.master>.master-content>.tabs>.tab-panels{margin-top:1em;overflow:auto;flex:1}\n"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"developerConsole": "_1Xhfenclb4mCpJ3JCvyiHV"
};
export default ___CSS_LOADER_EXPORT___;
