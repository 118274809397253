import { Icon, Icons, Page, Unless } from "portal-components";
import React, { Fragment, ReactNode } from "react";
import { Link } from "react-router-dom";
import Markup from "../controls/markup";
import translate, { DefaultStrings } from "../i18n/translate";

const defaultStrings = {
    header: "Page or resource not found",
    line1: "**Uh oh!** It looks like the internet broke.",
    line2: "Perhaps there is just a problem with the link you clicked on or the URL you entered.",
    line3: "Go back to the main page.",
};

export interface NotFoundProps {
    /** Wrap in a master details page? */
    fullPage: boolean;
    strings: DefaultStrings<typeof defaultStrings>;
}

export class NotFound extends React.Component<NotFoundProps> {
    public static readonly defaultProps = {
        fullPage: true,
        strings: defaultStrings,
    };

    public render(): ReactNode {
        const { fullPage, strings } = this.props;

        const content = (
            <Fragment>
                <div className="cell auto">
                    <Unless condition={fullPage}>
                        <h1>{strings.header}</h1>
                    </Unless>
                    <Markup string={strings.line1} />
                    <Markup string={strings.line2} />
                    <p>
                        <Link to="/">
                            <Icon name={Icons.GoBack} />
                            &nbsp;{strings.line3}
                        </Link>
                    </p>
                </div>
                <div className="cell shrink">
                    <div className="error-man show-for-medium" />
                </div>
            </Fragment>
        );

        return fullPage ? <Page title={strings.header}>{content}</Page> : content;
    }
}

export default translate("NotFound")(NotFound);
