import { Loading } from "portal-components";
import React from "react";
import { LazilyLoadFactory } from "./lazilyload";

export interface LoadingComponentProps {
    loading?: boolean | string;
    random?: boolean;
    small?: boolean;
    description?: string;
    apps: string;
    board: string;
    connector: string;
    devices: string;
    world: string;
}

export const LoadingComponent: React.FunctionComponent<LoadingComponentProps> = (props) => {
    const loading = typeof props.loading === "string" ? props.loading !== "LOADED" : !!props.loading;
    if (!loading) {
        return <React.Fragment>{props.children}</React.Fragment>;
    }

    return (
        <Loading
            description={props.description}
            random={props.random}
            customImages={[props.apps, props.board, props.connector, props.devices, props.world]}
            small={props.small}
        />
    );
};

LoadingComponent.displayName = "LoadingComponent";
LoadingComponent.defaultProps = {
    description: "",
    random: false,
    small: false,
    loading: true,
};

export default LazilyLoadFactory(LoadingComponent, {
    apps: () => import("../images/Connector_Apps.svg").then((m) => m.default),
    board: () => import("../images/Connector_Devices_dev_board.svg").then((m) => m.default),
    connector: () => import("../images/Connector.svg").then((m) => m.default),
    devices: () => import("../images/Connector_Devices.svg").then((m) => m.default),
    world: () => import("../images/Connector_Connector.svg").then((m) => m.default),
});
