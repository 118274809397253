// Go to the given identity provider to start the OIDC flow
export const goToIdP = (redirectUrl: string, additionalParameters?: { [key: string]: string }) => {
    if (additionalParameters) {
        const queryString = Object.keys(additionalParameters)
            .map((key) => `${key}=${encodeURIComponent(additionalParameters[key])}`)
            .join("&");

        redirectUrl = redirectUrl + queryString;
    }

    location.replace(redirectUrl);
};

interface AuthorizeUrlParameters {
    clientId: string;
    authorizationUrl: string;
    redirectPath: string;
    additionalParameters: { [key: string]: string };
}

const createAuthorizeUrl = ({
    clientId,
    authorizationUrl,
    redirectPath,
    additionalParameters = {},
}: AuthorizeUrlParameters) => {
    // Generate an unguessable state string to protect against CSRF attacks

    const queryParameters: { [key: string]: string } = {
        client_id: clientId,
        response_type: "code",
        redirect_uri: `${window.location.protocol}//${window.location.host}/${redirectPath}`,
        scope: "openid profile email",
        ...additionalParameters,
    };

    const queryString = Object.keys(queryParameters)
        .map((key) => `${key}=${encodeURIComponent(queryParameters[key])}`)
        .join("&");

    return `${authorizationUrl}?${queryString}`;
};

interface IdpParameters {
    clientId: string;
    authorizationUrl: string;
    additionalParameters?: { [key: string]: string };
}

// Go to the given identity provider with no prompt to start the OIDC flow
export const goToIdPSilent = ({ clientId, authorizationUrl, additionalParameters = {} }: IdpParameters) => {
    const url = createAuthorizeUrl({
        clientId,
        authorizationUrl,
        redirectPath: "federated-login/authenticate",
        additionalParameters: { ...additionalParameters, prompt: "none" },
    });

    location.replace(url);
};
