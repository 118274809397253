import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import { adminAccess, importLazy, LazilyLoadFactory } from "../layout/lazilyload";
import NotFound from "../layout/404";
import ErrorBoundary from "../layout/errorBoundary";
import { PropagatedProps } from "../layout/main/component";
import { asyncRender } from "./BaseRoute";

export interface AccessRouteProps extends RouteComponentProps {
    AccessKeys: React.ComponentType<PropagatedProps>;
    AccessKeyDetail: React.ComponentType<PropagatedProps>;
    AddKeysToGroup: React.ComponentType<PropagatedProps>;
    AddUsersToGroup: React.ComponentType<PropagatedProps>;
    AddApplicationsToGroup: React.ComponentType<PropagatedProps>;
    Applications: React.ComponentType<PropagatedProps>;
    ApplicationDetail: React.ComponentType<PropagatedProps>;
    EditSecureDevicePolicy: React.ComponentType<PropagatedProps>;
    GroupDetail: React.ComponentType<PropagatedProps>;
    Groups: React.ComponentType<PropagatedProps>;
    IAM: React.ComponentType<PropagatedProps>;
    KeyDetail: React.ComponentType<PropagatedProps>;
    Keys: React.ComponentType<PropagatedProps>;
    NewPassword: React.ComponentType<PropagatedProps>;
    Policies: React.ComponentType<PropagatedProps>;
    PoliciesDetail: React.ComponentType<PropagatedProps>;
    SecureDevicePolicy: React.ComponentType<PropagatedProps>;
    UserDetail: React.ComponentType<PropagatedProps>;
    Users: React.ComponentType<PropagatedProps>;
    InvitedUsers: React.ComponentType<PropagatedProps>;
    VerificationKeyDetail: React.ComponentType<PropagatedProps>;
    componentProps: PropagatedProps;
}

export const AccessRoute: React.FunctionComponent<AccessRouteProps> = (props) => {
    const {
        AccessKeys,
        AccessKeyDetail,
        AddKeysToGroup,
        AddUsersToGroup,
        AddApplicationsToGroup,
        Applications,
        ApplicationDetail,
        EditSecureDevicePolicy,
        GroupDetail,
        Groups,
        IAM,
        KeyDetail,
        Keys,
        NewPassword,
        Policies,
        PoliciesDetail,
        SecureDevicePolicy,
        UserDetail,
        Users,
        InvitedUsers,
        VerificationKeyDetail,
        componentProps,
    } = props;

    const asyncRenderWithProps = asyncRender(componentProps);

    return (
        <ErrorBoundary>
            <Switch>
                <Route exact path="/access" render={asyncRenderWithProps(IAM, "access")} />
                <Route path="/access/groups/list" render={asyncRenderWithProps(Groups, "groups")} />
                <Route
                    exact
                    path="/access/groups/:id/add/key"
                    render={asyncRenderWithProps(AddKeysToGroup, "addKeysToGroup")}
                />
                <Route
                    exact
                    path="/access/groups/:id/add/user"
                    render={asyncRenderWithProps(AddUsersToGroup, "addUsersToGroup")}
                />
                <Route
                    exact
                    path="/access/groups/:id/add/application"
                    render={asyncRenderWithProps(AddApplicationsToGroup, "addApplicationsToGroup")}
                />
                <Route path="/access/groups/:id" render={asyncRenderWithProps(GroupDetail, "groupDetail")} />
                <Redirect exact path="/access/groups" to="/access/groups/list" />
                <Route path="/access/keys/list" render={asyncRenderWithProps(Keys, "keys")} />
                <Route path="/access/keys/:id" render={asyncRenderWithProps(KeyDetail, "keyDetail")} />
                <Redirect exact path="/access/keys" to="/access/keys/list" />
                <Route path="/access/applications/list" render={asyncRenderWithProps(Applications, "applications")} />
                <Route
                    path="/access/applications/:applicationId/verificationKeys/:id"
                    render={asyncRenderWithProps(VerificationKeyDetail, "accessKeyDetails")}
                />
                <Route
                    path="/access/applications/:id"
                    render={asyncRenderWithProps(ApplicationDetail, "applictionDetail")}
                />
                <Redirect exact path="/access/applications" to="/access/applications/list" />
                <Route path="/access/accessKeys/list" render={asyncRenderWithProps(AccessKeys, "accessKeys")} />
                <Route
                    path="/access/accessKeys/:id"
                    render={asyncRenderWithProps(AccessKeyDetail, "accessKeyDetails")}
                />
                <Redirect exact path="/access/accessKeys" to="/access/accessKeys/list" />
                <Route path="/access/policies/list" render={asyncRenderWithProps(Policies, "policies")} />
                <Route path="/access/policies/list/:id" render={asyncRenderWithProps(Policies, "policyDetails")} />
                <Route
                    exact
                    path="/access/policies/sda/new"
                    render={asyncRenderWithProps(SecureDevicePolicy, "createsecuredevicepolicy")}
                />
                <Route
                    exact
                    path="/access/policies/sda/wizard"
                    render={asyncRender({
                        ...componentProps,
                        wizard: true,
                    } as PropagatedProps & { wizard: boolean })(SecureDevicePolicy, "createsecuredevicepolicy")}
                />
                <Route
                    exact
                    path="/access/policies/sda/edit/:id"
                    render={asyncRenderWithProps(EditSecureDevicePolicy, "editecuredevicepolicy", {
                        edit: true,
                    })}
                />
                <Route path="/access/policies/:id" render={asyncRenderWithProps(PoliciesDetail, "policiesDetail")} />
                <Route path="/access/users/invites/list" render={asyncRenderWithProps(InvitedUsers, "users")} />
                <Redirect exact from="/access/users/invites/new" to="/access/users/invites/list?action=add" />
                <Route path="/access/users/list" render={asyncRenderWithProps(Users, "users")} />
                <Redirect exact from="/access/users/new" to="/access/users/list?action=add" />
                <Route
                    exact
                    path="/access/users/:id/regenerate"
                    render={asyncRenderWithProps(NewPassword, "resetUserPassword")}
                />
                <Route path="/access/users/:id" render={asyncRenderWithProps(UserDetail, "userDetail")} />
                <Redirect exact path="/access/users" to="/access/users/list" />
                <Redirect from="/access/policies" to="/access/policies/list" />
                <Route component={NotFound} />
            </Switch>
        </ErrorBoundary>
    );
};

export default LazilyLoadFactory(withRouter(AccessRoute), {
    AccessKeys: () => importLazy(import("../features/access/accessKeys/container"), adminAccess),
    AccessKeyDetail: () => importLazy(import("../features/access/accessKeys/detail/container"), adminAccess),
    AddKeysToGroup: () => importLazy(import("../features/access/groups/add/key"), adminAccess),
    AddUsersToGroup: () => importLazy(import("../features/access/groups/add/user"), adminAccess),
    AddApplicationsToGroup: () => importLazy(import("../features/access/groups/add/application"), adminAccess),
    Applications: () => importLazy(import("../features/access/applications/container"), adminAccess),
    ApplicationDetail: () => importLazy(import("../features/access/applications/detail/container"), adminAccess),
    EditSecureDevicePolicy: () => importLazy(import("../features/access/policies/create/container"), adminAccess),
    GroupDetail: () => importLazy(import("../features/access/groups/detail/container"), adminAccess),
    Groups: () => importLazy(import("../features/access/groups/container"), adminAccess),
    IAM: () => importLazy(import("../features/access/container")),
    KeyDetail: () => importLazy(import("../features/access/keys/detail/container")),
    Keys: () => importLazy(import("../features/access/keys/container")),
    NewPassword: () => importLazy(import("../features/access/users/regenerate/newPasswordContainer"), adminAccess),
    Policies: () => importLazy(import("../features/access/policies/container"), adminAccess),
    PoliciesDetail: () => importLazy(import("../features/access/policies/detail/container"), adminAccess),
    SecureDevicePolicy: () => importLazy(import("../features/access/policies/create/container"), adminAccess),
    UserDetail: () => importLazy(import("../features/access/users/detail/container"), adminAccess),
    Users: () => importLazy(import("../features/access/users/container"), adminAccess),
    InvitedUsers: () => importLazy(import("../features/access/users/invites/container"), adminAccess),
    VerificationKeyDetail: () =>
        importLazy(import("../features/access/verificationKeys/detail/container"), adminAccess),
});
