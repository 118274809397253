import { TextBox, ValueEvent } from "portal-components";
import React from "react";
import translate from "../../i18n/translate";

enum MfaType {
    TwoFactor = 0,
    ScratchCode = 1,
}

const defaultStrings = {
    mfaCode: "Authentication code:",
    mfaHeader:
        "Use the authentication code from the two-factor authentication app on your device to verify your identity.",
    scratchCode: "Recovery code:",
    scratchHeader: "Enter a two-factor recovery code if you do not have access to your phone.",
    useMfaCode: "Use a two-factor code",
    useRecoverCode: "Use a recovery code",
};

export interface MfaLoginComponentProps {
    otp: string;
    onOtpChange: (otp: string) => void;
    strings: typeof defaultStrings;
}

interface MfaLoginComponentState {
    mfaType: MfaType;
}

export class MfaLoginComponent extends React.Component<MfaLoginComponentProps, MfaLoginComponentState> {
    public static readonly defaultProps = {
        onOtpChange: () => {},
        strings: defaultStrings,
    };

    constructor(props: MfaLoginComponentProps) {
        super(props);
        this.handleOtpChange = this.handleOtpChange.bind(this);
        this.handleRecoveryOptionSwitch = this.handleRecoveryOptionSwitch.bind(this);
        this.state = { mfaType: MfaType.TwoFactor };
    }

    handleRecoveryOptionSwitch(event: React.MouseEvent<unknown>) {
        event.preventDefault();
        const { mfaType } = this.state;
        const newType = mfaType === MfaType.ScratchCode ? MfaType.TwoFactor : MfaType.ScratchCode;
        this.setState({ mfaType: newType });
    }

    handleOtpChange({ value }: ValueEvent<string>) {
        this.props.onOtpChange(value);
    }

    render() {
        const { otp, strings } = this.props;
        const { mfaType } = this.state;

        const authStrings = {
            header: [strings.mfaHeader, strings.scratchHeader],
            label: [strings.mfaCode, strings.scratchCode],
            switchType: [strings.useRecoverCode, strings.useMfaCode],
        };

        return (
            <React.Fragment>
                <p className="lead">{authStrings.header[mfaType]}</p>
                <TextBox
                    id="otp"
                    name="otp"
                    label={authStrings.label[mfaType]}
                    autoComplete="one-time-code"
                    autoFocus
                    required
                    value={otp}
                    onValueChange={this.handleOtpChange}
                />
                <p className="help-text float-right">
                    <a onClick={this.handleRecoveryOptionSwitch}>{authStrings.switchType[mfaType]}</a>
                </p>
            </React.Fragment>
        );
    }
}

export default translate("mfaLoginComponent")(MfaLoginComponent);
