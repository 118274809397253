import { Reducer, ReducersMapObject } from "redux";

/**
 * Registry that keeps track of the reducers and provides methods to dynamically add more
 * Based on the concept presented here: http://nicolasgallagher.com/redux-modules-and-code-splitting/
 */
export class ReducerRegistry {
    private emitChange?: (reducers: ReducersMapObject<any>) => void;
    private reducers = {} as ReducersMapObject<any>;

    /**
     * Initializes the registry
     * For now we set the reducers to a combination of the existing appState and the dynamic as exposed by the root reducer
     * once we have the all te reducers ported over we will keep the dynamic only
     * @param reducers the initial reducer collection, should be the appState of the root reducer
     */
    public init<TState>(reducers: ReducersMapObject<TState>) {
        if (!this.reducers) {
            this.reducers = reducers;
        }
    }

    /**
     * Gets the the current collection of reducers
     */
    public getReducers() {
        return this.reducers;
    }

    /**
     * Registers a new collection of reducers
     * @param reducers the reducer collection
     */
    public register(reducers: { [key: string]: Reducer }) {
        this.reducers = { ...this.reducers, ...reducers };
        if (this.emitChange) {
            this.emitChange(this.getReducers());
        }
    }

    /**
     * Listener that registers with the storeProvider to fire whenever we have new registrations
     * @param listener the change listener, purposefully set to AppDynamicState to highlight that only new reducers should be registered
     */
    public setChangeListener<TState>(listener: (reducers: ReducersMapObject<TState>) => void) {
        this.emitChange = listener;
    }
}

const reducerRegistry = new ReducerRegistry();

export default reducerRegistry;
