import { ListResponse } from "mbed-cloud-sdk/types/legacy/common/listResponse";
import { Loading } from "portal-components";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { Config } from "../typings/interfaces";
import { AjaxState, onGroupStateLoaded, onStateChange, RequestState } from "../creators/AjaxCreator";
import { CurrentAccount, CurrentUser } from "../features/account";
import {
    getAccountBrandingColorsDark,
    getAccountBrandingColorsLight,
    getAccountBrandingImagesDark,
    getAccountBrandingImagesLight,
    resetBranding,
    BrandingColorsDefinition,
    BrandingImagesDefinition,
} from "../features/organization";
import { LoginTeamState } from "../features/login";
import { AuthState } from "../layout/reducer";
import { setStyling } from "../script/branding";
import { AppDispatch, AppState } from "../creators/reducers";
import { getFeatureToggle, loadCookie } from "../script/utility";

export interface ApplyBrandingProps extends RouteComponentProps {
    accountBrandingColorsDark: AjaxState<ListResponse<BrandingColorsDefinition>>;
    accountBrandingColorsLight: AjaxState<ListResponse<BrandingColorsDefinition>>;
    accountBrandingImagesDark: AjaxState<ListResponse<BrandingImagesDefinition>>;
    accountBrandingImagesLight: AjaxState<ListResponse<BrandingImagesDefinition>>;
    auth: AuthState;
    config: Config;
    currentAccount: AjaxState<CurrentAccount>;
    teamLogin: LoginTeamState;
    currentUser: AjaxState<CurrentUser>;
    updateUserSettings: AjaxState<CurrentUser>;
    actions: {
        getAccountBrandingColorsDark: typeof getAccountBrandingColorsDark;
        getAccountBrandingColorsLight: typeof getAccountBrandingColorsLight;
        getAccountBrandingImagesDark: typeof getAccountBrandingImagesDark;
        getAccountBrandingImagesLight: typeof getAccountBrandingImagesLight;
        resetBranding: typeof resetBranding;
    };
}

interface ApplyBrandingState {
    loading: boolean;
    team: string;
    teamBrandingAllowed: boolean;
}

export class ApplyBranding extends React.Component<ApplyBrandingProps, ApplyBrandingState> {
    constructor(props: ApplyBrandingProps) {
        super(props);
        this.checkLocalStorageAccess = this.checkLocalStorageAccess.bind(this);
        this.getBranding = this.getBranding.bind(this);

        const { location: { pathname = "" } = {} } = props;
        const isLoggedIn = !!loadCookie("token");
        const teamBrandingAllowed =
            getFeatureToggle("uiCustomization", props) || (pathname && pathname.startsWith("/team/"));

        this.state = {
            loading: teamBrandingAllowed && isLoggedIn,
            team: "",
            teamBrandingAllowed,
        };
    }

    static getDerivedStateFromProps(props: ApplyBrandingProps, state: ApplyBrandingState) {
        const { auth, currentAccount, location: { pathname = "" } = {} } = props;

        // Always get account id from url if user is switching teams, could be an alias though.
        // Otherwise get account id from currentAccount.
        // But keep previous team id if currentAccount is updating and user is still authenticated
        // If no id is found, app will go into a loading mode until we get branding
        const team =
            pathname && pathname.startsWith("/team/")
                ? pathname.split("/team/")[1].split("/")[0]
                : currentAccount.requestState === RequestState.LOADED
                ? currentAccount.data.id
                : auth.isAuthenticated
                ? state.team
                : "";

        const teamBrandingAllowed =
            getFeatureToggle("uiCustomization", props) || (pathname && pathname.startsWith("/team/"));

        return { team, teamBrandingAllowed };
    }

    componentDidMount() {
        // Immediately reset branding, will force a check for on-premises overrides
        setStyling({}, true);
        // Get team branding
        if (this.state.teamBrandingAllowed) {
            this.getBranding();
        }
    }

    componentDidUpdate(prevProps: ApplyBrandingProps, prevState: ApplyBrandingState) {
        const {
            actions,
            auth,
            accountBrandingColorsDark,
            accountBrandingColorsLight,
            accountBrandingImagesDark,
            accountBrandingImagesLight,
            currentAccount,
            updateUserSettings,
            location: { pathname = "" } = {},
            teamLogin,
        } = this.props;

        // Logged out, clear cloud branding and reset to initial values
        const isAtLogin = pathname && pathname.startsWith("/login");
        const wasAtLogin =
            prevProps.location && prevProps.location.pathname && prevProps.location.pathname.startsWith("/login");
        const teamReset = teamLogin.state !== prevProps.teamLogin.state && teamLogin.state === "INITIAL";

        if ((!auth.isLoggingOut && prevProps.auth.isLoggingOut) || (isAtLogin && !wasAtLogin) || teamReset) {
            if (this.state.loading) {
                setStyling({}, true);
                this.setState({ loading: false }, () => {
                    actions.resetBranding();
                });
            }
        } else if (this.state.teamBrandingAllowed && prevState.team !== this.state.team) {
            // Fetch new branding only if feature is enabled
            this.getBranding();
        }

        // If any branding api request fails, we need to stop loading mode and just show on-premises or native branding
        onStateChange(prevProps.accountBrandingColorsDark, accountBrandingColorsDark, {
            failed: () => this.setState({ loading: false }),
        });

        onStateChange(prevProps.accountBrandingColorsLight, accountBrandingColorsLight, {
            failed: () => this.setState({ loading: false }),
        });

        onStateChange(prevProps.accountBrandingImagesDark, accountBrandingImagesDark, {
            failed: () => this.setState({ loading: false }),
        });

        onStateChange(prevProps.accountBrandingImagesLight, accountBrandingImagesLight, {
            failed: () => this.setState({ loading: false }),
        });

        // Apply branding for team log in flow
        if (
            !auth.isAuthenticated ||
            (auth.isAuthenticated && (teamLogin.state === "CONFIRM_SWITCH_TEAM" || teamLogin.state === "LOADING")) ||
            currentAccount.requestState === RequestState.INITIALIZING
        ) {
            onGroupStateLoaded(
                [
                    prevProps.accountBrandingColorsDark,
                    prevProps.accountBrandingColorsLight,
                    prevProps.accountBrandingImagesDark,
                    prevProps.accountBrandingImagesLight,
                ],
                [
                    accountBrandingColorsDark,
                    accountBrandingColorsLight,
                    accountBrandingImagesDark,
                    accountBrandingImagesLight,
                ],
                () => {
                    this.setState({ loading: false });
                    // Anonymous branding request always return branding (with null values if not enabled or never set),
                    // force ui_customization and management_console_branding feature package as enabled to apply or clear branding
                    setStyling({
                        featureToggle: { uiCustomization: true },
                    } as Partial<Config>);
                }
            );
        } else {
            // Apply branding for logged in user
            onGroupStateLoaded(
                [
                    prevProps.accountBrandingColorsDark,
                    prevProps.accountBrandingColorsLight,
                    prevProps.accountBrandingImagesDark,
                    prevProps.accountBrandingImagesLight,
                    prevProps.currentAccount,
                ],
                [
                    accountBrandingColorsDark,
                    accountBrandingColorsLight,
                    accountBrandingImagesDark,
                    accountBrandingImagesLight,
                    currentAccount,
                ],
                () => {
                    this.setState({ loading: false });
                    // We know more details about the team, apply branding based on feature toggles
                    setStyling();
                    const {
                        brandingColorsDark,
                        brandingColorsLight,
                        brandingImagesLight,
                        brandingImagesDark,
                        defaultTheme,
                    } = this.props.config;
                    const branding = JSON.stringify({
                        brandingColorsDark,
                        brandingColorsLight,
                        brandingImagesLight,
                        brandingImagesDark,
                        defaultTheme,
                    });
                    // Store complete branding profile
                    if (this.checkLocalStorageAccess()) {
                        localStorage.setItem(`branding_${this.state.team}`, branding);
                    }
                }
            );
        }

        const applyUserTheme = (customFields: { [key: string]: unknown }) => {
            if (customFields && "portal_theme" in customFields) {
                const { theme = "" } = (customFields.portal_theme || {}) as { version: string; theme: string };
                setStyling({ userTheme: theme } as Partial<Config>);
            }
        };

        onStateChange(prevProps.updateUserSettings, updateUserSettings, {
            loaded: ({ data: { custom_fields } }) => applyUserTheme(custom_fields),
        });
    }

    getBranding() {
        const { actions } = this.props;
        const { team } = this.state;

        if (team) {
            let branding = null;

            if (this.checkLocalStorageAccess()) {
                const brandingString = localStorage.getItem(`branding_${team}`) || "";
                if (brandingString) {
                    try {
                        branding = JSON.parse(brandingString);
                    } catch (e) {
                        // error parsing branding cache
                    }
                }
            }

            // Apply branding from cache while we load fresh values
            if (branding) {
                setStyling(branding);
            } else {
                this.setState({ loading: true });
            }
            actions.getAccountBrandingColorsDark(team);
            actions.getAccountBrandingColorsLight(team);
            actions.getAccountBrandingImagesDark(team);
            actions.getAccountBrandingImagesLight(team);
        }
    }

    checkLocalStorageAccess() {
        const checkLocalStorageAccess = "checkLocalStorageAccess";
        try {
            localStorage.setItem(checkLocalStorageAccess, checkLocalStorageAccess);
            localStorage.removeItem(checkLocalStorageAccess);
            return true;
        } catch (e) {
            return false;
        }
    }

    render() {
        const { loading, team, teamBrandingAllowed } = this.state;
        return teamBrandingAllowed && loading && team ? (
            <div className="center saturate">
                <Loading />
            </div>
        ) : (
            this.props.children
        );
    }
}

const mapStateToProps = ({
    auth,
    config,
    currentaccount,
    currentuser,
    updateusersettings,
    accountBrandingColorsDark,
    accountBrandingColorsLight,
    accountBrandingImagesDark,
    accountBrandingImagesLight,
    teamLogin,
}: AppState) => ({
    auth,
    config,
    currentAccount: currentaccount,
    currentUser: currentuser,
    updateUserSettings: updateusersettings,
    accountBrandingColorsDark,
    accountBrandingColorsLight,
    accountBrandingImagesDark,
    accountBrandingImagesLight,
    teamLogin,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    actions: bindActionCreators(
        {
            getAccountBrandingColorsDark,
            getAccountBrandingColorsLight,
            getAccountBrandingImagesDark,
            getAccountBrandingImagesLight,
            resetBranding,
        },
        dispatch
    ),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplyBranding));
