import React from "react";
import { AnyAction } from "redux";
import { getNextUniqueId } from "../script/utility";

export interface CalloutStateBody {
    isError: boolean;
    response: React.ReactNode | null;
    message: string | null;
}

export type CalloutBodyType = CalloutStateBody | CalloutStateBody[] | React.ReactNode;

export interface CalloutState {
    severity: string | null;
    header: string | null;
    body: CalloutBodyType | null;
    acked?: boolean;
    showToast?: boolean;
    id?: string | null;
    stale?: boolean;
    timestamp?: string;
}
type Keys = keyof typeof CalloutCreator.actions;

export interface CreateCalloutReturn {
    type: typeof CalloutCreator.actions[Keys];
    severity: string;
    header: string;
    body: React.ReactNode | null;
    showToast: boolean;
}

export default class CalloutCreator {
    public static readonly actions = {
        newCallout: "NEW_CALLOUT",
        clearCallout: "CLEAR_CALLOUT",
        ackCallout: "ACK_CALLOUT",
        staleCallout: "STALE_CALLOUT",
    };

    public static createCallout(
        severity: string,
        header: string,
        body: React.ReactNode | null,
        showToast = true
    ): CreateCalloutReturn {
        return {
            type: CalloutCreator.actions.newCallout,
            severity,
            header,
            body,
            showToast,
        };
    }

    public static clearCallout() {
        return { type: CalloutCreator.actions.clearCallout };
    }

    public static ackCallout() {
        return { type: CalloutCreator.actions.ackCallout };
    }

    public static staleCallout() {
        return { type: CalloutCreator.actions.staleCallout };
    }

    public static createReducer() {
        return (
            state: CalloutState = {
                severity: null,
                header: null,
                body: null,
                acked: false,
                showToast: true,
                stale: false,
                id: null,
                timestamp: "",
            },
            action: AnyAction
        ): CalloutState => {
            switch (action.type) {
                case CalloutCreator.actions.newCallout:
                    return {
                        severity: action.severity,
                        header: action.header,
                        body: action.body,
                        acked: action.acked,
                        showToast: action.showToast === undefined ? true : !!action.showToast,
                        id: action.id || getNextUniqueId(),
                        stale: false,
                        timestamp: new Date().toISOString(),
                    };
                case CalloutCreator.actions.clearCallout:
                    return {
                        ...state,
                        severity: null,
                        header: null,
                        body: null,
                        acked: false,
                        showToast: false,
                    };
                case CalloutCreator.actions.ackCallout:
                    return {
                        ...state,
                        acked: true,
                        showToast: false,
                    };
                case CalloutCreator.actions.staleCallout:
                    return {
                        ...state,
                        stale: true,
                    };
                default:
                    return state;
            }
        };
    }
}
