import {
    ButtonVariant,
    DropdownButton,
    Else,
    If,
    NameValueItem,
    NameValueTable,
    Orientation,
    Panel,
    SizeMode,
    Span,
    StackPanel,
    Strings,
} from "portal-components";
import React, { ReactNode } from "react";
import { Column, GridWrap } from "../../controls/grid";
import LogLevelComponent from "../../controls/logLevelComponent";
import translate, { DefaultStrings } from "../../i18n/translate";
import { isObject } from "../../script/utility";
import { ApiActionsResponseState, ApiActionsState, GenericObject, MaxHistoryEntries } from "./creator";

export const defaultStrings = {
    data: "Data",
    hasData: "With data",
    hasQueryParameters: "With query parameters",
    method: "Method",
    parameters: "Parameters",
    query: "Query parameters",
    response: "Response",
    timestamp: "Time",
    url: "Endpoint",
    waiting: "Waiting for response...",
};

export interface ConsoleAPICallsProps {
    host: string;
    strings: DefaultStrings<typeof defaultStrings>;
    data: ApiActionsState[];
}

interface ConsoleAPICallsState {
    columns: Column<ApiActionsState>[];
}

export class ConsoleAPICalls extends React.Component<ConsoleAPICallsProps, ConsoleAPICallsState> {
    public static readonly defaultProps = {
        data: [],
        strings: defaultStrings,
    };

    constructor(props: ConsoleAPICallsProps) {
        super(props);
        this.parseMetadata = this.parseMetadata.bind(this);
        this.parseParameters = this.parseParameters.bind(this);
        this.splitUrl = this.splitUrl.bind(this);

        const { strings } = props;

        this.state = {
            columns: [
                {
                    id: 0,
                    isSortable: true,
                    name: "timestamp",
                    title: strings.timestamp,
                    type: "date",
                    ellipsis: "end",
                },
                {
                    id: 1,
                    isSortable: false,
                    name: "method",
                    title: strings.method,
                    ellipsis: "end",
                },
                {
                    id: 2,
                    isSortable: false,
                    name: "url",
                    title: strings.url,
                    renderFunction: (url: unknown) => {
                        return <Span text={this.splitUrl(url as string)[0]} />;
                    },
                },
                {
                    id: 3,
                    name: "",
                    isSortable: false,
                    title: strings.parameters,
                    renderFunction: (row: unknown) => this.parseParameters(row as ApiActionsState),
                },
                {
                    id: 4,
                    name: "",
                    isSortable: false,
                    title: strings.response,
                    renderFunction: (row: unknown) => this.parseMetadata(row as ApiActionsState),
                },
            ],
        };
    }

    splitUrl(url: string) {
        const { host = "" } = this.props;
        return url
            .replace(/(^\w+:|^)\/\//, "")
            .replace(host, "")
            .split("?");
    }

    parseMetadata(row: ApiActionsState) {
        const { response, responseState } = row;

        if (responseState === ApiActionsResponseState.Loading) {
            return <em>{this.props.strings.waiting}</em>;
        }

        let pretty = "";
        try {
            pretty = JSON.stringify(response, null, 2);
        } catch {
            // Failed to parse
        }

        return (
            <DropdownButton
                variant={ButtonVariant.Compact}
                text={
                    <a>
                        <If condition={responseState === ApiActionsResponseState.Loaded}>
                            <LogLevelComponent level="SUCCESS" />
                            <Else>
                                <LogLevelComponent level="ERROR" />
                            </Else>
                        </If>
                    </a>
                }
            >
                <pre>{pretty}</pre>
            </DropdownButton>
        );
    }

    parseParameters(row: ApiActionsState) {
        const { strings } = this.props;
        const { data = null, queryParameters = {}, url = "" } = row || {};

        // Get query parameters
        let parameters = {};
        // From encoded URL
        const urlParameters = Strings.convertSearchStringToObject(this.splitUrl(url)[1] || "");
        if (urlParameters && Object.keys(urlParameters).length > 0) {
            parameters = urlParameters;
        } else if (queryParameters && Object.keys(queryParameters).length > 0) {
            // From queryParameters field
            parameters = queryParameters;
        }
        const parametersValues = Object.entries(parameters).map(([title, value], index) => {
            return (
                <NameValueItem key={`parameter-${index}`} text={title}>
                    {value as ReactNode}
                </NameValueItem>
            );
        });

        // Get PUT/POST data
        let dataValues: JSX.Element[] = [];
        let parsedData = data;
        if (typeof data === "string") {
            try {
                parsedData = JSON.parse(data);
            } catch {
                // Failed to parse
                parsedData = {};
            }
        }
        if (parsedData instanceof FormData || isObject(parsedData)) {
            let dataArray: GenericObject[] = [];
            if (parsedData instanceof FormData && parsedData.entries && Array.from) {
                dataArray = Array.from(parsedData.entries()).map(([title, value]) => {
                    if (value instanceof File) {
                        const { lastModified, name, size, type } = value;
                        return { [title]: { lastModified, name, size, type } };
                    }
                    return { [title]: value };
                });
            } else {
                dataArray = Object.entries(parsedData as GenericObject).map(([k, v]) => ({ [k]: v }));
            }
            dataValues = dataArray.map((entry, index) => {
                const title = (entry[0] || "") as string;
                const value = entry[1] || {};
                return (
                    <NameValueItem key={`parameter-${index}`} text={title}>
                        {Strings.toString(value)}
                    </NameValueItem>
                );
            });
        }

        const content = (
            <StackPanel orientation={Orientation.Vertical}>
                <StackPanel when={parametersValues && parametersValues.length > 0} orientation={Orientation.Vertical}>
                    <Panel element="h5" size={SizeMode.Grow}>
                        {strings.query}
                    </Panel>
                    <StackPanel orientation={Orientation.Horizontal} gutter>
                        <NameValueTable>{parametersValues}</NameValueTable>
                    </StackPanel>
                </StackPanel>
                <StackPanel when={dataValues && dataValues.length > 0} orientation={Orientation.Vertical}>
                    <Panel element="h5" size={SizeMode.Grow}>
                        {strings.data}
                    </Panel>
                    <StackPanel orientation={Orientation.Horizontal} gutter>
                        <NameValueTable>{dataValues}</NameValueTable>
                    </StackPanel>
                </StackPanel>
            </StackPanel>
        );

        return (
            <DropdownButton
                variant={ButtonVariant.Compact}
                text={
                    <StackPanel orientation={Orientation.Horizontal} className="clickable">
                        <Panel element="a" when={parametersValues && parametersValues.length > 0}>
                            {strings.hasQueryParameters}
                        </Panel>
                        <Panel element="a" when={dataValues && dataValues.length > 0}>
                            {strings.hasData}
                        </Panel>
                    </StackPanel>
                }
            >
                {content}
            </DropdownButton>
        );
    }

    public render(): ReactNode {
        const { data = [] } = this.props;
        const { columns } = this.state;

        return (
            <GridWrap<ApiActionsState>
                id="console-api"
                columns={columns}
                data={data}
                defaultPageSize={MaxHistoryEntries}
                pagingPageSizeSmall={[{ value: MaxHistoryEntries }]}
                noSelection
            />
        );
    }
}

export default translate("ConsoleAPICalls")(ConsoleAPICalls);
