import {
    Icons,
    IconSize,
    If,
    LinkMenuItem,
    MenuItem,
    NavigationMenuGroup,
    NavigationMenuGroupItem,
    SearchableFeature,
} from "portal-components";
import React from "react";
import { connect } from "react-redux";
import { Config } from "../../typings/interfaces";
import { AjaxState, RequestState } from "../../creators/AjaxCreator";
import { CurrentAccount, CurrentUser, getUserTeams, Team } from "../../features/account";
import { logout } from "../../features/login";
import translate from "../../i18n/translate";
import { AppDispatch, AppState } from "../../creators/reducers";
import { ListResponse } from "../../script/types";
import { getFeatureToggle } from "../../script/utility";

const defaultStrings = {
    identity: "Identity",
    profile: "Profile",
    account: "Account",
    accountSettings: "Profile settings",
    switchTeam: "Switch team",
    termsOfService: "Terms of service",
    logOut: "Log out",
    upgradeAccount: "Upgrade account",
    privacy: "Privacy",
    loggedInAs: "Logged in as",
};

interface AccountMenuProps {
    dispatch: AppDispatch;
    config?: Config;
    strings: typeof defaultStrings;
    account?: AjaxState<CurrentAccount>;
    teams?: AjaxState<ListResponse<Team>>;
    user?: AjaxState<CurrentUser>;
    style?: Record<string, unknown>;
}

interface State {
    switchingTeam: boolean;
}

export class AccountMenu extends React.Component<AccountMenuProps, State> {
    public static readonly defaultProps = {
        strings: defaultStrings,
    };

    public readonly state: State = {
        switchingTeam: false,
    };

    public componentDidMount() {
        this.props.dispatch(getUserTeams);
    }

    public shouldComponentUpdate(nextProps: Readonly<AccountMenuProps>) {
        // User's definition comes from "outside" and it might be refreshed
        // externally. We do not refresh this component to avoid flickering
        // while reloading.
        if (nextProps.user && nextProps.user.requestState !== RequestState.LOADED) {
            return false;
        }

        // Account data MIGHT be refreshed externally (with a timer),
        // do not refresh this component to avoid flickering.
        if (nextProps.account && nextProps.account.requestState !== RequestState.LOADED) {
            return false;
        }

        return true;
    }

    public render() {
        const { user, strings } = this.props;
        const useNewMenu = getFeatureToggle("useNewMenu", this.props);

        let userNameLine =
            user && user.requestState === RequestState.LOADED ? user.data.full_name || user.data.email : "";

        if (userNameLine) {
            userNameLine = (
                <span>
                    <small className="muted">{strings.loggedInAs}</small>
                    <br />
                    {userNameLine}
                </span>
            );
        }

        return (
            <React.Fragment>
                <SearchableFeature
                    group={strings.identity}
                    icon={Icons.User}
                    text={strings.accountSettings}
                    description={`${strings.account} → ${strings.accountSettings}`}
                    to="/account"
                />
                <SearchableFeature
                    group={strings.identity}
                    icon={Icons.User}
                    text={strings.termsOfService}
                    description={`${strings.account} → ${strings.termsOfService}`}
                    to="/account/terms-of-service"
                />
                <If condition={useNewMenu}>
                    <NavigationMenuGroup expand={true} to="/account/profile" icon={Icons.User} text={strings.profile}>
                        <NavigationMenuGroupItem to="/account/profile" text={strings.accountSettings} />
                        <NavigationMenuGroupItem
                            when={getFeatureToggle("showTermsOfService", this.props)}
                            to="/account/terms-of-service"
                            text={strings.termsOfService}
                        />
                        <NavigationMenuGroupItem
                            extras={{ externalLink: true }}
                            to=""
                            id="log-out"
                            text={strings.logOut}
                            onClick={() => {
                                this.props.dispatch(logout({ endIdpSession: true }));
                            }}
                        />
                    </NavigationMenuGroup>
                </If>
                <If condition={!useNewMenu}>
                    <MenuItem id="user-account-menu" icon={Icons.User} iconSize={IconSize.Large} text={strings.profile}>
                        <MenuItem disabled text={userNameLine} className="user-name-line" />
                        <LinkMenuItem
                            id="accounts-page-link"
                            to="/account"
                            icon={Icons.UserSettings}
                            iconSize={IconSize.Large}
                            text={strings.accountSettings}
                        />
                        <LinkMenuItem
                            when={getFeatureToggle("showTermsOfService", this.props)}
                            to="/account/terms-of-service"
                            icon={Icons.UserTermsOfService}
                            iconSize={IconSize.Large}
                            text={strings.termsOfService}
                        />
                        <MenuItem
                            id="log-out"
                            icon={Icons.LogOut}
                            iconSize={IconSize.Large}
                            text={strings.logOut}
                            onClick={() => {
                                this.props.dispatch(logout({ endIdpSession: true }));
                            }}
                        />
                    </MenuItem>
                </If>
            </React.Fragment>
        );
    }
}

export function mapStateToProps(state: AppState) {
    return {
        config: state.config,
        user: state.currentuser,
        account: state.currentaccount,
        teams: state.currentuserteams,
    };
}

export default connect(mapStateToProps)(translate("AccountMenu")(AccountMenu));
