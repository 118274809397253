import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import NotFound from "../layout/404";
import ErrorBoundary from "../layout/errorBoundary";
import { adminAccess, importLazy, LazilyLoadFactory } from "../layout/lazilyload";
import { PropagatedProps } from "../layout/main/component";
import { asyncRender } from "./BaseRoute";

export interface DirectoryRouteProps extends RouteComponentProps {
    GroupCreate: React.ComponentType<PropagatedProps>;
    GroupList: React.ComponentType<PropagatedProps>;
    GroupDetail: React.ComponentType<PropagatedProps>;
    DeviceObjects: React.ComponentType<PropagatedProps>;
    UploadObject: React.ComponentType<PropagatedProps>;
    Catalog: React.ComponentType<PropagatedProps>;
    CatalogFilters: React.ComponentType<PropagatedProps>;
    DeviceDetail: React.ComponentType<PropagatedProps>;
    DeviceInsights: React.ComponentType<PropagatedProps>;
    DeviceInsightsCreate: React.ComponentType<PropagatedProps>;
    DeviceInsightsEdit: React.ComponentType<PropagatedProps>;
    DeviceEnrollment: React.ComponentType<PropagatedProps>;
    DeviceEnrollmentCreate: React.ComponentType<PropagatedProps>;
    DeviceLog: React.ComponentType<PropagatedProps>;
    componentProps: PropagatedProps;
}

export const DirectoryRoute: React.FunctionComponent<DirectoryRouteProps> = (props) => {
    const {
        GroupCreate,
        GroupList,
        GroupDetail,
        Catalog,
        DeviceDetail,
        DeviceInsights,
        DeviceInsightsCreate,
        DeviceInsightsEdit,
        DeviceEnrollment,
        DeviceEnrollmentCreate,
        DeviceLog,
        DeviceObjects,
        UploadObject,
        componentProps,
    } = props;
    const asyncRenderWithProps = asyncRender(componentProps);
    return (
        <ErrorBoundary>
            <Switch>
                <Route path="/devices/objects/list" render={asyncRenderWithProps(DeviceObjects, "deviceObjects")} />
                <Route
                    exact
                    path="/devices/objects/upload"
                    render={asyncRenderWithProps(UploadObject, "UploadObjectContainer")}
                />
                <Route path="/devices/groups/list" render={asyncRenderWithProps(GroupList, "deviceGroups")} />
                <Route
                    exact
                    path="/devices/groups/new"
                    render={asyncRenderWithProps(GroupCreate, "deviceGroupCreate")}
                />
                <Route
                    exact
                    path="/devices/groups/:id/edit"
                    render={asyncRenderWithProps(GroupCreate, "deviceGroupEdit")}
                />
                <Route path="/devices/groups/:id" render={asyncRenderWithProps(GroupDetail, "deviceGroupDetail")} />
                <Route path="/devices/list" render={asyncRenderWithProps(Catalog, "devices")} />
                <Route path="/devices/logs" render={asyncRenderWithProps(DeviceLog, "logs")} />
                <Route path="/devices/logs/:id" render={asyncRenderWithProps(DeviceLog, "logsDetails")} />
                <Route
                    exact
                    path="/devices/enrollment/new"
                    render={asyncRenderWithProps(DeviceEnrollmentCreate, "deviceEnrollmentCreate")}
                />
                <Route path="/devices/enrollment" render={asyncRenderWithProps(DeviceEnrollment, "deviceEnrollment")} />
                <Route
                    path="/devices/insights/new"
                    render={asyncRenderWithProps(DeviceInsightsCreate, "deviceInsightsCreate")}
                />
                <Route
                    path="/devices/insights/:id/edit"
                    render={asyncRenderWithProps(DeviceInsightsEdit, "deviceInsightsEdit")}
                />
                <Route path="/devices/insights" render={asyncRenderWithProps(DeviceInsights, "deviceInsights")} />
                <Route path="/devices/:id" render={asyncRenderWithProps(DeviceDetail, "deviceDetail")} />
                <Redirect exact from="/devices/" to="/devices/list" />
                <Route component={NotFound} />
            </Switch>
        </ErrorBoundary>
    );
};

export default LazilyLoadFactory(withRouter(DirectoryRoute), {
    GroupList: () => importLazy(import("../features/devices/groups/container")),
    GroupCreate: () => importLazy(import("../features/devices/groups/create/container")),
    GroupDetail: () => importLazy(import("../features/devices/groups/detail/container")),
    DeviceObjects: () => importLazy(import("../features/devices/objects/container"), adminAccess),
    UploadObject: () => importLazy(import("../features/devices/objects/upload/container"), adminAccess),
    Catalog: () => importLazy(import("../features/devices/container")),
    DeviceDetail: () => importLazy(import("../features/devices/detail/container")),
    DeviceInsights: () => importLazy(import("../features/devices/insights/container")),
    DeviceInsightsCreate: () => importLazy(import("../features/devices/insights/create/container")),
    DeviceInsightsEdit: () => importLazy(import("../features/devices/insights/edit/container")),
    DeviceEnrollment: () => importLazy(import("../features/devices/enrollment/container")),
    DeviceEnrollmentCreate: () => importLazy(import("../features/devices/enrollment/create/container")),
    DeviceLog: () => importLazy(import("../features/devices/logs/container")),
});
