import FileSaver from "file-saver";
import moment from "moment";
import {
    Button,
    Card,
    DialogButtons,
    ErrorMessage,
    Form,
    Grid,
    Icon,
    Icons,
    MediaQueries,
    MediaQuery,
    Paragraph,
    Text,
    TextBox,
    TextVariant,
} from "portal-components";
import React from "react";
import { Config, RecommendedApp } from "../../../../typings/interfaces";
import QRCode from "../../../../controls/qrcode";
import translate, { DefaultStrings } from "../../../../i18n/translate";
import { Documentation, mergeBuiltInListWithConfiguration } from "../../../../script/utility";
import ApiReauthMonitor from "../../../../controls/apiReauthMonitor";

export const defaultStrings = {
    setupStep1Header: "Introduction",
    setupStep1Help:
        "Two-factor authentication adds an extra layer of security to your user account. When you log in, you will be asked to provide an authentication code after your username and password. This code is generated by an app installed on your smartphone.",
    setupStep1Recommended: "Recommended apps:",
    setupStep1Button: "Set up two-factor authentication",
    setupStep2Header: "1. Scan the barcode with your app",
    setupStep2Help: "Scan the QR code below with the two-factor authentication app on your mobile device.",
    step2Alternative: "Or if you can't scan, enter the secret directly in the app:",
    setupStep2Button: "Next: Enter the six digit code",
    setupStep3Header: "2. Enter your six-digit code",
    setupStep3Help: "Nearly done! Enter the six digit code generated by the app on your mobile device.",
    setupStep3Button: "Finish and show recovery codes",
    setupStep4Header: "Your new recovery codes",
    setupStep4Help:
        "Recovery codes are used to access your account in the event you cannot generate two-factor authentication codes on you mobile device.",
    setupStep4CodesHeader: "RECOVERY CODES",
    setupStep4CodesHelp:
        "Keep your recovery codes somewhere safe and accessible. You can only use each recovery code once.",
    setupStep4CodeDate: "Generated on {0}",
    setupStep4CodeMore: "Create additional recovery codes at {0}",
    setupStep4Button: "OK",
    generateCodesHeader: "Generate new recovery codes",
    generateCodesHelp:
        "Your set of recovery codes are generated and never stored by the system. If you generate new recovery codes, your previous codes will cease to work.",
    generateCodesButton: "Generate new recovery codes",
    seeDocs: "Find out more",
    deactivateHeader: "Turn off two-factor authentication",
    deactivateButton: "Disable two-factor authentication",
    cancel: "Cancel",
    copyButton: "Copy to clipboard",
    copyFailed: "Failed to copy to clipboard",
    copySuccess: "Copied!",
    saveButton: "Save as text file",
    mfaEnforced: "Two-factor authentication is required for your organization.",
    fileName: "recovery-codes-device-management-{0}-{1}.txt",
};

export interface Totp {
    email: string;
    host: string;
    issuer: string;
    secret: string;
    url: string;
}

interface TotpSetupComponentState {
    totp_code: string;
    didCopy: string;
    compact: boolean;
}

interface TotpSetupComponentProps {
    strings: DefaultStrings<typeof defaultStrings>;
    actionDisabled: boolean;
    cancelButton: JSX.Element;
    config: Config;
    errorMessage: string;
    loginFlow: boolean;
    recommendedApps: { [key: string]: RecommendedApp };
    recovery: string[];
    step: string;
    totp?: Totp;
    working: boolean;
    onStepSubmit: (state: TotpSetupComponentState) => void;
    onCancel: () => void;
}

export class TotpSetupComponent extends React.PureComponent<TotpSetupComponentProps, TotpSetupComponentState> {
    public static defaultProps = {
        strings: defaultStrings,
        recommendedApps: {
            Google: {
                enabled: true,
                name: "Google Authenticator",
                link: "https://support.google.com/accounts/answer/1066447",
            },
            LastPass: {
                enabled: true,
                name: "LastPass Authenticator",
                link: "https://lastpass.com/auth",
            },
            Microsoft: {
                enabled: true,
                name: "Microsoft Authenticator",
                link:
                    "https://docs.microsoft.com/en-us/azure/multi-factor-authentication/end-user/microsoft-authenticator-app-how-to",
            },
        },
    };

    private renderSteps: { [key: string]: () => JSX.Element };
    private keyRef!: HTMLSpanElement;
    private totpDocsLink: string;

    constructor(props: TotpSetupComponentProps) {
        super(props);
        this.state = {
            totp_code: "",
            didCopy: "",
            compact: false,
        };

        this.getStep1Render = this.getStep1Render.bind(this);
        this.getStep2Render = this.getStep2Render.bind(this);
        this.getStep3Render = this.getStep3Render.bind(this);
        this.getStep4Render = this.getStep4Render.bind(this);
        this.getDeactivateRender = this.getDeactivateRender.bind(this);
        this.handleStepSubmit = this.handleStepSubmit.bind(this);
        this.getGenerateRecoveryRender = this.getGenerateRecoveryRender.bind(this);
        this.handleCopy = this.handleCopy.bind(this);
        this.setKeyRef = this.setKeyRef.bind(this);
        this.selectElementValue = this.selectElementValue.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleViewportSizeChanged = this.handleViewportSizeChanged.bind(this);

        const { config } = this.props;
        this.totpDocsLink = config ? config.docs_base + config.docs_version + Documentation.accountSecurity : "#";

        this.renderSteps = {
            start: this.getStep1Render,
            scan: this.getStep2Render,
            code: this.getStep3Render,
            recovery: this.getStep4Render,
            deactivate: this.getDeactivateRender,
            regenerate: this.getGenerateRecoveryRender,
        };
    }

    handleStepSubmit() {
        this.props.onStepSubmit({ ...this.state });
    }

    getStep1Render() {
        const { errorMessage, strings, actionDisabled, loginFlow } = this.props;

        const recommendedApps = mergeBuiltInListWithConfiguration(
            this.props.recommendedApps,
            this.props.config.totpRecommendedApps
        );

        // Parse list of recommended apps
        const apps = recommendedApps
            .filter((app) => app.enabled)
            .map((app, index) => {
                return (
                    <li key={index}>
                        <a href={app.link} target="_blank" rel="noopener noreferrer">
                            {app.name}
                        </a>
                    </li>
                );
            });

        const loginFlowMessage = <p>{strings.mfaEnforced}</p>;

        return (
            <Form onSubmit={this.handleStepSubmit}>
                <ApiReauthMonitor onSuccess={this.handleStepSubmit} />
                <Text variant={TextVariant.DialogTitle}>{strings.setupStep1Header}</Text>
                <Text>{strings.setupStep1Help}</Text>
                <Text>{loginFlow && loginFlowMessage}</Text>
                <Text>
                    <a target="_blank" rel="noopener noreferrer" href={this.totpDocsLink}>
                        {strings.seeDocs} <Icon name={Icons.OpenExternalLink} />
                    </a>
                </Text>
                <Text variant={TextVariant.ParagraphTitle}>{strings.setupStep1Recommended}</Text>
                <ul>{apps}</ul>
                <ErrorMessage>{errorMessage}</ErrorMessage>
                <DialogButtons
                    confirmTitle={strings.setupStep1Button}
                    canConfirm={!actionDisabled}
                    onCancel={this.props.onCancel}
                />
            </Form>
        );
    }

    getStep2Render() {
        const { errorMessage, strings, totp } = this.props;
        const { secret = "", email = "", issuer = "" } = totp ?? {};

        return (
            <Form onSubmit={this.handleStepSubmit}>
                <ApiReauthMonitor onSuccess={this.handleStepSubmit} />
                <Text variant={TextVariant.DialogTitle}>{strings.setupStep2Header}</Text>
                <Text>{strings.setupStep2Help}</Text>
                <QRCode secret={secret} email={email} issuer={issuer} />
                <Text>{strings.step2Alternative}</Text>
                <Text variant={TextVariant.Code}>{secret}</Text>
                <ErrorMessage>{errorMessage}</ErrorMessage>
                <DialogButtons confirmTitle={strings.setupStep2Button} onCancel={this.props.onCancel} />
            </Form>
        );
    }

    getStep3Render() {
        const { errorMessage, strings } = this.props;
        const handleTotpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({ totp_code: event.target.value });
        };

        return (
            <Form onSubmit={this.handleStepSubmit}>
                <ApiReauthMonitor onSuccess={this.handleStepSubmit} />
                <Text variant={TextVariant.DialogTitle}>{strings.setupStep3Header}</Text>
                <Text>{strings.setupStep3Help}</Text>
                <TextBox autoFocus value={this.state.totp_code} maxLength={10} onChange={handleTotpChange} required />
                <ErrorMessage>{errorMessage}</ErrorMessage>
                <DialogButtons confirmTitle={strings.setupStep1Button} onCancel={this.props.onCancel} />
            </Form>
        );
    }

    private handleViewportSizeChanged(matches: boolean) {
        this.setState({ compact: matches });
    }

    getStep4Render() {
        const { errorMessage, recovery, strings, totp } = this.props;

        // Get the recovery codes
        const recoveryCodes =
            recovery && recovery.length > 0
                ? recovery.map((code, index) => {
                      return (
                          <Text variant={TextVariant.Code} key={index}>
                              {code}
                          </Text>
                      );
                  })
                : [];
        const text = (
            <span>
                <MediaQuery query={MediaQueries.LargeOrBigger} onChanged={this.handleViewportSizeChanged} />

                <Grid columns={this.state.compact ? 2 : 1} gutter>
                    <Button text={strings.copyButton} icon={Icons.Copy} onClick={this.handleCopy} />
                    <Button text={strings.saveButton} icon={Icons.Download} onClick={this.handleSave} />
                </Grid>

                <Text>{this.state.didCopy}</Text>
                <span ref={this.setKeyRef}>
                    <Paragraph title={strings.setupStep4CodesHeader}>{strings.setupStep4CodesHelp}</Paragraph>
                    <Paragraph>{recoveryCodes}</Paragraph>
                    <Text>* {strings.setupStep4CodeDate.format(moment(Date.now()).format())}</Text>
                    <Text>* {totp?.email}</Text>
                    <Text>* {strings.setupStep4CodeMore.format(totp?.url)}</Text>
                </span>
            </span>
        );

        return (
            <Form onSubmit={this.handleStepSubmit}>
                <ApiReauthMonitor onSuccess={this.handleStepSubmit} />
                <Text variant={TextVariant.DialogTitle}>{strings.setupStep4Header}</Text>
                <Text>{strings.setupStep4Help}</Text>
                <Text>
                    <a target="_blank" rel="noopener noreferrer" href={this.totpDocsLink}>
                        {strings.seeDocs} <Icon name={Icons.OpenExternalLink} />
                    </a>
                </Text>
                <Card>{text}</Card>
                <ErrorMessage>{errorMessage}</ErrorMessage>
                <DialogButtons confirmTitle={strings.setupStep4Button} hideCancel />
            </Form>
        );
    }

    getDeactivateRender() {
        const { errorMessage, strings, actionDisabled } = this.props;

        return (
            <Form onSubmit={this.handleStepSubmit}>
                <ApiReauthMonitor onSuccess={this.handleStepSubmit} />
                <Text variant={TextVariant.DialogTitle}>{strings.deactivateHeader}</Text>
                <Text>{strings.setupStep1Help}</Text>
                <Text>
                    <a target="_blank" rel="noopener noreferrer" href={this.totpDocsLink}>
                        {strings.seeDocs} <Icon name={Icons.OpenExternalLink} />
                    </a>
                </Text>
                <ErrorMessage>{errorMessage}</ErrorMessage>
                <DialogButtons
                    critical
                    confirmTitle={strings.deactivateButton}
                    canConfirm={!actionDisabled}
                    onCancel={this.props.onCancel}
                />
            </Form>
        );
    }

    getGenerateRecoveryRender() {
        const { errorMessage, strings, actionDisabled } = this.props;

        return (
            <Form onSubmit={this.handleStepSubmit}>
                <ApiReauthMonitor onSuccess={this.handleStepSubmit} />
                <Text variant={TextVariant.DialogTitle}>{strings.generateCodesHeader}</Text>
                <Text>{strings.setupStep4Help}</Text>
                <Text>
                    <a target="_blank" rel="noopener noreferrer" href={this.totpDocsLink}>
                        {strings.seeDocs} <Icon name={Icons.OpenExternalLink} />
                    </a>
                </Text>
                <Text>{strings.generateCodesHelp}</Text>
                <ErrorMessage>{errorMessage}</ErrorMessage>
                <DialogButtons
                    confirmTitle={strings.generateCodesButton}
                    canConfirm={!actionDisabled}
                    onCancel={this.props.onCancel}
                />
            </Form>
        );
    }

    handleCopy() {
        this.selectElementValue(this.keyRef);
        const success = document.execCommand("copy");
        this.setState({
            didCopy: success ? this.props.strings.copySuccess : this.props.strings.copyFailed,
        });
    }

    setKeyRef(ref: HTMLSpanElement) {
        this.keyRef = ref;
    }

    // .select() just woks on textarea and input elements
    selectElementValue(el: Node) {
        const range = document.createRange();
        range.selectNodeContents(el);
        const sel: Selection | null = window.getSelection();
        sel?.removeAllRanges();
        sel?.addRange(range);
    }

    handleSave() {
        const { strings, totp } = this.props;
        const text = this.keyRef?.innerText;
        const filename = strings.fileName.format(totp?.host, totp?.email);
        const blob = new Blob([text], { type: "text/plain;charset=utf-8" });
        FileSaver.saveAs(blob, filename, true);
    }

    render() {
        const { step } = this.props;

        const stepRender = Object.keys(this.renderSteps).find((key) => key === step) ? this.renderSteps[step]() : "";

        return <React.Fragment>{stepRender}</React.Fragment>;
    }
}

export default translate("TotpSetupComponent")(TotpSetupComponent);
