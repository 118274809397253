import { GroupPanel, Paragraph } from "portal-components";
import React from "react";
import Markup from "../../controls/markup";
import translate from "../../i18n/translate";

const defaultStrings = {
    note: "For technical support, contact your Izuma Device Management reseller.",
    supportContact: "Support Contact",
    supportEmail: "Support Email",
    emailSupportLink: "You can request support via [{0}](mailto:{0})",
};

export interface SubtenantProps {
    strings: typeof defaultStrings;
    parentAccount: { admin_name: string; admin_email: string };
}

export const Subtenant: React.FunctionComponent<SubtenantProps> = (props) => {
    const { parentAccount, strings } = props;
    return (
        <GroupPanel>
            <Paragraph title={strings.supportContact}>{parentAccount.admin_name}</Paragraph>
            <Paragraph title={strings.supportEmail}>
                <Markup string={strings.emailSupportLink.format(parentAccount.admin_email)} />
            </Paragraph>
        </GroupPanel>
    );
};

Subtenant.defaultProps = {
    strings: defaultStrings,
};

export default translate("Subtenant")(Subtenant);
