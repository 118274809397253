import { makeConfigApiCreator } from "../../../../creators";
import { UpdateCampaign } from "../detail/types";
import history from "../../../../script/history";

const { reducer, createThunk: createCampaignThunk, createResetAction } = makeConfigApiCreator<UpdateCampaign>(
    "v3_base"
)("update_create");

const { reducer: cloneReducer, createThunk: cloneCampaignThunk } = makeConfigApiCreator<UpdateCampaign>("v3_base")(
    "update_clone"
);

const postAction = (data: UpdateCampaign) =>
    createCampaignThunk({
        param: "update-campaigns/",
        data,
        method: "POST",
        errorCallout: false,
    });

const putAction = (campaignId: string, data: UpdateCampaign) =>
    createCampaignThunk({
        param: `update-campaigns/${campaignId}/`,
        data,
        method: "PUT",
        errorCallout: false,
    });

const cloneAction = (campaignId: string) =>
    cloneCampaignThunk({
        param: `update-campaigns/${campaignId}/clone`,
        data: {},
        method: "POST",
        errorCallout: true,
        errorRedirect: () => history.push("/firmware/deployments/list"),
    });

const reset = createResetAction();

export { reset, postAction, putAction, reducer, cloneAction, cloneReducer };
