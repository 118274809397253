import React from "react";
import translate, { DefaultStrings } from "../i18n/translate";
import { loadCookie, saveCookie } from "../script/utility";
import Markup from "./markup";

const defaultStrings = {
    dismiss: "Accept and dismiss",
    header: "Important Information",
    message:
        "This site uses cookies. By continuing to use our site, you consent to our Cookie Policy, please review our [Cookie Policy]({0}) to learn more about our use of cookies and to learn how they can be disabled. By disabling cookies, some features of the site will not work.",
};

export interface CookieConsentProps {
    strings: DefaultStrings<typeof defaultStrings>;
    type?: "floating" | "text" | "callout";
    buttonType?: "button" | "click";
    className?: string;
    link?: string;
}

interface State {
    show: boolean;
}

export class CookieConsent extends React.Component<CookieConsentProps, State> {
    public static readonly defaultProps: Partial<CookieConsentProps> = {
        buttonType: "button",
        className: "cookieconsent-container callout secondary",
        link: "https://www.arm.com/company/policies/cookies",
        strings: defaultStrings,
        type: "floating",
    };

    constructor(props: CookieConsentProps) {
        super(props);
        this.state = {
            show: !loadCookie("cookieconsent"),
        };
        this.handleAccept = this.handleAccept.bind(this);
    }

    handleAccept(e: React.MouseEvent<HTMLElement>) {
        e.preventDefault();
        saveCookie("cookieconsent", true);
        this.setState({ show: false });
    }

    render() {
        const { buttonType, className, link, strings, type } = this.props;
        const { dismiss, header, message } = strings;
        const { show } = this.state;

        if (!show && type !== "text") {
            return false;
        }

        const button =
            type === "text" ? null : buttonType === "button" ? (
                <button className="button expanded" onClick={this.handleAccept}>
                    {dismiss}
                </button>
            ) : (
                <a onClick={this.handleAccept}>{dismiss}</a>
            );

        const headerEl =
            type !== "text" ? (
                <p>
                    <strong>{header}</strong>
                </p>
            ) : null;

        const content = (
            <div id="cookieconsent" className={className}>
                {headerEl}
                <Markup string={message.format(link)} />
                {button}
            </div>
        );

        const wrapper =
            type !== "floating" ? (
                content
            ) : (
                <div id="cookieconsent-wrapper" className="cookieconsent-wrapper columns small-12">
                    {content}
                </div>
            );

        return wrapper;
    }
}

export default translate("CookieConsent")(CookieConsent);
