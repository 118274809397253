import React from "react";
import { connect } from "react-redux";
import { onStateChange } from "../creators/AjaxCreator";
import { AppDispatch, AppState } from "../creators/reducers";
import { AjaxState, LoadingState, FailedState, SuccessState } from "../creators/AjaxCreator";

export interface ApiRequestStateMonitorProps {
    dispatch: AppDispatch;
    item?: AjaxState<any> | Record<string, AjaxState<any>>;
    id?: string;
    storePropertyName: string;
    onBeginRequest?: () => ArrayLike<any>;
    onStateChanged?: (requestState: string) => void;
    onLoading?: (state: LoadingState) => void;
    onLoaded?: (state: SuccessState<any>) => void;
    onFailed?: (state: FailedState) => void;
}

export class ApiRequestStateMonitor extends React.PureComponent<ApiRequestStateMonitorProps> {
    componentDidMount() {
        const { dispatch, onBeginRequest } = this.props;
        if (onBeginRequest) {
            const payloadsToDispatch = onBeginRequest();
            if (Array.isArray(payloadsToDispatch)) {
                payloadsToDispatch.forEach((payload) => dispatch(payload));
            }
        }
    }

    componentDidUpdate(prevProps: Partial<ApiRequestStateMonitorProps>) {
        const previous = this.getValue(prevProps);
        const current = this.getValue(this.props);

        onStateChange(previous, current, {
            loading: () => this.tryCall(this.props.onLoading, current),
            loaded: () => this.tryCall(this.props.onLoaded, current),
            failed: () => this.tryCall(this.props.onFailed, current),
        });
    }

    getValue(props: Partial<ApiRequestStateMonitorProps>) {
        if (!props.item) {
            return undefined;
        }

        const { id } = this.props;
        return id ? (props.item as any)[id] : props.item;
    }

    tryCall(functionToCall: ((state: any) => void) | undefined, value: AjaxState<any>) {
        functionToCall?.(value);
        this.props.onStateChanged?.(value.requestState);
    }

    render() {
        return null;
    }
}

export function mapStateToProps(state: AppState, ownProps: Partial<ApiRequestStateMonitorProps>) {
    return {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        item: (state as any)[ownProps.storePropertyName!],
    };
}

export default connect(mapStateToProps)(ApiRequestStateMonitor);
