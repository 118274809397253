import { makeCollectionCreator, makeConfigApiCreator } from "../../../../creators";
import { ParseFunction } from "../../../../creators/AjaxCreator";
import history from "../../../../script/history";

interface DeviceGroup {
    id: string;
    created_at?: string;
    custom_attributes?: {
        [key: string]: string;
    };
    description?: string;
    devices_count?: number;
    name?: string;
    updated_at?: string;
}

const makeDeviceGroupCreator = <T>(type: string, parseFunction?: ParseFunction<T>) =>
    makeConfigApiCreator<T>("v3_base", {
        errorRedirect: () => history.push("/devices/list"),
        errorCallout: true,
    })(type, parseFunction);

const { createThunk: createDeviceGroupsThunk, reducer: deviceGroupReducer } = makeCollectionCreator(
    makeDeviceGroupCreator<DeviceGroup>("device_group")
);
const getDeviceGroup = (id: string, groupId: string) =>
    createDeviceGroupsThunk({
        id,
        param: `device-groups/${groupId}`,
        method: "GET",
        errorRedirect: false as any, // To work around the js creator "typing" till we sort collection creator
    });

const { createThunk: groupThunk, reducer: editDeviceGroupReducer } = makeDeviceGroupCreator<DeviceGroup>(
    "edit_device_groups"
);

const createDeviceGroup = (data: DeviceGroup) => {
    return groupThunk({
        param: "device-groups/",
        data,
        method: "POST",
        errorCallout: false,
    });
};

const modifyDeviceGroup = (groupId: string, data: DeviceGroup) => {
    return groupThunk({
        param: `device-groups/${groupId}/`,
        data,
        method: "PUT",
    });
};

const { createThunk: createAddDeviceToGroupThunk, reducer: addDeviceToGroupReducer } = makeCollectionCreator(
    makeDeviceGroupCreator<void>("add_devices_to_device_group")
);
const addDeviceToGroup = (operationId: string, groupId: string, deviceId: string) =>
    createAddDeviceToGroupThunk({
        id: operationId,
        param: `device-groups/${groupId}/devices/add`,
        data: { device_id: deviceId },
        method: "POST",
    });

const { createThunk: createRemoveDeviceFromGroupThunk, reducer: removeDeviceFromGroupReducer } = makeCollectionCreator(
    makeDeviceGroupCreator<void>("remove_devices_from_device_group")
);
const removeDeviceFromGroup = (operationId: string, groupId: string, deviceId: string) =>
    createRemoveDeviceFromGroupThunk({
        id: operationId,
        param: `device-groups/${groupId}/devices/remove`,
        data: { device_id: deviceId },
        method: "POST",
    });

export {
    DeviceGroup,
    getDeviceGroup,
    deviceGroupReducer,
    createDeviceGroup,
    modifyDeviceGroup,
    addDeviceToGroup,
    removeDeviceFromGroup,
    editDeviceGroupReducer,
    addDeviceToGroupReducer,
    removeDeviceFromGroupReducer,
};
