import React from "react";
import { RouteComponentProps } from "react-router";
import { PropagatedProps } from "../layout/main/component";
import ErrorBoundary from "../layout/errorBoundary";

export const asyncRender = (props: PropagatedProps) => {
    return (Component: React.ComponentType<PropagatedProps>, title: string, otherProps = {}) => (
        rProps: RouteComponentProps
    ) => (
        <ErrorBoundary>
            <Component {...rProps} {...props} title={title} {...otherProps} />
        </ErrorBoundary>
    );
};

export default asyncRender;
