import { ButtonVariant, Dialog, DialogSize, StackPanel } from "portal-components";
import React from "react";
import { connect } from "react-redux";
import { Action, bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { Config } from "../../typings/interfaces";
import { AjaxState, isLoading, onStateChange } from "../../creators/AjaxCreator";
import translate from "../../i18n/translate";
import { AppState } from "../../creators/reducers";
import { reloadPage } from "../../script/utility";
import { getVersion, Version } from "./creator";

const POLLING_INTERVAL = 1000 * 60 * 5; // 5 Minutes

export const defaultStrings = {
    newVersionAvailable: "A new version of Portal is available",
    refreshAction: "REFRESH NOW",
    refreshLater: "REMIND ME LATER",
};

export interface VersionCheckerProps {
    strings: typeof defaultStrings;
    versionChecker: AjaxState<Version>;
    config: Config;
    actions: {
        getVersion: typeof getVersion;
    };
}

interface State {
    intervalObj: any;
    newVersion: boolean;
    data: Version;
}

export class VersionChecker extends React.PureComponent<VersionCheckerProps, State> {
    public static defaultProps = {
        strings: defaultStrings,
    };

    constructor(props: VersionCheckerProps) {
        super(props);
        this.state = {
            intervalObj: null,
            newVersion: false,
            data: {} as Version,
        };
        this.clearTimer = this.clearTimer.bind(this);
        this.getVersion = this.getVersion.bind(this);
        this.getVersionAfterDelay = this.getVersionAfterDelay.bind(this);
    }

    componentDidMount() {
        if (!isLoading(this.props.versionChecker)) {
            this.getVersion();
        }
    }

    componentWillUnmount() {
        this.clearTimer();
    }

    componentDidUpdate(prevProps: VersionCheckerProps) {
        const { versionChecker } = this.props;
        const { tag } = this.state.data;

        onStateChange(prevProps.versionChecker, versionChecker, {
            loaded: ({ data }) => {
                this.clearTimer();
                const newVersion = tag !== undefined && tag !== data.tag;
                this.setState({
                    data: tag ? this.state.data : data,
                    newVersion,
                });
                !newVersion && this.getVersionAfterDelay();
            },
            failed: () => {
                this.getVersionAfterDelay();
            },
        });
    }

    getVersionAfterDelay(interval = POLLING_INTERVAL) {
        const intervalObj = setInterval(this.getVersion, interval);
        this.setState({ intervalObj });
    }

    getVersion() {
        this.clearTimer();
        this.props.actions.getVersion();
    }

    clearTimer() {
        if (this.state.intervalObj !== null) {
            clearInterval(this.state.intervalObj);
        }
    }

    render() {
        const { newVersion } = this.state;
        const { strings } = this.props;
        const handleDismiss = () => {
            this.clearTimer();
            this.getVersionAfterDelay(POLLING_INTERVAL * 12); // 5 minutes * 12 = 60 minutes
            this.setState({ newVersion: false });
        };

        if (newVersion) {
            return (
                <Dialog
                    autoclose={false}
                    open
                    buttons={[
                        { text: strings.refreshAction, primary: true, onClick: reloadPage },
                        { text: strings.refreshLater, onClick: handleDismiss, variant: ButtonVariant.Light },
                    ]}
                    size={DialogSize.Tiny}
                >
                    <StackPanel gutter>{strings.newVersionAvailable}</StackPanel>
                </Dialog>
            );
        }
        return null;
    }
}

// map Redux store state to React props
const mapStateToProps = ({ config, versionChecker }: AppState) => {
    return { config, versionChecker };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, never, Action>) => {
    return {
        actions: bindActionCreators({ getVersion }, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(translate("VersionChecker")(VersionChecker));
