import { makeConfigApiCreator } from "../../../creators";
import { ListResponse } from "../../../script";

export interface TrustAnchor {
    description: string;
    public_key: string;
    public_key_der: string;
    fingerprint: string;
}

// Get trust anchors
const { reducer, createThunk: getActionThunk } = makeConfigApiCreator<ListResponse<TrustAnchor>>("v3_base")(
    "trust_anchors"
);
const getAction = getActionThunk({ param: "trust-anchors" });

// Creeate trust anchor
const { reducer: generateReducer, createThunk: generateCreatorThunk } = makeConfigApiCreator<TrustAnchor>("v3_base")(
    "trust_anchors_generate"
);
const generateAction = (description = "Secure device access trust anchor") =>
    generateCreatorThunk({
        param: "trust-anchors",
        method: "POST",
        data: { description },
        errorCallout: true,
    });

export { getAction, reducer, generateAction, generateReducer };
