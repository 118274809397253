import { Config, ConnectApi, LwM2MResourceInfo, SDKError, TlvDataType, TlvParser } from "mbed-cloud-sdk";
import { NotificationObject } from "mbed-cloud-sdk/types/legacy/connect/types";
import { makeConfigApiCreator, ParseFunction, sdkCreator } from "../../../../../creators";

export interface Resource {
    description?: string;
    itemID?: string;
    mandatory?: string;
    multipleInstances?: string;
    name?: string;
    rangeEnumeration?: any;
    type?: string;
}

export enum DeviceCapabilityList {
    terminal = "terminal",
    log = "log",
    kass = "kaas",
    stats = "stats",
    deviceJs = "deviceJS",
    deviceDb = "deviceDb",
    alerts = "alerts",
}

export interface DeviceCapabilities {
    terminal?: boolean;
    log?: boolean;
    kaas?: boolean;
    stats?: boolean;
    deviceJS?: boolean;
    deviceDb?: boolean;
    alerts?: boolean;
}

const makeConnectorCreator = <A, D = undefined>(type: string, parseFunction?: ParseFunction<A, D>) =>
    makeConfigApiCreator<A, D>("v2_base", { errorCallout: false })(type, parseFunction);

const getConnectConfig = (sdkConfig: Config) => {
    return { ...sdkConfig, autostartNotifications: false, apiKey: sdkConfig.apiKey };
};

let connectInstance: ConnectApi;

const getConnectInstance = (sdkConfig: Config) => {
    connectInstance = new ConnectApi(getConnectConfig(sdkConfig));
    return connectInstance;
};

const getTlvInstance = (object: LwM2MResourceInfo) => {
    return new TlvParser("", object);
};

export const notify = (data: NotificationObject) => {
    connectInstance && connectInstance.notify(data);
};

const {
    reducer: resourceObserverSubscriptionReducer,
    createThunk: createSubscribeThunk,
    createResetAction: resetSubscriptionThunk,
} = makeConnectorCreator<unknown>("resource_observer_subscribe");
const resetSubscription = resetSubscriptionThunk();

const subscribeAction = (endpoint: string, path: string) =>
    createSubscribeThunk({
        param: "subscriptions/" + endpoint + path,
        method: "PUT",
    });

const { createThunk: createUnSubscribeThunk } = makeConnectorCreator<unknown>("resource_observer_unsubscribe");

const unSubscribeAction = (endpoint: string, path: string) =>
    createUnSubscribeThunk({
        param: "subscriptions/" + endpoint + path,
        method: "DELETE",
    });

const {
    reducer: resourceValueReducer,
    createThunk: getResourceValueThunk,
    createResetAction: resetResourceValueThunk,
} = sdkCreator("GET_RESOURCE_VALUE", {
    sdkMethod: (
        {
            deviceId,
            path,
            resource,
            object,
        }: { deviceId: string; path: string; resource: Resource; object: LwM2MResourceInfo },
        sdkConfig: Config
    ) => {
        return getConnectInstance(sdkConfig).getResourceValue(
            deviceId,
            path,
            undefined,
            undefined,
            { path, type: (TlvDataType as any)[resource.type as string] },
            getTlvInstance(object)
        );
    },
});

export const FeatureManagementResourcePath = "/33457";

const getResourceValue = (
    {
        deviceId,
        path,
        resource,
        object,
    }: { deviceId: string; path: string; resource: Resource; object: LwM2MResourceInfo },
    errorAction?: (error?: SDKError) => void
) => getResourceValueThunk({ deviceId, path, resource, object }, { errorAction, errorRedirect: false });
const resetResourceValue = resetResourceValueThunk();

const {
    reducer: setResourceValueReducer,
    createThunk: setResourceValueThunk,
    createResetAction: resetSetResourceValueThunk,
} = sdkCreator("SET_RESOURCE_VALUE", {
    sdkMethod: ({ deviceId, path, data }: { deviceId: string; path: string; data: any }, sdkConfig: Config) => {
        return getConnectInstance(sdkConfig).setResourceValue(deviceId, path, data);
    },
});
const setResourceValue = (
    { deviceId, path, data }: { deviceId: string; path: string; data: any },
    errorAction?: (error?: SDKError) => void
) => setResourceValueThunk({ deviceId, path, data }, { errorAction, errorRedirect: false });
const resetSetResourceValue = resetSetResourceValueThunk();

const { reducer: executeResourceReducer, createThunk: executeResourceThunk } = sdkCreator("EXECUTE_RESOURCE_VALUE", {
    sdkMethod: ({ deviceId, path, data }: { deviceId: string; path: string; data: any }, sdkConfig: Config) => {
        return getConnectInstance(sdkConfig).executeResource(deviceId, path, data);
    },
});
const executeResource = (
    { deviceId, path, data }: { deviceId: string; path: string; data: any },
    errorAction?: (error?: SDKError) => void
) => executeResourceThunk({ deviceId, path, data }, { errorAction, errorRedirect: false });

const { reducer: resourceObserverInitReducer, createThunk: createResourceInitThunk } = makeConnectorCreator<unknown>(
    "resource_observer_init"
);

const initAction = (endpoint: string, path: string) =>
    createResourceInitThunk({
        param: "endpoints/" + endpoint + path,
    });

const { reducer: resourceObserverPollerReducer, createThunk: createPollThunk } = makeConnectorCreator<unknown>(
    "resource_observer_poll",
    (data: any) => data
);

const pollingAction = () =>
    createPollThunk({
        param: "notification/pull",
        errorCallout: false,
    });

type WebSocketConnectionStatus = "connected" | "disconnected";

interface WebSocketChannel {
    channel_id: string;
    status: WebSocketConnectionStatus;
}

const { reducer: registerWebSocketReducer, createThunk: createRegisterWebSocketThunk } = makeConnectorCreator<
    WebSocketChannel
>("resource_observer_register_websocket");

const registerWebSocketAction = () =>
    createRegisterWebSocketThunk({
        param: "notification/websocket",
        method: "PUT",
    });

const { reducer: webSocketReducer, createThunk: createGetWebSocketThunk } = makeConnectorCreator<WebSocketChannel>(
    "resource_observer_get_websocket"
);

const getWebSocketAction = () =>
    createGetWebSocketThunk({
        param: "notification/websocket",
        errorRedirect: false,
    });

const { reducer: deleteWebSocketReducer, createThunk: createDeleteWebSocketThunk } = makeConnectorCreator<
    WebSocketChannel
>("resource_observer_delete_websocket");

const deleteWebSocketAction = () =>
    createDeleteWebSocketThunk({
        param: "notification/websocket",
        method: "DELETE",
        errorRedirect: false,
    });

export {
    pollingAction,
    resourceObserverPollerReducer,
    subscribeAction,
    resetSubscription,
    resourceObserverSubscriptionReducer,
    getResourceValue,
    resourceValueReducer,
    resetResourceValue,
    setResourceValue,
    setResourceValueReducer,
    resetSetResourceValue,
    executeResource,
    executeResourceReducer,
    unSubscribeAction,
    initAction,
    resourceObserverInitReducer,
    WebSocketConnectionStatus,
    WebSocketChannel,
    registerWebSocketAction,
    registerWebSocketReducer,
    getWebSocketAction,
    webSocketReducer,
    deleteWebSocketAction,
    deleteWebSocketReducer,
};
