import { Button, ButtonVariant, Else, Icon, Icons, IconSize, If } from "portal-components";
import React from "react";
import { ReflexContainer, ReflexElement, ReflexHandle, ReflexSplitter } from "react-reflex";
import translate, { DefaultStrings } from "../i18n/translate";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const classes = require("./withReflexContainer.scss");

const VisibilityTreshold = 0.1;
const ConsoleExpandedHeight = 0.3;
const storageName = "developer-console-height";

const defaultStrings = {
    expand: "Expand",
    minimize: "Minimize",
};

export interface WithReflexContainerProps {
    compact?: boolean;
    customHandleLabel?: string;
    drawerChildren?: React.ReactNode;
    enable?: boolean;
    strings: DefaultStrings<typeof defaultStrings>;
    useCustomHandle?: boolean;
}

export interface WithReflexContainerState {
    bottomChildHeight: number; // as a flex value 0 to 1
}

export class WithReflexContainer extends React.PureComponent<WithReflexContainerProps, WithReflexContainerState> {
    public static readonly defaultProps = {
        compact: true,
        customHandleLabel: "",
        drawerChildren: null,
        enable: true,
        strings: defaultStrings,
        useCustomHandle: true,
    };

    constructor(props: WithReflexContainerProps) {
        super(props);
        this.state = {
            bottomChildHeight: 0,
        };

        this.handleResize = this.handleResize.bind(this);
        this.setHeight = this.setHeight.bind(this);
    }

    UNSAFE_componentWillMount() {
        const bottomChildHeight = parseFloat(localStorage.getItem(storageName) || "0") || 0;
        this.setState({ bottomChildHeight });
    }

    handleResize(event: { domElement: string; component: React.Component<{ flex: number }> }) {
        const {
            component: {
                props: { flex },
            },
        } = event;
        this.setHeight(flex);
    }

    setHeight(bottomChildHeight: number) {
        this.setState({ bottomChildHeight });
        localStorage.setItem(storageName, bottomChildHeight.toString());
    }

    render() {
        const { children, drawerChildren, enable, compact, useCustomHandle, customHandleLabel, strings } = this.props;
        const { bottomChildHeight } = this.state;
        const drawerContent = useCustomHandle ? (
            <ReflexElement
                flex={bottomChildHeight}
                minSize={compact ? 44 : 22}
                className="reflex-drawer"
                onResize={this.handleResize}
            >
                <ReflexHandle>
                    <If condition={bottomChildHeight > VisibilityTreshold}>
                        <div className="reflex-handle-label">
                            <h4>{customHandleLabel}</h4>
                        </div>
                    </If>
                    <Icon name={Icons.ResizeHandle} size={IconSize.Smaller} className="reflex-handle-icon" />
                    <div className="reflex-handle-actions">
                        <Button
                            icon={Icons.ResizeMinimize}
                            variant={ButtonVariant.Inline}
                            onClick={() => this.setHeight(0)}
                            tooltip={strings.minimize}
                            when={bottomChildHeight > VisibilityTreshold}
                        />
                        <Button
                            icon={Icons.ResizeExpand}
                            variant={ButtonVariant.Inline}
                            onClick={() => this.setHeight(ConsoleExpandedHeight)}
                            tooltip={strings.expand}
                        />
                    </div>
                </ReflexHandle>
                {drawerChildren}
            </ReflexElement>
        ) : (
            <ReflexElement>{drawerChildren}</ReflexElement>
        );

        return (
            <If condition={enable && !!drawerChildren}>
                <ReflexContainer orientation="horizontal" className={classes.reflexContainer}>
                    <ReflexElement minSize={150}>{children}</ReflexElement>
                    <ReflexSplitter className={useCustomHandle ? "hide" : ""} />
                    {drawerContent}
                </ReflexContainer>
                <Else>{children}</Else>
            </If>
        );
    }
}

export default translate("WithReflexContainer")(WithReflexContainer);
