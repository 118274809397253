import { SwitchButton, ValueEvent } from "portal-components";
import React from "react";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const classes = require("./style.scss");

export interface SwitchFilterProps {
    id?: string;
    label: string;
    value: boolean;
    onChange: (event: ValueEvent<boolean>) => void;
}

export const SwitchFilter: React.FunctionComponent<SwitchFilterProps> = (props) => (
    <SwitchButton id={props.id} className={classes.switchFilter} value={props.value} onChange={props.onChange}>
        <span className="switch-filter-label">{props.label}</span>
    </SwitchButton>
);
