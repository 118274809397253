import { Icon, Icons, Span, SpanCopyMode } from "portal-components";
import React from "react";
import translate, { DefaultStrings } from "../i18n/translate";
import { getColors } from "../script/branding";
import { CellContext } from "./grid/cellContext";

const defaultStrings = {
    ALERT: "ALERT",
    CRITICAL: "CRITICAL",
    DEBUG: "DEBUG",
    ERROR: "ERROR",
    FAIL: "FAIL",
    INFO: "INFO",
    OK: "OK",
    PENDING: "PENDING",
    SKIPPED: "SKIPPED",
    SUCCESS: "SUCCESS",
    TRACE: "TRACE",
    WARN: "WARN",
    WARNING: "WARNING",
};

export interface LogLevelComponentProps {
    strings: DefaultStrings<typeof defaultStrings>;
    level: string;
    truncated?: boolean;
}

export class LogLevelComponent extends React.PureComponent<LogLevelComponentProps> {
    public static readonly levels = {
        ALERT: { color: "red", icon: Icons.ErrorMark },
        CRITICAL: { color: "red", icon: Icons.ErrorMark },
        INFO: { color: "blue", icon: Icons.MoreInformationSymbol },
        TRACE: { color: "", icon: [Icons.ConsoleMaximize, Icons.ConsoleTerminal] },
        WARN: { color: "yellow", icon: Icons.WarningSymbol },
        WARNING: { color: "yellow", icon: Icons.WarningSymbol },
        DEBUG: { color: "", icon: [Icons.ConsoleMaximize, Icons.ConsoleTerminal] },
        ERROR: { color: "red", icon: Icons.ErrorMark },
        FAIL: { color: "red", icon: Icons.ErrorMark },
        OK: { color: "green", icon: Icons.SuccessMark },
        PENDING: { color: "yellow", icon: Icons.ErrorMark },
        SKIPPED: { color: "red", icon: Icons.ErrorMark },
        SUCCESS: { color: "green", icon: Icons.SuccessMark },
    };

    public static readonly defaultProps: Partial<LogLevelComponentProps> = {
        truncated: true,
        strings: defaultStrings,
    };

    render() {
        const { level = "", strings, truncated } = this.props;
        const upperLevel = typeof level === "string" ? level.toUpperCase() : "";
        const { color = undefined, icon = undefined } =
            upperLevel && upperLevel in LogLevelComponent.levels ? (LogLevelComponent.levels as any)[upperLevel] : {};
        const colors = getColors();
        const iconColor = color in colors ? colors[color] : null;

        const iconElement = !icon ? null : Array.isArray(icon) ? (
            <span className="fa-layers fa-fw">
                <Icon name={icon[0]} />
                <Icon name={icon[1]} transform="shrink-10" />
            </span>
        ) : (
            <Icon name={icon} />
        );

        const alternativeText = truncated ? <Span text={level} copiable={SpanCopyMode.None} /> : level;

        const content = (
            <span style={truncated ? { whiteSpace: "nowrap" } : {}}>
                {icon ? (
                    <span style={{ color: iconColor ?? undefined }}>
                        {iconElement}
                        &nbsp;
                    </span>
                ) : null}
                {strings[upperLevel] || alternativeText}
            </span>
        );

        return (
            <CellContext.Consumer>
                {(context) => {
                    if (context && context.key && context.row) {
                        context.row[context.key] = strings[upperLevel] || level;
                    }

                    return content;
                }}
            </CellContext.Consumer>
        );
    }
}

export default translate("LogLevelComponent")(LogLevelComponent);
