import { makeConfigApiCreator } from "../../../../creators";
import { ListResponse } from "../../../../script";
import { Device } from "../../../devices";

// Enum type for the different types of policies we distinguish
const types = {
    SDA: "SECURE DEVICE ACCESS",
};

export interface AccessPolicy {
    account_id: string;
    name: string;
    description: string;
    status: string; // enum INACTIVE
    default: boolean;
    principals: string[];
    resources: string[];
    not_resources: string[];
    target: { resources: { type: string; id: string; attributes: any }; not_resources: {} };
    actions: { [index: string]: boolean };
    not_actions: unknown[];
    conditions: unknown[];
    not_conditions: unknown[];
    context: unknown; // { conditions: []; not_conditions: [] };
    users: string[];
    apikeys: string[];
    groups: string[];
    error_message: string;
    grant_expires_in: number;
    valid_until: number;
    valid_from: number;
    mismatch_verdict: string;
    id: string;
    created_at: Date;
    updated_at: Date;
    tag: string;
}

// Get devices filtered by policy rules
const { reducer: getReducer, createThunk: getDevicesThunk } = makeConfigApiCreator<ListResponse<Device>>("v3_base")(
    "policy_devices",
    (response: any) => {
        response.data = response.data.map((device: Device, index: number) => {
            return { ...device, index, selected: false };
        });
        return response;
    }
);

const getDevices = ({ pageSize = 1000, order = "DESC", query = "", errorAction = undefined, errorCallout = false }) =>
    getDevicesThunk({
        param: "devices",
        method: "GET",
        queryParameters: {
            order,
            limit: `${pageSize}`,
            include: "total_count",
            filter: query,
        },
        errorAction,
        errorCallout,
    });

// Policy creation
const { reducer: createReducer, createThunk: createPolicyThunk } = makeConfigApiCreator<AccessPolicy>("v3_base")(
    "policy_new"
);

const createPolicy = (data: Partial<AccessPolicy>) =>
    createPolicyThunk({
        param: "policies",
        data,
        method: "POST",
        errorCallout: false,
    });

// Device policy update
const { reducer: updateReducer, createThunk: updatePolicyThunk } = makeConfigApiCreator<AccessPolicy>("v3_base")(
    "policy_update"
);

const updatePolicy = (id: string, data: Partial<AccessPolicy>) =>
    updatePolicyThunk({
        param: `policies/${id}`,
        data,
        method: "PUT",
    });

// Policy delete
const { reducer: deleteReducer, createThunk: deletePolicyThunk } = makeConfigApiCreator<AccessPolicy>("v3_base")(
    "policy_delete"
);

const deletePolicy = (id: string) => deletePolicyThunk({ param: `policies/${id}`, method: "DELETE" });

export {
    createPolicy,
    createReducer,
    deletePolicy,
    deleteReducer,
    getDevices,
    getReducer,
    types,
    updatePolicy,
    updateReducer,
};
