import { ApplicationContext, LoginService, LOGIN_SERVICE_ID } from "portal-components";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../creators/reducers";

export const AuthChecker: React.FunctionComponent = () => {
    const context = useContext(ApplicationContext);
    const service = context.services.get<LoginService>(LOGIN_SERVICE_ID);
    const auth = useSelector(({ auth }: AppState) => auth);
    const dispatch = useDispatch();

    if (auth.type === "LOGOUT_REQUEST") {
        service.logout();
        dispatch({ type: "LOGOUT_SUCCESS" });
    }

    return null;
};

export default AuthChecker;
