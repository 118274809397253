import { Action, combineReducers, Reducer } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { Config } from "../typings/interfaces";
import { AjaxState } from "./AjaxCreator";
import {
    applications,
    applicationCount,
    deleteapplication,
    deletepolicy,
    groupaddkeys,
    groupaddusers,
    groupremovekeys,
    groupremoveusers,
    newpassword,
    newpolicy,
    policies,
    policyCount,
    policydevices,
    updatepolicy,
} from "../features/access";
import {
    accountReducer as currentaccount,
    updateCurrentAccountReducer as updateaccount,
    resetPasswordReducer as resetpassword,
    servicePackageReducer as servicepackage,
    createTenantAccountReducer as tenantAccountNew,
    updateTenantAccountReducer as tenantAccountUpdate,
    totpGenerateScratchCodesReducer as totpNewCodes,
    totpActivationReducer as totpactivate,
    updateAccountSettingsReducer as updateAccountSettings,
    updateUserSettingsReducer as updateusersettings,
    userReducer as currentuser,
    userTeamsReducer as currentuserteams,
    updateUserReducer as updateuser,
} from "../features/account";
import { reducer as asyncJobs } from "../features/asyncJobList/creator";
import {
    activeDevicesReport,
    billing,
    firmwareUpdatesReport,
    servicePackage,
    servicePackageQuota,
} from "../features/billing";
import { captchaReducer as captcha } from "../features/captcha/creator";
import {
    addDeviceToGroup,
    allResources,
    certificateEnrollments,
    createBulkDeviceEnrollments,
    createBulkDeviceEnrollmentsReport,
    createBulkDeviceEnrollmentsStatus,
    createDeviceEnrollment,
    deleteDevice,
    deleteDeviceGroup,
    deleteEnrollment,
    deleteFilter,
    deleteWebSocket,
    device,
    deviceBlockCategories,
    deviceEnrollments,
    deviceEnrollmentsBulkDelete,
    deviceEnrollmentsBulkDeleteReport,
    deviceEnrollmentsBulkDeleteStatus,
    deviceEvents,
    deviceEventsForDevice,
    deviceGroup,
    deviceGroups,
    lwm2mObject,
    lwm2mDownloadObject,
    deleteLwm2mObject,
    lwm2mObjects,
    lwm2mCustomObjects,
    lwm2mSystemObjects,
    uploadLwm2mObject,
    deviceRenew,
    devices,
    editDeviceGroup,
    executeResource,
    filter,
    gateway,
    gatewayDevices,
    gatewayLogs,
    gateways,
    registerWebsocket,
    removeDeviceFromGroup,
    resourceObserverInit,
    resourceObserverPoller,
    resourceObserverSubscription,
    resourceValue,
    resumeDevice,
    saveFilter,
    setResourceValue,
    suspendDevice,
    updateDevice,
    updateFilter,
    updateGateway,
    resetdevicesession,
    websocket,
} from "../features/devices";
import { connectorstatistics, dashboardDevices } from "../features/dashboard";
import {
    firmwareImageDelete,
    firmwareImageDetail,
    firmwareImages,
    firmwareManifestDelete,
    firmwareManifestDetail,
    firmwareManifests,
    firmwareUploads,
    newFirmwareManifest,
    updateArchive,
    updateCreate,
    updateClone,
    updateDelete,
    updateDetail,
    updateFailedStatistics,
    updateInfoStatistics,
    updateList,
    updateMetaDataDetail,
    updateMetrics,
    updateMesh,
    updateSkippedStatistics,
    updateStart,
    updateStop,
} from "../features/firmware";
import {
    allservercredentials,
    cacertificatedevices,
    certificateDetail,
    certificatesList,
    createCertificate,
    createDeveloperCertificate,
    deleteCertificate,
    deletePSKs,
    developercertificatedetail,
    externalCAConfigurationList,
    externalCAConfigurationSet,
    externalCACreate,
    externalCADelete,
    externalCADetail,
    externalCAList,
    externalCALwm2m,
    externalCALwm2mUpdate,
    externalCAUpdate,
    externalCAVerify,
    factorydownloadinfo,
    factoryreleasenote,
    pskList,
    pskUpload,
    trustAnchors,
    trustAnchorsAction,
    updateCertificate,
    releaseCertificate,
    oemCertificateList,
    removeCertificate,
    unreleaseCertificate,
} from "../features/identity";
import { reducer as factoryMetrics } from "../features/fleet/creator";
import {
    agreements,
    acceptagreement,
    agreementcontent,
    currentTeamSwitch,
    identityProvider,
    inviteAccept,
    initialSamlLogin,
    inviteStatus,
    passwordRecovery,
    startFederatedLogin,
    teamLogin,
    verifyEmail,
} from "../features/login";
import {
    accountBrandingColorsDark,
    accountBrandingColorsLight,
    accountBrandingImagesDark,
    accountBrandingImagesLight,
    brandingColorsDark,
    brandingColorsLight,
    brandingImagesDark,
    brandingImagesLight,
    clearBrandingImageDark,
    clearBrandingImageLight,
    currentAccount,
    hardResetThemes,
    identityProviderCreate,
    identityProviderDelete,
    identityProviderEdit,
    identityProviderRenew,
    identityProviders,
    identityProviderSetStatus,
    leaveTeam,
    tenantAgreements,
    tenantDetails,
    updateAccount,
    updateBrandingColorDark,
    updateBrandingColorLight,
    updateBrandingImageDark,
    updateBrandingImageLight,
    getSubtenantConfiguration,
    editSubtenantConfiguration,
} from "../features/organization";
import { i18nReducer as i18n } from "../i18n/reducer";
import {
    authReducer as auth,
    AuthState,
    calloutReducer as callout,
    configReducer as config,
    pollingReducer as pollingData,
    lwm2mReducer as resourceData,
    versionChecker,
} from "../layout/reducer";
import { mergeDeep } from "../script/utility";

const appInitialState = {
    acceptagreement,
    accountBrandingColorsDark,
    accountBrandingColorsLight,
    accountBrandingImagesDark,
    accountBrandingImagesLight,
    activeDevicesReport,
    addDeviceToGroup,
    agreementcontent,
    agreements,
    allservercredentials,
    applications,
    applicationCount,
    asyncJobs,
    billing,
    brandingColorsDark,
    brandingColorsLight,
    brandingImagesDark,
    brandingImagesLight,
    cacertificatedevices,
    callout,
    captcha,
    certificateDetail,
    certificateEnrollments,
    certificatesList,
    clearBrandingImageDark,
    clearBrandingImageLight,
    connectorstatistics,
    createBulkDeviceEnrollments,
    createBulkDeviceEnrollmentsReport,
    createBulkDeviceEnrollmentsStatus,
    createCertificate,
    createDeveloperCertificate,
    createDeviceEnrollment,
    currentAccount,
    currentTeamSwitch,
    currentaccount,
    currentuser,
    currentuserteams,
    dashboardDevices,
    deleteapplication,
    deleteCertificate,
    deleteDevice,
    deleteDeviceGroup,
    deleteEnrollment,
    deleteFilter,
    deletePSKs,
    deleteWebSocket,
    deletepolicy,
    developercertificatedetail,
    device,
    deviceBlockCategories,
    deviceEnrollments,
    deviceEnrollmentsBulkDelete,
    deviceEnrollmentsBulkDeleteReport,
    deviceEnrollmentsBulkDeleteStatus,
    deviceEvents,
    deviceEventsForDevice,
    deviceGroup,
    deviceGroups,
    lwm2mObject,
    lwm2mDownloadObject,
    deleteLwm2mObject,
    lwm2mObjects,
    lwm2mCustomObjects,
    lwm2mSystemObjects,
    uploadLwm2mObject,
    deviceRenew,
    devices,
    allResources,
    editDeviceGroup,
    executeResource,
    externalCAConfigurationList,
    externalCAConfigurationSet,
    externalCACreate,
    externalCADelete,
    externalCADetail,
    externalCAList,
    externalCALwm2m,
    externalCALwm2mUpdate,
    externalCAUpdate,
    externalCAVerify,
    factorydownloadinfo,
    factoryMetrics,
    factoryreleasenote,
    filter,
    firmwareImageDelete,
    firmwareImageDetail,
    firmwareImages,
    firmwareManifestDelete,
    firmwareManifestDetail,
    firmwareManifests,
    firmwareUpdatesReport,
    firmwareUploads,
    gateway,
    gatewayDevices,
    gatewayLogs,
    gateways,
    groupaddkeys,
    groupaddusers,
    groupremovekeys,
    groupremoveusers,
    hardResetThemes,
    i18n,
    identityProvider,
    identityProviderCreate,
    identityProviderDelete,
    identityProviderEdit,
    identityProviderRenew,
    identityProviderSetStatus,
    identityProviders,
    initialSamlLogin,
    inviteAccept,
    inviteStatus,
    leaveTeam,
    newFirmwareManifest,
    newpassword,
    newpolicy,
    passwordRecovery,
    policies,
    policyCount,
    policydevices,
    pskList,
    pskUpload,
    registerWebsocket,
    removeDeviceFromGroup,
    resetpassword,
    resourceObserverInit,
    resourceObserverPoller,
    resourceObserverSubscription,
    resourceValue,
    resumeDevice,
    saveFilter,
    servicePackage,
    servicePackageQuota,
    servicepackage,
    setResourceValue,
    startFederatedLogin,
    suspendDevice,
    teamLogin,
    tenantAccountNew,
    tenantAccountUpdate,
    tenantAgreements,
    tenantDetails,
    totpNewCodes,
    totpactivate,
    trustAnchors,
    trustAnchorsAction,
    updateAccount,
    updateAccountSettings,
    updateArchive,
    updateBrandingColorDark,
    updateBrandingColorLight,
    updateBrandingImageDark,
    updateBrandingImageLight,
    getSubtenantConfiguration,
    editSubtenantConfiguration,
    updateCertificate,
    releaseCertificate,
    removeCertificate,
    oemCertificateList,
    unreleaseCertificate,
    updateCreate,
    updateClone,
    updateDelete,
    updateDetail,
    updateDevice,
    updateFailedStatistics,
    updateFilter,
    updateGateway,
    resetdevicesession,
    updateInfoStatistics,
    updateList,
    updateMetaDataDetail,
    updateMetrics,
    updateMesh,
    updateSkippedStatistics,
    updateStart,
    updateStop,
    updateaccount,
    updatepolicy,
    updateuser,
    updateusersettings,
    verifyEmail,
    versionChecker,
    websocket,
};

// first incarnation of the dynamic state with the anbsolute minimum we need from the start
const appDynamicState = {
    auth,
    config,
    pollingData,
    resourceData,
};

// combine the existing state and dynamic for initialisation. Eventually the existing state will be reduced and cleared
export const appState = { ...appInitialState, ...appDynamicState };

export const appReducer = combineReducers(appState);

// first incarnation of the dynamic state type
export interface AppDynamicState {
    [key: string]: AjaxState<any> | AuthState | Config | {};
    auth: AuthState;
    config: Config;
    pollingData: {};
    resourceData: {};
}
// combine the existing app state and dynamic for easier type migration
export type AppState = ReturnType<typeof appReducer> & AppDynamicState;
export type AppDispatch = ThunkDispatch<AppState, never, Action>;

const rootReducer: Reducer = (state: AppState, action: Action) => {
    if (action.type === "LOGOUT_SUCCESS") {
        const config = mergeDeep(window.appConfig, window.siteConfig || {});
        state = { config, i18n: state.i18n } as AppState;
    }

    return appReducer(state, action);
};

export default rootReducer;
