import { Strings } from "portal-components";
import React from "react";
import { Redirect, RouteComponentProps, RouteProps, withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import { PropagatedProps } from "../layout/main/component";
import NotFound from "../layout/404";
import ErrorBoundary from "../layout/errorBoundary";
import { importLazy, LazilyLoadFactory } from "../layout/lazilyload";
import { asyncRender } from "./BaseRoute";

interface LoginRouteProps extends RouteComponentProps {
    InviteAccept: React.ComponentType<PropagatedProps>;
    EmailVerification: React.ComponentType<PropagatedProps>;
    Login: React.ComponentType<PropagatedProps>;
    LoginTeam: React.ComponentType<PropagatedProps>;
    LoginMfaSetup: React.ComponentType<PropagatedProps>;
    FederatedLogin: React.ComponentType<PropagatedProps>;
    SamlLogin: React.ComponentType<PropagatedProps>;
    SamlLoginReauthenticate: React.ComponentType<PropagatedProps>;
    FederatedLoginAuthenticate: React.ComponentType<PropagatedProps>;
    FederatedLoginReAuthenticate: React.ComponentType<PropagatedProps>;
    LoginRecovery: React.ComponentType<PropagatedProps>;
    LoginRecoveryApply: React.ComponentType<PropagatedProps>;
    LULAccept: React.ComponentType<PropagatedProps>;
    Welcome: React.ComponentType<PropagatedProps>;
    componentProps: PropagatedProps;
}

const loginTeamRedirect = ({ location }: RouteProps) => {
    const query = Strings.convertSearchStringToObject(location?.search ?? "");
    let to;

    if (query.team) {
        to = {
            pathname: `/team/${query.team}`,
            search: query.next ? Strings.convertObjectToSearchString({ next: query.next }) : "",
        };
    } else {
        to = { pathname: "/" };
    }

    return <Redirect to={to} />;
};

export const LoginRoute: React.FunctionComponent<LoginRouteProps> = (props) => {
    const {
        InviteAccept,
        EmailVerification,
        Login,
        LoginTeam,
        LoginMfaSetup,
        FederatedLogin,
        SamlLogin,
        SamlLoginReauthenticate,
        FederatedLoginAuthenticate,
        FederatedLoginReAuthenticate,
        LoginRecovery,
        LoginRecoveryApply,
        LULAccept,
        Welcome,
        componentProps,
    } = props;
    const asyncRenderWithProps = asyncRender(componentProps);

    return (
        <ErrorBoundary>
            <Switch>
                <Route exact path="/welcome" render={asyncRenderWithProps(Welcome, "Welcome")} />
                <Route exact path="/lul" render={asyncRenderWithProps(LULAccept, "LULAccept")} />
                <Route exact path="/invitation" render={asyncRenderWithProps(InviteAccept, "InviteAccept")} />
                <Route exact path="/login-mfasetup" render={asyncRenderWithProps(LoginMfaSetup, "LoginMfaSetup")} />
                <Route exact path="/login-recovery" render={asyncRenderWithProps(LoginRecovery, "LoginRecovery")} />
                <Route exact path="/login" render={asyncRenderWithProps(Login, "Login")} />
                <Route exact path="/team-login" render={loginTeamRedirect} />
                <Route exact path="/team/:team?" render={asyncRenderWithProps(LoginTeam, "LoginTeam")} />
                <Route exact path="/team//" render={asyncRenderWithProps(LoginTeam, "LoginTeam")} />
                <Route exact path="/recovery" render={asyncRenderWithProps(LoginRecoveryApply, "LoginRecoveryApply")} />
                <Route exact path="/verify" render={asyncRenderWithProps(EmailVerification, "EmailVerification")} />
                <Route exact path="/saml-login" render={asyncRenderWithProps(SamlLogin, "SamlLogin")} />
                <Route
                    exact
                    path="/saml-login/reauthenticate"
                    render={asyncRenderWithProps(SamlLoginReauthenticate, "SamlLoginReauthenticate")}
                />
                <Route exact path="/federated-login" render={asyncRenderWithProps(FederatedLogin, "FederatedLogin")} />
                <Route
                    exact
                    path="/federated-login/authenticate"
                    render={asyncRenderWithProps(FederatedLoginAuthenticate, "FederatedLoginAuthenticate")}
                />
                <Route
                    exact
                    path="/federated-login/reauthenticate"
                    render={asyncRenderWithProps(FederatedLoginReAuthenticate, "FederatedLoginReAuthenticate")}
                />
                <Route component={NotFound} />
            </Switch>
        </ErrorBoundary>
    );
};

export const publicRoutes = [
    "/welcome",
    "/lul",
    "/invitation",
    "/login-mfasetup",
    "/login-recovery",
    "/login",
    "/team-login",
    "/team/:team?",
    "/team//",
    "/recovery",
    "/verify",
    "/saml-login",
    "/saml-login/reauthenticate",
    "/federated-login",
    "/federated-login/authenticate",
    "/federated-login/reauthenticate",
];

export default LazilyLoadFactory(withRouter(LoginRoute), {
    InviteAccept: () => importLazy(import("../features/login/inviteAccept/container")),
    EmailVerification: () => importLazy(import("../features/login/emailVerify/container")),
    Login: () => importLazy(import("../features/login/container")),
    LoginTeam: () => importLazy(import("../features/login/team/container")),
    LoginMfaSetup: () => importLazy(import("../features/login/mfaSetup/container")),
    FederatedLogin: () => importLazy(import("../features/login/federated/container")),
    SamlLogin: () => importLazy(import("../features/login/saml/container")),
    SamlLoginReauthenticate: () => importLazy(import("../features/login/saml/reauth/container")),
    FederatedLoginAuthenticate: () => importLazy(import("../features/login/federated/auth/container")),
    FederatedLoginReAuthenticate: () => importLazy(import("../features/login/federated/reauth/container")),
    LoginRecovery: () => importLazy(import("../features/login/recovery/container")),
    LoginRecoveryApply: () => importLazy(import("../features/login/recovery/apply/container")),
    LULAccept: () => importLazy(import("../features/login/lulAccept/container")),
    Welcome: () => importLazy(import("../features/login/firstLogin/container")),
});
