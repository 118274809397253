import { Device } from "../../../features/devices";
import { ListResponse } from "../../../script/types";
import { makeCollectionCreator, makeConfigApiCreator } from "../../../creators";

const { reducer, createThunk: connectorStatisticsThunk } = makeConfigApiCreator<unknown>("v3_base")(
    "connector_statistics",
    (unknownResponse) => {
        const response = unknownResponse as ListResponse<{ id: string }>;
        // Get the data
        const data = response.data.map((period: unknown) => Object.assign(period, {}));
        // Store last entry id, for paging GET calls
        if (response.data && response.data.length > 1) {
            response.last = response.data[response.data.length - 1].id;
        }

        // Get the stat fields
        // Using the first entry, get the object field names and build a total count for the whole period
        // Remove the id and timestamp fields
        const fields = Object.getOwnPropertyNames(data[0]).filter((field) => field !== "id" && field !== "timestamp");
        const initial: { [key: string]: number } = {};
        fields.forEach((field) => (initial[field] = 0));
        // Reduce to provide totals for the period
        const totals = data.reduce((acc: { [key: string]: number }, interval: { [key: string]: number }) => {
            fields.forEach((field) => (acc[field] += interval[field]));
            return acc;
        }, initial);

        return { data, response, totals };
    }
);

const getAction = ({
    last = null,
    include = "",
    period = "30d",
    start = null,
    end = null,
    interval = "1d",
    limit = 1000,
    errorAction = undefined,
    errorCallout = true,
}) => {
    include =
        include ||
        "bootstraps_successful,connect_rest_api_success,deleted_registrations,device_proxy_request_success,device_subscription_request_success,expired_registrations,registration_updates,transactions";
    const queryParams = [
        `include=${include}`,
        `interval=${interval}`,
        `limit=${limit}`,
        last ? `after=${last}` : false,
        period ? `period=${period}` : false,
        start ? `start=${start}` : false, // start and end is required only if period is not set
        end ? `end=${end}` : false,
    ];

    const queryString = queryParams
        .filter((c) => {
            return !!c;
        })
        .join("&");

    return connectorStatisticsThunk({
        param: `metrics?${queryString}`,
        errorAction,
        errorCallout,
    });
};

const catalogCreator = makeConfigApiCreator<void>("v3_base", { errorCallout: false })(
    "dashboard_devices_catalog_metrics",
    (untypedResponse) => {
        const response = untypedResponse as ListResponse<Device>;
        const data = response.data.map((device) => Object.assign(device, { selected: false }));
        if (response.data && response.data.length > 1) {
            response.last = response.data[response.data.length - 1].id;
        }
        return { data, response };
    }
);

const { createThunk: createDevicesThunk, reducer: deviceReducer } = makeCollectionCreator(catalogCreator);

const deviceAction = ({
    id = "",
    errorAction = undefined,
    errorCallout = false,
    include = "total_count",
    last = null,
    order = "DESC",
    pageSize = 0,
    query = "",
}) => {
    const queryParams = [
        `include=${include}`,
        `limit=${pageSize}`,
        `order=${order}`,
        last ? `after=${last}` : false,
        query ? `filter=${query}` : false,
    ];

    const queryString = queryParams
        .filter((c) => {
            return !!c;
        })
        .join("&");

    return createDevicesThunk({
        id,
        param: `devices?${queryString}`,
        errorAction,
        errorCallout,
    });
};

export { deviceAction, deviceReducer, getAction, reducer };
