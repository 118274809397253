import { TrustedCertificate as SdkTrustedCertificate } from "mbed-cloud-sdk";
import { makeConfigApiCreator, ParseFunction } from "../../../..//creators";
import { ListResponse } from "../../../../script";
import { ListRequestParameters } from "../../../../script/types";
import { reducerRegistry } from "../../../../script";

export interface TrustedCertificate extends SdkTrustedCertificate {
    index?: number;
    selected?: boolean;
    subjectName?: string;
    executionService?: string;
    released_at?: Date;
    imported_at?: Date;
}

const getSubjectName = (certificate: TrustedCertificate) => {
    const subject = certificate?.subject?.split(",") || "";
    for (const item of subject) {
        const subjects = item.split("=");
        if (subjects[0] === "CN") {
            return subjects[1];
        }
    }

    return "-";
};

const getExecutionService = (certificate: TrustedCertificate) => {
    let mode = certificate.deviceExecutionMode === 1 ? "Dev" : "Prod";
    mode = certificate.enrollmentMode ? mode + "-enrollment" : mode;

    return `${mode}/${certificate.service}`;
};

const makeOemCertificateCreator = <T, D = undefined>(type: string, parseFunction?: ParseFunction<T, D>) =>
    makeConfigApiCreator<T, D>("v3_base", {
        errorCallout: false,
    })(type, parseFunction);

// get oem certificates
const { reducer: getOemCertificateReducer, createThunk } = makeOemCertificateCreator<ListResponse<TrustedCertificate>>(
    "oem-certificates",
    (resp) => {
        const response = resp as ListResponse<TrustedCertificate>;
        response.data = response.data
            .map((item) => ({
                ...item,
                selected: false,
                executionService: getExecutionService(item as any), // the generic type for map results is not working
                subjectName: getSubjectName(item as any),
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
        if (response.data && response.data.length > 1) {
            response.last = response.data[response.data.length - 1].id;
        }
        return response;
    }
);

interface OemCertificatesListParameters extends ListRequestParameters {
    query?: string;
}

const getOemCertificates = (
    {
        pageSize = 50,
        last = undefined,
        order = "DESC",
        query = undefined,
        errorAction = undefined,
        errorCallout = true,
    } = {} as OemCertificatesListParameters
) =>
    createThunk({
        param: `oem-certificates`,
        queryParameters: {
            order,
            limit: `${pageSize}`,
            include: "total_count",
            filter: query,
            after: last,
        },
        errorAction,
        errorCallout,
    });

const {
    reducer: importReducer,
    createThunk: importCertificateThunk,
    createResetAction: importCertificateResetThunk,
} = makeConfigApiCreator<TrustedCertificate>("v3_base")("import_certificate");

const importCertificates = (id: string) =>
    importCertificateThunk({
        param: `oem-certificates/${id}/import`,
        method: "POST",
        errorCallout: true,
    });

const importCertificatesReset = importCertificateResetThunk();

const reducers = {
    getOemCertificate: getOemCertificateReducer,
    importCertificate: importReducer,
};

reducerRegistry.register(reducers);

type OemCertificateReducers = typeof reducers;

export {
    getOemCertificates,
    importCertificates,
    getOemCertificateReducer,
    importCertificatesReset,
    OemCertificateReducers,
};
