import FileSaver from "file-saver";
import moment from "moment";
import { Button, ButtonSize, ButtonVariant, Icon, Icons, If, Then } from "portal-components";
import React from "react";
import translate, { DefaultStrings } from "../../i18n/translate";

const MINIMUM_PROGRESS_FOR_ESTIMATION = 5;
const MINIMUM_ELAPSED_MS_FOR_ESTIMATION = 5000;

const defaultStrings = {
    downloadReport: "Download report",
    started: "Started:",
    remaining: "Remaining (estimated):",
    tryAgain: "Try again",
};

export interface JobStatusIndicatorProps {
    strings: DefaultStrings<typeof defaultStrings>;
    canRetry?: boolean;
    description?: string;
    endTime?: Date;
    invisible?: boolean;
    progress?: number;
    report?: string;
    startTime?: Date;
    status?: "pending" | "succeeded" | "failed";
    title?: string;
    onCancel?: (event: React.MouseEvent) => void;
    onPause?: (event: React.MouseEvent) => void; // Currently unused
    onResume?: (event: React.MouseEvent) => void; // Currently unused
    onRetry?: (event: React.MouseEvent) => void;
}

export class JobStatusIndicator extends React.PureComponent<JobStatusIndicatorProps> {
    public static readonly defaultProps = {
        strings: defaultStrings,
    };

    constructor(props: JobStatusIndicatorProps) {
        super(props);
        this.handleDownloadReport = this.handleDownloadReport.bind(this);
    }

    createIconButton(icon: Icons, clickHandler?: React.MouseEventHandler) {
        return (
            <Button
                key={icon}
                variant={ButtonVariant.Compact}
                size={ButtonSize.Small}
                icon={icon}
                onClick={clickHandler}
                disabled={!clickHandler}
            />
        );
    }

    handleDownloadReport(e: React.MouseEvent) {
        e.preventDefault();

        const timestamp = moment(Date.now()).format("YYYY-MM-DD-HH-mm-ss");
        FileSaver.saveAs(
            new Blob([this.props.report ?? ""], { type: "text/csv" }),
            `Report ${this.props.title} - ${timestamp}.csv`
        );
    }

    renderProgress() {
        const { progress, status } = this.props;

        if (status && status !== "pending") {
            return null;
        }

        const style = progress !== undefined ? { width: `${this.props.progress}%` } : {};
        const modeClass = progress !== undefined ? "" : "indefinite";

        return (
            <div className={`meter ${modeClass}`}>
                <div className={`indicator ${modeClass}`} style={style} />
            </div>
        );
    }

    renderFooter() {
        const { startTime, endTime, progress, strings } = this.props;

        if (!startTime) {
            return null;
        }

        const startTimeMessage = `${strings.started} ${moment(startTime).format("lll")}`;

        // Show the (exact) start time if already completed.
        if (endTime) {
            return startTimeMessage;
        }

        // It's in progress then try to guess. We TRY to give an educated estimation then we have to wait to complete at least
        // a minimum progress for a minimum amount of time.
        // duration.humanize() will do the rest (round changing numbers to user friendly text).
        if (progress && progress >= MINIMUM_PROGRESS_FOR_ESTIMATION) {
            const elapsed = new Date().getTime() - startTime.getTime();

            if (elapsed >= MINIMUM_ELAPSED_MS_FOR_ESTIMATION) {
                const estimatedDuration = (elapsed * 100) / progress;
                const left = estimatedDuration - elapsed;

                const endTime = moment.duration(left, "ms").humanize();
                return (
                    <React.Fragment>
                        {startTimeMessage}
                        <br />
                        {strings.remaining}&ensp;{endTime}
                    </React.Fragment>
                );
            }
        }

        // If we cannot calculate an estimated duration then just give back an exact start time.
        return startTimeMessage;
    }

    renderControlsOrStatus() {
        const { onPause, onResume, onCancel, status } = this.props;

        if (status && status !== "pending") {
            const isFailed = status === "failed";
            const className = "result-indicator " + (isFailed ? "text-error" : "text-success");
            const icon = isFailed ? Icons.JobStatusFail : Icons.JobStatusSuccess;

            return (
                <div className={className}>
                    <Icon name={icon} />
                </div>
            );
        }

        return (
            <React.Fragment>
                {this.createIconButton(Icons.JobPause, onPause)}
                {this.createIconButton(Icons.JobPlay, onResume)}
                {this.createIconButton(Icons.Delete, onCancel)}
            </React.Fragment>
        );
    }

    render() {
        const { report, invisible, title, description, strings, onRetry, canRetry, status } = this.props;
        if (invisible) {
            return <div className="async-job-list-small-indicator">{this.renderProgress()}</div>;
        }

        return (
            <div className="async-job-list-indicator">
                <div className="async-job-list-indicator-title">
                    {title}
                    <div className="secondary">
                        <If condition={description}>
                            <Then>{description}</Then>
                        </If>
                        <If condition={report}>
                            <Then>
                                <span>
                                    &ensp;
                                    <a onClick={this.handleDownloadReport}>{strings.downloadReport}</a>
                                </span>
                            </Then>
                        </If>
                    </div>
                    <If condition={status === "failed" && canRetry}>
                        <Then>
                            <div className="secondary">
                                <br />
                                <Button onClick={onRetry} text={strings.tryAgain} />
                            </div>
                        </Then>
                    </If>
                    <div className="secondary">
                        <small>{this.renderFooter()}</small>
                    </div>
                    {this.renderProgress()}
                </div>
                <div className="async-job-list-indicator-controls">{this.renderControlsOrStatus()}</div>
            </div>
        );
    }
}

export default translate("JobStatusIndicator")(JobStatusIndicator);
