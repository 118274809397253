import { makeConfigApiCreator } from "../../../../creators/AjaxApiCreator";
import { ListResponse } from "../../../../script/types";

interface GatewayLogsFilterOption {
    default?: boolean;
    value: string;
    text: string;
}

interface GatewayLog {
    id?: string;
    etag?: string;
    created_at?: string;
    device_id?: string;
    account_id?: string;
    type?: string;
    timestamp?: number;
    level?: string;
    app_name?: string;
    message?: string;
}

interface GatewayLogsParameters {
    id?: string;
    pageSize?: number;
    last?: string;
    order?: string;
    errorCallout?: boolean;
}

const {
    createThunk: createGatewayLogsThunk,
    reducer: gatewayLogsReducer,
    createResetAction: createGatewayLogsResetAction,
} = makeConfigApiCreator<ListResponse<GatewayLog>>("v3_base")("gateway_logs", (response) => {
    return response as ListResponse<GatewayLog>;
});

const getGatewayLogs = ({ id, pageSize = 1000, last, order = "desc", errorCallout = false }: GatewayLogsParameters) => {
    const queryParameters = {
        order: order,
        limit: pageSize?.toString(),
        include: "total_count",
        before: last ? (order === "desc" ? undefined : last) : undefined,
        after: last ? (order === "desc" ? last : undefined) : undefined,
    };

    return id
        ? createGatewayLogsThunk({
              param: `devices/${id}/logs`,
              queryParameters,
              errorCallout,
          })
        : createGatewayLogsThunk({
              param: "device-logs",
              queryParameters,
              errorCallout,
          });
};
const resetGatewayLogs = createGatewayLogsResetAction();

export { GatewayLog, getGatewayLogs, gatewayLogsReducer, resetGatewayLogs, GatewayLogsFilterOption };
