import { makeConfigApiCreator } from "../../../creators";
import { ListResponse } from "../../../script";
import { FirmwareManifest } from "./detail/types";

// firmware manifest list
const { reducer, createThunk: getManifestThunk } = makeConfigApiCreator<ListResponse<FirmwareManifest>>("v3_base")(
    "firmware_manifest",
    (response: any) => {
        response = response as ListResponse<FirmwareManifest>;
        const data = response.data.map((item: FirmwareManifest) => ({ ...item, selected: false }));
        if (response.data && response.data.length > 1) {
            response.last = response.data[response.data.length - 1].id;
        }
        return { ...response, data };
    }
);
const getAction = ({
    pageSize = 1000,
    last,
    order = "DESC",
    query,
    errorAction,
    errorCallout = true,
}: {
    pageSize?: number;
    last?: string;
    order?: string;
    query?: string;
    errorAction?: () => void;
    errorCallout?: boolean;
}) => {
    const queryParameters: { [param: string]: string } = {
        order,
        limit: `${pageSize}`,
        include: "total_count",
    };
    if (last) {
        queryParameters.after = last;
    }
    if (query) {
        queryParameters.filter = query;
    }
    return getManifestThunk({
        param: "firmware-manifests",
        queryParameters,
        errorAction,
        errorCallout,
    });
};

// firmware manifest detail
const { reducer: detailReducer, createThunk: getManifestDetailThunk } = makeConfigApiCreator<FirmwareManifest>(
    "v3_base"
)("firmware_manifest_detail");
const detailAction = (id: string, errorRedirect = true) =>
    getManifestDetailThunk({
        param: `firmware-manifests/${id}`,
        method: "GET",
        errorRedirect,
    });

// delete firmware manifest
const { reducer: deleteReducer, createThunk: deleteManifestThunk } = makeConfigApiCreator<void>("v3_base")(
    "firmware_manifest_delete"
);
const deleteAction = (id: string) => deleteManifestThunk({ param: `firmware-manifests/${id}`, method: "DELETE" });

export { getAction, reducer, deleteAction, deleteReducer, detailAction, detailReducer };
