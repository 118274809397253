import { makeConfigApiCreator } from "../../../creators";

const { reducer, createThunk: passwordRecoveryThunk } = makeConfigApiCreator("auth_base", {
    useAuth: false,
})("password_recovery");

const passwordRecoveryAction = (data: {}, method: "POST" | "PUT") =>
    passwordRecoveryThunk({
        param: "recover",
        data,
        method: method,
        errorCallout: false,
    });

export { passwordRecoveryAction, reducer };
