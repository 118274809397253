import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import requireAuthorization from "../features/authorization/container";
import ErrorBoundary from "../layout/errorBoundary";
import { importLazy, LazilyLoadFactory } from "../layout/lazilyload";
import { PropagatedProps } from "../layout/main/component";
import { asyncRender } from "./BaseRoute";

export interface DashboardRouteProps extends RouteComponentProps {
    CustomBoard: React.ComponentType<PropagatedProps>;
    Dashboard: React.ComponentType<PropagatedProps>;
    Default: React.ComponentType<PropagatedProps>;
    GatewaysDashboard: React.ComponentType<PropagatedProps>;
    componentProps: PropagatedProps;
}

export const DashboardRoute: React.FunctionComponent<DashboardRouteProps> = (props) => {
    const { CustomBoard, componentProps, Dashboard, Default, GatewaysDashboard } = props;
    const asyncRenderWithProps = asyncRender(componentProps);
    return (
        <ErrorBoundary>
            <Switch>
                <Route exact path="/dashboard" render={asyncRenderWithProps(Default, "home")} />
                <Route exact path="/dashboard/usage" render={asyncRenderWithProps(Dashboard, "usageboard")} />
                <Route
                    exact
                    path="/dashboard/gateways"
                    render={asyncRenderWithProps(GatewaysDashboard, "gatewaysboard")}
                />
                <Route exact path="/dashboard/custom" render={asyncRenderWithProps(CustomBoard, "customboard")} />
                <Redirect from="/dashboard/*" to="/dashboard/usage" />
            </Switch>
        </ErrorBoundary>
    );
};

export default LazilyLoadFactory(withRouter(DashboardRoute), {
    CustomBoard: () =>
        importLazy(import("../features/dashboard/custom/container"), (c) =>
            requireAuthorization("customDashboard", c.default)
        ),
    Dashboard: () => importLazy(import("../features/dashboard/container")),
    Default: () => importLazy(import("../features/dashboard/default/container")),
    GatewaysDashboard: () =>
        importLazy(import("../features/dashboard/gateways/container"), (c) =>
            requireAuthorization("edgeThick", c.default)
        ),
});
