import { makeConfigApiCreator } from "../../../../creators";
import { GroupDetail } from "../detail/creator";
import { reducerRegistry } from "../../../../script";

export interface UsersAddRemove {
    users: string[];
}

export interface KeysAddRemove {
    apikeys: string[];
}

export interface ApplicationsAddRemove {
    applications: string[];
}

const makeGroupCreator = <T>(type: string) =>
    makeConfigApiCreator<T>("v3_base", {
        errorCallout: false,
    })(type);

// add user to group
const { reducer: addUsersReducer, createThunk: addUserThunk } = makeGroupCreator<GroupDetail>("iam_add_group_users");
const addGroupUsers = (id: string, data: UsersAddRemove) =>
    addUserThunk({ param: `policy-groups/${id}`, data, method: "POST" });

// remove group users
const { reducer: removeUsersReducer, createThunk: removeUserThunk } = makeGroupCreator<GroupDetail>(
    "iam_remove_group_users"
);
const removeGroupUsers = (id: string, data: UsersAddRemove) =>
    removeUserThunk({ param: `policy-groups/${id}/users`, data, method: "DELETE" });

// add key to group
const { reducer: addKeysReducer, createThunk: addKeysThunk } = makeGroupCreator<GroupDetail>("iam_add_group_keys");
const addGroupKeys = (id: string, data: KeysAddRemove) =>
    addKeysThunk({ param: `policy-groups/${id}`, data, method: "POST" });

// remove group keys
const { reducer: removeKeysReducer, createThunk: removeKeysThunk } = makeGroupCreator<GroupDetail>(
    "iam_remove_group_keys"
);
const removeGroupKeys = (id: string, data: KeysAddRemove) =>
    removeKeysThunk({ param: `policy-groups/${id}/api-keys`, data, method: "DELETE" });

// add application to group
const { reducer: addApplicationsReducer, createThunk: addApplicationsThunk } = makeGroupCreator<GroupDetail>(
    "iam_add_group_applications"
);
const addGroupApplications = (id: string, data: ApplicationsAddRemove) =>
    addApplicationsThunk({ param: `policy-groups/${id}/applications/add`, data, method: "POST" });

// remove applications
const { reducer: removeApplicationsReducer, createThunk: removeApplicationsThunk } = makeGroupCreator<GroupDetail>(
    "iam_remove_group_applications"
);
const removeGroupApplications = (id: string, data: ApplicationsAddRemove) =>
    removeApplicationsThunk({ param: `policy-groups/${id}/applications/remove`, data, method: "POST" });

const reducers = {
    groupaddapplications: addApplicationsReducer,
    groupremoveapplications: removeApplicationsReducer,
};

reducerRegistry.register(reducers);

export {
    removeGroupUsers,
    removeGroupKeys,
    addGroupUsers,
    removeUsersReducer,
    addGroupKeys,
    removeKeysReducer,
    addGroupApplications,
    removeGroupApplications,
    addUsersReducer,
    addKeysReducer,
};
