import { Orientation, Severity, StackPanel, Tag } from "portal-components";
import React from "react";
import { CheckedList, CheckedListItem } from "../../controls/checkedList";
import translate, { DefaultStrings } from "../../i18n/translate";
import { AuthAccount } from "../../layout/reducer";

const defaultStrings = { selectTeamHeader: "Select a team to log into", tenant: "Tenant", suspended: "Suspended" };

export interface TeamSelectionComponentProps {
    strings: DefaultStrings<typeof defaultStrings>;
    accounts?: AuthAccount[];
    onSelect: (id: string) => void;
}

export const TeamSelectionComponent: React.FunctionComponent<TeamSelectionComponentProps> = (props) => {
    const { accounts, strings } = props;

    const selection = accounts?.map((account, index) => {
        const { alias, id, parent_id, display_name, status } = account;
        const descriptionText = display_name === alias ? id : display_name === id ? "" : `${alias} ${id}`;
        const handleClick = () => props.onSelect(id);
        const description = `${descriptionText} ${parent_id ? strings.tenant : ""}`;

        let displayName: string | JSX.Element = display_name || alias || id;
        if (status === "SUSPENDED") {
            displayName = (
                <StackPanel orientation={Orientation.Horizontal}>
                    {displayName}
                    <Tag severity={Severity.Warning} text={strings.suspended} />
                </StackPanel>
            );
        }

        return (
            <CheckedListItem
                key={index}
                id={`id-${id}`}
                title={displayName}
                description={description}
                searchText={display_name || alias || id}
                unselectable
                onClick={handleClick}
                disabled={status !== "ACTIVE"}
            />
        );
    });

    return (
        <React.Fragment>
            <p className="lead">{strings.selectTeamHeader}</p>
            <CheckedList canFilter>{selection}</CheckedList>
        </React.Fragment>
    );
};

TeamSelectionComponent.defaultProps = {
    strings: defaultStrings,
};

export default translate("TeamSelectionComponent")(TeamSelectionComponent);
