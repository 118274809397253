import { Icon, Icons } from "portal-components";
import React from "react";
import { iif } from "../../script/utility";

export interface CheckedListItemProps {
    id?: string;

    className?: string;

    /** Main title for this list item. */
    title?: string | JSX.Element | JSX.Element[];

    /** Optional description. */
    description?: string | JSX.Element | JSX.Element[];

    /** Optional secondary text displayed in the "badge" area. */
    secondaryText?: string | JSX.Element | JSX.Element[];

    /** If specified it extends the search/filter feature to this text, use this instead of `title` and `descriptions` if they are not strings. */
    searchText?: string;

    /** FortAwesome icon ID. */
    icon?: Icons;

    /** If specified then item cannot be clicked. */
    disabled?: boolean;

    /** If specified then the item is always hidden. */
    hidden?: boolean;

    /** Optional HTML color, if present a solid box with the specified color is displayed near the title. It might be useful to distinguish categorized items. */
    colorMark?: string;

    /** If true the title is displayed in a prominent way. */
    strong?: boolean;

    /** If specified then the item is _secondary_ and should be displayed with a less prominent style. Do not use this together with `strong`. */
    secondary?: boolean;

    /** If true then the item has a fixed width. */
    fixed?: boolean;

    /** Indicates whether this item is currently selected. */
    selected?: boolean;

    /** Indicates whether this item can be selected/unselected. If false then the item has not the checkmark area. */
    unselectable?: boolean;

    /** If true then the item cannot be excluded when filtering the list. */
    unfilterable?: boolean;

    /** Indicates if the item can be deleted. */
    canDelete?: boolean;

    onDelete?: () => void;
    onClick?: (e: React.SyntheticEvent) => void;
}

export const CheckedListItem: React.FunctionComponent<CheckedListItemProps> = (props) => {
    if (props.hidden) {
        return null;
    }

    const stateClasses = [
        iif(props.strong, "strong"),
        iif(props.selected, "selected"),
        iif(props.fixed, "fixed"),
        iif(props.disabled, "disabled"),
        iif(props.secondary, "secondary"),
    ].join(" ");

    const userDefinedClass = iif(props.className, props.className, "");

    const handleClick = props.disabled ? undefined : props.onClick;
    const handleKeyPress = (e: React.KeyboardEvent<HTMLLIElement>) => {
        if (e.key === "Enter") {
            handleClick?.(e);
        }
    };
    const handleDelete = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        props.onDelete?.();
    };

    const colorMarkStyle = {
        backgroundColor: props.colorMark,
        color: props.colorMark,
        marginRight: "0.5em",
    };
    const colorMark = iif(props.colorMark, <span style={colorMarkStyle}>—</span>);

    const extraProps = props.id ? { id: props.id } : {};

    const checkmarkAndIconArea = (
        <div className="checkmark-area">
            {props.selected && <Icon name={Icons.SelectedMark} />}
            {props.icon && (
                <span className="icon">
                    <Icon name={props.icon} />
                </span>
            )}
        </div>
    );

    const actionsAndBadgesArea = (
        <span className="actions">
            {props.secondaryText && <span className="gray">{props.secondaryText}</span>}
            {props.canDelete && <Icon name={Icons.Delete} onClick={handleDelete} />}
        </span>
    );

    return (
        <li
            {...extraProps}
            className={`checked-list-item ${stateClasses} ${userDefinedClass}`}
            onClick={handleClick}
            onKeyPress={handleKeyPress}
        >
            {(!props.unselectable || props.icon) && checkmarkAndIconArea}
            <div className="title-area">
                <div className="top">
                    <span tabIndex={0}>
                        {colorMark}
                        {props.title}
                    </span>
                    {actionsAndBadgesArea}
                </div>
                <div className="bottom">{props.description}</div>
            </div>
        </li>
    );
};

CheckedListItem.displayName = "CheckedListItem";
