import React from "react";
import ControlGroup from "../../../../controls/control-group";
import { DropdownFilter } from "../../../../controls/filterBar";
import GridWrap from "../../../../controls/grid/grid-wrap";
import LogLevelComponent from "../../../../controls/logLevelComponent";
import translate, { DefaultStrings } from "../../../../i18n/translate";
import LogDetails from "./logDetails";
import { GatewayLogsFilterOption, GatewayLog } from "./creator";
import { GridWrapProps } from "../../../../controls/grid";
import { ListResponse } from "../../../../script";
import { ValueEvent } from "portal-components";

const defaultStrings = {
    level: "Level",
    appName: "Application Name",
    refresh: "Refresh",
    type: "Type",
    message: "Message",
    timestamp: "Timestamp",
};

interface LogsFullTableProps {
    strings: DefaultStrings<typeof defaultStrings>;
    filter?: GatewayLogsFilterOption;
    filterOptions: GatewayLogsFilterOption[];
    id: string;
    logs: GatewayLog[];
    onFilterChanged: (filter: ValueEvent<string>) => void;
    onGridLoadMore: () => void;
    onRefresh: () => void;
    loading: boolean;
    info: ListResponse<GatewayLog>;
}

type LogsFullTableState = GridWrapProps<GatewayLog>;

export class LogsFullTable extends React.PureComponent<LogsFullTableProps, LogsFullTableState> {
    public static defaultProps = {
        strings: defaultStrings,
    };

    constructor(props: LogsFullTableProps) {
        super(props);

        this.handleGridLoadMore = this.handleGridLoadMore.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);

        const { strings } = props;
        this.state = {
            extraActions: {
                iconButtons: [
                    {
                        title: strings.refresh,
                        onClick: this.handleRefresh,
                    },
                ],
            },
            columns: [
                {
                    index: 0,
                    name: "timestamp",
                    title: strings.timestamp,
                    type: "date",
                    rawDate: "x",
                    dateFormat: "ll LTS",
                    ellipsis: "end",
                },
                {
                    index: 1,
                    name: "level",
                    title: strings.level,
                    renderFunction: (level: unknown) => <LogLevelComponent level={level as string} />,
                },
                {
                    index: 2,
                    name: "app_name",
                    title: strings.appName,
                },
                {
                    index: 3,
                    name: "type",
                    title: strings.type,
                },
                {
                    index: 4,
                    name: "message",
                    title: strings.message,
                    renderFunction: (_, log) => <LogDetails log={log as GatewayLog} />,
                },
            ],
        };
    }

    handleGridLoadMore() {
        this.props.onGridLoadMore && this.props.onGridLoadMore();
    }

    handleRefresh() {
        this.props.onRefresh && this.props.onRefresh();
    }

    render() {
        const { filter, id, info, filterOptions = [], loading, logs = [] } = this.props;
        const { columns, extraActions } = this.state;

        const filterDropdown = (
            <ControlGroup key="1" className="actionButtons float-left custom-actions" disabled={loading}>
                <DropdownFilter
                    label=""
                    items={filterOptions}
                    value={filter?.value || ""}
                    selectionMustExist={false}
                    onChange={this.props.onFilterChanged}
                />
            </ControlGroup>
        );

        return (
            <GridWrap
                id={`gateway_${id}_logs`}
                columns={columns}
                data={logs}
                extraActions={extraActions}
                loading={loading}
                customActions={[filterDropdown]}
                filterBy={[0, 2, 3, 4]}
                onGridLoadMore={this.handleGridLoadMore}
                info={filter && filterOptions.indexOf(filter) > 0 ? {} : info}
                noSelection
                downloadData
            />
        );
    }
}

export default translate("LogsFullTable")(LogsFullTable);
