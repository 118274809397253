import { ApiKey, ApiKeyListOptions, ApiKeyRepository, ApiKeyUpdateRequest, Config, Paginator } from "mbed-cloud-sdk";
import { PaginationDirection, sdkCreator, SdkCreatorOptions, sdkPaginatorCreator } from "../../../creators";
import { reducerRegistry } from "../../../script";

let repository: ApiKeyRepository;

const getRepository = (sdkConfig: Config) => {
    if (!repository) {
        repository = new ApiKeyRepository(sdkConfig);
    }

    return repository;
};

// atomic SDK actions

// delete api keys
const { reducer: deleteKeysReducer, createThunk: deleteKeysThunk } = sdkCreator("DELETE_API_KEYS", {
    sdkMethod: (id: string, sdkConfig) => getRepository(sdkConfig).delete(id),
});

const deleteKeys = (id: string) => deleteKeysThunk(id);

// update api key
const { reducer: updateKeysReducer, createThunk: updateKeysThunk, createResetAction: updateKeyResetThunk } = sdkCreator(
    "UPDATE_API_KEYS",
    {
        sdkMethod: (request: { id: string; data: ApiKeyUpdateRequest }, sdkConfig) => {
            return getRepository(sdkConfig).update(request.data, request.id);
        },
    }
);

const updateKeys = (id: string, data: ApiKeyUpdateRequest) => updateKeysThunk({ id, data });
const updateKeysReset = updateKeyResetThunk();

// paginator actions

// get the apiKey paginator
const { reducer: apiKeysReducer, createListThunk } = sdkPaginatorCreator<ApiKey, ApiKeyListOptions>("GET_API_KEYS", {
    paginator: (parameters, sdkConfig) => getRepository(sdkConfig).list(parameters),
    mapResults: <ApiKey>(item: ApiKey, index: number) => ({ ...item, index, selected: false }),
});

const getKeys = (options?: {
    paginator?: Paginator<ApiKey, ApiKeyListOptions>;
    listOptions?: ApiKeyListOptions;
    direction?: PaginationDirection;
    creatorOptions?: SdkCreatorOptions;
}) => {
    options = options || {};
    const { pageSize = 50, order = "DESC", maxResults = 1000, filter = {} } =
        (options.listOptions as ApiKeyListOptions) || {};
    const { errorCallout = true } = (options.creatorOptions as SdkCreatorOptions) || {};
    return createListThunk(
        options.paginator,
        { pageSize, order, maxResults, filter },
        { errorCallout },
        options.direction
    );
};

// get total count
const { totalCountReducer, createTotalCountThunk: totalCountThunk } = sdkPaginatorCreator("GET_API_KEYS_COUNT", {
    paginator: (parameters, sdkConfig) => getRepository(sdkConfig).list(parameters),
});

const getKeysCount = (options?: {
    paginator?: Paginator<ApiKey, ApiKeyListOptions>;
    listOptions?: ApiKeyListOptions;
    creatorOptions?: SdkCreatorOptions;
}) =>
    totalCountThunk(
        options?.paginator,
        { errorCallout: options?.creatorOptions?.errorCallout ?? true },
        options?.listOptions
    );

const reducers = {
    key: apiKeysReducer,
    keyTotalCount: totalCountReducer,
    deletekeys: deleteKeysReducer,
    updateKeys: updateKeysReducer,
};

reducerRegistry.register(reducers);

type KeyReducers = typeof reducers;

export { deleteKeys, updateKeys, getKeys, getKeysCount, updateKeysReset, KeyReducers };
