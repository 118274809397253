import PropTypes from "prop-types";
import { Action, AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { Response } from "superagent";
import { Config } from "../../../../typings/interfaces";
import { addAuth } from "../../../../creators/AjaxApiCreator";
import { collectionReducer } from "../../../../creators/CollectionCreator";
import { configAjax, ConfigAjaxOptions } from "../../../../script/ajax";
import { HTTPCodes } from "../../../../script/utility";

const ACTION_PREFIX = "OBJECT_UPLOAD_";
const START_ACTION = `${ACTION_PREFIX}START`;
const SUCCESS_ACTION = `${ACTION_PREFIX}SUCCESS`;
const PROGRESS_ACTION = `${ACTION_PREFIX}PROGRESS`;
const ERROR_ACTION = `${ACTION_PREFIX}ERROR`;

const objectUploadAjax = (options: ConfigAjaxOptions) =>
    configAjax("v3_base")({
        ...options,
        headers: addAuth(options.headers),
    });

interface FileUploadProps {
    payload: string;
    name: string;
    overrideExisting: boolean;
}

interface uploadObjectOptions {
    data: FileUploadProps;
    getState: () => { config: Config };
}

export interface UploadObjectOptions {
    data: { [index: string]: boolean | string | null } & FileUploadProps;
    id: string;
}

const upload = ({ data, getState }: uploadObjectOptions): Promise<{}> => {
    const form = new FormData();
    form.append("lwm2m-object-file", new Blob([data.payload], { type: "application/xml" }), data.name);
    form.append("override-existing", data.overrideExisting ? "true" : "false");

    return objectUploadAjax({
        getState,
        param: "lwm2m-objects",
        data: form,
        method: "POST",
        type: null,
    });
};

export const uploadObject = ({
    data,
    id,
}: UploadObjectOptions): ThunkAction<Promise<void>, { config: Config }, never, Action> => (dispatch, getState) => {
    dispatch({ type: START_ACTION, id, fileName: data.name });
    const uploadData: FileUploadProps = {
        payload: data.payload,
        name: data.name,
        overrideExisting: data.overrideExisting,
    };
    const promise = upload({ data: uploadData, getState });

    return promise.then(
        (response: { response?: Response }) => {
            dispatch({ type: SUCCESS_ACTION, id, response: response });
        },
        (reason: { response?: Response; status?: number }) => {
            const statusCode = reason.status;
            if (statusCode && statusCode === HTTPCodes.UNAUTHORIZED) {
                dispatch({
                    type: "REAUTH_REQUEST",
                    data: {},
                });
            }

            dispatch({ type: ERROR_ACTION, id, error: reason });
        }
    );
};

/**
 * State of one firmware upload. Redux state firmwareUploads key consists of a map of string id to
 * an UploadFirmwareState.
 */
export type UploadObjectState = { progress: number; fileName: string } & (
    | { state: "CREATING" | "UPLOADING" }
    | { state: "SUCCESS"; response: { body: any } }
    | { state: "ERROR"; error: { body: { message: string }; response?: Response; status?: number } }
);

export const uploadObjectItemReducer = (
    state: UploadObjectState = {
        state: "CREATING",
        progress: 0,
        fileName: "",
    },
    action: AnyAction
): UploadObjectState => {
    switch (action.type) {
        case START_ACTION:
            return {
                fileName: action.fileName,
                state: "CREATING",
                progress: 0,
            };
        case PROGRESS_ACTION:
            return {
                fileName: state.fileName,
                state: "UPLOADING",
                progress: action.progress,
            };
        case SUCCESS_ACTION:
            return {
                fileName: state.fileName,
                state: "SUCCESS",
                progress: 1,
                response: action.response.body,
            };
        case ERROR_ACTION:
            return {
                fileName: state.fileName,
                state: "ERROR",
                progress: state ? state.progress : 0,
                error: action.error,
            };
        default:
            return state;
    }
};

export const uploadLwm2mReducer = collectionReducer<UploadObjectState>({
    typePrefix: ACTION_PREFIX,
    itemReducer: uploadObjectItemReducer,
});

export const firmwareUploadsPropType = PropTypes.objectOf(
    PropTypes.shape({
        state: PropTypes.oneOf(["CREATING", "UPLOADING", "SUCCESS", "ERROR"]).isRequired,
        progress: PropTypes.number.isRequired,
        error: PropTypes.object,
        fileName: PropTypes.string.isRequired,
    })
);
