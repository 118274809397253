import { Loading, Text, TextVariant, Tag, StackPanel, Severity, Orientation, Arrays } from "portal-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckedList, CheckedListItem } from "../../controls/checkedList";
import { useAjaxStateSelector } from "../../creators/AjaxCreator";
import { Team, TeamLoginProfile } from "../../features/account";
import { AccountExpired, switchTeams } from "../../features/login";
import translate from "../../i18n/translate";
import { AppState } from "../../creators/reducers";
import { redirectTo } from "../../script/routing";
import { AccountStatus } from "../../script/utility";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const classes = require("./switchTeam.scss");

const { SUSPENDED, EXPIRED } = AccountStatus;

const defaultStrings = {
    tenant: "Subtenant",
    badConfiguration:
        "Cannot login to the selected team. Contact your account administrator to verify the login configuration.",
    genericError: "Try again, ensure that you are using the correct login method for the selected team.",
    suspended: "Suspended",
};

interface SwitchTeamProps {
    strings: typeof defaultStrings;
    teams: Team[];
}

export const SwitchTeam: React.FunctionComponent<SwitchTeamProps> = (props) => {
    const { teams, strings } = props;
    const [switching, setSwitching] = useState(false);
    const [error, setError] = useState<React.ReactNode>();
    const [account, setAccount] = useState<string>();
    const config = useSelector((state: AppState) => state.config);
    const { error: teamSwitchError, failed: teamSwitchFailed } = useAjaxStateSelector(
        (state) => state.currentTeamSwitch
    );
    const dispatch = useDispatch();

    const handleSwitchTeamSelect = (id: string, alias?: string) => {
        setSwitching(true);
        setError("");
        setAccount(alias || id);
        dispatch(switchTeams({ account_id: id }));
    };

    const teamSortingFunction = (a: Team, b: Team) => {
        const aComp = a.alias || a.display_name || a.id;
        const bComp = b.alias || b.display_name || b.id;
        return aComp.localeCompare(bComp);
    };

    useEffect(() => {
        if (teamSwitchFailed) {
            const { fields } = teamSwitchError?.response?.body;
            if (!fields || !fields.length) {
                return;
            }

            const isAccountSuspended = fields.find(
                (field: any) => field.name === "account_status" && field.message === SUSPENDED
            );
            const isAccountExpired = fields.find(
                (field: any) => field.name === "account_status" && field.message === EXPIRED
            );
            const badConfiguration = fields.find(
                (field: any) => field.name === "authorization_endpoint" || field.name === "sso_endpoint"
            );

            let error: React.ReactNode = fields.find((field: any) => field.name === "reason")?.message ?? "";

            if (isAccountSuspended) {
                // display reason message
            } else if (isAccountExpired) {
                error = <AccountExpired config={config} />;
            } else if (account) {
                redirectTo({ path: `/team/${account}`, delay: 0 });
                return;
            } else {
                error = (
                    <React.Fragment>
                        {badConfiguration ? strings.badConfiguration : strings.genericError}
                        <Text variant={TextVariant.Help} when={!!error}>
                            {error}
                        </Text>
                    </React.Fragment>
                );
            }
            setError(error);
            setSwitching(false);
            setAccount("");
        }
    }, [teamSwitchFailed]);

    const selection = teams
        .filter((team) => team.alias !== "/")
        .sort(teamSortingFunction)
        .map((account, index) => {
            const { alias, id, parent_id, display_name, status, login_profiles } = account;
            const displayName = display_name || alias || id;

            let descriptionText = "";
            if (displayName === alias) {
                descriptionText = id;
            } else if (displayName !== id) {
                descriptionText = alias ? `${alias} ${id}` : id;
            }

            let profiles;
            if (login_profiles?.length) {
                profiles = Object.entries(Arrays.groupBy(login_profiles, (x) => x.type)).map((entry) => {
                    const type = entry[0];
                    const profilesByType = entry[1] as TeamLoginProfile[];

                    return <Tag key={type} text={type} tooltip={profilesByType.map((x) => x.name).join("\n")} />;
                });
            }

            let listItemTitle: string | JSX.Element = displayName;
            if (status === "SUSPENDED") {
                listItemTitle = (
                    <StackPanel orientation={Orientation.Horizontal}>
                        {displayName}
                        <Tag severity={Severity.Warning} text={strings.suspended} />
                    </StackPanel>
                );
            }

            return (
                <CheckedListItem
                    key={index}
                    id={`id-${id}`}
                    title={listItemTitle}
                    description={`${descriptionText} ${parent_id ? strings.tenant : ""}`}
                    secondaryText={profiles}
                    searchText={displayName}
                    unselectable
                    onClick={() => handleSwitchTeamSelect(id, alias)}
                    disabled={status !== "ACTIVE"}
                />
            );
        });

    return (
        <Loading random busy={switching}>
            <Text when={error} variant={TextVariant.Error}>
                {error}
            </Text>
            <CheckedList className={classes.switchTeam} canFilter>
                {selection}
            </CheckedList>
        </Loading>
    );
};

SwitchTeam.defaultProps = {
    strings: defaultStrings,
};

SwitchTeam.displayName = "SwitchTeam";

export default translate("SwitchTeam")(SwitchTeam);
