import PropTypes from "prop-types";
import { Action, AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { Response } from "superagent";
import { Config } from "../../../../typings/interfaces";
import { addAuth } from "../../../../creators/AjaxApiCreator";
import { collectionReducer } from "../../../../creators/CollectionCreator";
import { configAjax, ConfigAjaxOptions } from "../../../../script/ajax";
import { md5Buffer } from "../../../../script/md5-buffer";

const ACTION_PREFIX = "FW_UPLOAD_";
const START_ACTION = `${ACTION_PREFIX}START`;
const SUCCESS_ACTION = `${ACTION_PREFIX}SUCCESS`;
const PROGRESS_ACTION = `${ACTION_PREFIX}PROGRESS`;
const ERROR_ACTION = `${ACTION_PREFIX}ERROR`;

const HUNDRED_MIB = 100 * 1024 * 1024;
const CHUNK_CONTENT_TYPE = "binary/octet-stream";
const NUMBER_OF_RETRIES_ON_ERROR = 1;

const firmwareAjax = (options: ConfigAjaxOptions) =>
    configAjax("v3_base")({
        ...options,
        headers: addAuth(options.headers),
    });

const blobToArrayBuffer = (blob: Blob): Promise<ArrayBuffer> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(blob);

        reader.addEventListener("load", () => {
            resolve(reader.result as ArrayBuffer);
        });

        reader.addEventListener("error", () => {
            reject(reader.error);
        });
    });

interface ChunkedUploadFirmwareOptions {
    id: string;
    name: string;
    description: string;
    file: File;
    getState: () => { config: Config };
    dispatch: ThunkDispatch<{ config: Config }, never, Action>;
    chunkSize: number;
}

const chunkedUploadFirmware = ({
    id,
    name,
    description,
    file,
    getState,
    dispatch,
    chunkSize,
}: ChunkedUploadFirmwareOptions): Promise<{}> => {
    const postChunk = ({
        blob,
        uploadId,
        attempt = 1,
    }: {
        blob: Blob;
        uploadId: string;
        attempt?: number;
    }): Promise<any> =>
        blobToArrayBuffer(blob)
            .then((buffer) => md5Buffer(buffer).then((checksum) => ({ checksum, buffer })))
            .then(({ checksum, buffer }) =>
                firmwareAjax({
                    getState,
                    data: buffer,
                    param: `firmware-images/upload-jobs/${uploadId}/chunks`,
                    method: "POST",
                    type: CHUNK_CONTENT_TYPE,
                    headers: { "Content-MD5": checksum },
                })
            )
            .catch((error) => {
                if (attempt > NUMBER_OF_RETRIES_ON_ERROR) {
                    throw error;
                }

                return postChunk({ blob, uploadId, attempt: attempt + 1 });
            });

    return firmwareAjax({
        getState,
        param: "firmware-images/upload-jobs",
        data: { name, description },
        method: "POST",
    }).then(({ body: { id: uploadId } }) => {
        const chunkCount = Math.ceil(file.size / chunkSize);

        dispatch({ type: PROGRESS_ACTION, id, progress: 0 });

        // Upload chunks of chunkSize bytes
        const uploadChunk = (chunkIndex: number): Promise<{ uploadId: string }> => {
            const blob = file.slice(chunkIndex * chunkSize, (chunkIndex + 1) * chunkSize, CHUNK_CONTENT_TYPE);

            return postChunk({ blob, uploadId }).then(() => {
                dispatch({ type: PROGRESS_ACTION, id, progress: (chunkIndex + 1) / chunkCount });
                // If not complete, upload the next chunk
                return chunkIndex >= chunkCount - 1 ? Promise.resolve({ uploadId }) : uploadChunk(chunkIndex + 1);
            });
        };

        return uploadChunk(0)
            .then(({ uploadId }) => {
                const emptyBlob = new Blob([], { type: CHUNK_CONTENT_TYPE });
                // Finish upload by posting an empty chunk
                return postChunk({ uploadId, blob: emptyBlob });
            })
            .catch((error) => {
                const reThrow = () => {
                    throw error;
                };

                // Attempt to clean up by deleting failed upload jobs, allowing the name to be used again
                return firmwareAjax({
                    getState,
                    param: `firmware-images/upload-jobs/${uploadId}`,
                    method: "DELETE",
                }).then(reThrow, reThrow);
            });
    });
};

interface SmallUploadFirmwareOptions {
    name: string;
    description: string;
    file: File;
    imageEncryption: string;
    getState: () => { config: Config };
}

const smallUploadFirmware = ({
    name,
    description,
    file,
    imageEncryption,
    getState,
}: SmallUploadFirmwareOptions): Promise<{}> => {
    const form = new FormData();
    form.append("datafile", file);
    form.append("name", name);

    if (description) {
        form.append("description", description);
    }

    if (imageEncryption) {
        form.append("datafile_encryption", imageEncryption);
    }

    return firmwareAjax({
        getState,
        param: "firmware-images",
        data: form,
        method: "POST",
        type: null,
    });
};

export interface UploadFirmwareOptions {
    data: {
        [index: string]: string | File;
        name: string;
        description: string;
        file: File;
        imageEncryption: string;
    };
    id: string;
}

export const uploadFirmware = ({
    data,
    id,
}: UploadFirmwareOptions): ThunkAction<Promise<void>, { config: Config }, never, Action> => (dispatch, getState) => {
    const config = getState().config;
    const enableChunked = config?.featureToggle?.chunkedFirmwareUpload ?? false;
    const chunkSize = config?.firmwareUploadChunkBytes ?? HUNDRED_MIB;
    const chunkedThreshold = config?.firmwareUploadChunkedThresholdBytes ?? HUNDRED_MIB;

    dispatch({ type: START_ACTION, id, fileName: data.file.name });

    const promise =
        enableChunked && data.file.size > chunkedThreshold
            ? chunkedUploadFirmware({ ...data, id, getState, dispatch, chunkSize })
            : smallUploadFirmware({ ...data, getState });

    return promise.then(
        () => {
            dispatch({ type: SUCCESS_ACTION, id });
        },
        (reason: { response?: Response; status?: number }) => {
            dispatch({ type: ERROR_ACTION, id, error: reason });
        }
    );
};

/**
 * State of one firmware upload. Redux state firmwareUploads key consists of a map of string id to
 * an UploadFirmwareState.
 */
export type UploadFirmwareState = { progress: number; fileName: string } & (
    | { state: "CREATING" | "UPLOADING" | "SUCCESS" }
    | { state: "ERROR"; error: { body: { message: string }; response?: Response; status?: number } }
);

export const uploadFirmwareItemReducer = (
    state: UploadFirmwareState = {
        state: "CREATING",
        progress: 0,
        fileName: "",
    },
    action: AnyAction
): UploadFirmwareState => {
    switch (action.type) {
        case START_ACTION:
            return {
                fileName: action.fileName,
                state: "CREATING",
                progress: 0,
            };
        case PROGRESS_ACTION:
            return {
                fileName: state.fileName,
                state: "UPLOADING",
                progress: action.progress,
            };
        case SUCCESS_ACTION:
            return { fileName: state.fileName, state: "SUCCESS", progress: 1 };
        case ERROR_ACTION:
            return {
                fileName: state.fileName,
                state: "ERROR",
                progress: state ? state.progress : 0,
                error: action.error,
            };
        default:
            return state;
    }
};

export const uploadFirmwareReducer = collectionReducer<UploadFirmwareState>({
    typePrefix: ACTION_PREFIX,
    itemReducer: uploadFirmwareItemReducer,
});

export const firmwareUploadsPropType = PropTypes.objectOf(
    PropTypes.shape({
        state: PropTypes.oneOf(["CREATING", "UPLOADING", "SUCCESS", "ERROR"]).isRequired,
        progress: PropTypes.number.isRequired,
        error: PropTypes.object,
        fileName: PropTypes.string.isRequired,
    })
);
