import { Strings } from "portal-components";
import { Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { Response } from "superagent";
import { AppState } from "../../../creators/reducers";
import { AccountStatus, loadCookie, resolveUrl, saveCookie } from "../../../script/utility";
import { getLoginCookie, setLoginCookie } from "../creator";
import {
    FLOW_STORAGE_KEY,
    LOGIN_FLOW,
    NEXT_STORAGE_KEY,
    SILENT_STORAGE_KEY,
    SILENT_TEAM_LOGIN_FLOW,
    TEAM_STORAGE_KEY,
} from "../federated/auth/container";
import * as OIDC from "../federated/oidc";
import {
    getIdpsForUser,
    Idp,
    impersonate,
    loadCurrentAccount,
    loadCurrentTeams,
    LoginCredentials,
    LoginResponse,
    teamLogout,
    postLogin,
} from "./api";
import { EnterCredentialsStep, LoadError, LoadIdpError, LoginError, LoginTeamAction, LoginTeamState } from "./reducer";

export const SAML_FLOW_STORAGE_KEY = "saml-flow";
export const SAML_TEAM_STORAGE_KEY = "team-login-saml-team";
export const SAML_NEXT_STORAGE_KEY = "team-login-saml-next";
const ISSUER_STORAGE_KEY = "team-login-issuer";

export const SAML_LOGIN_FLOW = "login";
export const SAML_REAUTH_FLOW = "reauth";

type LoginTeamThunk = ThunkAction<Promise<void>, AppState, never, LoginTeamAction>;

/**
 * Dispatch this thunk to start the login process for the given team. If we are already logged in to the correct
 * team, sets the state to success. If we are logged in to the wrong team, sets the state to ask for switch team
 * confirmation. Otherwise, requests email to start login.
 * @param team Team alias or id
 * @param next Application URL to route to after login
 * @returns {Function} Thunk
 */
export const logInToTeam = ({ team, next }: { team: string; next: string }): LoginTeamThunk => (dispatch, getState) => {
    dispatch({ type: "TEAM_LOGIN_LOADING_START", team, next });

    if (loadCookie("token")) {
        // Load current teams to test current token and check if we're logged in to the correct team
        return loadCurrentAccount({ getState }).then(
            (account) => {
                const match = [account.id, ...account.aliases].includes(team);
                dispatch(match ? { type: "TEAM_LOGIN_SUCCESS" } : { type: "TEAM_LOGIN_REQUEST_CONFIRM" });
            },
            (error) => {
                if (error.status === 401) {
                    // Stored token was invalid. Start login to new team.
                    dispatch({ type: "TEAM_LOGIN_REQUEST_EMAIL" });
                } else {
                    dispatch({
                        type: "TEAM_LOGIN_LOADING_ERROR",
                        error: LoadError.LOAD_ACCOUNT_FAILED,
                        response: error.response && error.response.body,
                    });
                }
            }
        );
    } else {
        dispatch({ type: "TEAM_LOGIN_REQUEST_EMAIL" });
        return Promise.resolve();
    }
};

/**
 * Dispatch this thunk when the user has confirmed that they wish to switch team.
 * Uses the impersonate API to switch team if possible, otherwise loads the IdP to start the login flow.
 * @returns {Function} Thunk
 */
export const confirmTeamSwitch = (assignLocation = window.location.assign.bind(window.location)): LoginTeamThunk => (
    dispatch,
    getState
) => {
    const { teamLogin } = getState();
    const { next = "", team = "" } = teamLogin as {
        next?: string;
        team?: string;
    };

    dispatch({ type: "TEAM_LOGIN_LOADING_START", team, next });

    return loadCurrentTeams({ getState })
        .then(
            (teams) => {
                const matchingTeam = teams.find(({ id, alias }) => id === team || alias === team);

                return matchingTeam
                    ? impersonate({
                          getState,
                          accountId: matchingTeam.id,
                      }).catch(() => undefined)
                    : undefined;
            },
            () => undefined
        )
        .then((data) => {
            if (data) {
                // Impersonate succeeded, store new token
                handleLoginSuccess({
                    dispatch,
                    data: { ...data, role: data.roles[0] },
                });
            } else {
                // Impersonate failed, silently logout then perform full login.
                return teamLogout({ getState }).then(
                    (body) => {
                        const iamRedirectUri = body.redirect_uri;

                        if (iamRedirectUri) {
                            // Mbed logout requires a trip to the Auth0 logout page
                            const { idpLogoutRedirectUri } = getState().config;
                            let returnToBase;

                            if (idpLogoutRedirectUri) {
                                returnToBase = resolveUrl(idpLogoutRedirectUri);
                            } else {
                                const iamReturnToMatch = /returnTo=([^&]*)/.exec(iamRedirectUri);
                                returnToBase = iamReturnToMatch ? decodeURIComponent(iamReturnToMatch[1]) : "";
                            }

                            // Replace IAM-templated returnTo parameter with team login page to resume the flow
                            const returnToSearch = Strings.convertObjectToSearchString({
                                next,
                                team,
                            });
                            const returnTo = new URL(`/team-login${returnToSearch}`, returnToBase).href;
                            const redirectUrl = iamRedirectUri.replace(
                                /returnTo=[^&]*/,
                                `returnTo=${encodeURIComponent(returnTo)}`
                            );
                            assignLocation(redirectUrl);
                        } else {
                            dispatch({ type: "TEAM_LOGIN_REQUEST_EMAIL" });
                        }
                    },
                    (error) => {
                        if (error.status === 401) {
                            // Logout failed because the token is invalid. Silently switch to full login.
                            dispatch({ type: "TEAM_LOGIN_REQUEST_EMAIL" });
                        } else {
                            dispatch({
                                type: "TEAM_LOGIN_LOADING_ERROR",
                                error: LoadError.LOGOUT_FAILED,
                                response: error.response && error.response.body,
                            });
                        }
                    }
                );
            }
        });
};

export interface SubmitEmailOptions {
    email: string;
    assignLocation?: (url: string) => void;
}

/**
 * Thunk dispatched to load IdPs for the user with the given email in the current team.
 */
export const submitEmail = ({
    email,
    assignLocation = window.location.assign.bind(window.location),
}: SubmitEmailOptions): LoginTeamThunk => (dispatch, getState) => {
    const { teamLogin } = getState();
    const { next = "", team = "" } = teamLogin as {
        next?: string;
        team?: string;
    };

    dispatch({ type: "TEAM_LOGIN_SUBMIT_EMAIL", email });

    return getIdpsForUser({ getState, email, team }).then(
        (idps) => {
            if (idps.length === 0) {
                dispatch({
                    type: "TEAM_LOGIN_LOAD_IDP_ERROR",
                    error: LoadIdpError.NOT_FOUND,
                });
            } else {
                if (idps.length === 0) {
                    dispatch({
                        type: "TEAM_LOGIN_LOAD_IDP_ERROR",
                        error: LoadIdpError.UNKNOWN_IDP_TYPE,
                    });
                } else if (idps.length === 1) {
                    return dispatch(
                        startLogin({
                            assignLocation,
                            idp: idps[0],
                            team,
                            next,
                            email,
                        })
                    );
                } else {
                    dispatch({
                        type: "TEAM_LOGIN_SELECT_IDP",
                        idps: idps,
                    });
                }
            }
        },
        (error) => {
            dispatch({
                type: "TEAM_LOGIN_LOAD_IDP_ERROR",
                error: LoadIdpError.LOAD_IDP_FAILED,
                response: error.response && error.response.body,
            });
        }
    );
};

/* This is to get the list of all IdP for the CURRENT team, even without a specific user */
export const fetchAllIdpForTeam = (): LoginTeamThunk => (dispatch, getState) => {
    const { teamLogin } = getState();
    const { team = "" } = teamLogin as { next?: string; team?: string };

    return getIdpsForUser({ getState, team }).then((idps) => {
        const supportedIdps = idps.filter(({ type }) => ["NATIVE", "MBED", "SAML2"].includes(type));
        dispatch({ type: "TEAM_LOGIN_LIST_ALL_IDPS", idps: supportedIdps });
    });
};

export interface SelectIdpOptions {
    idpId: string;
    assignLocation?: (url: string) => void;
}

/**
 * Dispatch this thunk to begin login for the given IdP.
 * @param idpId ID of an IdP currently in the team login state
 * @param assignLocation
 * @returns {Function}
 */
export const selectIdp = ({
    idpId,
    assignLocation = window.location.assign.bind(window.location),
}: SelectIdpOptions): LoginTeamThunk => (dispatch, getState) => {
    const { teamLogin } = getState();

    if (teamLogin.state === "SELECT_IDP") {
        const { idps, next, team, email } = teamLogin;
        const idp = idps.find(({ id }) => id === idpId);

        if (idp) {
            return dispatch(startLogin({ assignLocation, idp, next, team, email }));
        }
    }

    return Promise.resolve();
};

interface StartLoginOptions {
    assignLocation: (url: string) => void;
    idp: Idp;
    next: string;
    email: string;
    team: string;
}

// Internal thunk that starts log in to the given IdP
const startLogin = ({ assignLocation, idp, email, next, team }: StartLoginOptions): LoginTeamThunk => (
    dispatch,
    getState
) => {
    switch (idp.type) {
        case "NATIVE": {
            setLoginCookie({ username: email });
            dispatch({
                type: "TEAM_LOGIN_REQUEST_CREDENTIALS",
                step: "LOGIN",
                isCaptcha: false,
            });
            break;
        }
        case "MBED": {
            const {
                issuer,
                oidc: { client_id: clientId = "", authorization_endpoint: authorizationUrl = "" },
                id,
            } = idp;
            sessionStorage.setItem(FLOW_STORAGE_KEY, SILENT_TEAM_LOGIN_FLOW);
            sessionStorage.setItem(NEXT_STORAGE_KEY, next);
            sessionStorage.setItem(SILENT_STORAGE_KEY, JSON.stringify({ id, clientId, authorizationUrl }));
            sessionStorage.setItem(TEAM_STORAGE_KEY, team);
            sessionStorage.setItem(ISSUER_STORAGE_KEY, issuer);
            setLoginCookie({ ...getLoginCookie(), issuer });
            OIDC.goToIdPSilent({ clientId, authorizationUrl });
            break;
        }
        case "OIDC": {
            dispatch({ type: "TEAM_LOGIN_LOADING_START", team, next });
            return postLogin({
                getState,
                credentials: { idp_id: idp.id, account: team },
            }).then(
                ({ redirect_uri: redirectUri = "" }) => {
                    setLoginCookie({ ...getLoginCookie(), issuer: idp.issuer });
                    sessionStorage.setItem(TEAM_STORAGE_KEY, team);
                    sessionStorage.setItem(FLOW_STORAGE_KEY, LOGIN_FLOW);
                    OIDC.goToIdP(redirectUri);
                },
                (error) => {
                    dispatch({
                        type: "TEAM_LOGIN_LOADING_ERROR",
                        error: LoadError.LOGIN_FAILED,
                        response: error.response && error.response.body,
                    });
                }
            );
        }
        case "SAML2": {
            dispatch({ type: "TEAM_LOGIN_LOADING_START", team, next });

            return postLogin({
                getState,
                credentials: { idp_id: idp.id, account: team },
            }).then(
                ({ redirect_uri: redirectUri }) => {
                    setLoginCookie({ ...getLoginCookie(), idpId: idp.id });
                    sessionStorage.setItem(SAML_TEAM_STORAGE_KEY, team);
                    sessionStorage.setItem(SAML_FLOW_STORAGE_KEY, SAML_LOGIN_FLOW);
                    sessionStorage.setItem(SAML_NEXT_STORAGE_KEY, next);
                    assignLocation(redirectUri || "");
                },
                (error) => {
                    dispatch({
                        type: "TEAM_LOGIN_LOADING_ERROR",
                        error: LoadError.LOGIN_FAILED,
                        response: error.response && error.response.body,
                    });
                }
            );
        }
    }

    return Promise.resolve();
};

export interface SubmitLoginOptions {
    password: string;
    captchaId?: string;
    captchaAnswer?: string;
}

/**
 * Dispatch this thunk when the user has submitted an email and password, and optionally a captcha response.
 * Attempts to log in to the team in the state.
 * @returns {Function} Thunk
 */
export const submitLogin = ({ password, captchaId, captchaAnswer }: SubmitLoginOptions): LoginTeamThunk =>
    doLoginRequest({ password, captcha: captchaAnswer, captcha_id: captchaId });

export interface SubmitOtpOptions {
    otp: string;
    captchaAnswer?: string;
    captchaId?: string;
}

/**
 * Dispatch this thunk when the user has submitted an OTP code, and optionally a captcha response.
 * Attempts to log in to the team in the state.
 * @returns {Function} Thunk
 */
export const submitOtp = ({ otp, captchaAnswer, captchaId }: SubmitOtpOptions): LoginTeamThunk =>
    doLoginRequest({
        otp: otp.replace(/\s+/g, ""),
        captcha: captchaAnswer,
        captcha_id: captchaId,
    });

/**
 * Thunk dispatched after returning from the IdP with a code. Attempts to log in to IAM.
 * @param team Account alias or id
 * @param code Authorization code from the IdP
 * @param next
 * @returns {Function}
 */
export const mbedLogInToTeam = ({ team, code, next }: { team: string; code: string; next: string }): LoginTeamThunk => (
    dispatch,
    getState
) => {
    const issuer = sessionStorage.getItem(ISSUER_STORAGE_KEY);

    if (issuer) {
        sessionStorage.removeItem(ISSUER_STORAGE_KEY);
        dispatch({ type: "TEAM_LOGIN_LOADING_START", team, next });

        const credentials = { code, issuer };

        return postLogin({
            getState,
            credentials: { ...credentials, account: team },
        }).then(
            (data) => {
                handleLoginSuccess({ dispatch, data });
            },
            (error) => {
                dispatch(getLoginErrorAction(error, credentials, getState().teamLogin, true));
            }
        );
    } else {
        dispatch({
            type: "TEAM_LOGIN_LOADING_ERROR",
            error: LoadError.LOGIN_FAILED,
        });
        return Promise.resolve();
    }
};

/**
 * Thunk dispatched after returning from SAML IdP. Reads the saml-response element templated
 * from the server and starts logging in to IAM.
 * @param team Account alias or id
 * @returns {Function} Thunk
 */
export const samlLogInToTeam = ({ team }: { team: string }): LoginTeamThunk => (dispatch, getState) => {
    const responseEl = document.getElementById("saml-response");
    const next = sessionStorage.getItem(SAML_NEXT_STORAGE_KEY) || "/";

    dispatch({ type: "TEAM_LOGIN_LOADING_START", team, next });

    if (responseEl) {
        const responseJson = JSON.parse(responseEl.innerText);
        const credentials = {
            saml_response: responseJson.samlResponse,
            relay_state: responseJson.relayState,
        };

        return postLogin({ getState, credentials }).then(
            (data) => {
                handleLoginSuccess({ dispatch, data });
            },
            (error) => {
                dispatch(getLoginErrorAction(error, credentials, getState().teamLogin, true));
            }
        );
    } else {
        dispatch({
            type: "TEAM_LOGIN_LOADING_ERROR",
            error: LoadError.LOGIN_FAILED,
        });
        return Promise.resolve();
    }
};

// Internal thunk for submitting user credentials to IAM and parsing the response
const doLoginRequest = (inputCredentials: LoginCredentials): LoginTeamThunk => (dispatch, getState) => {
    const { teamLogin } = getState();

    // Don't initialise email: for Mbed logins, we don't want to send username as empty string
    const { team = "", email } = teamLogin as { [key: string]: string };

    const existingCredentials = teamLogin.state === "ENTER_CREDENTIALS" ? teamLogin.credentials : {};
    const credentials = { ...existingCredentials, ...inputCredentials };

    dispatch({ type: "TEAM_LOGIN_SUBMIT_LOGIN", credentials });
    return postLogin({
        getState,
        credentials: { ...credentials, account: team, username: email },
    }).then(
        (data) => {
            handleLoginSuccess({ dispatch, data });
        },
        (error) => {
            dispatch(getLoginErrorAction(error, credentials, teamLogin));
        }
    );
};

const getLoginErrorAction = (
    error: { response?: Response },
    credentials: LoginCredentials,
    teamLogin: LoginTeamState,
    federated = false
): LoginTeamAction => {
    const currentStep: EnterCredentialsStep = teamLogin.state === "ENTER_CREDENTIALS" ? teamLogin.step : "LOGIN";
    const currentIsCaptcha = teamLogin.state === "ENTER_CREDENTIALS" ? teamLogin.isCaptcha : false;

    // If we're doing a federated login, a login error is terminal. Otherwise, the user can re-enter their credentials.
    const errorAction: LoginTeamAction = federated
        ? {
              type: "TEAM_LOGIN_LOADING_ERROR",
              error: LoadError.LOGIN_FAILED,
              response: error.response && error.response.body,
          }
        : {
              type: "TEAM_LOGIN_REQUEST_CREDENTIALS",
              step: currentStep,
              isCaptcha: currentIsCaptcha,
              error: LoginError.LOGIN_FAILED,
              credentials,
          };

    if (error.response) {
        const { body } = error.response;
        const fields: { name: string; message: string }[] = body.fields || [];

        if (fields.find((field) => field.name === "account_status" && field.message === AccountStatus.SUSPENDED)) {
            const reason = fields.find((field) => field.name === "reason");
            const suspendedMessage = (reason && reason.message) || undefined;
            return { type: "TEAM_LOGIN_SUSPENDED", reason: suspendedMessage };
        } else if (fields.find((field) => field.name === "status" && field.message === AccountStatus.INACTIVE)) {
            return { type: "TEAM_LOGIN_INACTIVE" };
        } else if (fields.find((field) => field.name === "account_status" && field.message === AccountStatus.EXPIRED)) {
            return { type: "TEAM_LOGIN_EXPIRED" };
        } else {
            const otpField = fields.find((field) => field.name === "otp");
            const captchaField = fields.find((field) => field.name === "captcha");
            const isCaptcha = !!captchaField;

            if (otpField) {
                const errorType = otpField.message === "null" ? undefined : LoginError.OTP_INVALID;
                return {
                    type: "TEAM_LOGIN_REQUEST_CREDENTIALS",
                    isCaptcha,
                    step: "MFA",
                    error: errorType,
                    credentials,
                };
            } else if (captchaField) {
                const errorType = captchaField.message === "null" ? undefined : LoginError.CAPTCHA_INVALID;
                const nextStep = credentials.issuer ? "MFA" : currentStep;
                return {
                    type: "TEAM_LOGIN_REQUEST_CREDENTIALS",
                    isCaptcha,
                    step: nextStep,
                    error: errorType,
                    credentials,
                };
            } else {
                return errorAction;
            }
        }
    } else {
        return errorAction;
    }
};

// Call after login to IAM succeeds.
const handleLoginSuccess = ({ dispatch, data }: { dispatch: Dispatch<LoginTeamAction>; data: LoginResponse }) => {
    const threeDaysFromNow = new Date(Date.now() + 3 * 24 * 60 * 60 * 1000);
    saveCookie("token", data.token, { expires: threeDaysFromNow });
    localStorage.setItem("login-event", "login" + Math.random());
    dispatch({ type: "TEAM_LOGIN_SUCCESS", info: data });
};

export const reset = () => {
    return { type: "TEAM_LOGIN_RESET" };
};
