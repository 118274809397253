import { Button, ButtonVariant, Icons } from "portal-components";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { CheckedList, CheckedListItem } from "../../../controls/checkedList";
import translate, { DefaultStrings } from "../../../i18n/translate";
import { DeviceQuery } from "./creator";

const defaultStrings = {
    title: "Select active filter",
    placeholderForFilter: "Search",
    unfilteredItemTitle: "Unfiltered",
    manageFilters: "Manage saved filters",
};

interface FilterSearchComponentProps extends RouteComponentProps {
    strings: DefaultStrings<typeof defaultStrings>;
    selectedFilterId: string;
    onSelected: (id?: string) => void;
    filters: DeviceQuery[];
}

export class FilterSearch extends React.PureComponent<FilterSearchComponentProps> {
    public static defaultProps = {
        strings: defaultStrings,
    };

    constructor(props: FilterSearchComponentProps) {
        super(props);

        this.handleManageFiltersClick = this.handleManageFiltersClick.bind(this);
        this.handleFilterClick = this.handleFilterClick.bind(this);
        this.renderItem = this.renderItem.bind(this);
    }

    handleManageFiltersClick() {
        const search = this.props.location.search;
        this.props.history.push(`/devices/list/savedFilters${search}`);
    }

    handleFilterClick(id: string) {
        this.props?.onSelected(id);
    }

    renderItem(filter: DeviceQuery, index: number) {
        const { selectedFilterId } = this.props;
        const isSelected = filter.id === selectedFilterId;
        const handleSelect = () => this.handleFilterClick(filter.id);

        return <CheckedListItem key={index} title={filter.name} onClick={handleSelect} selected={isSelected} />;
    }

    render() {
        const { strings } = this.props;

        return (
            <React.Fragment>
                <CheckedList placeholderForFilter={strings.placeholderForFilter} canFilter>
                    {this.props.filters.map(this.renderItem)}
                </CheckedList>
                <Button
                    icon={Icons.ManageList}
                    text={strings.manageFilters}
                    onClick={this.handleManageFiltersClick}
                    variant={ButtonVariant.Light}
                />
            </React.Fragment>
        );
    }
}

export default withRouter(translate("FilterSearch")(FilterSearch) as React.ComponentType<any>);
