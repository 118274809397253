import { makeConfigApiCreator, ParseFunction } from "../../../../creators";
import { ListResponse } from "../../../../script";
import history from "../../../../script/history";

export const MBED_CLOUD = "MBED_CLOUD";
export const GLOBAL_SIGN = "GLOBAL_SIGN";
export const CFSSL = "CFSSL";
export const CFSSL_AUTH = "CFSSL_AUTH";

interface CertificateIssuer {
    id?: string;
    created_at?: Date;
    description?: string;
    issuer_attributes?: {
        [key: string]: string;
    };
    issuer_credentials?: {
        [key: string]: string;
    };
    issuer_type?: "GLOBAL_SIGN" | "CFSSL_AUTH" | "MBED_CLOUD";
    name?: string;
}

const INTERNAL = "INTERNAL";
const INTERNAL_CA: CertificateIssuer = {
    id: INTERNAL,
    issuer_type: MBED_CLOUD,
    description: "Native certificate authority",
    name: "Native",
};

const makeCertificateCreator = <T>(type: string, parseFunction?: ParseFunction<T>) =>
    makeConfigApiCreator<T>("v3_base", {
        errorCallout: true,
        errorRedirect: () => history.push("/certificates/list"),
    })(type, parseFunction);

const { createThunk: createGetCertificateIssuersThunk, reducer: getReducer } = makeCertificateCreator<
    ListResponse<CertificateIssuer>
>("external_certificate_issuers", (untypedResponse: unknown) => {
    const response = untypedResponse as ListResponse<CertificateIssuer>;
    response.data.push(INTERNAL_CA);
    return response;
});
const getAction = createGetCertificateIssuersThunk({ param: "certificate-issuers" });

const { createThunk: createDeleteCertificateIssuerThunk, reducer: deleteReducer } = makeCertificateCreator<void>(
    "external_certificate_issuers_delete"
);

const deleteAction = (id: string) =>
    createDeleteCertificateIssuerThunk({
        param: `certificate-issuers/${id}`,
        method: "DELETE",
        errorRedirect: false,
    });

interface CertificateIssuerConfigurationParameters {
    id?: string;
    certificate_issuer_id?: string;
    created_at?: string;
    reference?: string;
    updated_at?: string;
}
const { createThunk: createGetLw2mThunk, reducer: getLwm2mReducer } = makeCertificateCreator<
    CertificateIssuerConfigurationParameters
>("certificate_issuer_lwm2m_get", (untypedResponse: unknown) => {
    const response = untypedResponse as CertificateIssuerConfigurationParameters;
    if (!response.certificate_issuer_id) {
        response.certificate_issuer_id = INTERNAL;
    }
    return response;
});

const getLWM2MAction = createGetLw2mThunk({
    param: "certificate-issuer-configurations/lwm2m",
});

const { createThunk: createUpdateLw2mThunk, reducer: updateLWM2MReducer } = makeCertificateCreator<
    CertificateIssuerConfigurationParameters
>("certificate_issuer_lwm2m_update", (untypedResponse: unknown) => {
    const response = untypedResponse as CertificateIssuerConfigurationParameters;
    if (!response.certificate_issuer_id) {
        response.certificate_issuer_id = INTERNAL;
    }
    return response;
});
const updateLWM2MAction = (issuerId: string) =>
    createUpdateLw2mThunk({
        param: "certificate-issuer-configurations/lwm2m",
        method: "PUT",
        data: {
            certificate_issuer_id: issuerId === INTERNAL ? null : issuerId,
        },
        errorRedirect: false,
    });

interface CertificateIssuerVerification {
    id?: string;
    message?: string;
    successful?: boolean;
}

const { createThunk: createVerifyThunk, reducer: verifyReducer } = makeCertificateCreator<
    CertificateIssuerVerification
>("external_certificate_issuers_verify");
const verifyAction = (id: string) =>
    createVerifyThunk({
        param: `certificate-issuers/${id}/verify`,
        method: "POST",
        errorRedirect: false,
    });

interface ConfigurationsParameters {
    pageSize?: number;
    last?: string;
    order?: string;
    errorCallout?: boolean;
}
// Gets the list of CA configurations
const { createThunk: createGetConfigurationsThunk, reducer: getConfigurationsReducer } = makeCertificateCreator<
    ListResponse<CertificateIssuerVerification>
>("external_certificate_issuer_configurations");
const getConfigurationsAction = ({
    pageSize = 1000,
    last,
    order = "ASC",
    errorCallout = true,
}: ConfigurationsParameters = {}) => {
    const queryParameters = {
        order,
        limit: pageSize?.toString(),
        include: "total_count",
        after: last,
    };

    return createGetConfigurationsThunk({
        param: "certificate-issuer-configurations",
        queryParameters,
        errorCallout,
        errorRedirect: false,
    });
};

interface CertificateIssuerConfigurationParameters {
    id?: string;
    certificate_issuer_id?: string;
    reference?: string;
    errorCallout?: boolean;
}

// Associate a new or existing configuration to specified CA or delete one. Note that they all have the
// same Creator because they all trigger the same action (refresh the list when done).
const { createThunk: createConfigurationThunk, reducer: setConfigurationReducer } = makeCertificateCreator<
    CertificateIssuerConfigurationParameters
>("external_certificate_issuer_set_configuration");
const createConfigurationAction = ({
    reference,
    certificate_issuer_id,
    errorCallout = true,
}: CertificateIssuerConfigurationParameters = {}) =>
    createConfigurationThunk({
        param: "certificate-issuer-configurations",
        data: {
            reference,
            certificate_issuer_id,
        },
        method: "POST",
        errorCallout,
        errorRedirect: false,
    });

const setConfigurationAction = ({
    id,
    certificate_issuer_id,
    errorCallout = true,
}: CertificateIssuerConfigurationParameters) =>
    createConfigurationThunk({
        param: `certificate-issuer-configurations/${id}`,
        data: { certificate_issuer_id },
        method: "PUT",
        errorCallout,
        errorRedirect: false,
    });

const deleteConfigurationAction = ({ id, errorCallout = true }: CertificateIssuerConfigurationParameters) => {
    return createConfigurationThunk({
        param: `certificate-issuer-configurations/${id}`,
        method: "DELETE",
        errorCallout,
        errorRedirect: false,
    });
};

export {
    getAction,
    getReducer,
    deleteAction,
    deleteReducer,
    getLWM2MAction,
    updateLWM2MAction,
    getLwm2mReducer,
    updateLWM2MReducer,
    INTERNAL,
    verifyAction,
    verifyReducer,
    getConfigurationsAction,
    getConfigurationsReducer,
    setConfigurationAction,
    createConfigurationAction,
    setConfigurationReducer,
    deleteConfigurationAction,
    CertificateIssuerConfigurationParameters,
    CertificateIssuer,
};
