declare interface String {
    format: (...args: any[]) => string;
}

String.prototype.format = function (...args: any[]) {
    return this.replace(/\{\{|\}\}|\{(\d+)\}/g, function (curlyBrack, index) {
        return curlyBrack === "{{" ? "{" : curlyBrack === "}}" ? "}" : args[index];
    });
};

// IE11 does not support String.normalize(). String normalization isn't a small task,
// for a possible implementation see https://github.com/walling/unorm, then we just mock
// this function to return original unmodified string.
if (!String.prototype.normalize) {
    String.prototype.normalize = function () {
        return this.toString();
    };
}
