import { applyMiddleware, combineReducers, compose, createStore, Dispatch, Middleware, Store } from "redux";
import { createLogger } from "redux-logger";
import thunkMiddleware from "redux-thunk";
import reducerRegistry from "../creators/reducerRegistry";
import rootReducer, { AppState, appState } from "../creators/reducers";
import { mergeDeep } from "./utility";

let store: Store;

const getTeamName = (state: AppState) => {
    const teamLogin = state.teamLogin;
    const currentaccount = state.currentaccount;
    if (!teamLogin || teamLogin.state !== "SUCCESS") {
        return null;
    }

    if (!currentaccount || currentaccount.requestState !== "LOADED" || currentaccount.data.aliases.length === 0) {
        return null;
    }

    return currentaccount.data.aliases[0];
};

// middleware to take care of cleaning up when we logout
const customMiddleware: Middleware<{}, AppState> = (api) => (next: Dispatch) => (action) => {
    switch (action.type) {
        // When logging out we do not want to keep any data (for example cached values or user sensible
        // information) about the previous session, just drop the old state. Note that we keep track of
        // the current team (if team login) to redirect the user the her correct login page (and clear
        // it after a successful login).
        case "LOGOUT_REQUEST": {
            const teamId = getTeamName(api.getState());
            if (teamId) {
                sessionStorage.setItem("TEAM_LOGIN_ID", teamId);
            }
            break;
        }
        case "TEAM_LOGIN_SUCCESS":
        case "LOGIN_SUCCESS":
            sessionStorage.removeItem("TEAM_LOGIN_ID");
            break;
    }
    next(action);
};

const middlewares = [thunkMiddleware, customMiddleware];
const configureStore = function configureStore(initialState: any) {
    if (process.env.NODE_ENV === "development") {
        middlewares.push(createLogger({ collapsed: true }));
        const composeEnhancers =
            typeof (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === "function"
                ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ maxAge: 15, shouldHotReload: false })
                : null || compose;
        return createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middlewares)));
    }

    return createStore(rootReducer, initialState, applyMiddleware(...middlewares));
};

export default {
    init() {
        if (!store) {
            // Load global config variable from JS file loaded in header of index.
            // We initialize our config reducer with this data.
            const config = mergeDeep(window.appConfig, window.siteConfig || {});
            store = configureStore({ config });
            reducerRegistry.setChangeListener((reducers) => {
                store.replaceReducer(
                    combineReducers({
                        ...rootReducer,
                        ...reducers,
                    })
                );
            });

            // Register existing state AFTER we attached the event listener or we won't
            // get any notification for dynamic reducers already registered.
            reducerRegistry.register(appState);
        }
    },
    getStore() {
        return store;
    },
};
