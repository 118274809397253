import { makeConfigApiCreator } from "../../../creators";
import history from "../../../script/history";

export interface AllServerCredentials {
    lwm2m: CertificateAndUris;
    bootstrap: CertificateAndUris;
}

export interface CertificateAndUris {
    certificate: string;
    url: string;
    urls: {
        coaps: {
            tcp: string;
            tcp_alt: string;
            udp: string;
        };
        ws_dtls_tunnel?: {
            ws?: string;
            wss?: string;
        };
    };
}

const errorAction = () => () => history.push("/certificates/list");

const { reducer: allCredentialsReducer, createThunk: getAllCredentialsThunk } = makeConfigApiCreator<
    AllServerCredentials
>("v3_base", { errorAction, errorCallout: false })("all_server_credentials");

const getAllCredentials = getAllCredentialsThunk({ param: "server-credentials" });

export { getAllCredentials, allCredentialsReducer };
