import React from "react";
import { DeviceBlockCategory, getBlockCategories } from "../../features/devices";
import ApiListConsumer from "../apiListConsumer";
export default class DeviceSuspensionCategory extends React.PureComponent<{}> {
    public render() {
        return (
            <ApiListConsumer getter={getBlockCategories} storePropertyName="deviceBlockCategories">
                {(categories: DeviceBlockCategory[]) => {
                    return categories.map((x) => (
                        <option key={x.reference} value={x.reference}>
                            {x.description}
                        </option>
                    ));
                }}
            </ApiListConsumer>
        );
    }
}
