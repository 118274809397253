import { serialize } from "../../../script/utility";
import { DeviceEvent, GetDeviceEventsParameters } from "../detail/events/creator";
import { makeConfigApiCreator } from "../../../creators/AjaxApiCreator";
import { ListResponse } from "../../../script/types";

const { createThunk: createDeviceEventsThunk, reducer: deviceEventsReducer } = makeConfigApiCreator<
    ListResponse<DeviceEvent>
>("v3_base", {
    errorCallout: true,
})("device_log", (untypedResponse) => {
    const response = untypedResponse as ListResponse<DeviceEvent>;
    response.data = response.data.map((item) => {
        return Object.assign(item, { selected: false });
    });

    if (response.data && response.data.length > 1) {
        response.last = response.data[response.data.length - 1].id;
    }

    return response;
});

const getDeviceEvents = ({ pageSize = 1000, last, order = "DESC", query }: GetDeviceEventsParameters) => {
    const queryString = [query?.replace("id=", "device_id=") || false].filter((f) => !!f).join("&");

    const queryParameters = {
        order,
        limit: pageSize?.toString(),
        include: "total_count",
        after: last,
        filter: queryString ? encodeURIComponent(queryString) : undefined,
    };

    return createDeviceEventsThunk({
        param: "device-events",
        queryParameters,
    });
};

const getFilteredDeviceEvents = (filterQuery: string, pageSize = 1000, last = "") => {
    const query: { [key: string]: string } = {
        limit: `${pageSize}`,
        include: `total_count`,
        filter: `${filterQuery}`,
    };

    if (last) {
        query.after = `${last}`;
    }
    return createDeviceEventsThunk({ param: `device-events?${serialize(query)}` });
};

export { getDeviceEvents, getFilteredDeviceEvents, deviceEventsReducer };
