import {
    copyToClipboard,
    Icons,
    IconSize,
    If,
    MenuItem,
    Modal,
    NavigationMenuGroupItemLink,
    SearchableFeature,
} from "portal-components";
import React from "react";
import { connect } from "react-redux";
import { Config } from "../../typings/interfaces";
import { AjaxState, RequestState } from "../../creators/AjaxCreator";
import { CurrentAccount, CurrentUser, getUserTeams, Team } from "../../features/account";
import translate from "../../i18n/translate";
import { AppDispatch, AppState } from "../../creators/reducers";
import { ListResponse } from "../../script/types";
import SwitchTeam from "./switchTeam";
import { getFeatureToggle } from "../../script/utility";

declare const __VERSION__: string;

const defaultStrings = {
    application: "Application",
    uiVersion: "UI version",
    switchAccountHeader: "Select a team to switch to",
    untitledTeam: "Untitled team",
};

interface TeamMenuProps {
    dispatch: AppDispatch;
    config?: Config;
    strings: typeof defaultStrings;
    account?: AjaxState<CurrentAccount>;
    teams?: AjaxState<ListResponse<Team>>;
    user?: AjaxState<CurrentUser>;
    style?: object;
}

interface State {
    switchingTeam: boolean;
}

export class TeamMenu extends React.Component<TeamMenuProps, State> {
    public static readonly defaultProps = {
        strings: defaultStrings,
    };

    public readonly state: State = {
        switchingTeam: false,
    };

    public constructor(props: Readonly<TeamMenuProps>) {
        super(props);

        this.handleSwitchTeam = this.handleSwitchTeam.bind(this);
        this.handleCancelSwitchTeam = this.handleCancelSwitchTeam.bind(this);
    }

    public componentDidMount() {
        this.props.dispatch(getUserTeams);
    }

    public shouldComponentUpdate(nextProps: Readonly<TeamMenuProps>) {
        // User's definition comes from "outside" and it might be refreshed
        // externally. We do not refresh this component to avoid flickering
        // while reloading.
        if (nextProps.user && nextProps.user.requestState !== RequestState.LOADED) {
            return false;
        }

        // Account data MIGHT be refreshed externally (with a timer),
        // do not refresh this component to avoid flickering.
        if (nextProps.account && nextProps.account.requestState !== RequestState.LOADED) {
            return false;
        }

        return true;
    }

    public render() {
        const { account, user, strings } = this.props;

        const teams =
            this.props.teams && this.props.teams.requestState === RequestState.LOADED ? this.props.teams.data.data : [];

        const userNameLine =
            user && user.requestState === RequestState.LOADED ? user.data.full_name || user.data.email : "";

        const currentTeamId = account && account.requestState === RequestState.LOADED ? account.data.id : undefined;

        const team = teams.find((x) => x.id === currentTeamId) || ({} as Team);
        const teamNameLine = team.display_name?.trim() || team.alias?.trim() || strings.untitledTeam;
        const tooltip = `${teamNameLine}\n${userNameLine}\n\n${strings.uiVersion}: ${__VERSION__}`;
        const useNewMenu = getFeatureToggle("useNewMenu", this.props);

        return (
            <React.Fragment>
                <SearchableFeature
                    group={strings.application}
                    icon={Icons.User}
                    text={strings.uiVersion}
                    description={__VERSION__}
                    onClick={() => copyToClipboard(__VERSION__)}
                />
                <If condition={!useNewMenu}>
                    <MenuItem
                        when={teams.length > 1}
                        id="switch-account"
                        icon={Icons.SwitchAccount}
                        iconSize={IconSize.Large}
                        text={teamNameLine}
                        textBeforeIcon
                        tooltip={tooltip}
                        onClick={this.handleSwitchTeam}
                    />
                </If>
                <If condition={useNewMenu}>
                    <NavigationMenuGroupItemLink
                        icon={Icons.SwitchAccount}
                        text={teamNameLine}
                        onClick={this.handleSwitchTeam}
                        to={""}
                    />
                </If>
                <Modal
                    title={strings.switchAccountHeader}
                    open={this.state.switchingTeam}
                    onCloseRequest={this.handleCancelSwitchTeam}
                >
                    <SwitchTeam teams={teams} />
                </Modal>
            </React.Fragment>
        );
    }

    private handleSwitchTeam() {
        this.setState({ switchingTeam: true });
    }

    private handleCancelSwitchTeam() {
        this.setState({ switchingTeam: false });
    }
}

export function mapStateToProps(state: AppState) {
    return {
        config: state.config,
        user: state.currentuser,
        account: state.currentaccount,
        teams: state.currentuserteams,
    };
}

export default connect(mapStateToProps)(translate("TeamMenu")(TeamMenu));
