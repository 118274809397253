import { copyToClipboard, DialogSize, Icons, If, Modal, Objects, SearchableFeature, Strings } from "portal-components";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { Config } from "../../typings/interfaces";
import { AjaxState, isLoaded, onGroupStateLoaded, onStateChange } from "../../creators/AjaxCreator";
import { CurrentAccount, CurrentUser, getAccount, getUser } from "../../features/account";
import { getAction as getLuls, LULAccept, ReAuthenticate } from "../../features/login";
import translate from "../../i18n/translate";
import { AppDispatch, AppState } from "../../creators/reducers";
import { redirectTo } from "../../script/routing";
import { reloadPage } from "../../script/utility";
import LoadingComponent from "../loadingComponent";
import { AuthState } from "../reducer";

const defaultStrings = {
    identity: "Identity",
    currentAccount: "Current account ID",
    currentUser: "Current user name",
};

interface Agreement {
    accepted_at?: string;
    accepted_by?: string;
    type?: string;
}

interface AuthenticationBarrierProps {
    dispatch: AppDispatch;
    config: Config;
    auth: AuthState;
    currentAccount: AjaxState<CurrentAccount>;
    currentUser: AjaxState<CurrentUser>;
    agreements?: AjaxState<any>;
    strings: typeof defaultStrings;
    actions: {
        getUser: typeof getUser;
        getLuls: typeof getLuls;
        getAccount: typeof getAccount;
    };
}

type AuthenticationBarrierWithRoutingProps = AuthenticationBarrierProps & RouteComponentProps<Record<string, string>>;

interface AuthenticationBarrierState {
    showReauth: boolean;
    cancelRequested: boolean;
    showLuL: boolean;
    loading: boolean;
}

export class AuthenticationBarrier extends React.Component<
    AuthenticationBarrierWithRoutingProps,
    AuthenticationBarrierState
> {
    public static readonly defaultProps = {
        strings: defaultStrings,
    };

    private static readonly LOGOUT_LOGIN_DELAY = 500; // half second
    private static readonly POLLING_INTERVAL = 1000 * 60 * 5; // 5 minutes

    private unmounted = false;
    private pollingTimerId?: number;

    public state: AuthenticationBarrierState = {
        showReauth: false,
        cancelRequested: false,
        showLuL: false,
        loading: true,
    };

    public constructor(props: Readonly<AuthenticationBarrierWithRoutingProps>) {
        super(props);

        this.isAccepted = this.isAccepted.bind(this);
        this.handleWindowStorage = this.handleWindowStorage.bind(this);
        this.handleReauthClose = this.handleReauthClose.bind(this);
        this.handleLulAccepted = this.handleLulAccepted.bind(this);
        this.handleAccountIdClick = this.handleAccountIdClick.bind(this);
        this.backgroundPolling = this.backgroundPolling.bind(this);
    }

    public static getDerivedStateFromProps(
        props: AuthenticationBarrierWithRoutingProps,
        state: AuthenticationBarrierState
    ) {
        if (state.cancelRequested) {
            return { showReauth: false, cancelRequested: false };
        }
        if (props.auth.requiresReauth) {
            return { showReauth: true };
        }

        return null;
    }

    public componentDidMount() {
        this.checkForRedirects();

        window.addEventListener("storage", this.handleWindowStorage);
        this.backgroundPolling();

        this.props.dispatch(getUser);
        this.props.dispatch(getAccount);
        this.props.actions.getLuls();
    }

    public componentDidUpdate(prevProps: AuthenticationBarrierWithRoutingProps) {
        this.checkForRedirects();

        onStateChange(prevProps.currentUser, this.props.currentUser, {
            loaded: () => {
                // If we've successfully loaded the user data, and from there we see that the user status
                // is RESET we redirect back to login component in order for the user to go through
                // onboarding process.
                if (this.props.auth.isPasswordChangeNeeded) {
                    redirectTo({ path: "/welcome" });
                    return;
                }
            },
        });

        onGroupStateLoaded(
            [prevProps.currentAccount, prevProps.currentUser],
            [this.props.currentAccount, this.props.currentUser],
            (currentAccount, currentUser) => {
                this.setState({ loading: false });

                // Check if MFA is enabled for the environment
                const mfaEnabled = this.props?.config?.featureToggle?.mfa ?? false;

                // Check if organization has two factor enforcement activated but user doens't have it setup
                const mfaEnforced = (currentAccount?.mfa_status ?? "") === "enforced";
                const mfaConfigured = currentUser?.is_totp_enabled ?? false;
                const authenticated = this.props?.auth?.isAuthenticated ?? false;

                // If MFA required, take user to setup and then redirect to LUL acceptance if also required
                if (mfaEnabled && authenticated && mfaEnforced && !mfaConfigured) {
                    redirectTo({ path: "/login-mfasetup", replace: true });
                }
            }
        );

        onStateChange(prevProps.agreements, this.props.agreements, {
            loaded: ({ data }) => {
                if (!data.data.every(this.isAccepted)) {
                    this.setState({ showLuL: true });
                }
            },
        });
    }

    public componentWillUnmount() {
        this.unmounted = true;
        window.clearInterval(this.pollingTimerId);
        window.removeEventListener("storage", this.handleWindowStorage);
    }

    public render() {
        const { auth, strings, children } = this.props;
        const { isAuthenticated, isLoggingOut } = auth;

        const isLoggingIn = !isAuthenticated || Strings.convertSearchStringToObject(location.search).team;

        if (this.state.loading || isLoggingOut || isLoggingIn) {
            return <LoadingComponent random />;
        }

        const reAuthenticateProps = {
            onClose: this.handleReauthClose,
        };

        const LULAcceptProps = {
            isModal: true,
            onAccept: this.handleLulAccepted,
        };

        return (
            <React.Fragment>
                <If condition={this.state.showReauth}>
                    <ReAuthenticate {...reAuthenticateProps} />
                </If>
                <If condition={this.state.showLuL}>
                    <Modal size={DialogSize.Large} canClose={false}>
                        <LULAccept {...LULAcceptProps} />
                    </Modal>
                </If>
                <SearchableFeature
                    group={strings.identity}
                    icon={Icons.User}
                    text={strings.currentAccount}
                    description={isLoaded(this.props.currentAccount) ? this.props.currentAccount?.data?.id ?? "" : ""}
                    onClick={this.handleAccountIdClick}
                />
                <SearchableFeature
                    group={strings.identity}
                    icon={Icons.User}
                    text={strings.currentUser}
                    description={isLoaded(this.props.currentUser) ? this.props.currentUser?.data?.full_name ?? "" : ""}
                    onClick={this.handleAccountIdClick}
                />
                {children}
            </React.Fragment>
        );
    }

    private checkForRedirects() {
        const { auth } = this.props;

        if (this.switchTeamIfRequired()) {
            return;
        }

        if (!auth.isAuthenticated && !auth.isLoggingOut) {
            redirectTo(this.resolveLoginPath());
            return;
        }
    }

    private switchTeamIfRequired(): boolean {
        const { location } = this.props;

        const query = Strings.convertSearchStringToObject(location.search);
        if (query.team) {
            const newQuery = Objects.omit(["team"], query);
            const queryObj =
                location.pathname === "/"
                    ? undefined
                    : { next: `${location.pathname}${Strings.convertObjectToSearchString(newQuery)}` };
            redirectTo({
                path: `/team/${query.team}`,
                query: queryObj,
            });

            return true;
        }

        return false;
    }

    private resolveLoginPath() {
        const {
            auth,
            location: { pathname: requestedPathname, search: requestedSearch },
        } = this.props;
        const requestedQuery = Strings.convertSearchStringToObject(requestedSearch);

        let loginPageAddress = "/login";
        const teamLoginId = sessionStorage.getItem("TEAM_LOGIN_ID");
        if (teamLoginId) {
            loginPageAddress = `/team/${teamLoginId}`;
        }

        const newSearch = Strings.convertObjectToSearchString(requestedQuery);
        // Don't set next if the user is logging out
        const dontUseNext = auth.isLoggedOut || auth.isLoggingOut || requestedPathname === "/";
        const query = dontUseNext ? undefined : { next: requestedPathname + newSearch };
        return { query, path: loginPageAddress, replace: true };
    }

    private isAccepted(agreement: Agreement): boolean {
        return (
            agreement.type === "fcu" ||
            agreement.type === "psk" ||
            !Strings.isNullOrEmpty(agreement.accepted_at) ||
            !Strings.isNullOrEmpty(agreement.accepted_by)
        );
    }

    private handleWindowStorage(e: StorageEvent) {
        if (!event || this.unmounted) {
            return;
        }

        setTimeout(() => {
            if (e.key === "logout-event") {
                redirectTo({ path: "/login" });
            } else if (e.key === "login-event" && !window.location.href.includes("login")) {
                reloadPage();
            }
        }, AuthenticationBarrier.LOGOUT_LOGIN_DELAY);
    }

    private handleReauthClose() {
        this.setState({ cancelRequested: true });
    }

    private handleLulAccepted() {
        this.setState({ showLuL: false });
    }

    private handleAccountIdClick() {
        copyToClipboard(isLoaded(this.props.currentAccount) ? this.props.currentAccount?.data?.id ?? "" : "");
    }

    /* Poll IAM service at regular interval, in order to check and ensure we're still
     * logged in to the service, the agreement has been accepted and that the token is valid. If the token is invalid
     * then the APICreator will log us out and the user will be explained why. This makes
     * for a better experience, compared to the token being invalid but the user still being
     * able to use/look at the site */
    private backgroundPolling() {
        this.pollingTimerId = window.setInterval(() => {
            this.props.dispatch(getAccount);
            this.props.actions.getLuls();
        }, AuthenticationBarrier.POLLING_INTERVAL);
    }
}

const mapStateToProps = (state: AppState) => ({
    agreements: state.agreements,
    auth: state.auth,
    config: state.config,
    currentAccount: state.currentaccount,
    currentUser: state.currentuser,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    actions: bindActionCreators({ getLuls, getUser, getAccount }, dispatch),
    dispatch,
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(translate("AuthenticationBarrier")(AuthenticationBarrier))
);
