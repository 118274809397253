import moment from "moment";

export const addBusinessDays = (date: moment.Moment, daysToAdd: number) => {
    // ISO weekday is always 6 for saturday and 7 for sunday. Note that it's the
    // only "locale-aware" action we take because we assume that sat/sun are not
    // business days (it's not true in every culture) and that all the other days
    // are business days (ignoring, for example, bank holidays).
    for (let daysLeft = daysToAdd; daysLeft; ) {
        date = date.add(1, "days");
        if (date.isoWeekday() < 6) {
            --daysLeft;
        }
    }

    return date;
};

export const isNewBillingMonthBegan = () => {
    return moment() > addBusinessDays(moment().startOf("month"), 3);
};

export const showServicePackageAboutToExpireWarning = (expires?: string) => {
    if (!expires) {
        return false;
    }
    const expireDate = moment(expires);
    const threeMonthsFromNow = moment().add(3, "months");
    const diff = threeMonthsFromNow.diff(expireDate, "months");

    return diff < 3 && diff > 0;
};

export const showServicePackageExpiredWarning = (expires?: string) => {
    if (!expires) {
        return false;
    }
    const expireDate = moment(expires);
    const diff = expireDate.diff(moment(), "days");

    return diff < 0;
};

export const getLastDayOfTheMonth = (month: string) => moment(month).add(1, "months").date(0).format("MMMM DD, YYYY");
