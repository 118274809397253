import { makeCollectionCreator, makeConfigApiCreator } from "../../../creators";
import { ParseFunction } from "../../../creators/AjaxCreator";
import { ListResponse } from "../../../script/types";
import { expirationTime, hasDatePassed } from "../../../script/utility";

interface DeviceEnrollment {
    id?: string;
    account_id?: string;
    claimed_at?: string;
    created_at?: string;
    enrolled_device_id?: string;
    enrollment_identity?: string;
    expires_at?: string;
}

const makeEnrollmentCreator = <T>(type: string, parseFunction?: ParseFunction<T>) =>
    makeConfigApiCreator<T>("v3_base", {
        errorRedirect: true,
        errorCallout: false,
    })(type, parseFunction);

// List enrolled devices
const { createThunk: createDeviceEnrollmentsThunk, reducer: deviceEnrollmentsReducer } = makeEnrollmentCreator<
    ListResponse<DeviceEnrollment>
>("device_enrollment", (untypedResponse) => {
    const response = untypedResponse as ListResponse<DeviceEnrollment>;
    response.data = response.data.map((item) => {
        let status = "Pending";
        if (item.claimed_at) {
            status = "Claimed";
        } else if (hasDatePassed(item.expires_at) === expirationTime.EXPIRED) {
            status = "Expired";
        }

        return { ...item, status, selected: false };
    });
    if (response.data && response.data.length > 1) {
        response.last = response.data[response.data.length - 1].id;
    }
    return response;
});

const getDeviceEnrollments = (pageSize = 1000, last?: string, order = "DESC") => {
    const queryParameters = {
        order,
        limit: pageSize?.toString(),
        include: "total_count",
        after: last,
    };

    return createDeviceEnrollmentsThunk({
        param: "device-enrollments",
        queryParameters,
    });
};

// Cancel enrolling for a single device
const { createThunk: createDeleteDeviceEnrollmentsThunk, reducer: deleteEnrollmentReducer } = makeEnrollmentCreator<
    void
>("delete_device_enrollment");
const deleteDeviceEnrollment = (id: string) =>
    createDeleteDeviceEnrollmentsThunk({
        param: `device-enrollments/${id}`,
        method: "DELETE",
        id,
        errorRedirect: false,
    });

// Bulk cancel (note: do NOT set content-type, bulk operations use multipart/form-data)

interface DeviceEnrollmentBulkDelete {
    id: string;
    account_id?: string;
    completed_at?: string;
    created_at?: string;
    errors_count?: number;
    errors_report_file?: string;
    full_report_file?: string;
    processed_count?: number;
    status?: "new" | "processing" | "completed";
    total_count?: number;
}

const { createThunk: createBulkDeleteThunk, reducer: deviceEnrollmentsBulkDeleteReducer } = makeCollectionCreator(
    makeEnrollmentCreator<DeviceEnrollmentBulkDelete>("device_enrollment_cancel_bulk")
);
const bulkDeleteAction = (jobId: string, data: FormData) =>
    createBulkDeleteThunk({
        id: jobId,
        param: "device-enrollments-bulk-deletes",
        data,
        method: "POST",
        type: null, // need to leave the browser determine the type
    });

const { createThunk: createBulkStatusThunk, reducer: deviceEnrollmentsBulkDeleteStatusReducer } = makeCollectionCreator(
    makeEnrollmentCreator<DeviceEnrollmentBulkDelete>("device_enrollment_cancel_get_bulk_status")
);
const bulkDeleteGetStatus = (jobId: string, deleteOperationId: string) =>
    createBulkStatusThunk({
        id: jobId,
        param: `device-enrollments-bulk-deletes/${deleteOperationId}`,
    });

const {
    createThunk: createBulkDeleteReportThunk,
    reducer: deviceEnrollmentsBulkDeleteReportReducer,
} = makeCollectionCreator(makeEnrollmentCreator<unknown>("device_enrollment_get_bulk_status_report"));
const getBulkDeleteReport = (jobId: string, id: string) =>
    createBulkDeleteReportThunk({
        id: jobId,
        param: `device-enrollments-bulk-deletes/${id}/full_report.csv`,
    });

export {
    DeviceEnrollment,
    DeviceEnrollmentBulkDelete,
    getDeviceEnrollments,
    deleteDeviceEnrollment,
    deviceEnrollmentsReducer,
    deleteEnrollmentReducer,
    bulkDeleteAction,
    deviceEnrollmentsBulkDeleteReducer,
    bulkDeleteGetStatus,
    deviceEnrollmentsBulkDeleteStatusReducer,
    getBulkDeleteReport,
    deviceEnrollmentsBulkDeleteReportReducer,
};
