import { DialogButtons, ErrorMessage, Form, SwitchButton, TextArea, ValueEvent } from "portal-components";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { AjaxState, ErrorAndUrl, onStateChange } from "../../../creators/AjaxCreator";
import translate, { DefaultStrings } from "../../../i18n/translate";
import LoadingComponent from "../../../layout/loadingComponent";
import { AuthState } from "../../../layout/reducer";
import { AppDispatch, AppState } from "../../../creators/reducers";
import { redirectTo } from "../../../script/routing";
import { ListResponse } from "../../../script/types";
import { convertSearchToQuery, HTTPCodes, sendEvent } from "../../../script/utility";
import { CurrentUser, getUser } from "../../account";
import BaseLoginComponent from "../loginComponent";
import { Agreement, getAction, getAgreementContent, postAction } from "./creator";
import { UserStatus } from "../../../typings/interfaces";

const defaultStrings = {
    lulTitle: "Accept limited use licence",
    iAccept: "I accept this licence on behalf of my team",
    accept: "Accept",
    notAcceptedMessage: "Please read and accept the licence.",
    acceptFailed: "Accepting limited use licence failed. Please try again.",
};

interface LulAcceptPropsBase {
    auth: AuthState;
    currentUser: AjaxState<CurrentUser>;
    dispatch: AppDispatch;
    strings: DefaultStrings<typeof defaultStrings>;
    agreements: AjaxState<ListResponse<Agreement>>;
    agreementContent: AjaxState<Agreement>;
    acceptAgreement: AjaxState<void>;
    isModal: boolean;
    onAccept: () => void;
}

type LulAcceptProps = LulAcceptPropsBase & RouteComponentProps<any>;

interface State {
    message: string;
    accepted: boolean;
    data: Agreement;
    agreements: Agreement[];
    userStatus: number;
    loading: boolean;
    loadingLUL: boolean;
}

export class LULAccept extends React.PureComponent<LulAcceptProps, State> {
    public static defaultProps = {
        strings: defaultStrings,
    };

    constructor(props: LulAcceptProps) {
        super(props);
        this.state = {
            message: "",
            accepted: false,
            data: {} as Agreement,
            agreements: [],
            userStatus: props.auth.userStatus,
            loadingLUL: true,
            loading: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCheckBox = this.handleCheckBox.bind(this);
        this.handleRedirect = this.handleRedirect.bind(this);
    }

    componentDidMount() {
        const { auth, isModal, location, dispatch } = this.props;

        if (!isModal) {
            // Not logged in
            if (!auth.isAuthenticated) {
                redirectTo({ path: "/login" });
                return;
            }

            // This is accessed from the 'Login' page
            if (convertSearchToQuery(location.search).id) {
                dispatch(getUser);
                return;
            }
        }

        dispatch(getUser);
    }

    componentDidUpdate(prevProps: LulAcceptProps) {
        const {
            auth,
            acceptAgreement,
            agreementContent,
            agreements,
            currentUser,
            dispatch,
            onAccept,
            strings,
        } = this.props;

        onStateChange(prevProps.currentUser, currentUser, {
            loaded: () => {
                this.setState({
                    userStatus: auth.userStatus,
                });
                dispatch(getAction());
            },
        });

        onStateChange(prevProps.agreementContent, agreementContent, {
            loaded: ({ data }) => {
                this.setState({
                    data,
                    loadingLUL: false,
                });
            },
        });

        onStateChange(prevProps.acceptAgreement, acceptAgreement, {
            loaded: () => {
                sendEvent("Accept Agreement", "Agreement accepted.");
                this.setState({
                    message: "",
                    accepted: false,
                    data: {} as Agreement,
                    loading: false,
                    agreements: [],
                });
                dispatch(getAction());
                onAccept();
            },
            failed: (error) => {
                const apiError = error as ErrorAndUrl;
                // someone has already accepted
                if (apiError.response.status === HTTPCodes.CONFLICT) {
                    dispatch(getAction());
                    return;
                }
                this.setState({
                    message: strings.acceptFailed,
                });
                sendEvent("Accept Agreement", "Accept agreement failed.");
            },
        });

        onStateChange(prevProps.agreements, agreements, {
            loaded: ({ data }) => {
                const notAccepted = data.data.filter(
                    (agreement) =>
                        !agreement.accepted_at &&
                        !agreement.accepted_by &&
                        agreement.type !== "fcu" &&
                        agreement.type !== "psk"
                );

                if (!this.props.isModal && (!notAccepted || notAccepted.length < 1)) {
                    this.handleRedirect();
                    return;
                }

                this.setState({
                    agreements: notAccepted,
                    loadingLUL: true,
                });
                dispatch(getAgreementContent(notAccepted[0].id));
                return;
            },
        });

        if (prevProps.auth.type !== auth.type && auth.type === "REAUTH_SUCCESS") {
            this.handleSubmit();
        }
    }

    handleRedirect() {
        if (this.state.userStatus === UserStatus.Active) {
            redirectTo({ path: "/", replace: true });
            return;
        }

        if (this.state.userStatus === UserStatus.Reset) {
            redirectTo({ path: "/welcome", replace: true });
            return;
        }
    }

    handleSubmit() {
        if (!this.state.accepted) {
            this.setState({
                message: this.props.strings.notAcceptedMessage,
            });
            return false;
        }

        this.setState({ loading: true });

        this.props.dispatch(
            postAction({
                agreement_id: this.state.agreements[0].id,
            })
        );
    }

    handleCheckBox(e: ValueEvent<boolean>) {
        this.setState({
            accepted: e.value,
        });
    }

    render() {
        const { isModal, strings } = this.props;
        const { loading, loadingLUL, accepted, data, message } = this.state;

        const lulText = loadingLUL ? (
            <LoadingComponent random />
        ) : (
            <TextArea className="lul-content" readOnly value={data.text} />
        );
        const inputForm = (
            <React.Fragment>
                <p className="lead">{strings.lulTitle}</p>
                {lulText}
                <Form id="lul-accept-form" onSubmit={this.handleSubmit}>
                    <SwitchButton
                        id="accept-lul"
                        disabled={loadingLUL}
                        autoFocus
                        required
                        onValueChange={this.handleCheckBox}
                        value={accepted}
                    >
                        {strings.iAccept}
                    </SwitchButton>
                    <ErrorMessage value={message} />
                    <DialogButtons
                        id="accept-cla"
                        disabled={!accepted || !data.text}
                        submitting={loading || loadingLUL}
                        confirmTitle={strings.accept}
                        hideCancel
                    />
                </Form>
            </React.Fragment>
        );

        if (isModal) {
            return inputForm;
        }

        return <BaseLoginComponent id="lul-accept-component">{inputForm}</BaseLoginComponent>;
    }
}

// map Redux store state to React props
const mapStateToProps = (state: AppState) => {
    return {
        auth: state.auth,
        agreementContent: state.agreementcontent,
        acceptAgreement: state.acceptagreement,
        agreements: state.agreements,
        currentUser: state.currentuser,
    };
};

export default withRouter(connect(mapStateToProps)(translate("LULAccept")(LULAccept)));
