import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import { adminAccess, importLazy, LazilyLoadFactory } from "../layout/lazilyload";
import NotFound from "../layout/404";
import ErrorBoundary from "../layout/errorBoundary";
import { PropagatedProps } from "../layout/main/component";
import { asyncRender } from "./BaseRoute";

export interface JobsRouteProps extends RouteComponentProps {
    JobCreate: React.ComponentType<PropagatedProps>;
    JobDetail: React.ComponentType<PropagatedProps>;
    Jobs: React.ComponentType<PropagatedProps>;
    componentProps: PropagatedProps;
}

export const JobsRoute: React.FunctionComponent<JobsRouteProps> = (props) => {
    const { JobCreate, Jobs, JobDetail, componentProps } = props;
    const asyncRenderWithProps = asyncRender(componentProps);
    return (
        <ErrorBoundary>
            <Switch>
                <Route path="/jobs/new" render={asyncRenderWithProps(JobCreate, "newJob")} />
                <Route path="/jobs/list" render={asyncRenderWithProps(Jobs, "jobs")} />
                <Route path="/jobs/:id" render={asyncRenderWithProps(JobDetail, "jobDetail")} />
                <Redirect from="/jobs" to="/jobs/list" />
                <Route component={NotFound} />
            </Switch>
        </ErrorBoundary>
    );
};

export default LazilyLoadFactory(withRouter(JobsRoute), {
    JobCreate: () => importLazy(import("../features/jobs/create/container"), adminAccess),
    JobDetail: () => importLazy(import("../features/jobs/detail/container"), adminAccess),
    Jobs: () => importLazy(import("../features/jobs/container"), adminAccess),
});
