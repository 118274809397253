import {
    DarkThemeColor,
    DarkThemeColorRepository,
    DarkThemeColorUpdateRequest,
    DarkThemeImage,
    DarkThemeImageRepository,
    LightThemeColor,
    LightThemeColorRepository,
    LightThemeColorUpdateRequest,
    LightThemeImage,
    LightThemeImageRepository,
    ListOptions,
    Paginator,
} from "mbed-cloud-sdk";
import { APICreator, makeConfigApiCreator, ParseFunction, sdkCreator, SdkCreatorOptions } from "../../../creators";
import { PaginationDirection, sdkPaginatorCreator } from "../../../creators/SdkPaginatorCreator";
import { ListResponse } from "../../../script/types";
import { BrandingColorsDefinition, BrandingImagesDefinition, SubtenantConfiguration } from "./types";

const makeAuthCreator = <A, D = undefined>(type: string, parseFunction?: ParseFunction<A, D>): APICreator<A, D> =>
    makeConfigApiCreator<A, D>("auth_base", { useAuth: false, errorCallout: false, errorRedirect: false })(
        type,
        parseFunction
    );

const getData = <T>(
    thunk: any,
    options?: {
        paginator?: Paginator<T, ListOptions>;
        listOptions?: ListOptions;
        direction?: PaginationDirection;
        creatorOptions?: SdkCreatorOptions;
    }
) => {
    options = options || {};
    const { pageSize = 20, order = "ASC", maxResults = 20 } = (options.listOptions as ListOptions) || {};
    const { errorCallout = true } = (options.creatorOptions as SdkCreatorOptions) || {};
    return thunk(options.paginator, { pageSize, order, maxResults }, { errorCallout }, options.direction);
};

// Get branding colors and images
const { reducer: getBrandingColorsDarkReducer, createListThunk: getBrandingColorsDarkThunk } = sdkPaginatorCreator<
    DarkThemeColor,
    ListOptions
>("IAM_GET_BRANDING_COLORS_DARK", {
    paginator: (parameters, sdkConfig) => new DarkThemeColorRepository(sdkConfig).list(parameters),
});
const getBrandingColorsDark = () => getData<DarkThemeColor>(getBrandingColorsDarkThunk);

const { reducer: getBrandingColorsLightReducer, createListThunk: getBrandingColorsLightThunk } = sdkPaginatorCreator<
    LightThemeColor,
    ListOptions
>("IAM_GET_BRANDING_COLORS_LIGHT", {
    paginator: (parameters, sdkConfig) => new LightThemeColorRepository(sdkConfig).list(parameters),
});
const getBrandingColorsLight = () => getData<LightThemeColor>(getBrandingColorsLightThunk);

const { reducer: getBrandingImagesDarkReducer, createListThunk: getBrandingImagesDarkThunk } = sdkPaginatorCreator<
    DarkThemeImage,
    ListOptions
>("IAM_GET_BRANDING_IMAGES_DARK", {
    paginator: (parameters, sdkConfig) => new DarkThemeImageRepository(sdkConfig).list(parameters),
});
const getBrandingImagesDark = () => getData<DarkThemeImage>(getBrandingImagesDarkThunk);

const { reducer: getBrandingImagesLightReducer, createListThunk: getBrandingImagesLightThunk } = sdkPaginatorCreator<
    LightThemeImage,
    ListOptions
>("IAM_GET_BRANDING_IMAGES_LIGHT", {
    paginator: (parameters, sdkConfig) => new LightThemeImageRepository(sdkConfig).list(parameters),
});
const getBrandingImagesLight = () => getData<LightThemeImage>(getBrandingImagesLightThunk);

// Delete images
const { reducer: clearBrandingImageDarkReducer, createThunk: clearBrandingImageDarkThunk } = sdkCreator(
    "IAM_CLEAR_BRANDING_IMAGE_DARK",
    {
        sdkMethod: (reference: string, sdkConfig) => new DarkThemeImageRepository(sdkConfig).delete(reference),
    }
);
const clearBrandingImageDark = (reference: string) => clearBrandingImageDarkThunk(reference);

const { reducer: clearBrandingImageLightReducer, createThunk: clearBrandingImageLightThunk } = sdkCreator(
    "IAM_CLEAR_BRANDING_IMAGE_LIGHT",
    {
        sdkMethod: (reference: string, sdkConfig) => new LightThemeImageRepository(sdkConfig).delete(reference),
    }
);
const clearBrandingImageLight = (reference: string) => clearBrandingImageLightThunk(reference);

// Update colors
const { reducer: updateBrandingColorDarkReducer, createThunk: updateBrandingColorDarkThunk } = sdkCreator(
    "IAM_UPDATE_BRANDING_COLOR_DARK",
    {
        sdkMethod: (request: { request: DarkThemeColorUpdateRequest; reference: string }, sdkConfig) =>
            new DarkThemeColorRepository(sdkConfig).update(request.request, request.reference),
    }
);
const updateBrandingColorDark = (request: DarkThemeColorUpdateRequest, reference: string) =>
    updateBrandingColorDarkThunk({ request, reference });

const { reducer: updateBrandingColorLightReducer, createThunk: updateBrandingColorLightThunk } = sdkCreator(
    "IAM_UPDATE_BRANDING_COLOR_LIGHT",
    {
        sdkMethod: (request: { request: LightThemeColorUpdateRequest; reference: string }, sdkConfig) =>
            new LightThemeColorRepository(sdkConfig).update(request.request, request.reference),
    }
);
const updateBrandingColorLight = (request: LightThemeColorUpdateRequest, reference: string) =>
    updateBrandingColorLightThunk({ request, reference });

const resetBranding = () => {
    getAccountBrandingColorsDarkReset();
    getAccountBrandingColorsLightReset();
    getAccountBrandingImagesDarkReset();
    getAccountBrandingImagesLightReset();
    return { type: "IAM_ACCOUNT_BRANDING_RESET" };
};

const { reducer: updateBrandingImageDarkReducer, createThunk: updateBrandingImageDarkThunk } = sdkCreator(
    "IAM_UPDATE_BRANDING_IMAGES_DARK",
    {
        sdkMethod: (input: { image: File; reference: string }, sdkConfig) =>
            new DarkThemeImageRepository(sdkConfig).update(input.image, input.reference),
    }
);
const updateBrandingImageDark = (image: File, reference: string) => updateBrandingImageDarkThunk({ image, reference });

const { reducer: updateBrandingImageLightReducer, createThunk: updateBrandingImageLightThunk } = sdkCreator(
    "IAM_UPDATE_BRANDING_IMAGES_LIGHT",
    {
        sdkMethod: (input: { image: File; reference: string }, sdkConfig) =>
            new LightThemeImageRepository(sdkConfig).update(input.image, input.reference),
    }
);
const updateBrandingImageLight = (image: File, reference: string) =>
    updateBrandingImageLightThunk({ image, reference });

// OLD API CREATOR

// Hard reset
// Can't be removed because SDK method does not exist yet
const { reducer: hardResetThemesReducer, createThunk: hardResetThemesThunk } = makeConfigApiCreator<void>("v3_base", {
    errorCallout: false,
})("iam_hard_reset_themes");
const hardResetThemes = (what: string) => hardResetThemesThunk({ param: `branding-${what}`, method: "DELETE" });

// Get Account Branding
// Can't be removed because SDK endpoint uses /v3/ instead of /auth/ API which makes this not work on login screens
const {
    reducer: getAccountBrandingColorsDarkReducer,
    createThunk: getAccountBrandingColorsDarkThunk,
    createResetAction: getAccountBrandingColorsDarkReset,
} = makeAuthCreator<ListResponse<BrandingColorsDefinition>>("iam_get_account_branding_colors_dark");
const getAccountBrandingColorsDark = (id: string) =>
    getAccountBrandingColorsDarkThunk({ param: `accounts/${id}/branding-colors/dark` });

const {
    reducer: getAccountBrandingColorsLightReducer,
    createThunk: getAccountBrandingColorsLightThunk,
    createResetAction: getAccountBrandingColorsLightReset,
} = makeAuthCreator<ListResponse<BrandingColorsDefinition>>("iam_get_account_branding_colors_light");
const getAccountBrandingColorsLight = (id: string) =>
    getAccountBrandingColorsLightThunk({ param: `accounts/${id}/branding-colors/light` });

const {
    reducer: getAccountBrandingImagesDarkReducer,
    createThunk: getAccountBrandingImagesDarkThunk,
    createResetAction: getAccountBrandingImagesDarkReset,
} = makeAuthCreator<ListResponse<BrandingImagesDefinition>>("iam_get_account_branding_images_dark");
const getAccountBrandingImagesDark = (id: string) =>
    getAccountBrandingImagesDarkThunk({ param: `accounts/${id}/branding-images/dark` });

const {
    reducer: getAccountBrandingImagesLightReducer,
    createThunk: getAccountBrandingImagesLightThunk,
    createResetAction: getAccountBrandingImagesLightReset,
} = makeAuthCreator<ListResponse<BrandingImagesDefinition>>("iam_get_account_branding_images_light");
const getAccountBrandingImagesLight = (id: string) =>
    getAccountBrandingImagesLightThunk({ param: `accounts/${id}/branding-images/light` });

const {
    reducer: getAccountSubtenantConfigurationReducer,
    createThunk: getAccountSubtenantConfigurationThunk,
    createResetAction: getAccountSubtenantConfigurationReset,
} = makeConfigApiCreator<SubtenantConfiguration>("v3_base")("iam_get_account_subtenant_configuration");
const getAccountSubtenantConfiguration = () =>
    getAccountSubtenantConfigurationThunk({ param: `accounts/me/subtenant-configuration` });

const {
    reducer: editAccountSubtenantConfigurationReducer,
    createThunk: editAccountSubtenantConfigurationThunk,
    createResetAction: editAccountSubtenantConfigurationReset,
} = makeConfigApiCreator<SubtenantConfiguration>("v3_base")("iam_edit_account_subtenant_configuration");

const editAccountSubtenantConfiguration = (data: SubtenantConfiguration) =>
    editAccountSubtenantConfigurationThunk({
        param: `accounts/me/subtenant-configuration`,
        method: "PUT",
        data,
    });

export {
    getBrandingColorsDarkReducer,
    getBrandingColorsDark,
    getBrandingColorsLightReducer,
    getBrandingColorsLight,
    getBrandingImagesDarkReducer,
    getBrandingImagesDark,
    getBrandingImagesLightReducer,
    getBrandingImagesLight,
    clearBrandingImageDarkReducer,
    clearBrandingImageDark,
    clearBrandingImageLightReducer,
    clearBrandingImageLight,
    updateBrandingColorDarkReducer,
    updateBrandingColorDark,
    updateBrandingColorLightReducer,
    updateBrandingColorLight,
    updateBrandingImageDarkReducer,
    updateBrandingImageDark,
    updateBrandingImageLightReducer,
    updateBrandingImageLight,
    resetBranding,
};
export {
    hardResetThemes,
    hardResetThemesReducer,
    getAccountBrandingColorsDarkReducer,
    getAccountBrandingColorsDark,
    getAccountBrandingColorsDarkReset,
    getAccountBrandingColorsLightReducer,
    getAccountBrandingColorsLight,
    getAccountBrandingColorsLightReset,
    getAccountBrandingImagesDarkReducer,
    getAccountBrandingImagesDark,
    getAccountBrandingImagesDarkReset,
    getAccountBrandingImagesLightReducer,
    getAccountBrandingImagesLight,
    getAccountBrandingImagesLightReset,
    getAccountSubtenantConfigurationReducer,
    getAccountSubtenantConfiguration,
    getAccountSubtenantConfigurationReset,
    editAccountSubtenantConfigurationReducer,
    editAccountSubtenantConfigurationReset,
    editAccountSubtenantConfiguration,
};
