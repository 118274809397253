import { makeConfigApiCreator } from "../../../creators";
import { ListResponse } from "../../../script/types";
import { Saml2Configuration } from "../../organization";
import { INVITATION_KEY } from "../inviteAccept/container";
import { FLOW_STORAGE_KEY, SILENT_INVITE_ACCEPT_FLOW } from "./auth/container";
import * as OIDC from "./oidc";

interface IdentityProviderParameters {
    accountId?: string;
    issuer?: string;
}

export interface OidcConfiguration {
    authorization_endpoint?: string;
    auto_enrollment?: boolean;
    client_id?: string;
    end_session_endpoint?: string;
    enrollment_resp_endpoint?: string;
    revocation_endpoint?: string;
    jwks_uri?: string;
    redirect_uri?: string;
    logout_redirect_uri?: string;
    token_endpoint?: string;
    userinfo_endpoint?: string;
}

export interface IdentityProvider {
    id: string;
    type: "MBED" | "NATIVE" | "OIDC" | "SAML2";
    name?: string;
    issuer?: string;
    oidc?: OidcConfiguration;
    saml2?: Saml2Configuration;
    is_default: boolean;
}

const {
    createThunk: identityProviderThunk,
    reducer,
    createResetAction: resetIdentityProviderAction,
} = makeConfigApiCreator<ListResponse<IdentityProvider>>("auth_base", {
    errorCallout: true,
    useAuth: false,
})("identity_provider");

const identityProviderAction = ({ accountId = "", issuer = "" }: IdentityProviderParameters = {}) => {
    const queryParameters = [];

    if (accountId) {
        queryParameters.push({ key: "account_id__eq", value: accountId });
    }

    if (issuer) {
        queryParameters.push({ key: "issuer__eq", value: issuer });
    }

    const queryString = queryParameters
        .map(({ key, value }) => {
            return `${key}=${encodeURIComponent(value)}`;
        })
        .join("&");

    return identityProviderThunk({
        errorCallout: false,
        method: "GET",
        param: `identity-providers?${queryString}`,
    });
};

export interface AuthorizationEndpoint {
    type?: string;
    redirect_uri?: string;
}

export interface AuthorizationEndpointParameters {
    idp_id?: string;
    username?: string;
}

const { createThunk: startFederatedLoginThunk, reducer: startFederatedLoginReducer } = makeConfigApiCreator<
    AuthorizationEndpoint
>("auth_base", {
    errorCallout: true,
    useAuth: false,
})("start_federated_login", (response) => {
    const info = response as AuthorizationEndpoint;
    const flow = sessionStorage.getItem(FLOW_STORAGE_KEY);

    if (info.redirect_uri) {
        OIDC.goToIdP(
            info.redirect_uri,
            flow === SILENT_INVITE_ACCEPT_FLOW
                ? {
                      inviteId: sessionStorage.getItem(INVITATION_KEY) as string,
                  }
                : undefined
        );
    }

    return info;
});

const getAuthorizationEndpoint = (data: AuthorizationEndpointParameters) =>
    startFederatedLoginThunk({
        param: "login",
        method: "POST",
        data,
    });

export {
    identityProviderAction,
    resetIdentityProviderAction,
    reducer,
    getAuthorizationEndpoint,
    startFederatedLoginReducer,
};
