// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ySHk5whuD_hZGo9b1eNCY{position:absolute;top:0;right:1.5em;opacity:0.4;margin:1em auto}\n", "",{"version":3,"sources":["offlineScreen.scss"],"names":[],"mappings":"AAAA,uBAAe,iBAAiB,CAAC,KAAK,CAAC,WAAW,CAAC,WAAW,CAAC,eAAe","file":"offlineScreen.scss","sourcesContent":[":local(.cloud){position:absolute;top:0;right:1.5em;opacity:0.4;margin:1em auto}\n"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cloud": "ySHk5whuD_hZGo9b1eNCY"
};
export default ___CSS_LOADER_EXPORT___;
