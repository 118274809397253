import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import NotFound from "../layout/404";
import ErrorBoundary from "../layout/errorBoundary";
import { adminAccess, importLazy, LazilyLoadFactory } from "../layout/lazilyload";
import { PropagatedProps } from "../layout/main/component";
import { asyncRender } from "./BaseRoute";

export interface TeamRouteProps extends RouteComponentProps {
    IdentityProviderCreate: React.ComponentType<PropagatedProps>;
    Team: React.ComponentType<PropagatedProps>;
    TeamSecurity: React.ComponentType<PropagatedProps>;
    Tenants: React.ComponentType<PropagatedProps>;
    UpdateTeam: React.ComponentType<PropagatedProps>;
    UpdateNotificationContacts: React.ComponentType<PropagatedProps>;
    UpdateSecurity: React.ComponentType<PropagatedProps>;
    TenantsCreate: React.ComponentType<PropagatedProps>;
    TenantsDetail: React.ComponentType<PropagatedProps>;
    Branding: React.ComponentType<PropagatedProps>;
    componentProps: PropagatedProps;
}

export const TeamRoute: React.FunctionComponent<TeamRouteProps> = (props) => {
    const {
        IdentityProviderCreate,
        Team,
        TeamSecurity,
        Tenants,
        UpdateTeam,
        UpdateSecurity,
        TenantsCreate,
        TenantsDetail,
        UpdateNotificationContacts,
        componentProps,
        Branding,
    } = props;

    const asyncRenderWithProps = asyncRender(componentProps);

    return (
        <ErrorBoundary>
            <Switch>
                <Route exact path="/organization/profile" render={asyncRenderWithProps(Team, "myTeam")} />
                <Route
                    exact
                    path="/organization/profile/edit"
                    render={asyncRenderWithProps(UpdateTeam, "editOrganizationDetails")}
                />

                <Route
                    exact
                    path="/organization/identity/edit"
                    render={asyncRenderWithProps(UpdateSecurity, "editOrganizationSecurity")}
                />
                <Route
                    exact
                    path="/organization/identity/new"
                    render={asyncRenderWithProps(IdentityProviderCreate, "createIdp")}
                />
                <Route
                    exact
                    path="/organization/identity/:id/edit"
                    render={asyncRenderWithProps(IdentityProviderCreate, "editIdp")}
                />
                <Route path="/organization/identity" render={asyncRenderWithProps(TeamSecurity, "myTeamSecurity")} />

                <Route path="/organization/tenants/list" render={asyncRenderWithProps(Tenants, "subtenants")} />
                <Redirect exact from="/organization/tenants" to="/organization/tenants/list" />
                <Route
                    exact
                    path="/organization/tenants/new"
                    render={asyncRenderWithProps(TenantsCreate, "tenantsNew")}
                />
                <Route
                    exact
                    path="/organization/tenants/:id/edit"
                    render={asyncRenderWithProps(TenantsCreate, "tenantsEdit", { edit: true })}
                />
                <Route path="/organization/tenants/:id" render={asyncRenderWithProps(TenantsDetail, "tenantsDetail")} />

                <Route exact path="/organization/branding" render={asyncRenderWithProps(Branding, "branding")} />

                <Route
                    exact
                    path="/organization/profile/notifications"
                    render={asyncRenderWithProps(UpdateNotificationContacts, "editOrganizationNotificationContacts")}
                />

                <Redirect exact from="/organization" to="/organization/profile" />
                <Route component={NotFound} />
            </Switch>
        </ErrorBoundary>
    );
};

export default LazilyLoadFactory(withRouter(TeamRoute), {
    IdentityProviderCreate: () => importLazy(import("../features/organization/identity/create/container"), adminAccess),
    Team: () => importLazy(import("../features/organization/profile/container")),
    TeamSecurity: () => importLazy(import("../features/organization/identity/container"), adminAccess),
    Tenants: () => importLazy(import("../features/organization/tenants/container"), adminAccess),
    UpdateTeam: () => importLazy(import("../features/organization/profile/update/updateTeam"), adminAccess),
    UpdateNotificationContacts: () =>
        importLazy(import("../features/organization/profile/notifications/updateNotifcationContacts"), adminAccess),
    UpdateSecurity: () => importLazy(import("../features/organization/identity/update/updateSecurity"), adminAccess),
    TenantsCreate: () => importLazy(import("../features/organization/tenants/create/container"), adminAccess),
    TenantsDetail: () => importLazy(import("../features/organization/tenants/read/container")),
    Branding: () => importLazy(import("../features/organization/branding/container"), adminAccess),
});
