import { Action, AnyAction } from "redux";
import { Response } from "superagent";
import { Config, UserStatus } from "../typings/interfaces";
import { SuccessAction } from "../creators/AjaxCreator";
import CalloutCreator from "../creators/CalloutCreator";
import PollingCreator from "../creators/PollingCreator";
import { CurrentAccount, CurrentUser, parseCustomFields } from "../features/account";
import { getLoginCookie, LAST_LOGIN_COOKIE } from "../features/login/creator";
import { BrandingColorsDefinition, BrandingImagesDefinition, Theme } from "../features/organization";
import { referenceNormalize } from "../script/branding";
import { iif, loadCookie, mergeDeep, removeCookie, resolveUrl, saveCookie } from "../script/utility";
import { versionChecker } from "./versionChecker";
import ResourcesCreator from "../creators/ResourcesCreator";

// State is initialized from global variable during startup
const configReducer = (state: Config = {} as Config, action: Action): Config => {
    let newState: Partial<Config> = { ...state };
    switch (action.type) {
        case "FEATURE_TOGGLE_OVERWRITE": {
            const { feature, value } = (action as unknown) as { feature: string; value: boolean };
            newState = mergeDeep(newState, {
                featureToggle: { ...state.featureToggle, [feature]: value },
                featureToggleOverwrites: { [feature]: value },
            });
            break;
        }
        case "IAM_CURRENT_ACCOUNT_SUCCESS": {
            const accountAction = action as SuccessAction<CurrentAccount>;
            const { policies = [], custom_fields } = accountAction.info as CurrentAccount;

            const features = {
                edgeThick: "edge_additional_features",
                externalCA: "external_certificate",
                sda: "secure_device_access",
                miniclient: "psk_capability",
                subtenants: "manage_subtenants",
                uiCustomization: "ui_customization",
                idp_management: "idp_management",
                jobManagement: "app_workflow_service",
                update: [
                    "firmware_update",
                    "firmware_catalog",
                    "firmware_image",
                    "firmware_images",
                    "firmware_manifest",
                    "firmware_manifests",
                    "update-campaign",
                    "update-campaigns",
                ],
                uploadCertificate: [
                    "bring_your_own_certificates",
                    "upload-certificate",
                    "trusted-certificate",
                    "trusted-certificates",
                    "trusted_certificate",
                    "trusted_certificates",
                    "accounts-trusted-certificate",
                    "accounts-trusted-certificates",
                ],
            };

            const featureToggle = Object.entries(features).reduce((acc, [toggle, value]) => {
                const toggles: string[] = Array.isArray(value) ? value : [value];
                const policy = policies.find(({ feature }) => toggles.includes(feature));
                return { ...acc, [toggle]: policy ? policy.allow : false };
            }, {});

            // Present all other IAM feature packages
            const iamFeatures = policies
                .map((p) => ({ [p.feature]: p.allow }))
                .reduce((acc, val) => ({ ...acc, ...val }), {});

            // Get default theme if set
            const { default_theme } = parseCustomFields(custom_fields as { [key: string]: string });
            const { theme = "", allowUserTheme = false } =
                (default_theme as { version: string; theme: string; allowUserTheme: boolean }) || {};

            newState = mergeDeep(newState, {
                featureToggle: { ...featureToggle, ...iamFeatures, ...state.featureToggleOverwrites },
                defaultTheme: theme,
                allowUserTheme,
            });
            break;
        }
        case "IAM_UPDATE_USER_CUSTOM_FIELD_SUCCESS":
        case "IAM_CURRENT_USER_SUCCESS": {
            const userAction = action as SuccessAction<CurrentUser>;
            const { custom_fields } = userAction.info as CurrentUser;
            // Get user theme if set
            const { portal_theme } = parseCustomFields(custom_fields as { [key: string]: string });
            const { theme = "" } = (portal_theme as { version: string; theme: string }) || {};
            newState.userTheme = iif(theme, theme === Theme.Dark ? Theme.Dark : Theme.Light, undefined);
            break;
        }
        case "IAM_GET_ACCOUNT_BRANDING_COLORS_DARK_SUCCESS":
            newState.brandingColorsDark = getBrandingColors(action as SuccessAction<BrandingColorsDefinition>);
            break;
        case "IAM_GET_ACCOUNT_BRANDING_COLORS_LIGHT_SUCCESS":
            newState.brandingColorsLight = getBrandingColors(action as SuccessAction<BrandingColorsDefinition>);
            break;
        case "IAM_GET_ACCOUNT_BRANDING_IMAGES_DARK_SUCCESS":
            newState.brandingImagesDark = getBrandingImages(action as SuccessAction<BrandingImagesDefinition>);
            break;
        case "IAM_GET_ACCOUNT_BRANDING_IMAGES_LIGHT_SUCCESS":
            newState.brandingImagesLight = getBrandingImages(action as SuccessAction<BrandingImagesDefinition>);
            break;
        case "IAM_ACCOUNT_BRANDING_RESET":
            newState = mergeDeep(newState, {
                brandingColorsDark: undefined,
                brandingColorsLight: undefined,
                brandingImagesDark: undefined,
                brandingImagesLight: undefined,
            });
            break;
    }

    return newState as Config;
};

const getBrandingColors = (brandingProps: SuccessAction<BrandingColorsDefinition>) => {
    let branding;
    if (brandingProps && brandingProps.info) {
        const info = brandingProps.info as { data: BrandingColorsDefinition[] };
        if (info && info.data && Array.isArray(info.data)) {
            branding = info.data
                .filter((colorDef) => colorDef.color)
                .reduce(
                    (acc, { reference, color }) => ({
                        ...acc,
                        [referenceNormalize(reference)]: color,
                    }),
                    {}
                );
        }

        return branding;
    }
};

const getBrandingImages = (brandingProps: SuccessAction<BrandingImagesDefinition>) => {
    let branding;
    if (brandingProps && brandingProps.info) {
        const info = brandingProps.info as { data: BrandingImagesDefinition[] };
        if (info && info.data && Array.isArray(info.data)) {
            branding = info.data
                .filter((imageDef) => imageDef.static_uri)
                .reduce(
                    (acc, { reference, static_uri }) => ({
                        ...acc,
                        [reference]: static_uri,
                    }),
                    {}
                );
        }
    }

    return branding;
};

const calloutReducer = CalloutCreator.createReducer();
const pollingReducer = PollingCreator.createReducer();
const lwm2mReducer = ResourcesCreator.createReducer();

const { Initalizing: INITIALIZING, Reset: RESET, Active: ACTIVE } = UserStatus;

export enum LoginState {
    NOT_AUTH = "NOT_AUTH",
    SELECT_TEAM = "SELECT_TEAM",
    ACCEPT_CLA = "ACCEPT_CLA",
    ENTER_MFA = "ENTER_MFA",
    SETUP_MFA = "SETUP_MFA",
    CHANGE_PASSWORD = "CHANGE_PASSWORD",
    AUTHENTICATED = "AUTHENTICATED",
}

export interface AuthAccount {
    alias: string;
    display_name: string;
    id: string;
    parent_id: string;
    status: string;
}

export interface AuthState {
    isAuthenticating: boolean;
    isAuthenticated: boolean;
    isLoggingOut: boolean;
    isIdpLoggedOut?: boolean;
    isLoggedOut?: boolean;
    requiresReauth: boolean;
    lastReauth: Date | undefined;
    userStatus: number;
    info: null | {
        response: Response;
        accounts: AuthAccount[];
        overlayMessage: string;
        token: string;
    };
    idpId?: string;
    federated: boolean;
    issuer: string;
    state: LoginState;
    authErrorMessage: string;
    type: string;
    redirectUri?: string;
    oldPassword: string;
    roles?: string[];
    isPasswordChangeNeeded?: boolean;
}

const reduceLoginSuccess = ({ type, info }: AnyAction, state: AuthState): AuthState => {
    // Team login SUCCESS actions don't have new login info if we are already logged in to the correct team
    return info
        ? {
              ...state,
              lastReauth: new Date(),
              isAuthenticating: false,
              isAuthenticated: true,
              isLoggedOut: false,
              requiresReauth: false,
              isIdpLoggedOut: false,
              state: LoginState.AUTHENTICATED,
              info,
              authErrorMessage: "",
              type,
              roles: info.roles,
          }
        : state;
};

const checkLogin = function checkLogin(action: AnyAction, state: AuthState): AuthState {
    const { data, info } = action;
    const threeDaysFromNow = new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000);
    if (Object.prototype.hasOwnProperty.call(action.info, "accounts")) {
        return { ...state, info, state: LoginState.SELECT_TEAM };
    }
    saveCookie("token", action.info.token, { expires: threeDaysFromNow });
    saveCookie(LAST_LOGIN_COOKIE, { issuer: (data && data.issuer) || null, time: new Date() });
    return reduceLoginSuccess(action, state);
};

const cookie = getLoginCookie();
const issuer = cookie?.issuer ?? "";
const idpId = cookie?.idpId ?? "";

const authReducer = (
    state: AuthState = {
        isAuthenticating: false,
        isAuthenticated: !!loadCookie("token"),
        isLoggingOut: false,
        isIdpLoggedOut: false,
        userStatus: INITIALIZING,
        info: null,
        federated: !!issuer,
        issuer,
        idpId,
        authErrorMessage: "",
        lastReauth: undefined,
        requiresReauth: false,
        state: LoginState.NOT_AUTH,
        type: "",
        oldPassword: "",
    },
    action: AnyAction
) => {
    const { data = {}, error, info, oldPassword, type } = action;
    const { idpLogoutRedirectUri } = window.appConfig;
    const redirectRegEx = /returnTo=[^&]*/;
    let redirectUri = info?.redirect_uri ?? "";
    redirectUri = idpLogoutRedirectUri
        ? redirectUri.replace(redirectRegEx, `returnTo=${encodeURIComponent(resolveUrl(idpLogoutRedirectUri) ?? "")}`)
        : redirectUri;

    switch (type) {
        case "LOGIN_REQUEST":
            return {
                ...state,
                federated: !!data.issuer,
                issuer: data.issuer,
                isAuthenticating: true,
                type,
                state: LoginState.NOT_AUTH,
            };

        case "LOGIN_SUCCESS":
            return checkLogin(action, state);

        case "TEAM_LOGIN_SUCCESS":
            return reduceLoginSuccess(action, state);

        case "LOGIN_FAILURE":
            return Object.assign({}, state, {
                isAuthenticating: false,
                authErrorMessage: "Login failed: invalid username or password? Please try again.",
                info: error,
                state: LoginState.NOT_AUTH,
            });

        case "LOGOUT_REQUEST":
            removeCookie("token");
            return {
                ...state,
                isAuthenticating: false,
                isAuthenticated: false,
                isLoggingOut: data?.endIdpSession ?? false,
                requiresReauth: false,
                info,
                authErrorMessage: "",
                type,
                state: LoginState.NOT_AUTH,
                roles: [""],
            };

        case "LOGOUT_SUCCESS":
            if (redirectUri && (data?.endIdpSession ?? false)) {
                window.location.assign(redirectUri);
                return { ...state };
            } else {
                return { ...state, type, isLoggingOut: false, isLoggedOut: true };
            }

        case "LOGOUT_FAILURE":
            return { ...state, type, isLoggingOut: false };

        case "IDP_LOGOUT_REQUEST":
            return { ...state, type, isIdpLoggedOut: false };
        case "IDP_LOGOUT_FAILURE":
        case "IDP_LOGOUT_SUCCESS":
            return { ...state, type, isIdpLoggedOut: true, redirectUri };

        case "REAUTH_REQUEST":
            return {
                ...state,
                isAuthenticating: false,
                isAuthenticated: true,
                requiresReauth: true,
                info: data,
                authErrorMessage: "",
                type,
            };

        case "REAUTH_CANCEL":
            return {
                ...state,
                isAuthenticating: false,
                isAuthenticated: true,
                requiresReauth: false,
                info: data,
                authErrorMessage: "",
                type,
            };

        case "REAUTH_SUCCESS":
            return {
                ...state,
                isAuthenticating: false,
                isAuthenticated: true,
                requiresReauth: false,
                info: data,
                authErrorMessage: "",
                type,
            };

        case "IAM_CURRENT_ACCOUNT_SUCCESS":
            return { ...state, type };

        case "FIRST_LOGIN":
            return { ...state, oldPassword, type, state: LoginState.CHANGE_PASSWORD };

        case "SET_USER_ACTIVE":
            return { ...state, oldPassword: null, userStatus: ACTIVE, type };

        case "IAM_CURRENT_USER_SUCCESS":
            return {
                ...state,
                isPasswordChangeNeeded: info.is_password_change_needed,
                roles: info.roles,
                userStatus: info.status === "RESET" ? RESET : ACTIVE,
                type,
            };

        default:
            return { ...state };
    }
};

export { authReducer, configReducer, calloutReducer, pollingReducer, versionChecker, lwm2mReducer };
