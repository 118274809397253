import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import ErrorHandler from "../controls/errorHandler";
import { CalloutState, CalloutBodyType, CalloutStateBody } from "../creators/CalloutCreator";
import CalloutComponent, { CalloutComponentProps } from "./calloutComponent";
import { staleCallout } from "../creators/callouts";
import { AppDispatch } from "../creators/reducers";

export interface ToastCalloutComponentProps extends CalloutComponentProps {
    dispatch?: AppDispatch;
    strings?: object;
}

interface State {
    prevId?: string;
    toastShown: boolean;
}

export class ToastCalloutComponent extends React.Component<ToastCalloutComponentProps, State> {
    constructor(props: ToastCalloutComponentProps) {
        super(props);
        this.state = {
            prevId: props.callout?.id ?? undefined,
            toastShown: false,
        };

        this.createToast();
    }

    componentDidUpdate(prevProps: ToastCalloutComponentProps) {
        if (this.props.callout === undefined) {
            return;
        }

        if (prevProps.callout?.id && prevProps.callout?.id !== this.state.prevId) {
            this.setState({ prevId: prevProps.callout?.id, toastShown: false });
        }

        this.createToast();
    }

    componentWillUnmount() {
        const { callout: { id } = {} } = this.props;
        if (id && toast.isActive(id)) {
            toast.update(id, { onClose: undefined, autoClose: 1 });
        }
    }

    createToast() {
        if (this.props.callout === undefined) {
            return;
        }

        const { toastShown } = this.state;
        const { body, header, id, severity, stale, showToast = true } = this.props.callout;

        if (!this.isValid(this.props.callout) || !showToast || toastShown || stale || toast.isActive(id ?? "")) {
            return;
        }

        const content = (
            <React.Fragment>
                <h5>{header}</h5>
                <div>{this.parseBody(body)}</div>
            </React.Fragment>
        );

        const handleToastToCallout = () => this.setState({ toastShown: true });
        const handleToastOpen = () => this.props.dispatch?.(staleCallout());

        const toastId = id ?? undefined;
        switch (severity) {
            case CalloutComponent.severities.alert:
                toast.error(content, {
                    onOpen: handleToastOpen,
                    onClose: handleToastToCallout,
                    toastId,
                });
                break;
            case CalloutComponent.severities.success:
                toast.success(content, {
                    onOpen: handleToastOpen,
                    toastId,
                });
                break;
            case CalloutComponent.severities.warning:
                toast.warn(content, {
                    onOpen: handleToastOpen,
                    onClose: handleToastToCallout,
                    toastId,
                });
                break;
            default:
                toast.info(content, { onOpen: handleToastOpen, toastId });
        }
    }

    parseBody(body: CalloutBodyType | null) {
        if (Array.isArray(body)) {
            const arrayOfResponses = body as CalloutStateBody[];
            if (arrayOfResponses.find((elem) => elem.isError)) {
                return arrayOfResponses
                    .filter((elem) => elem.isError)
                    .map((elem, index) => (
                        <ErrorHandler
                            key={`errorCompo${index}`}
                            responseBody={elem.response ?? undefined}
                            message={elem.message}
                        />
                    ));
            }
        }

        return Array.isArray(body)
            ? (body as React.ReactNodeArray).map((text, index) => <div key={`message-${index}`}>{text}</div>)
            : body;
    }

    isValid(callout: CalloutState | undefined = this.props.callout) {
        return (
            callout?.severity && (callout?.body || callout?.header) && callout?.severity in CalloutComponent.severities
        );
    }

    render() {
        const { callout: { showToast = true } = {} } = this.props;
        const { toastShown } = this.state;

        if (!this.isValid()) {
            return null;
        }

        return !showToast || toastShown ? <CalloutComponent {...this.props} /> : null;
    }
}

export default connect()(ToastCalloutComponent);
