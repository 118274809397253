import { Strings } from "portal-components";
import history from "./history";

interface RedirectTo {
    path: string;
    replace?: boolean;
    delay?: number;
    query?: { [key: string]: string | boolean };
    search?: string;
    hash?: string;
    state?: {};
    cb?: () => void;
}

export const redirectTo = (options: RedirectTo) => {
    const { path, replace, delay = 0, query, search, hash, state, cb } = options;
    if (path === "") {
        return;
    }

    return setTimeout(() => {
        let actualSearch = search;
        if (!actualSearch && query) {
            actualSearch = Strings.convertObjectToSearchString(query);
        }

        if (replace) {
            // If replace is true, we replace the current URL instead of
            // pushing a new one. This is useful when you're redirecting to
            // a page where going back does not make sense. E.g. after logging
            // in or similar.
            if (query) {
                history.replace({
                    pathname: path,
                    search: actualSearch,
                    hash,
                    state,
                });
            } else {
                history.replace({ pathname: path, hash, state });
            }
        } else {
            // Push new URL to the history stack
            if (query) {
                history.push({
                    pathname: path,
                    search: actualSearch,
                    hash,
                    state,
                });
            } else {
                history.push({ pathname: path, hash, state });
            }
        }

        // Optionally call the callback
        if (cb) {
            cb();
        }
    }, delay);
};

export const combinePathNameWithCurrentSearch = (path: string) => {
    return path + history.location.search;
};

export interface PartialLocation {
    pathname?: string;
    search?: string;
}
