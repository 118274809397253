import { makeConfigApiCreator, ParseFunction } from "../../../../../creators";
import history from "../../../../../script/history";
import { CertificateIssuer } from "../creator";

const makeCertificateCreator = <T>(type: string, parseFunction?: ParseFunction<T>) =>
    makeConfigApiCreator<T>("v3_base", {
        errorCallout: true,
        errorRedirect: () => history.push("/certificates/list"),
    })(type, parseFunction);

const {
    createThunk: createCertificateIssuerThunk,
    reducer: postReducer,
    createResetAction: postResetAction,
} = makeCertificateCreator<CertificateIssuer>("external_certificate_issuers_create");

const postReset = postResetAction();

const postAction = ({ issuer_type, name, description, issuer_credentials, issuer_attributes }: CertificateIssuer) =>
    createCertificateIssuerThunk({
        method: "POST",
        param: "certificate-issuers",
        data: {
            name,
            description,
            issuer_type,
            issuer_attributes,
            issuer_credentials,
        },
    });

const {
    createThunk: createEditCertificateIssuerThunk,
    reducer: putReducer,
    createResetAction: putResetAction,
} = makeCertificateCreator<CertificateIssuer>("external_certificate_issuers_update");

const putAction = ({ id, name, description, issuer_credentials, issuer_attributes }: CertificateIssuer) =>
    createEditCertificateIssuerThunk({
        method: "PUT",
        param: `certificate-issuers/${id}`,
        data: { name, description, issuer_attributes, issuer_credentials },
    });
const putReset = putResetAction();

const { createThunk: createGetCertificateIssuerThunk, reducer: detailReducer } = makeCertificateCreator<
    CertificateIssuer
>("external_certificate_issuers_detail");

const detailAction = (id: string) => createGetCertificateIssuerThunk({ param: `certificate-issuers/${id}` });

export { detailAction, detailReducer, postAction, postReset, postReducer, putAction, putReset, putReducer };
