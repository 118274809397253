import { makeConfigApiCreator } from "../../../../creators/AjaxApiCreator";
import history from "../../../../script/history";

enum DeviceExecutionMode {
    NotSet = 0,
    Development = 1,
    Production = 5,
}

type Device = {
    id: string;
    account_id?: string;
    auto_update?: boolean;
    bootstrap_expiration_date?: string;
    bootstrapped_timestamp?: string;
    ca_id?: string;
    connector_expiration_date?: string;
    created_at?: string;
    custom_attributes?: Record<string, string>;
    component_attributes?: {
        [key: string]: string;
    };
    deployed_state?: "development" | "production";
    deployment?: string;
    description?: string;
    device_class?: string;
    device_execution_mode?: DeviceExecutionMode;
    device_key?: string;
    endpoint_name?: string;
    endpoint_type?: string;
    enrolment_list_timestamp?: string;
    firmware_checksum?: string;
    groups?: string[];
    host_gateway?: string;
    issuer_fingerprint?: string;
    isExpanded?: boolean;
    last_operator_suspended_category?: string;
    last_operator_suspended_description?: string;
    last_operator_suspended_updated_at?: string;
    last_system_suspended_category?: string;
    last_system_suspended_description?: string;
    last_system_suspended_updated_at?: string;
    lifecycle_status?: "enabled" | "blocked";
    manifest?: string;
    manifest_timestamp?: string;
    mechanism?: "connector" | "direct";
    mechanismUrl?: string;
    name?: string;
    net_id?: string;
    operator_suspended?: boolean;
    serial_number?: string;
    selected?: boolean;
    state?: "unenrolled" | "cloud_enrolling" | "bootstrapped" | "registered" | "deregistered";
    system_suspended?: boolean;
    updated_at?: string;
    vendor_id?: string;
} & { [key: string]: string };

const { createThunk: createDeviceThunk, reducer: deviceReducer } = makeConfigApiCreator<Device>("v3_base", {
    errorCallout: true,
    errorRedirect: () => history.push("/devices/list"),
})("devices_catalog_detail");

const getDevice = (deviceId: string) => createDeviceThunk({ param: `devices/${deviceId}` });

export { getDevice, deviceReducer, Device, DeviceExecutionMode };
