import React from "react";
import { MarkupProps } from "./types";
const MarkupComponent = React.lazy(() => import("./component"));

export const Markup: React.FunctionComponent<MarkupProps> = (props) => (
    <React.Suspense fallback={null}>
        <MarkupComponent {...props} />
    </React.Suspense>
);

Markup.displayName = "Markup";
export default Markup;
