import { v4 as uuidv4 } from "uuid";
import Md5Worker from "./md5-buffer.worker.ts";

let worker: Worker;
const jobs: { [id: string]: (checksum: string) => void } = {};
export interface MD5MessageSend {
    buffer: ArrayBuffer;
    id: string;
}

export interface MD5MessageResp {
    checksum: string;
    id: string;
}

interface Message {
    data: MD5MessageResp;
}
/**
 * Computes the MD5 for a given ArrayBuffer in a WebWorker, resolving a promise when complete.
 * @param {ArrayBuffer} buffer
 * @returns {Promise<string>}
 */
export const md5Buffer = (buffer: ArrayBuffer): Promise<string> => {
    if (!worker) {
        worker = new Md5Worker();

        worker.addEventListener("message", ({ data: { checksum, id } }: Message) => {
            if (jobs[id]) {
                const resolve = jobs[id];
                delete jobs[id];
                resolve(checksum);
            }
        });
    }

    return new Promise((resolve) => {
        const id = uuidv4();
        const message: MD5MessageSend = { buffer, id };
        worker.postMessage(message);
        jobs[id] = resolve;
    });
};
