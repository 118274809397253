import { Button, Icons } from "portal-components";
import React from "react";
import translate, { DefaultStrings } from "../../../../i18n/translate";
import EventTable from "../../logs/eventTable";
import { DeviceEvent, DeviceEventDetailFilter } from "./creator";
import { ListResponse } from "../../../../script";

const defaultStrings = {
    resetLabel: "Clear",
    resetTooltip: "You are viewing a selected subset of the entire list, click here to reset.",
};

interface DeviceEventDetailComponentProps {
    id: string;
    strings: DefaultStrings<typeof defaultStrings>;
    onResetPartialList?: () => void;
    gridData: DeviceEvent[];
    loading: boolean;
    onRefresh: () => void;
    onGridLoadMore: (props: { last: string | undefined; pageSize?: undefined }) => void;
    gridInfo: ListResponse<DeviceEvent>;
    filterIndex: number;
    onFilterChanged: (filter: DeviceEventDetailFilter) => void;
}

export const DeviceEventDetailComponent = (props: DeviceEventDetailComponentProps) => {
    const { id, strings, onResetPartialList } = props;
    const gridProps = { ...props, strings: undefined, id };
    return (
        <EventTable {...gridProps}>
            <Button
                when={onResetPartialList !== undefined}
                icon={Icons.ClearList}
                text={strings.resetLabel}
                tooltip={strings.resetTooltip}
                onClick={onResetPartialList}
            />
        </EventTable>
    );
};

DeviceEventDetailComponent.defaultProps = {
    strings: defaultStrings,
};

export default translate("DeviceEventDetailComponent")(DeviceEventDetailComponent);
