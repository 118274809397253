import React from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { Config } from "../../typings/interfaces";
import { AjaxState, onStateChange } from "../../creators/AjaxCreator";
import translate, { DefaultStrings } from "../../i18n/translate";
import { AppDispatch } from "../../creators/reducers";
import { Job, updateJobAction } from "../asyncJobList/creator";
import JobStatusIndicator from "../asyncJobList/jobStatusIndicator";

const defaultStrings = {
    starting: "Less than 1% completed...",
    processing: "{0} of {1}, {2}% completed...",
    finishing: "Almost completed...",
    successBody: "{0} completed.",
    partialSuccessBody: "{0} completed, {1} failed.",
};

export interface ClientBatchOperationProps {
    strings: DefaultStrings<typeof defaultStrings>;
    job: Job;
    list: { id: string }[];
    response: AjaxState<void>;
    onCompleted: () => void;
    onProcess: (device: { id: string }) => ThunkAction<Promise<void>, { config: Config }, undefined, Action<any>>;
    dispatch: AppDispatch;
}

interface ClientBatchOperationState {
    completedCount: number;
    failedCount: number;
    index: number;
    progress?: number;
}

export class ClientBatchOperation extends React.PureComponent<ClientBatchOperationProps, ClientBatchOperationState> {
    public static readonly defaultProps = {
        strings: defaultStrings,
    };

    constructor(props: ClientBatchOperationProps) {
        super(props);

        this.processNextOrFinish = this.processNextOrFinish.bind(this);

        this.state = {
            index: 0,
            completedCount: 0,
            failedCount: 0,
        };
    }

    componentDidMount() {
        this.processNextOrFinish();
    }

    componentDidUpdate(prevProps: ClientBatchOperationProps) {
        onStateChange(prevProps.response, this.props.response, {
            loaded: () => this.processNextOrFinish(true),
            failed: () => this.processNextOrFinish(false),
        });
    }

    processNextOrFinish(lastOperationSucceeded?: boolean) {
        const { strings, list, dispatch, onCompleted, onProcess } = this.props;

        const index = this.state.index + (lastOperationSucceeded !== undefined ? 1 : 0);
        const totalCount = list.length;
        const completedCount = this.state.completedCount + (lastOperationSucceeded === true ? 1 : 0);
        const failedCount = this.state.failedCount + (lastOperationSucceeded === false ? 1 : 0);
        const processedCount = completedCount + failedCount;

        this.setState({ index, completedCount, failedCount });

        // If we're done then mark the job as completed
        if (index === totalCount) {
            let description = strings.successBody.format(processedCount);
            if (failedCount) {
                description = strings.partialSuccessBody.format(completedCount, failedCount);
            }

            dispatch(
                updateJobAction({
                    id: this.props.job.id,
                    status: failedCount ? "failed" : "succeeded",
                    description: description,
                })
            );
            onCompleted?.();
            return;
        }

        // If not then enqueue the new operation
        dispatch(onProcess(this.props.list[index]));

        // And keep the user informed about what's going on
        const progress = Math.trunc((processedCount / totalCount) * 100);
        let description = strings.starting;
        if (progress >= 1) {
            description =
                progress >= 99 ? strings.finishing : strings.processing.format(processedCount, totalCount, progress);
        }

        this.setState({ progress });
        dispatch(
            updateJobAction({
                id: this.props.job.id,
                description,
            })
        );
    }

    render() {
        const { title, description, startTime, endTime, invisible } = this.props.job;
        return (
            <JobStatusIndicator
                title={title}
                description={description}
                startTime={startTime}
                endTime={endTime}
                progress={this.state.progress}
                invisible={invisible ?? false}
            />
        );
    }
}

export default connect()(translate("ClientBatchOperation")(ClientBatchOperation));
