import {
    ActiveSession,
    Config,
    Paginator,
    User as SDKUser,
    UserFilter,
    UserListOptions,
    UserRepository,
} from "mbed-cloud-sdk";
import { Objects } from "portal-components";
import { makeConfigApiCreator, PaginationDirection, SdkCreatorOptions, sdkPaginatorCreator } from "../../../creators";
import { ListResponse, ListRequestParameters, reducerRegistry } from "../../../script";

export interface User extends SDKUser {
    index?: number;
    selected?: boolean;
    groupCount?: number;
    is_password_change_needed?: boolean;
}

let repository: UserRepository;

const getRepository = (sdkConfig: Config) => {
    if (!repository) {
        repository = new UserRepository(sdkConfig);
    }

    return repository;
};

// paginator actions

// get users
const { reducer: getUsersReducer, createListThunk } = sdkPaginatorCreator<User, UserListOptions>("GET_USERS", {
    paginator: (parameters, sdkConfig) => getRepository(sdkConfig).list(parameters),
    mapResults: <User>(item: User, index: number) => {
        const groupCount = 0; // item && item.groups ? item.groups.length : 0;
        return { ...item, index, selected: false, groupCount };
    },
});

const getUsers = (options?: {
    paginator?: Paginator<User, UserListOptions>;
    listOptions?: UserListOptions;
    direction?: PaginationDirection;
    creatorOptions?: SdkCreatorOptions;
}) => {
    options = options || {};
    const { pageSize = 1000, order = "DESC", maxResults = 1000, filter = {} } =
        (options.listOptions as UserListOptions) || {};
    const { errorCallout = true } = (options.creatorOptions as SdkCreatorOptions) || {};

    return createListThunk(
        options.paginator,
        {
            pageSize,
            order,
            maxResults,
            filter: {
                ...{
                    status: {
                        in: ["ACTIVE", "INACTIVE", "RESET"],
                    },
                    ...filter,
                },
            },
        },
        { errorCallout },
        options.direction
    );
};

// get total count
const { totalCountReducer, createTotalCountThunk } = sdkPaginatorCreator("GET_USERS_COUNT", {
    paginator: (parameters, sdkConfig) => getRepository(sdkConfig).list(parameters),
});

const getUsersCount = (options?: {
    paginator?: Paginator<User, UserListOptions>;
    creatorOptions?: SdkCreatorOptions;
}) => {
    options = options || {};
    const { errorCallout = true } = (options.creatorOptions as SdkCreatorOptions) || {};
    return createTotalCountThunk(options.paginator, { errorCallout });
};

// get user idp count
const { reducer: getUsersIdpStatsReducer, createTotalCountThunk: createIdpStatsThunk } = sdkPaginatorCreator<
    User,
    UserListOptions
>("GET_USER_IDP_STATS", {
    paginator: (parameters, sdkConfig) => getRepository(sdkConfig).list(parameters),
});

const getUsersAssociatedWithIdp = (
    id: string,
    options?: {
        paginator?: Paginator<User, UserListOptions>;
        creatorOptions?: SdkCreatorOptions;
    }
) => {
    options = options || {};
    const { errorCallout = false } = (options.creatorOptions as SdkCreatorOptions) || {};
    return createIdpStatsThunk(
        options.paginator,
        { errorCallout },
        {
            filter: {
                status: {
                    in: ["ACTIVE", "INVITED", "INACTIVE", "RESET"],
                },
                loginProfiles: [`${id}`],
            } as UserFilter,
        }
    );
};

// atomic sdk actions

// List of all user sessions

export interface Session {
    id: string;
    email: string;
    activeSessions: ActiveSession[];
}

const { reducer: getSessionsReducer, createThunk } = makeConfigApiCreator<ListResponse<Session>, ListResponse<Session>>(
    "v3_base"
)("iam_users_sessions", (resp: unknown) => {
    const response = resp as ListResponse<Session>;
    const apiData = response.data || [];

    if (response.data && response.data.length > 1) {
        response.last = response.data[response.data.length - 1].id;
    }
    response.data = apiData.map(Objects.snakeCaseToCamelCase) as Session[];
    return response;
});

const getSessionsAction = (
    {
        pageSize = 1000,
        last = undefined,
        order = "DESC",
        errorAction = undefined,
        errorCallout = true,
    } = {} as ListRequestParameters
) =>
    createThunk({
        param: `accounts/me/sessions`,
        queryParameters: {
            order,
            limit: `${pageSize}`,
            after: last,
        },
        errorAction,
        errorCallout,
    });

// delete user
const { reducer: removeUserReducer, createThunk: removeUserThunk, createResetAction } = makeConfigApiCreator<User>(
    "v3_base"
)("user_remove");

const removeUser = (id: string) => removeUserThunk({ param: `users/${id}/remove`, method: "POST" });
const removeUserReset = createResetAction();

const reducers = {
    userRemove: removeUserReducer,
    user: getUsersReducer,
    useridpstats: getUsersIdpStatsReducer,
    activeSessions: getSessionsReducer,
    usersTotalCount: totalCountReducer,
};

reducerRegistry.register(reducers);

type UsersReducers = typeof reducers;

export {
    removeUser,
    removeUserReset,
    getUsers,
    getUsersAssociatedWithIdp,
    getSessionsAction,
    getUsersCount,
    UsersReducers,
};
