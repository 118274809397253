import { AnyAction } from "redux";
import { concatData } from "../script/utility";
import { LwM2MObject, LwM2MObjectResource, ResourcesDataState } from "../features/devices/objects/types";

export default class ResourcesCreator {
    public static createReducer() {
        return (state: ResourcesDataState = { data: [], state: null, data_loaded: false }, action: AnyAction) => {
            switch (action.type) {
                case ResourcesCreator.actions.newData: {
                    if (!state.data_loaded) {
                        const data = concatData(state.data, action.body.data);
                        return Object.assign({}, state, {
                            data: data,
                            data_loaded: !action.body.hasMore,
                            state: ResourcesCreator.actions.newData,
                        });
                    }
                    return {
                        ...state,
                    };
                }
                case ResourcesCreator.actions.clearData:
                    return Object.assign({}, state, {
                        data: [],
                        data_loaded: false,
                        state: ResourcesCreator.actions.clearData,
                    });
                case ResourcesCreator.actions.addData: {
                    const resourceData = action.body as LwM2MObject;
                    const resource: LwM2MObject = {
                        id: resourceData.id,
                        etag: resourceData.etag,
                        created_at: resourceData.created_at,
                        updated_at: resourceData.updated_at,
                        object_id: resourceData.object_id,
                        object_source: resourceData.object_source,
                        name: resourceData.name,
                        resources: resourceData.resources
                            ? resourceData.resources.map(
                                  (resource: LwM2MObjectResource) =>
                                      ({
                                          id: resource.id,
                                          name: resource.name,
                                          type: resource.type,
                                          description: resource.description,
                                          units: resource.units,
                                          operations: resource.operations,
                                      } as LwM2MObjectResource)
                              )
                            : [],
                    };

                    // add object if it doesn't exists
                    const object_exists = state.data.find((o) => o.object_id === resource.object_id);
                    if (!object_exists) {
                        state.data.push(resource);
                        return Object.assign({}, state, {
                            data: state.data,
                            state: ResourcesCreator.actions.addData,
                        });
                    } else if (object_exists) {
                        // replace object if exists
                        state.data = state.data.filter((o) => o.object_id !== resource.object_id);
                        state.data.push(resource);
                        return Object.assign({}, state, {
                            data: state.data,
                            state: ResourcesCreator.actions.addData,
                        });
                    }
                    return {
                        ...state,
                    };
                }
                case ResourcesCreator.actions.deleteData: {
                    // no logic for delete yet, so we cler all and fetch from the beginning
                    return Object.assign({}, state, {
                        data: [],
                        data_loaded: false,
                        state: ResourcesCreator.actions.clearData,
                    });
                }
                default:
                    return state;
            }
        };
    }

    public static actions = {
        newData: "NEW_RESOURCE_DATA",
        clearData: "CLEAR_RESOURCE_DATA",
        addData: "ADD_RESOURCE_DATA",
        deleteData: "DELETE_RESOURCE_DATA",
    };
}
