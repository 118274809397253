import { makeCollectionCreator, makeConfigApiCreator } from "../../../../creators";
import { ParseFunction } from "../../../../creators/AjaxCreator";
import { DeviceEnrollment } from "../creator";

const makeEnrollmentCreator = <T>(type: string, parseFunction?: ParseFunction<T>) =>
    makeConfigApiCreator<T>("v3_base", {
        errorRedirect: true,
        errorCallout: false,
    })(type, parseFunction);

const { createThunk: createEnrollmentThunk, reducer: createDeviceEnrollmentReducer } = makeEnrollmentCreator<
    DeviceEnrollment
>("device_enrollment_create");

const createDeviceEnrollment = (data: DeviceEnrollment) => {
    return createEnrollmentThunk({
        param: "device-enrollments",
        data,
        method: "POST",
    });
};

interface DeviceEnrollmentBulkData {
    fileName: string;
    enrollment_identities: File;
}

interface DeviceEnrollmentBulkCreate {
    id: string;
    account_id?: string;
    completed_at?: string;
    created_at?: string;
    errors_count?: number;
    error_report_file?: string;
    full_report_file?: string;
    processed_count?: number;
    status?: "new" | "processing" | "completed";
    total_count?: number;
}

const {
    createThunk: createBulkDeviceEnrollmentsThunk,
    reducer: createBulkDeviceEnrollmentsReducer,
} = makeCollectionCreator(makeEnrollmentCreator<DeviceEnrollmentBulkCreate>("device_enrollment_create_bulk"));
const createBulkDeviceEnrollments = (jobId: string, data: FormData) =>
    createBulkDeviceEnrollmentsThunk({
        id: jobId,
        param: "device-enrollments-bulk-uploads",
        data,
        method: "POST",
        type: null, // need to leave the browser determine the type
    });

const {
    createThunk: createBulkDeviceEnrollmentsStatusThunk,
    reducer: createBulkDeviceEnrollmentsStatusReducer,
} = makeCollectionCreator(makeEnrollmentCreator<DeviceEnrollmentBulkCreate>("device_enrollment_get_bulk_status"));

const createBulkDeviceEnrollmentsStatus = (jobId: string, id: string) =>
    createBulkDeviceEnrollmentsStatusThunk({
        id: jobId,
        param: `device-enrollments-bulk-uploads/${id}`,
    });

const {
    createThunk: createBulkDeviceEnrollmentsReportThunk,
    reducer: createBulkDeviceEnrollmentsReportReducer,
} = makeCollectionCreator(
    makeEnrollmentCreator<DeviceEnrollmentBulkCreate>("device_enrollment_get_bulk_status_report")
);

const createBulkDeviceEnrollmentsReport = (jobId: string, id: string) =>
    createBulkDeviceEnrollmentsReportThunk({
        id: jobId,
        param: `device-enrollments-bulk-uploads/${id}/full_report.csv`,
    });

export {
    DeviceEnrollmentBulkData,
    DeviceEnrollmentBulkCreate,
    createBulkDeviceEnrollmentsStatus,
    createBulkDeviceEnrollmentsReport,
    createDeviceEnrollment,
    createBulkDeviceEnrollments,
    createDeviceEnrollmentReducer,
    createBulkDeviceEnrollmentsReducer,
    createBulkDeviceEnrollmentsStatusReducer,
    createBulkDeviceEnrollmentsReportReducer,
};
