import { Response, ResponseError } from "superagent";

export const MaxHistoryEntries = 500;
export const StorageName = "developer-console-tab";
export enum PubSubConsoleAPI {
    Request = "console_aggregator_api_request",
    Error = "console_aggregator_api_error",
    Response = "console_aggregator_api_response",
}

export interface GenericObject {
    [key: string]: unknown;
}

export enum ApiActionsResponseState {
    Loading,
    Loaded,
    Error,
}

export interface ApiActionsState {
    consoleId: string;
    data?: unknown;
    method: string;
    queryParameters: { [key: string]: string };
    response?: Partial<Response> | Partial<ResponseError>;
    responseState: ApiActionsResponseState;
    timestamp: string;
    url: string;
}
