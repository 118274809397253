import { detectIE, setupFontAwesome } from "portal-components";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import login, { publicRoutes } from "../routes/login";
import ApplyBranding from "../layout/applyBranding";
import FeatureToggleOverwrite from "../layout/featureToggleOverwrite";
import MainContainer from "../layout/main";
import OfflineScreeen from "../layout/offlineScreen";
import { initialize } from "../script/googleAnalytics";
import history from "../script/history";
import storeProvider from "../script/storeProvider";
import { fetchLanguage } from "../i18n/creator";

storeProvider.init();

const NotSupportedBrowser: React.FunctionComponent<{}> = () => (
    <div>
        <h1>Unsupported Browser</h1>
        <div>
            Your browser is not <strong>supported</strong>
        </div>
        <div className="error-man float-right show-for-large" />
        <p>It is recommended that you use a modern browser with fewer security risks.</p>
        <p>
            Visit <a href="http://outdatedbrowser.com/">http://outdatedbrowser.com</a> for a list of available browsers.
        </p>
    </div>
);

export const App: React.FunctionComponent<{}> = () => {
    useEffect(() => {
        setupFontAwesome();
        initialize();
    }, []);

    if (detectIE()) {
        return <NotSupportedBrowser />;
    }

    const store = storeProvider.getStore();
    const state = store.getState();
    const {
        config,
        i18n: { lang },
        auth,
    } = state;
    store.dispatch(fetchLanguage(lang) as any);
    const loginRoutes = publicRoutes.map((path) => <Route key={path} path={path} component={login} />);

    const oldLogin = config.featureToggle.newLogin ? undefined : loginRoutes;

    return (
        <Router history={history}>
            <Provider store={store}>
                <FeatureToggleOverwrite>
                    <ApplyBranding>
                        <OfflineScreeen authenticated={auth?.isAuthenticated} />
                        <Switch>
                            {oldLogin}
                            <Route path="/" component={MainContainer} />
                        </Switch>
                    </ApplyBranding>
                </FeatureToggleOverwrite>
            </Provider>
        </Router>
    );
};

export default App;
