/* eslint-disable react/no-multi-comp */
import React from "react";
import requireAuthorization from "../features/authorization/container";
import Gone from "./410";

interface ModuleWithDefaultExport {
    default: unknown;
}

type ModuleList = Record<string | number, () => Promise<unknown>>;
type LoadedModuleList = Record<string | number, unknown>;

interface LazilyLoadProps {
    modules?: ModuleList;
    children: (modules: LoadedModuleList) => JSX.Element | null;
}

interface LazilyLoadState {
    isLoaded: boolean;
    modules?: LoadedModuleList;
}

class LazilyLoad extends React.PureComponent<LazilyLoadProps, LazilyLoadState> {
    private mounted = false;

    constructor(props: LazilyLoadProps) {
        super(props);
        this.state = {
            isLoaded: false,
        };
    }

    componentDidMount() {
        this.mounted = true;
        this.load();
    }

    componentDidUpdate(prevProps: Partial<LazilyLoadProps>) {
        if (this.props.modules !== prevProps.modules) {
            this.load();
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    load() {
        if (!this.mounted) {
            return;
        }

        this.setState({
            isLoaded: false,
        });

        const modules = this.props.modules ?? {};
        const keys = Object.keys(modules);
        Promise.all(keys.map((key) => modules[key]()))
            .then((values) =>
                keys.reduce((agg, key, index) => {
                    agg[key] = values[index];
                    return agg;
                }, {} as Record<string, unknown>)
            )
            .then((result) => {
                if (this.mounted) {
                    this.setState({ modules: result, isLoaded: true });
                }
            });
    }

    render() {
        if (!this.state.isLoaded || !this.mounted) {
            return null;
        }

        return React.Children.only(this.props.children(this.state.modules ?? {}));
    }
}

export function LazilyLoadFactory<P>(Component: React.ComponentType<P>, modules: ModuleList) {
    return function LazyLoad(props: any) {
        return <LazilyLoad modules={modules}>{(mods) => <Component {...mods} {...props} />}</LazilyLoad>;
    };
}

/*
 * Enzyme doesn't support React.forwardRef yet. Until it does, components using LazilyLoadFactoryRef
 * cannot be shallow rendered in unit tests.
 * TODO Combine LazilyLoadFactory and LazilyLoadFactoryRef when Enzyme supports forwardRef.
 */
export const LazilyLoadFactoryRef = (Component: React.ComponentType<any>, modules: ModuleList) => {
    return React.forwardRef<unknown, any>(function LazyLoad(props, ref) {
        return <LazilyLoad modules={modules}>{(mods) => <Component {...mods} {...props} ref={ref} />}</LazilyLoad>;
    });
};

export const importLazy = (
    promise: Promise<ModuleWithDefaultExport>,
    onFulfilled = (c: ModuleWithDefaultExport) => c.default,
    onRejected = () => {
        return Gone;
    }
) => {
    return promise.then(onFulfilled, onRejected);
};

export const adminAccess = (c: ModuleWithDefaultExport) => requireAuthorization("admin", c.default);
export const updateAccess = (c: ModuleWithDefaultExport) => requireAuthorization("update", c.default);
export const tenantAccess = (c: ModuleWithDefaultExport) => requireAuthorization("manage_subtenants", c.default);

export default LazilyLoad;
