import { TrustedCertificate as SdkTrustedCertificate } from "mbed-cloud-sdk";
import { makeConfigApiCreator, ParseFunction } from "../../..//creators";
import { ListResponse } from "../../../script";
import { ListRequestParameters } from "../../../script/types";

export interface TrustedCertificate extends SdkTrustedCertificate {
    index?: number;
    selected?: boolean;
    subjectName?: string;
    executionService?: string;
    released_at?: Date;
    imported_at?: Date;
    device_execution_mode?: number;
    enrollment_mode?: boolean;
}

const getSubjectName = (certificate: TrustedCertificate) => {
    const subject = certificate?.subject?.split(",") || "";
    for (const item of subject) {
        const subjects = item.split("=");
        if (subjects[0] === "CN") {
            return subjects[1];
        }
    }

    return "-";
};

const getExecutionService = (certificate: TrustedCertificate) => {
    let mode = certificate.device_execution_mode === 1 ? "Dev" : "Prod";
    mode = certificate.enrollment_mode ? mode + "-enrollment" : mode;

    return `${mode}/${certificate.service}`;
};

const makeCertificatesCreator = <T, D = undefined>(type: string, parseFunction?: ParseFunction<T, D>) =>
    makeConfigApiCreator<T, D>("v3_base", {
        errorCallout: false,
    })(type, parseFunction);

// get certificates
const { reducer: getCertificatesReducer, createThunk: createListThunk } = makeCertificatesCreator<
    ListResponse<TrustedCertificate>
>("trusted-certificates", (resp) => {
    const response = resp as ListResponse<TrustedCertificate>;
    response.data = response.data
        .map((item) => ({
            ...item,
            selected: false,
            executionService: getExecutionService(item as any), // the generic type for map results is not working
            subjectName: getSubjectName(item as any),
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
    if (response.data && response.data.length > 1) {
        response.last = response.data[response.data.length - 1].id;
    }
    return response;
});

interface CertificatesListParameters extends ListRequestParameters {
    query?: string;
}

const getCertificates = (
    {
        pageSize = 50,
        last = undefined,
        order = "DESC",
        query = undefined,
        errorAction = undefined,
        errorCallout = true,
    } = {} as CertificatesListParameters
) =>
    createListThunk({
        param: `trusted-certificates`,
        queryParameters: {
            order,
            limit: `${pageSize}`,
            include: "total_count",
            filter: query,
            after: last,
        },
        errorAction,
        errorCallout,
    });

// get total count
const { reducer: countcertificatesCountReducer, createThunk: countThunk } = makeCertificatesCreator<
    number,
    ListResponse<unknown>
>("count_certificates", (response) => (response as ListResponse<unknown>).total_count);
const getCertificatesCountAction = () =>
    countThunk({ param: `trusted-certificates?limit=2&include=total_count`, errorCallout: true });

const { reducer: releaseCertificateReducer, createThunk: releaseCertificateThunk } = makeConfigApiCreator<void>(
    "v3_base"
)("RELEASE_CERTIFICATE");
const releaseCertificates = (id: string) =>
    releaseCertificateThunk({ param: `trusted-certificates/${id}/release/`, method: "POST" });

const { reducer: removeCertificateReducer, createThunk: removeCertificateThunk } = makeConfigApiCreator<void>(
    "v3_base"
)("REMOVE_CERTIFICATE");
const removeCertificates = (id: string) =>
    removeCertificateThunk({ param: `trusted-certificates/${id}`, method: "DELETE" });

const { reducer: unreleaseCertificateReducer, createThunk: unreleaseCertificateThunk } = makeConfigApiCreator<void>(
    "v3_base"
)("UNRELEASE_CERTIFICATE");
const unreleaseCertificates = (id: string) =>
    unreleaseCertificateThunk({ param: `trusted-certificates/${id}/unrelease`, method: "POST" });

export {
    getCertificates,
    getCertificatesReducer,
    getCertificatesCountAction,
    countcertificatesCountReducer,
    releaseCertificateReducer,
    releaseCertificates,
    removeCertificateReducer,
    removeCertificates,
    unreleaseCertificates,
    unreleaseCertificateReducer,
};
