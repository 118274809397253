import { Button, ButtonVariant, copyToClipboard, Icons, Text } from "portal-components";
import React, { FunctionComponent } from "react";
import translate, { DefaultStrings } from "../i18n/translate";

const defaultStrings = {
    copy: "Copy",
};

export interface CopyCalloutProps {
    clear?: boolean;
    text?: string;
    title?: string;
    type?: "pre" | "code";
    strings?: DefaultStrings<typeof defaultStrings>;
}

export const CopyCallout: FunctionComponent<CopyCalloutProps> = (props: CopyCalloutProps) => {
    const { clear, strings = defaultStrings, text, title, type } = props;
    const textEl = type === "pre" ? <pre>{text}</pre> : <code>{text}</code>;

    if (title) {
        return (
            <div className="callout">
                <div className="flex-container">
                    <h3 className="flex-child-grow align-self-middle">{title}</h3>
                    <div className="flex-child-shrink align-self-middle">
                        <Button
                            variant={ButtonVariant.Light}
                            icon={Icons.Copy}
                            text={strings.copy}
                            onClick={() => copyToClipboard(text || "")}
                        />
                    </div>
                </div>
                {textEl}
            </div>
        );
    }

    return (
        <Text className={`copyCallout callout ${clear ? "clear" : "ternary"}`} copiable copiableText={text}>
            {textEl}
        </Text>
    );
};

CopyCallout.displayName = "CopyCallout";
CopyCallout.defaultProps = {
    clear: false,
    strings: defaultStrings,
    text: "",
    title: "",
    type: "pre",
};

export default translate("CopyCallout")(CopyCallout);
