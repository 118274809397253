import { makeConfigApiCreator } from "../../../../creators";

const { reducer, createThunk: newManifestThunk, createResetAction } = makeConfigApiCreator<FormData>("v3_base")(
    "firmware_newmanifest"
);

const postAction = (data: FormData) =>
    newManifestThunk({
        param: "firmware-manifests",
        data: data,
        method: "POST",
        errorCallout: false,
        type: null, //https://stackoverflow.com/questions/39280438/fetch-missing-boundary-in-multipart-form-data-post
    });

const reset = createResetAction();

export { postAction, reset, reducer };
