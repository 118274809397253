import { BusyIndicator } from "portal-components";
import React from "react";
import { connect } from "react-redux";
import PubSub from "../script/pubSub";
import { concatData, evalBoolean } from "../script/utility";
import ApiRequestStateMonitor from "./apiRequestStateMonitor";
import { REQUEST_STATUS } from "./statusIndicator";
import { AppDispatch } from "../creators/reducers";
import { ListResponse, AjaxListResponse } from "../script";
import { isLoaded } from "../creators/AjaxCreator";

const POLLING_INTERVAL = 5000;

export interface ApiListConsumerGetterArgs {
    id?: string;
    pageSize?: number;
    last?: string;
}

// Note: we can't make this `ApiListConsumerProps<T>` to get rid of `any` because
// this component is wrapped with `connect()` then it will lose `<T>` and it's not possible
// to set it when instantiating the component itself.
export interface ApiListConsumerProps {
    dispatch: AppDispatch;
    id?: string;
    withLoader?: boolean;
    disabled?: boolean;
    getter: (options: ApiListConsumerGetterArgs) => any;
    storePropertyName: string;
    externalChangeNotificationId?: string;
    onLoading?: () => void;
    onPageLoaded?: (data: { list: any[]; response?: ListResponse<any> }) => boolean | undefined | void;
    onLoaded?: (data: { list: any[]; refreshing?: boolean }) => boolean | undefined | void;
    onRenderItem?: (itenm: any, index: number, loading: boolean) => void;
    retryOnError?: boolean | (() => boolean);
    children?: React.ReactElement | React.ReactElement[] | ((data: any[]) => React.ReactElement | React.ReactElement[]);
}

interface State {
    list: unknown[];
    response?: AjaxListResponse<unknown> | ListResponse<unknown>;
    requestStatus: string;
}

export class ApiListConsumer extends React.PureComponent<ApiListConsumerProps, State> {
    private readonly operationId?: string;

    constructor(props: ApiListConsumerProps) {
        super(props);

        this.handleLoaded = this.handleLoaded.bind(this);
        this.handleFailed = this.handleFailed.bind(this);
        this.fetchMoreData = this.fetchMoreData.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);

        this.operationId = props.id;

        this.state = {
            list: [],
            requestStatus: REQUEST_STATUS.LOADING,
        };
    }

    componentDidMount() {
        this.handleRefresh();

        if (this.props.externalChangeNotificationId) {
            PubSub.subscribe(this.props.externalChangeNotificationId, this.handleRefresh);
        }
    }

    componentDidUpdate(prevProps: Partial<ApiListConsumerProps>) {
        if (this.props.disabled !== prevProps.disabled && !this.props.disabled) {
            this.handleRefresh();
        }
    }

    componentWillUnmount() {
        if (this.props.externalChangeNotificationId) {
            PubSub.unsubscribe(this.handleRefresh);
        }
    }

    fetchMoreData(pageSize = (this.state.response as ListResponse<unknown>)?.limit, last?: string) {
        this.props.dispatch(this.props.getter({ id: this.operationId, pageSize, last }));
    }

    handleLoaded(current: AjaxListResponse<unknown>) {
        if (!isLoaded(current)) {
            return;
        }

        const data = current.data;
        const list = concatData(this.state.list, data.data);
        const response = data.response || data; // until we migrate all creator to ts and use ListResponse in the parse functions
        let keepReading = response.has_more;

        if (this.props.onPageLoaded) {
            if (this.props.onPageLoaded({ list, response }) === false) {
                keepReading = false;
            }
        }

        this.setState({
            list,
            response,
            requestStatus: REQUEST_STATUS.SUCCEEDED,
        });

        if (this.props.disabled) {
            return;
        }

        if (keepReading) {
            this.fetchMoreData(response.limit, response.last);
        } else if (this.props.onLoaded) {
            const polling = this.props.onLoaded({ list });
            if (polling === true) {
                const limit = (this.state.response as ListResponse<unknown>)?.limit;
                setTimeout(() => this.fetchMoreData(limit), POLLING_INTERVAL);
            }
        }
    }

    handleFailed(current: AjaxListResponse<unknown>) {
        this.setState({
            list: [],
            response: undefined,
            requestStatus: REQUEST_STATUS.FAILED,
        });

        if (this.props.disabled) {
            return;
        }

        if (evalBoolean(this.props.retryOnError, current)) {
            const limit = (this.state.response as ListResponse<unknown>)?.limit;
            setTimeout(() => this.fetchMoreData(limit), POLLING_INTERVAL);
        }
    }

    handleRefresh() {
        if (this.props.disabled) {
            return;
        }

        this.props.onLoading && this.props.onLoading();
        this.props.onLoaded && this.props.onLoaded({ list: [], refreshing: true });

        this.setState({
            list: [],
            response: undefined,
            requestStatus: REQUEST_STATUS.LOADING,
        });
        this.fetchMoreData();
    }

    renderChildren() {
        const loading = this.state.requestStatus === REQUEST_STATUS.LOADING;

        if (this.props.onRenderItem) {
            return this.state.list.map((item, index) => {
                return this.props.onRenderItem?.(item, index, loading);
            });
        }

        if (typeof this.props.children === "function") {
            if (loading) {
                if (this.props.withLoader) {
                    return (
                        <div>
                            <BusyIndicator busy delay={0} />
                        </div>
                    );
                }

                return null;
            }

            return this.props.children(this.state.list);
        }

        return React.Children.map(this.props.children, (child, index) => {
            return React.cloneElement(child as React.ReactElement, {
                key: index,
                loading,
                status: this.state.requestStatus,
                data: loading ? [] : this.state.list,
            });
        });
    }

    render() {
        return (
            <React.Fragment>
                <ApiRequestStateMonitor
                    id={this.operationId}
                    storePropertyName={this.props.storePropertyName}
                    onLoaded={this.handleLoaded}
                    onFailed={this.handleFailed}
                />
                {this.renderChildren()}
            </React.Fragment>
        );
    }
}

export default connect()(ApiListConsumer);
