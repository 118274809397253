import { Callout, Else, If, Severity, Strings } from "portal-components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { resolveErrorMessage } from "../../../../controls/errorHandler";
import { isFailed } from "../../../../creators/AjaxCreator";
import translate from "../../../../i18n/translate";
import BaseLoginComponent from "../../loginComponent";
import { AppState } from "../../../../creators/reducers";
import { redirectTo } from "../../../../script/routing";
import { getAuthorizationEndpoint } from "../creator";
import { REAUTHENTICATION } from "../reauth/container";

export const FLOW_STORAGE_KEY = "oidc-flow";
export const NEXT_STORAGE_KEY = "oidc-next";
export const SILENT_STORAGE_KEY = "oidc-silent";
export const TEAM_STORAGE_KEY = "oidc-team";

export const SILENT_LOGIN_FLOW = "silent-login";
export const LOGIN_FLOW = "login";
export const SILENT_INVITE_ACCEPT_FLOW = "silent-invite-accept";
export const INVITE_ACCEPT_FLOW = "invite-accept";
export const TEAM_LOGIN_FLOW = "team-login";
export const SILENT_TEAM_LOGIN_FLOW = "silent-team-login";

// When a prompt=none authentication fails with one of these error codes,
// the user should be sent to their IdP to try a prompt=login authentication
const tryPromptLoginCodes = ["login_required", "consent_required", "interaction_required"];

const silentLoginFlows = [SILENT_LOGIN_FLOW, SILENT_INVITE_ACCEPT_FLOW, SILENT_TEAM_LOGIN_FLOW];

const flowMapping = new Map();
flowMapping.set(SILENT_TEAM_LOGIN_FLOW, TEAM_LOGIN_FLOW);
flowMapping.set(SILENT_LOGIN_FLOW, LOGIN_FLOW);
flowMapping.set(SILENT_INVITE_ACCEPT_FLOW, INVITE_ACCEPT_FLOW);

export const defaultStrings = {
    anErrorOccurred: "An error occurred",
    stateParameterMismatch: "State parameter did not match",
    unauthorized: "You are not authorized to access this service. You can register with the link below.",
    registrationLink: "Click here to register",
};

export interface LoginFederatedAuthenticateContainerProps extends RouteComponentProps {
    strings: typeof defaultStrings;
}

// Component that receives OIDC responses and routes valid codes to other views to handle. Required since we can only specify one redirect path.
export const LoginFederatedAuthenticateContainer: React.FunctionComponent<LoginFederatedAuthenticateContainerProps> = (
    props
) => {
    const { code, error: idpError, errorDescription, state } = Strings.convertSearchStringToObject(
        props.location.search
    );
    let error = idpError;
    const config = useSelector(({ config }: AppState) => config);
    const federatedLogin = useSelector(({ startFederatedLogin }: AppState) => startFederatedLogin);
    const dispatch = useDispatch();

    if (isFailed(federatedLogin)) {
        error = resolveErrorMessage(federatedLogin.error);
    }

    const flow = sessionStorage.getItem(FLOW_STORAGE_KEY);
    if (flow && (code || error)) {
        if (error) {
            if (tryPromptLoginCodes.includes(error) && silentLoginFlows.includes(flow)) {
                const silentAuthJson = sessionStorage.getItem(SILENT_STORAGE_KEY) as string;
                let silentAuthData;

                try {
                    silentAuthData = JSON.parse(silentAuthJson);
                } catch (e) {
                    silentAuthData = {};
                }

                const { id } = silentAuthData;

                if (id) {
                    // If this is a failed silent authentication, start a non-silent authentication
                    sessionStorage.removeItem(SILENT_STORAGE_KEY);
                    sessionStorage.setItem(FLOW_STORAGE_KEY, flowMapping.get(flow));
                    dispatch(getAuthorizationEndpoint({ idp_id: id }));
                } else {
                    sessionStorage.removeItem(FLOW_STORAGE_KEY);
                    redirectTo({ path: "/", replace: true });
                }
            }
        } else if (flow === INVITE_ACCEPT_FLOW || flow === SILENT_INVITE_ACCEPT_FLOW) {
            sessionStorage.removeItem(FLOW_STORAGE_KEY);
            redirectTo({ path: "/invitation", query: { code } });
        } else {
            sessionStorage.removeItem(FLOW_STORAGE_KEY);
            // If we are coming from a re-auth OIDC flow then notify the calling window and close
            if (flow === REAUTHENTICATION) {
                if (window.opener) {
                    window.opener.postMessage({ code }, window.location.origin);
                } else {
                    window.postMessage({ code }, window.location.origin);
                }
                window.close();
            } else {
                const next = sessionStorage.getItem(NEXT_STORAGE_KEY) || "/";
                sessionStorage.removeItem(NEXT_STORAGE_KEY);
                sessionStorage.removeItem(SILENT_STORAGE_KEY);

                let path = "/login";

                if (flow === TEAM_LOGIN_FLOW || flow === SILENT_TEAM_LOGIN_FLOW) {
                    const team = sessionStorage.getItem(TEAM_STORAGE_KEY) || "";
                    sessionStorage.removeItem(TEAM_STORAGE_KEY);
                    path = `/team/${team}`;
                }

                redirectTo({ path, query: { code, state, next } });
            }
        }
    } else {
        sessionStorage.removeItem(FLOW_STORAGE_KEY);
        redirectTo({ path: "/", replace: true });
    }

    const { strings } = props;
    const registrationLink = config.externalLinks.registration;
    const loading = (!error || error === "login_required") && !errorDescription;

    return (
        <BaseLoginComponent loading={loading} showBackButton id="federated-login-authenticate">
            <If condition={error && error !== "login_required"}>
                <If condition={error === "access_denied" || error === "unauthorized"}>
                    <React.Fragment>
                        <p>{strings.unauthorized}</p>
                        {errorDescription && (
                            <Callout canClose={false} severity={Severity.Alert}>
                                {errorDescription}
                            </Callout>
                        )}
                        <p>
                            <a
                                className="button"
                                href={registrationLink}
                                rel="external noopener noreferrer"
                                target="_blank"
                            >
                                {strings.registrationLink}
                            </a>
                        </p>
                    </React.Fragment>
                    <Else>
                        <p>{strings.anErrorOccurred}</p>
                        <p>
                            <code>{errorDescription || error}</code>
                        </p>
                    </Else>
                </If>
            </If>
        </BaseLoginComponent>
    );
};

LoginFederatedAuthenticateContainer.defaultProps = {
    strings: defaultStrings,
};

export default translate("LoginFederatedAuthenticateContainer")(LoginFederatedAuthenticateContainer);
