import { Button, ErrorMessage, ValueEvent } from "portal-components";
import React from "react";
import translate, { DefaultStrings } from "../../i18n/translate";
import BaseLoginComponent from "./loginComponent";
import { redirectTo } from "../../script/routing";
import Captcha from "../captcha";
import InitialLoginComponent from "./initialLoginComponent";
import MfaLoginComponent from "./mfaLoginComponent";
import TeamSelectionComponent from "./teamSelectionComponent";
import { IdentityProvider } from "./federated/creator";
import { AuthAccount } from "../../layout/reducer";
import { LoginCredentials } from "./team/api";

export const LoginStep = {
    MFA: "MFA",
    INITIAL_LOGIN: "INITIAL_LOGIN",
    TEAM_SELECTION: "TEAM_SELECTION",
};

const defaultStrings = {
    captchaPrompt: "Type the characters from the image above",
    continue: "Continue",
    leadAction: "Sign up",
    loginButton: "Log in",
};

export interface LoginComponentProps {
    strings: DefaultStrings<typeof defaultStrings>;
    showNativeLogin: boolean;
    initialCredentials: LoginCredentials;
    isCaptcha: boolean;
    accounts?: AuthAccount[];
    isOnPrem: boolean;
    identityProviders: IdentityProvider[];
    step: unknown;
    loginHeader: string;
    message: string;
    next: string;
    submitting: boolean;
    lastLogin: { time?: string; cloud?: boolean };
    errorMessage: string | JSX.Element;
    loginAttempts: number;
    onTeamSelect: (id: string) => void;
    onInitialLogin: (options: { email: string; password: string; captcha_id: string; captcha_answer: string }) => void;
    onOtpSubmit: (options: { otp: string; captcha_answer: string; captcha_id: string }) => void;
}

interface State {
    email: string;
    password: string;
    otp: string;
    captcha_answer: string;
    captcha_id: string;
    showNativeLogin: boolean;
    attempted: boolean;
}

export class LoginComponent extends React.PureComponent<LoginComponentProps, State> {
    public static readonly defaultProps = {
        strings: defaultStrings,
        loginAttempts: 0,
        isOnPrem: false,
        next: "/",
    };

    constructor(props: LoginComponentProps) {
        super(props);

        this.state = {
            email: props.initialCredentials.username || "",
            password: "",
            otp: "",
            captcha_answer: "",
            captcha_id: "",
            showNativeLogin: props.showNativeLogin,
            attempted: false,
        };

        this.handleInitialLoginSubmit = this.handleInitialLoginSubmit.bind(this);
        this.handleOtpSubmit = this.handleOtpSubmit.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleOtpChange = this.handleOtpChange.bind(this);
        this.handleCaptchaChange = this.handleCaptchaChange.bind(this);
        this.handleCaptchaFieldChange = this.handleCaptchaFieldChange.bind(this);
        this.handleChangeLoginType = this.handleChangeLoginType.bind(this);
        this.handleLoginWithTeam = this.handleLoginWithTeam.bind(this);
    }

    handleInitialLoginSubmit(e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent>) {
        const { email, password, captcha_id, captcha_answer } = this.state;
        const { isCaptcha, onInitialLogin } = this.props;

        if (!email || !password || (isCaptcha && !captcha_answer)) {
            return;
        }

        // Must be placed after validation to allow form to show required messages
        e.preventDefault();

        onInitialLogin({ email, password, captcha_id, captcha_answer });

        // Clear the password and captcha inputs on submit
        this.setState({ password: "", captcha_answer: "", attempted: true });
    }

    handleOtpSubmit(e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent>) {
        const { otp, captcha_answer, captcha_id } = this.state;
        const { isCaptcha, onOtpSubmit } = this.props;

        if (!otp || (isCaptcha && !captcha_answer)) {
            return;
        }

        // Must be placed after validation to allow form to show required messages
        e.preventDefault();

        onOtpSubmit({ otp, captcha_answer, captcha_id });

        // Clear the otp and captcha inputs on submit
        this.setState({ captcha_answer: "", otp: "", attempted: true });
    }

    handleEmailChange(email: string) {
        this.setState({ email });
    }

    handlePasswordChange(password: string) {
        this.setState({ password });
    }

    handleOtpChange(otp: string) {
        this.setState({ otp });
    }

    handleCaptchaChange(id: string) {
        this.setState({ captcha_id: id });
    }

    handleCaptchaFieldChange(e: ValueEvent<string>) {
        this.setState({ captcha_answer: e.value });
    }

    handleChangeLoginType() {
        this.setState({ showNativeLogin: !this.state.showNativeLogin });
    }

    handleLoginWithTeam() {
        redirectTo({ path: "/team" });
    }

    getLoginForm() {
        const {
            accounts,
            isCaptcha,
            onTeamSelect,
            isOnPrem,
            identityProviders,
            step,
            loginHeader,
            message,
            next,
            strings,
            submitting,
            lastLogin,
            errorMessage,
            loginAttempts,
        } = this.props;
        const { otp, email, password, showNativeLogin } = this.state;

        const isTeamSelection = step === LoginStep.TEAM_SELECTION;
        const isInitialLogin = step === LoginStep.INITIAL_LOGIN;

        const handleSubmit = isInitialLogin ? this.handleInitialLoginSubmit : this.handleOtpSubmit;

        const captchaEl = (
            <Captcha
                inputName="captcha_answer"
                captchaPrompt={strings.captchaPrompt}
                onChange={this.handleCaptchaFieldChange}
                onCaptchaIdChange={this.handleCaptchaChange}
            />
        );

        const content = isTeamSelection ? (
            isCaptcha ? null : (
                <TeamSelectionComponent accounts={accounts} onSelect={onTeamSelect} />
            )
        ) : step === LoginStep.MFA ? (
            <MfaLoginComponent otp={otp} onOtpChange={this.handleOtpChange} />
        ) : (
            <InitialLoginComponent
                identityProviders={identityProviders}
                loginHeader={loginHeader}
                isOnPrem={isOnPrem}
                email={email}
                password={password}
                onEmailChange={this.handleEmailChange}
                onPasswordChange={this.handlePasswordChange}
                lastLogin={lastLogin}
                submitting={submitting}
                captcha={isCaptcha ? captchaEl : null}
                message={message}
                errorMessage={this.state.attempted ? errorMessage : ""}
                next={next}
                onChangeLoginType={this.handleChangeLoginType}
                onLoginWithTeam={this.handleLoginWithTeam}
                showNativeLogin={showNativeLogin}
                loginAttempts={loginAttempts}
            />
        );

        const signInButton =
            (!isTeamSelection || isCaptcha) && !isInitialLogin ? (
                <Button
                    id="sign-in-button"
                    submit
                    primary
                    className="full-size"
                    text={strings.continue}
                    onClick={handleSubmit}
                    busy={submitting}
                />
            ) : null;

        const captcha = !isInitialLogin && isCaptcha ? captchaEl : null;

        return (
            <form id="login-form" onSubmit={handleSubmit}>
                {content}
                {captcha}
                <ErrorMessage value={(this.state.attempted ? (errorMessage as string) : "") || message} />
                {signInButton}
            </form>
        );
    }

    render() {
        const { submitting } = this.props;

        return <BaseLoginComponent loading={submitting}>{this.getLoginForm()}</BaseLoginComponent>;
    }
}

export default translate("LoginComponent")(LoginComponent);
