const WINDOW_PROPERTY_NAME = "pubSubStore";

type PubSubCallback = (payload: any) => void;
interface PubSubRecord {
    id: string;
    callback: PubSubCallback;
}

export default class PubSub {
    private nextId = 0;
    private readonly messages: Record<string, PubSubRecord[]> = {};

    public static subscribe(message: string, callback: PubSubCallback) {
        return PubSub._getInstance()._subscribe(message, callback);
    }

    public static unsubscribe(callbackOrId: string | PubSubCallback) {
        return PubSub._getInstance()._unsubscribe(callbackOrId);
    }

    public static send(message: string, payload?: any, synchronous = false) {
        return PubSub._getInstance()._send(message, payload, synchronous);
    }

    private static _getInstance() {
        if (!Object.prototype.hasOwnProperty.call(window, WINDOW_PROPERTY_NAME)) {
            throw Error("PubSub has not been initialized then it cannot be used.");
        }

        return (window as any)[WINDOW_PROPERTY_NAME] as PubSub;
    }

    private _subscribe(message: string, callback: PubSubCallback) {
        if (typeof message !== "string") {
            throw Error("Argument 'message' is required and it must be a string.");
        }

        if (typeof callback !== "function") {
            throw Error("Argument 'callback' is required and it must be a function.");
        }

        const id = `pubsub-${this.nextId++}`;

        if (Object.prototype.hasOwnProperty.call(this.messages, message)) {
            this.messages[message].push({ id, callback });
        } else {
            this.messages[message] = [{ id, callback }];
        }

        return id;
    }

    private _unsubscribe(callbackOrId: string | PubSubCallback) {
        if (callbackOrId === undefined) {
            return false;
        }

        if (typeof callbackOrId === "function") {
            return this._filterSubscriptions((x) => x.callback !== callbackOrId);
        } else if (typeof callbackOrId === "string") {
            return this._filterSubscriptions((x) => x.id !== callbackOrId);
        } else {
            throw Error("Argument must be an existing callback function or a subscription ID.");
        }
    }

    private _send(message: string, payload: any, synchronous: boolean) {
        if (typeof message !== "string") {
            throw Error("Argument 'message' is required and it must be a string.");
        }

        if (!Object.prototype.hasOwnProperty.call(this.messages, message)) {
            return false;
        }

        const invokeAllCallbacks = () => {
            const callbacks = this.messages[message];
            if (callbacks) {
                callbacks.forEach((x) => x && x.callback(payload));
            }
        };

        // Asynchronous if subscribers are notified in the same "thread" (actually call stack)
        // of the sender then we might have re-entrant code (if publisher itself subscribed to a message
        // and callbacks send that message). Synchronous is useful mostly for testing.
        if (synchronous) {
            invokeAllCallbacks();
        } else {
            setTimeout(invokeAllCallbacks, 0);
        }

        return true;
    }

    private _filterSubscriptions(filter: (entry: PubSubRecord) => boolean) {
        for (const message of Object.keys(this.messages)) {
            this.messages[message] = this.messages[message].filter(filter);

            if (this.messages[message].length === 0) {
                delete this.messages[message];
            }
        }

        return true;
    }
}

(function () {
    if (typeof window !== "object") {
        throw Error("PubSub needs to be hosted inside the browser.");
    }

    if (Object.prototype.hasOwnProperty.call(window, WINDOW_PROPERTY_NAME)) {
        return;
    }

    (window as any)[WINDOW_PROPERTY_NAME] = new PubSub();
})();
