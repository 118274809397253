import React from "react";
import { connect } from "react-redux";
import { Response, ResponseError } from "superagent";
import { Config } from "../../typings/interfaces";
import { CalloutState } from "../../creators/CalloutCreator";
import PubSub from "../../script/pubSub";
import { AppState } from "../../creators/reducers";
import { getFeatureToggle, shallowEqual } from "../../script/utility";
import Component from "./component";
import { ApiActionsResponseState, ApiActionsState, MaxHistoryEntries, PubSubConsoleAPI, StorageName } from "./creator";

export interface ConsoleContainerProps {
    callout: CalloutState;
    config: Config;
}

interface ConsoleContainerState {
    apiActions: ApiActionsState[];
    notifications: CalloutState[];
    tab: number;
}

export class ConsoleContainer extends React.Component<ConsoleContainerProps, ConsoleContainerState> {
    private apiActionsPubSubIdRequest?: string;
    private apiActionsPubSubIdError?: string;
    private apiActionsPubSubIdResponse?: string;

    constructor(props: ConsoleContainerProps) {
        super(props);
        this.state = {
            apiActions: [],
            notifications: [],
            tab: 0,
        };
        this.handleTabSelected = this.handleTabSelected.bind(this);
        this.handleApiActionsRequest = this.handleApiActionsRequest.bind(this);
        this.handleApiActionsError = this.handleApiActionsError.bind(this);
        this.handleApiActionsResponse = this.handleApiActionsResponse.bind(this);
        this.subscribeToAPIActions = this.subscribeToAPIActions.bind(this);
        this.unsubscribeToAPIActions = this.unsubscribeToAPIActions.bind(this);
        this.updateAction = this.updateAction.bind(this);
    }

    UNSAFE_componentWillMount() {
        const {
            config: {
                featureToggle: { consoleAPI = false, consoleNotifications = false, consoleFeatureToggles = false } = {},
            },
        } = this.props;

        const tab = parseInt(localStorage.getItem(StorageName) || "0", 10) || 0;
        this.setState({
            tab: [consoleAPI, consoleNotifications, consoleFeatureToggles].filter(Boolean).length - 1 < tab ? 0 : tab,
        });
    }

    componentDidMount() {
        if (this.props?.config?.featureToggle?.consoleAPI ?? false) {
            this.subscribeToAPIActions();
        }
    }

    componentWillUnmount() {
        this.unsubscribeToAPIActions();
    }

    componentDidUpdate(newProps: ConsoleContainerProps) {
        const {
            callout,
            config: {
                featureToggle: { consoleAPI = false, consoleNotifications = false, consoleFeatureToggles = false } = {},
            },
        } = newProps;

        const {
            callout: oldCallout,
            config: {
                featureToggle: {
                    consoleAPI: previousConsoleAPI = false,
                    consoleNotifications: previousConsoleNotifications = false,
                    consoleFeatureToggles: previousConsoleFeatureToggles = false,
                },
            },
        } = this.props;

        if ((!!callout.header || !!callout.body) && !shallowEqual(callout, oldCallout)) {
            this.setState({
                notifications: [callout, ...this.state.notifications].slice(0, MaxHistoryEntries),
            });
        }

        if (consoleAPI && !previousConsoleAPI) {
            this.subscribeToAPIActions();
        } else if (!consoleAPI && previousConsoleAPI) {
            this.unsubscribeToAPIActions();
        }

        if (
            (!consoleAPI && previousConsoleAPI) ||
            (!consoleNotifications && previousConsoleNotifications) ||
            (!consoleFeatureToggles && previousConsoleFeatureToggles)
        ) {
            this.handleTabSelected(0);
        }
    }

    subscribeToAPIActions() {
        this.apiActionsPubSubIdRequest = PubSub.subscribe(PubSubConsoleAPI.Request, this.handleApiActionsRequest);
        this.apiActionsPubSubIdError = PubSub.subscribe(PubSubConsoleAPI.Error, this.handleApiActionsError);
        this.apiActionsPubSubIdResponse = PubSub.subscribe(PubSubConsoleAPI.Response, this.handleApiActionsResponse);
    }

    unsubscribeToAPIActions() {
        if (this.apiActionsPubSubIdRequest) {
            PubSub.unsubscribe(this.apiActionsPubSubIdRequest);
        }
        if (this.apiActionsPubSubIdError) {
            PubSub.unsubscribe(this.apiActionsPubSubIdError);
        }
        if (this.apiActionsPubSubIdResponse) {
            PubSub.unsubscribe(this.apiActionsPubSubIdResponse);
        }
    }

    handleApiActionsRequest(actionState: Partial<ApiActionsState>) {
        const { consoleId = "", data, method = "", queryParameters = {}, url = "" } = actionState;
        const newAction = {
            consoleId,
            data,
            method,
            queryParameters,
            timestamp: new Date().toISOString(),
            url,
            responseState: ApiActionsResponseState.Loading,
        };
        this.setState({
            apiActions: [newAction, ...this.state.apiActions].slice(0, MaxHistoryEntries),
        });
    }

    updateAction(consoleId: string, response: Response | Error, responseState: ApiActionsResponseState) {
        const { apiActions = [] } = this.state;

        if (consoleId) {
            const actionIndex = apiActions.findIndex((action) => !!action.consoleId && action.consoleId === consoleId);
            if (actionIndex > -1) {
                const action = apiActions[actionIndex];
                let filtered = {};
                if (responseState === ApiActionsResponseState.Loaded) {
                    filtered =
                        !!response && typeof response !== "string" && response instanceof Response
                            ? { body: response.body, status: response.status }
                            : {};
                } else {
                    filtered =
                        !!response && typeof response !== "string" && response instanceof Error
                            ? { message: response.message, status: (response as ResponseError).status }
                            : {};
                }
                const updatedAction: ApiActionsState = { ...action, response: filtered, responseState };
                this.setState({
                    apiActions: [
                        ...apiActions.slice(0, actionIndex),
                        updatedAction,
                        ...apiActions.slice(actionIndex + 1),
                    ],
                });
            }
        }
    }

    handleApiActionsError(actionState: { consoleId: string; response: Response }) {
        const { consoleId = "", response } = actionState;
        this.updateAction(consoleId, response, ApiActionsResponseState.Error);
    }

    handleApiActionsResponse(actionState: { consoleId: string; response: Response }) {
        const { consoleId = "", response } = actionState;
        this.updateAction(consoleId, response, ApiActionsResponseState.Loaded);
    }

    handleTabSelected(tab: number) {
        this.setState({ tab });
        localStorage.setItem(StorageName, tab.toString());
    }

    render() {
        const {
            config: { apiHost = "" },
        } = this.props;
        const { notifications, apiActions, tab } = this.state;

        const enableAPI = getFeatureToggle("consoleAPI", this.props);
        const enableNotifications = getFeatureToggle("consoleNotifications", this.props);
        const enableToggles = getFeatureToggle("consoleFeatureToggles", this.props);

        return (
            <Component
                apiActions={apiActions}
                enableAPI={enableAPI}
                enableNotifications={enableNotifications}
                enableToggles={enableToggles}
                host={apiHost}
                notifications={notifications}
                onTabSelected={this.handleTabSelected}
                tab={tab}
            />
        );
    }
}

export const mapStateToProps = (state: AppState) => {
    const { callout, config } = state;
    return { callout, config };
};

export default connect(mapStateToProps)(ConsoleContainer);
