import { makeConfigApiCreator } from "../../../creators";

export interface SamlLoginResponse {
    redirect_uri: string;
}

const { reducer, createThunk } = makeConfigApiCreator<SamlLoginResponse>("v3_base", {
    useAuth: false,
    errorCallout: false,
    errorRedirect: false,
})("INITIAL_SAML_LOGIN");

export interface PostInitialSamlLoginOptions {
    idpId: string;
    team: string;
}

/**
 * First POST to /auth/login as part of the SAML login flow.
 */
export const postInitialSamlLogin = ({ idpId, team }: PostInitialSamlLoginOptions) =>
    createThunk({
        method: "POST",
        param: "login",
        data: { idp_id: idpId, account: team },
    });

export { reducer };
