import { MediaQueries, MediaQuery, Orientation, Toolbar } from "portal-components";
import React from "react";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const classes = require("./style.scss");

export interface FilterBarProps {
    className?: string;
    disabled?: boolean;
    hidden?: boolean;
}

export const FilterBar: React.FunctionComponent<FilterBarProps> = (props) => (
    <MediaQuery
        query={MediaQueries.SmallOnly}
        render={(small) => (
            <Toolbar
                className={[props.className, classes.filterBar, small ? "vertical" : ""]}
                disabled={props.disabled}
                hidden={props.hidden}
                elementProps={{ orientation: small ? Orientation.Vertical : Orientation.Horizontal }}
            >
                {props.children || null}
            </Toolbar>
        )}
    />
);
