// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3YsxshdRcVAawbovSLlZm6{overflow-y:auto;max-height:500px}\n", "",{"version":3,"sources":["switchTeam.scss"],"names":[],"mappings":"AAAA,yBAAoB,eAAe,CAAC,gBAAgB","file":"switchTeam.scss","sourcesContent":[":local(.switchTeam){overflow-y:auto;max-height:500px}\n"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switchTeam": "_3YsxshdRcVAawbovSLlZm6"
};
export default ___CSS_LOADER_EXPORT___;
