import React from "react";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps, withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import { AppState } from "../creators/reducers";
import { Config } from "../typings/interfaces";
import NotFound from "../layout/404";
import ErrorBoundary from "../layout/errorBoundary";
import { importLazy, LazilyLoadFactory } from "../layout/lazilyload";
import { PropagatedProps } from "../layout/main/component";
import { asyncRender } from "./BaseRoute";

export interface AccountRouteProps extends RouteComponentProps {
    Account: React.ComponentType<PropagatedProps>;
    ResetPassword: React.ComponentType<PropagatedProps>;
    TotpSetup: React.ComponentType<PropagatedProps>;
    UpdateNotificationContacts: React.ComponentType<PropagatedProps>;
    UpdateUser: React.ComponentType<PropagatedProps>;
    TermsOfService: React.ComponentType<PropagatedProps>;
    componentProps: PropagatedProps;
    config: Config;
}

export const AccountRoute: React.FunctionComponent<AccountRouteProps> = (props) => {
    const { Account, componentProps, config, ResetPassword, TermsOfService, TotpSetup, UpdateUser } = props;
    const asyncRenderWithProps = asyncRender(componentProps);
    return (
        <ErrorBoundary>
            <Switch>
                <Route exact path="/account/profile" render={asyncRenderWithProps(Account, "myAccount")} />
                {config.featureToggle.showTermsOfService && (
                    <Route
                        exact
                        path="/account/terms-of-service"
                        render={asyncRenderWithProps(TermsOfService, "termsOfService")}
                    />
                )}
                <Route exact path="/account/reset" render={asyncRenderWithProps(ResetPassword, "resetPassword")} />
                <Route exact path="/account/update/auth" render={asyncRenderWithProps(TotpSetup, "totpSetting")} />
                <Route
                    exact
                    path="/account/update/auth/deactivate"
                    render={asyncRenderWithProps(TotpSetup, "totpDeactivate")}
                />
                <Route
                    exact
                    path="/account/update/auth/generaterecoverycodes"
                    render={asyncRenderWithProps(TotpSetup, "totpGenerateRecoveryCodes")}
                />
                <Route exact path="/account/update/user" render={asyncRenderWithProps(UpdateUser, "editMyDetails")} />
                <Redirect exact from="/account" to="/account/profile" />
                <Redirect exact from="/account/logout" to="/account/profile" />
                <Route component={NotFound} />
            </Switch>
        </ErrorBoundary>
    );
};

const mapStateToProps = ({ config }: AppState) => ({ config });

export default LazilyLoadFactory(withRouter(connect(mapStateToProps)(AccountRoute)), {
    Account: () => importLazy(import("../features/account/container")),
    ResetPassword: () => importLazy(import("../features/account/reset/resetPassword")),
    TotpSetup: () => importLazy(import("../features/account/update/auth/container")),
    UpdateUser: () => importLazy(import("../features/account/update/user/updateUser")),
    TermsOfService: () => importLazy(import("../features/account/terms-of-service/termsOfService")),
});
