import { ActiveSession, ActiveSessionAdapter, UserUpdateRequest, User as SDKUser } from "mbed-cloud-sdk";
import { makeConfigApiCreator } from "../../../../creators";
import { ListResponse } from "../../../../script/types";
import reducerRegistry from "../../../../creators/reducerRegistry";

export interface User extends SDKUser {
    is_password_change_needed?: boolean;
}
export interface UserUpdate extends UserUpdateRequest {
    is_password_change_needed?: boolean;
    status?: string;
}

// get user
const { reducer: getUserReducer, createThunk: getUserThunk, createResetAction: userResetThunk } = makeConfigApiCreator<
    User
>("v3_base")("GET_USER");
const getUser = (id: string) =>
    getUserThunk({
        param: `users/${id}`,
        method: "GET",
    });
const resetUser = userResetThunk();

// update user
const {
    reducer: updateUserReducer,
    createThunk: updateUserThunk,
    createResetAction: updateUserResetThunk,
} = makeConfigApiCreator<UserUpdate>("v3_base")("UPDATE_USER");

const updateUser = (id: string, data: Partial<UserUpdate>) =>
    updateUserThunk({
        param: `users/${id}`,
        data,
        method: "PUT",
        errorCallout: true,
    });
const updateUserReset = updateUserResetThunk();

const { reducer: userSessionsReducer, createThunk: createUserSessionsThunk } = makeConfigApiCreator<
    ListResponse<ActiveSession>
>("v3_base", { errorCallout: false })("iam_user_sessions", (response) => {
    const mappedData = (response as ListResponse<any>).data.map((r) => ActiveSessionAdapter.fromApi(r));
    return { ...(response as ListResponse<any>), data: mappedData } as ListResponse<ActiveSession>;
});
const getSessionsAction = (id: string) => createUserSessionsThunk({ param: `users/${id}/sessions` });

const reducers = {
    getUser: getUserReducer,
    userSessions: userSessionsReducer,
    updateUser: updateUserReducer,
};

reducerRegistry.register(reducers);

type UserDetailReducers = typeof reducers;

export { getUser, getSessionsAction, resetUser, updateUser, updateUserReset, UserDetailReducers };
