import { makeConfigApiCreator, ParseFunction } from "../../../creators";
import { ListResponse } from "../../../script";
import history from "../../../script/history";
import { LwM2MObject, ResourcesParameters } from "./types";

const makeResourcesFiltersCreator = <T>(type: string, parseFunction?: ParseFunction<T>) =>
    makeConfigApiCreator<T>("v3_base", {
        errorCallout: true,
        errorRedirect: () => history.push("/devices/list"),
    })(type, parseFunction);

const { createThunk: createResourcesThunk, reducer: resourcesReducer } = makeResourcesFiltersCreator<
    ListResponse<LwM2MObject>
>("resources_catalog", (untypedResponse: unknown) => {
    const response = untypedResponse as ListResponse<LwM2MObject>;
    if (response.data?.length > 1) {
        response.last = response.data[response.data.length - 1].id;
    }
    return response;
});
const getAllResources = ({
    limit = 50,
    after = "",
    order = "ASC",
    errorAction = undefined,
    errorCallout = true,
}: ResourcesParameters = {}) => {
    const queryParameters = {
        order,
        limit: limit?.toString(),
        include: "total_count",
        ...(after && { after: after }),
    };

    return createResourcesThunk({
        param: "lwm2m-objects/resources",
        queryParameters,
        errorAction,
        errorCallout,
    });
};

export { resourcesReducer, getAllResources };
