import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Config } from "../../../typings/interfaces";
import { AuthState } from "../../../layout/reducer";
import { AppDispatch, AppState } from "../../../creators/reducers";
import { ListResponse } from "../../../script/types";
import { Response } from "superagent";
import { resolveErrorMessage } from "../../../controls/errorHandler";
import { AjaxState, ErrorAndUrl, onStateChange } from "../../../creators/AjaxCreator";
import translate, { DefaultStrings } from "../../../i18n/translate";
import { redirectTo } from "../../../script/routing";
import { convertSearchToQuery, sendEvent } from "../../../script/utility";
import { logout } from "../logout/creator";
import { setLoginCookie } from "../creator";
import { SILENT_INVITE_ACCEPT_FLOW, SILENT_STORAGE_KEY } from "../federated/auth/container";
import { IdentityProvider, identityProviderAction, resetIdentityProviderAction } from "../federated/creator";
import { goToIdPSilent } from "../federated/oidc";
import { FLOW_STORAGE_KEY } from "../federated/reauth/container";
import InviteAcceptComponent from "./component";
import { acceptInvite, getInviteStatus, InviteAccept as InviteAcceptance, InviteStatus } from "./creator";

export const INVITATION_KEY = "invitation";
export const defaultStrings = {
    inviteAccept: "Accept invitation",
    networkError: "A network error has occurred, please try again",
    redirectToLoginMessage: "Your user account has been created. Please log in to get started.",
    redirectToLoginAfterAcceptance: "You have been added to team. Please log in.",
    redirectToLoginAfterInvalidInvitation: "The invitation id provided is invalid.",
    redirectToLoginAfterForbidden: "The user account has been disabled.",
};

interface InviteAcceptPropsBase {
    auth: AuthState;
    config: Config;
    dispatch: AppDispatch;
    identityProvider: AjaxState<ListResponse<IdentityProvider>>;
    inviteAccept?: AjaxState<void>;
    inviteStatus?: AjaxState<InviteStatus>;
    strings: DefaultStrings<typeof defaultStrings>;
}

export type InviteAcceptProps = InviteAcceptPropsBase & RouteComponentProps<{}>;

interface State {
    invite?: InviteStatus;
    invitationId: string;
    identityProvider?: IdentityProvider;
    issuer?: string;
    message: string;
    validationMessage?: string;
    pendingLogin: boolean;
    working: boolean;
}

/*
  Container loaded at /invitation path, handles links in invitation emails. Gets the request status when mounted.
  The IDP list will be loaded once the invitation verified to be valid (!= 404) and any issuer stored in state.
  There are then three cases:
  - The flow field in the invite status is set to "enrollment", implying the user already exists and is joining a new team
    - PUT invitation id to IAM without body
    - If issuer is present, initiate OIDC flow, otherwise link to login page
  - The flow field in the invite status is set to "acceptance", implying the invited user is new to Pelion
    - If there is an issuer parameter
      - PUT invitation id to IAM with issuer
      - Redirect to OIDC with the inviteId query parameter, storing the ID in session storage
    - There is no issuer parameter, implying the account uses username & password login
      - Present a form for the user to set their password etc., PUT fields to IAM then load login page
  - There is a stored invitation ID with a code parameter, implying the user is new, joining an IdP authenticated team and
    has just returned from their IdP
      - PUT code and invitation ID to IAM
      - Load IdP for login flow
 */
export class InviteAccept extends React.PureComponent<InviteAcceptProps, State> {
    public static defaultProps = {
        strings: defaultStrings,
    };

    constructor(props: InviteAcceptProps) {
        super(props);

        const { id, "acceptance-id": acceptanceId, issuer } = convertSearchToQuery(this.props.location.search);

        this.state = {
            invitationId: id || acceptanceId,
            working: true,
            issuer,
            message: "",
            pendingLogin: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.startLogin = this.startLogin.bind(this);
        this.handleError = this.handleError.bind(this);
    }

    handleError(response: Response) {
        redirectTo({
            path: "/login",
            replace: true,
            state: response.status === 404 ? this.props.strings.redirectToLoginAfterInvalidInvitation : "",
        });
    }

    componentDidMount() {
        const { dispatch } = this.props;
        const { invitationId } = this.state;

        dispatch(resetIdentityProviderAction());

        if (invitationId) {
            dispatch(getInviteStatus(invitationId, this.handleError));
        } else {
            // User has arrived at this page without an invitation; send them to the login page
            redirectTo({ path: "/login", replace: true });
        }
    }

    componentDidUpdate(prevProps: InviteAcceptProps) {
        const { auth, identityProvider, inviteStatus, inviteAccept } = prevProps;
        if (this.props.auth.type === "LOGOUT_SUCCESS" && auth.type === "LOGOUT_REQUEST" && this.state.pendingLogin) {
            this.setState({ pendingLogin: false }, () => {
                this.startLogin(this.state.identityProvider);
            });
        }

        onStateChange(inviteStatus, this.props.inviteStatus, {
            loading: () => {
                this.setState({ working: true });
            },
            loaded: ({ data }) => {
                this.setState({ invite: data }, () => {
                    if (data.flow === "forbidden") {
                        redirectTo({
                            path: "/login",
                            replace: true,
                            state: this.props.strings.redirectToLoginAfterForbidden,
                        });
                    } else {
                        this.props.dispatch(
                            identityProviderAction({ accountId: data.account_id, issuer: this.state.issuer })
                        );
                    }
                });
            },
        });

        onStateChange(identityProvider, this.props.identityProvider, {
            loading: () => {
                this.setState({ working: true });
            },
            loaded: ({ data }) => {
                const { invite: account, invitationId } = this.state;

                if (account?.flow === "acceptance") {
                    // accept the invite, no further details are required
                    this.props.dispatch(acceptInvite(invitationId));

                    if (this.state.issuer) {
                        const provider = data.data.find((p) => p.issuer === this.state.issuer) as IdentityProvider;

                        // If the user has an IdP, we need an authorization code to accept the invitation
                        const {
                            oidc: { client_id: clientId = "", authorization_endpoint: authorizationUrl = "" } = {},
                        } = provider;
                        sessionStorage.setItem(INVITATION_KEY, invitationId);
                        sessionStorage.setItem(FLOW_STORAGE_KEY, SILENT_INVITE_ACCEPT_FLOW);

                        sessionStorage.setItem(SILENT_STORAGE_KEY, JSON.stringify({ clientId, authorizationUrl }));

                        goToIdPSilent({
                            clientId,
                            authorizationUrl,
                            additionalParameters: { inviteId: invitationId },
                        });
                    }
                } else {
                    this.setState({
                        working: false,
                    });
                }
            },
        });

        onStateChange(inviteAccept, this.props.inviteAccept, {
            loading: () => {
                this.setState({ working: true });
            },
            loaded: () => {
                sendEvent("Invite Accept", "Invite accepted.");

                if (this.props.auth.isAuthenticated) {
                    this.setState({ pendingLogin: true }, () => {
                        this.props.dispatch(logout());
                    });
                } else {
                    this.startLogin(this.state.identityProvider);
                }
            },
            failed: (error) => {
                this.setState({
                    working: false,
                    validationMessage: resolveErrorMessage(error as ErrorAndUrl),
                });
            },
        });
    }

    handleSubmit(data: InviteAcceptance) {
        const { dispatch } = this.props;
        const { invitationId } = this.state;

        dispatch(acceptInvite(invitationId, data));
    }

    startLogin(identityProvider?: IdentityProvider) {
        const { strings } = this.props;
        const { invite } = this.state;
        const issuer = identityProvider && identityProvider.issuer;

        setLoginCookie({
            username: invite?.email || "",
            issuer,
        });

        const message =
            invite?.flow === "enrollment" ? strings.redirectToLoginMessage : strings.redirectToLoginAfterAcceptance;

        redirectTo({ path: `/team/${invite?.team}`, replace: true, state: message });
    }

    render() {
        return (
            <InviteAcceptComponent
                invite={this.state.invite}
                onSubmit={this.handleSubmit}
                working={this.state.working}
                message={this.state.message}
                validationMessage={this.state.validationMessage}
            />
        );
    }
}

// map Redux store state to React props
const mapStateToProps = ({ auth, config, identityProvider, inviteAccept, inviteStatus }: AppState) => ({
    auth,
    inviteAccept,
    inviteStatus,
    config,
    identityProvider,
});

export default connect(mapStateToProps)(translate("InviteAccept")(InviteAccept));
