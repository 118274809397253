import {
    getCustomTag,
    Icons,
    IconSize,
    If,
    LinkMenuItem,
    MenuItem,
    NavigationGroup,
    NavigationItem,
    NavigationMenuGroup,
    NavigationMenuGroupItem,
    NavigationMenuGroupItemLink,
    SearchableFeature,
    Separator,
    SiteNavigationBar,
} from "portal-components";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Config } from "../../typings/interfaces";
import { AjaxState, isLoaded } from "../../creators/AjaxCreator";
import { CurrentAccount, CurrentUser } from "../../features/account";
import { createLanguageAction } from "../../i18n/creator";
import translate from "../../i18n/translate";
import NewIzumaLogo from "../../images/izuma-logo-white-horizontal-210-90.png";
import NewLogo from "../../images/izuma-logo-white-horizontal-210-90.png";
import { AuthState } from "../../layout/reducer";
import {
    getBrandedApplicationLogo,
    getBrandedLogo,
    isBrandedApplicationTeamLogo,
    isBrandedTeamLogo,
} from "../../script/branding";
import { AppDispatch, AppState } from "../../creators/reducers";
import { Access, AuthorizationRule, isAuthorized } from "../../script/permissions";
import { getAccess } from "../../script/roles";
import AccountMenu from "./accountMenu";
import { getFeatureToggle } from "../../script/utility";

declare const __VERSION__: string;

export const MenuComponentSlots = {
    Top: { order: 0 },
    Menu: { order: 10 },
    Bottom: { order: 20 },
};

const defaultStrings = {
    uiVersion: "User interface version",
    poweredBy: "Powered by Izuma",
    accessManagement: "Access management",
    identity: "Identity",
    account: "Account",
    privacy: "Privacy",
    apiKeys: "API keys",
    branding: "Team branding",
    certificate: "Certificates",
    certificateDetail: "Certificate Detail",
    connector: "Connected devices",
    customboard: "Custom dashboard",
    deviceCatalog: "Devices",
    deviceGroups: "Device groups",
    deviceObjects: "Device objects",
    deviceIdentity: "Device identity",
    deviceLogs: "Device events",
    deviceManagement: "Device directory",
    deviceInsights: "Insights sessions",
    enrollingDevices: "Enrolling devices",
    factoryMetrics: "Factory metrics",
    factoryTools: "Factory",
    factoryToolsNew: "Factory new",
    firmwareManagement: "Firmware update",
    fleetManagement: "Fleet management",
    gatewaysboard: "Gateway monitoring",
    groups: "Groups",
    home: "Dashboard",
    images: "Images",
    keyManagement: "Key management",
    manifests: "Manifests",
    policies: "Access policies",
    productionLine: "Production lines",
    serverCredentials: "Server",
    trustAnchor: "Trust anchor",
    updateCampaigns: "Update campaigns",
    preSharedKeys: "Pre-shared keys",
    users: "Users",
    teamConfiguration: "Team configuration",
    teamTenants: "Tenants",
    teamProfile: "Team profile",
    teamIdentityAndSecurity: "Identity and security",
    billingReports: "Billing reports",
    billingUsage: "Usage report",
    myBilling: "My billing",
    servicePackageHistory: "Service package history",
    language: "Language",
    help: "Help",
    connectGuideLink: "Connect Guide",
    forumLink: "Mbed forum",
    docsLink: "Documentation",
    contactLink: "Contact us",
    usageboard: "Usage dashboard",
    jobManagement: "Job management",
    jobs: "Jobs",
    applications: "Applications",
    accessKeys: "Access keys",
};

interface MenuComponentProps {
    strings: typeof defaultStrings;
    dispatch: AppDispatch;
    config: Config;
    auth: AuthState;
    access?: Access;
    currentAccount: AjaxState<CurrentAccount>;
    currentUser: AjaxState<CurrentUser>;
    hidden: boolean;
    withLogo?: boolean;
}

type MenuComponentWithRoutingProps = MenuComponentProps & RouteComponentProps<Record<string, string>>;

export class MenuComponent extends React.PureComponent<MenuComponentWithRoutingProps> {
    public static readonly defaultProps = {
        strings: defaultStrings,
    };

    private readonly access: Access;

    public constructor(props: Readonly<MenuComponentWithRoutingProps>) {
        super(props);

        this.isAuthorized = this.isAuthorized.bind(this);
        this.handleChangeLanguage = this.handleChangeLanguage.bind(this);

        this.access = props.access || getAccess(); // It's useful to have it as property only for testing
    }

    public componentDidUpdate() {
        const { currentAccount } = this.props;
        if (isLoaded(currentAccount)) {
            const { data } = currentAccount;
            this.access.tier = data.tier;
            this.access.tenant = data.parent_id !== null;
        }
    }

    public render() {
        const { hidden, config, auth, withLogo, strings, currentUser } = this.props;

        const { onPremises = false } = config || {};
        const useNewMenu = getFeatureToggle("useNewMenu", this.props);

        const defaultDashboard = isLoaded(currentUser)
            ? currentUser?.data?.custom_fields?.default_dashboard?.name ?? ""
            : "";

        // Get menu logo
        let logo = getBrandedLogo(config) || NewLogo;
        let showPoweredBy = config.showPoweredBy || (!config.onPremises && isBrandedTeamLogo(config));

        // If we're using the new menu system, load the new logo
        if (useNewMenu) {
            // if no application logo, use branded logo else use default application logo
            logo = getBrandedApplicationLogo(config) || getBrandedLogo(config) || NewIzumaLogo;
            showPoweredBy =
                config.showPoweredBy ||
                (!config.onPremises && (isBrandedApplicationTeamLogo(config) || isBrandedTeamLogo(config)));
        }

        const privacyPolicyUrl = config && config.externalLinks ? config.externalLinks.privacyPolicy : "";

        const uiVersionLine = (
            <span>
                <small className="muted">{strings.uiVersion}</small>
                <br />
                {__VERSION__}
            </span>
        );

        return (
            <SiteNavigationBar
                logoUrl={withLogo ? (logo as string) : undefined}
                logoFooter={withLogo && showPoweredBy ? strings.poweredBy : ""}
                hidden={hidden}
                {...(useNewMenu && { className: "site-menu-navigation-bar" })}
            >
                {this.props.children || null}
                <If condition={useNewMenu}>
                    <NavigationMenuGroup to="/dashboard" icon={Icons.DashboardView} text={strings.home}>
                        <NavigationMenuGroupItem
                            to="/dashboard/usage"
                            text={strings.usageboard}
                            favorite={defaultDashboard === "usage"}
                        />
                        <NavigationMenuGroupItem
                            to="/dashboard/gateways"
                            text={strings.gatewaysboard}
                            favorite={defaultDashboard === "gateways"}
                            when={this.isAuthorized({ feature: "edgeThick" })}
                        />
                        <NavigationMenuGroupItem
                            to="/dashboard/custom"
                            text={strings.customboard}
                            favorite={defaultDashboard === "custom"}
                            when={this.isAuthorized({ feature: "customDashboard" })}
                        />
                    </NavigationMenuGroup>
                    <NavigationMenuGroup to="/devices" icon={Icons.DeviceDirectoryView} text={strings.deviceManagement}>
                        <NavigationMenuGroupItem to="/devices/list" text={strings.deviceCatalog} />
                        <NavigationMenuGroupItem
                            to="/devices/insights"
                            text={strings.deviceInsights}
                            when={this.isAuthorized({ feature: "pelion_insight" })}
                        />
                        <NavigationMenuGroupItem
                            to="/devices/groups/list"
                            text={strings.deviceGroups}
                            when={this.isAuthorized({ feature: "device_groups" })}
                        />
                        <NavigationMenuGroupItem to="/devices/logs" text={strings.deviceLogs} />
                        <NavigationMenuGroupItem
                            to="/devices/objects/list"
                            text={strings.deviceObjects}
                            when={this.isAuthorized({ admin: true })}
                        />
                        <NavigationMenuGroupItem
                            to="/devices/enrollment"
                            text={strings.enrollingDevices}
                            when={this.isAuthorized({ feature: "bring_your_own_certificates" })}
                        />
                    </NavigationMenuGroup>
                    <NavigationMenuGroup to="/identity" icon={Icons.DeviceIdentityView} text={strings.deviceIdentity}>
                        <NavigationMenuGroupItem to="/identity/certificates/list" text={strings.certificate} />
                        <NavigationMenuGroupItem
                            to="/identity/factoryTools"
                            text={strings.factoryTools}
                            when={this.isAuthorized({ feature: "factory_configurator_utility" })}
                        />
                        <NavigationMenuGroupItem to="/identity/server" text={strings.serverCredentials} />
                        <NavigationMenuGroupItem
                            to="/identity/trust-anchor"
                            text={strings.trustAnchor}
                            when={this.isAuthorized({ feature: "sda" })}
                        />
                        <NavigationMenuGroupItem
                            to="/identity/pre-shared-keys"
                            text={strings.preSharedKeys}
                            when={this.isAuthorized({ feature: "miniclient" })}
                        />
                    </NavigationMenuGroup>
                    <NavigationMenuGroup
                        to="/fleet"
                        icon={Icons.FleetManagement}
                        text={strings.fleetManagement}
                        when={
                            this.isAuthorized({ feature: "fleetManagement" }) &&
                            this.isAuthorized({ feature: "manufacturing_statistics" })
                        }
                    >
                        <NavigationMenuGroupItem
                            to="/fleet/factory"
                            text={strings.factoryMetrics}
                            when={this.isAuthorized({ feature: "manufacturing_statistics" })}
                        />
                    </NavigationMenuGroup>
                    <NavigationMenuGroup
                        to="/firmware"
                        icon={Icons.FirmwareUpdateView}
                        text={strings.firmwareManagement}
                        when={this.isAuthorized({ feature: "update" })}
                    >
                        <NavigationMenuGroupItem to="/firmware/images/list" text={strings.images} />
                        <NavigationMenuGroupItem to="/firmware/manifests/list" text={strings.manifests} />
                        <NavigationMenuGroupItem
                            to="/firmware/deployments/list"
                            text={strings.updateCampaigns}
                            when={this.isAuthorized({ feature: "update" })}
                        />
                    </NavigationMenuGroup>
                    <NavigationMenuGroup to="/access" icon={Icons.AccessManagementView} text={strings.accessManagement}>
                        <NavigationMenuGroupItem
                            to="/access/users/list"
                            text={strings.users}
                            when={this.isAuthorized({ admin: true })}
                        />
                        <NavigationMenuGroupItem to="/access/keys" text={strings.apiKeys} />
                        <NavigationMenuGroupItem
                            to="/access/applications"
                            text={strings.applications}
                            when={this.isAuthorized({ admin: true })}
                        />
                        <NavigationMenuGroupItem
                            to="/access/accessKeys"
                            text={strings.accessKeys}
                            when={this.isAuthorized({ admin: true })}
                        />
                        <NavigationMenuGroupItem
                            to="/access/groups"
                            text={strings.groups}
                            when={this.isAuthorized({ admin: true })}
                        />
                        <NavigationMenuGroupItem
                            to="/access/policies/list"
                            text={strings.policies}
                            when={this.isAuthorized({ admin: true, feature: "sda" })}
                        />
                    </NavigationMenuGroup>
                    <NavigationMenuGroup
                        to="/organization"
                        icon={Icons.TeamConfigurationView}
                        text={strings.teamConfiguration}
                    >
                        <NavigationMenuGroupItem to="/organization/profile" text={strings.teamProfile} />
                        <NavigationMenuGroupItem
                            to="/organization/branding"
                            text={strings.branding}
                            when={this.isAuthorized({ admin: true, feature: "uiCustomization" })}
                        />
                        <NavigationMenuGroupItem
                            to="/organization/identity"
                            text={strings.teamIdentityAndSecurity}
                            when={this.isAuthorized({ admin: true })}
                        />
                        <NavigationMenuGroupItem
                            to="/organization/tenants/list"
                            text={strings.teamTenants}
                            when={this.isAuthorized({
                                admin: true,
                                feature: "manage_subtenants",
                                tenant: false,
                            })}
                        />
                    </NavigationMenuGroup>
                    <NavigationMenuGroup
                        to="/billing"
                        icon={Icons.BillingReportsView}
                        text={strings.billingReports}
                        when={this.isAuthorized({ tierCommercial: true })}
                    >
                        <NavigationMenuGroupItem to="/billing/me" text={strings.myBilling} />
                        <NavigationMenuGroupItem
                            when={this.isAuthorized({ tenant: false })}
                            to="/billing/history"
                            text={strings.servicePackageHistory}
                        />
                        <NavigationMenuGroupItem to="/billing/usage" text={strings.billingUsage} />
                    </NavigationMenuGroup>
                    <NavigationMenuGroup
                        to="/jobs"
                        icon={Icons.JobsView}
                        text={strings.jobManagement}
                        when={this.isAuthorized({ admin: true, feature: "jobManagement" })}
                    >
                        <NavigationMenuGroupItem to="/jobs/list" text={strings.jobs} />
                    </NavigationMenuGroup>
                    <Separator />
                    <AccountMenu />
                    <If condition={config.externalLinks}>
                        <NavigationMenuGroup
                            expand={true}
                            to="/contact"
                            icon={Icons.TeamConfigurationView}
                            text={strings.help}
                        >
                            <If
                                condition={
                                    config.externalLinks.connectGuide &&
                                    !onPremises &&
                                    auth.federated &&
                                    auth.issuer.includes("account.mbed.com")
                                }
                            >
                                <NavigationMenuGroupItem
                                    extras={{ externalLink: true, target: "_blank" }}
                                    to={config.externalLinks.connectGuide}
                                    text={strings.connectGuideLink}
                                />
                            </If>
                            <If condition={!onPremises && config.externalLinks.mbedForum}>
                                <NavigationMenuGroupItem
                                    extras={{ externalLink: true, target: "_blank" }}
                                    text={strings.forumLink}
                                    to={config.externalLinks.mbedForum}
                                />
                            </If>
                            <If condition={config.externalLinks.documentation}>
                                <NavigationMenuGroupItem
                                    extras={{ externalLink: true, target: "_blank" }}
                                    text={strings.docsLink}
                                    to={config.externalLinks.documentation}
                                />
                            </If>
                            <NavigationMenuGroupItem to="/contact" text={strings.contactLink} />
                        </NavigationMenuGroup>
                        <If
                            condition={
                                !onPremises &&
                                auth.federated &&
                                auth.issuer.includes("account.mbed.com") &&
                                config.externalLinks.connectGuide
                            }
                        >
                            <SearchableFeature
                                group={strings.help}
                                icon={Icons.Help}
                                text={strings.connectGuideLink}
                                description={config.externalLinks.connectGuide}
                                to={config.externalLinks.connectGuide}
                            />
                        </If>
                        <If condition={!onPremises && config.externalLinks.mbedForum}>
                            <SearchableFeature
                                group={strings.help}
                                icon={Icons.Help}
                                text={strings.forumLink}
                                description={config.externalLinks.mbedForum}
                                to={config.externalLinks.mbedForum}
                            />
                        </If>
                        <If condition={config.externalLinks.documentation}>
                            <SearchableFeature
                                group={strings.help}
                                icon={Icons.Help}
                                text={strings.docsLink}
                                description={config.externalLinks.documentation}
                                to={config.externalLinks.documentation}
                            />
                        </If>
                        <SearchableFeature
                            group={strings.help}
                            icon={Icons.Help}
                            text={strings.contactLink}
                            to="/contact"
                        />
                    </If>
                    <NavigationMenuGroup expand={true} to="/language" icon={Icons.Language} text={strings.language}>
                        <NavigationMenuGroupItem
                            extras={{ externalLink: true }}
                            to=""
                            text="English (US)"
                            tag="en_us"
                            onClick={this.handleChangeLanguage}
                        />
                        <NavigationMenuGroupItem
                            extras={{ externalLink: true }}
                            to=""
                            text="日本語"
                            tag="ja"
                            onClick={this.handleChangeLanguage}
                        />
                        <NavigationMenuGroupItem
                            extras={{ externalLink: true }}
                            to=""
                            text="中文(简体)"
                            tag="zh"
                            onClick={this.handleChangeLanguage}
                        />
                        <NavigationMenuGroupItem
                            extras={{ externalLink: true }}
                            to=""
                            text="Þšéûðô"
                            tag="pseudo"
                            when={!!config.i18nPseudo}
                            onClick={this.handleChangeLanguage}
                        />
                    </NavigationMenuGroup>
                    <div style={MenuComponentSlots.Bottom} />
                    <SearchableFeature
                        disabled={!privacyPolicyUrl}
                        group={strings.identity}
                        icon={Icons.User}
                        text={strings.privacy}
                        description={`${strings.account} → ${strings.privacy}`}
                        to={privacyPolicyUrl}
                    />
                    <NavigationMenuGroupItemLink
                        icon={Icons.UserPrivacy}
                        text={strings.privacy}
                        to={privacyPolicyUrl}
                    />
                </If>
                <If condition={!useNewMenu}>
                    <NavigationGroup to="/dashboard" icon={Icons.DashboardView} text={strings.home}>
                        <NavigationItem
                            to="/dashboard/usage"
                            text={strings.usageboard}
                            favorite={defaultDashboard === "usage"}
                        />
                        <NavigationItem
                            to="/dashboard/gateways"
                            text={strings.gatewaysboard}
                            favorite={defaultDashboard === "gateways"}
                            when={this.isAuthorized({ feature: "edgeThick" })}
                        />
                        <NavigationItem
                            to="/dashboard/custom"
                            text={strings.customboard}
                            favorite={defaultDashboard === "custom"}
                            when={this.isAuthorized({ feature: "customDashboard" })}
                        />
                    </NavigationGroup>
                    <NavigationGroup to="/devices" icon={Icons.DeviceDirectoryView} text={strings.deviceManagement}>
                        <NavigationItem to="/devices/list" text={strings.deviceCatalog} />
                        <NavigationItem
                            to="/devices/insights"
                            text={strings.deviceInsights}
                            when={this.isAuthorized({ feature: "pelion_insight" })}
                        />
                        <NavigationItem
                            to="/devices/groups/list"
                            text={strings.deviceGroups}
                            when={this.isAuthorized({ feature: "device_groups" })}
                        />
                        <NavigationItem to="/devices/logs" text={strings.deviceLogs} />
                        <NavigationItem
                            to="/devices/objects/list"
                            text={strings.deviceObjects}
                            when={this.isAuthorized({ admin: true })}
                        />
                        <NavigationItem
                            to="/devices/enrollment"
                            text={strings.enrollingDevices}
                            when={this.isAuthorized({ feature: "bring_your_own_certificates" })}
                        />
                    </NavigationGroup>
                    <NavigationGroup to="/identity" icon={Icons.DeviceIdentityView} text={strings.deviceIdentity}>
                        <NavigationItem to="/identity/certificates/list" text={strings.certificate} />
                        <NavigationItem
                            to="/identity/factoryTools"
                            text={strings.factoryTools}
                            when={this.isAuthorized({ feature: "factory_configurator_utility" })}
                        />
                        <NavigationItem to="/identity/server" text={strings.serverCredentials} />
                        <NavigationItem
                            to="/identity/trust-anchor"
                            text={strings.trustAnchor}
                            when={this.isAuthorized({ feature: "sda" })}
                        />
                        <NavigationItem
                            to="/identity/pre-shared-keys"
                            text={strings.preSharedKeys}
                            when={this.isAuthorized({ feature: "miniclient" })}
                        />
                    </NavigationGroup>
                    <NavigationGroup
                        to="/fleet"
                        icon={Icons.FleetManagement}
                        text={strings.fleetManagement}
                        when={
                            this.isAuthorized({ feature: "fleetManagement" }) &&
                            this.isAuthorized({ feature: "manufacturing_statistics" })
                        }
                    >
                        <NavigationItem
                            to="/fleet/factory"
                            text={strings.factoryMetrics}
                            when={this.isAuthorized({ feature: "manufacturing_statistics" })}
                        />
                    </NavigationGroup>
                    <NavigationGroup
                        to="/firmware"
                        icon={Icons.FirmwareUpdateView}
                        text={strings.firmwareManagement}
                        when={this.isAuthorized({ feature: "update" })}
                    >
                        <NavigationItem to="/firmware/images/list" text={strings.images} />
                        <NavigationItem to="/firmware/manifests/list" text={strings.manifests} />
                        <NavigationItem
                            to="/firmware/deployments/list"
                            text={strings.updateCampaigns}
                            when={this.isAuthorized({ feature: "update" })}
                        />
                    </NavigationGroup>
                    <NavigationGroup to="/access" icon={Icons.AccessManagementView} text={strings.accessManagement}>
                        <NavigationItem
                            to="/access/users/list"
                            text={strings.users}
                            when={this.isAuthorized({ admin: true })}
                        />
                        <NavigationItem to="/access/keys" text={strings.apiKeys} />
                        <NavigationItem
                            to="/access/applications"
                            text={strings.applications}
                            when={this.isAuthorized({ admin: true })}
                        />
                        <NavigationItem
                            to="/access/accessKeys"
                            text={strings.accessKeys}
                            when={this.isAuthorized({ admin: true })}
                        />
                        <NavigationItem
                            to="/access/groups"
                            text={strings.groups}
                            when={this.isAuthorized({ admin: true })}
                        />
                        <NavigationItem
                            to="/access/policies/list"
                            text={strings.policies}
                            when={this.isAuthorized({ admin: true, feature: "sda" })}
                        />
                    </NavigationGroup>
                    <NavigationGroup
                        to="/organization"
                        icon={Icons.TeamConfigurationView}
                        text={strings.teamConfiguration}
                    >
                        <NavigationItem to="/organization/profile" text={strings.teamProfile} />
                        <NavigationItem
                            to="/organization/branding"
                            text={strings.branding}
                            when={this.isAuthorized({ admin: true, feature: "uiCustomization" })}
                        />
                        <NavigationItem
                            to="/organization/identity"
                            text={strings.teamIdentityAndSecurity}
                            when={this.isAuthorized({ admin: true })}
                        />
                        <NavigationItem
                            to="/organization/tenants/list"
                            text={strings.teamTenants}
                            when={this.isAuthorized({
                                admin: true,
                                feature: "manage_subtenants",
                                tenant: false,
                            })}
                        />
                    </NavigationGroup>
                    <NavigationGroup
                        to="/billing"
                        icon={Icons.BillingReportsView}
                        text={strings.billingReports}
                        when={this.isAuthorized({ tierCommercial: true })}
                    >
                        <NavigationItem to="/billing/me" text={strings.myBilling} />
                        <NavigationItem
                            when={this.isAuthorized({ tenant: false })}
                            to="/billing/history"
                            text={strings.servicePackageHistory}
                        />
                        <NavigationItem to="/billing/usage" text={strings.billingUsage} />
                    </NavigationGroup>
                    <NavigationGroup
                        to="/jobs"
                        icon={Icons.JobsView}
                        text={strings.jobManagement}
                        when={this.isAuthorized({ admin: true, feature: "jobManagement" })}
                    >
                        <NavigationItem to="/jobs/list" text={strings.jobs} />
                    </NavigationGroup>
                    <Separator />
                    <AccountMenu />
                    <If condition={config.externalLinks}>
                        <MenuItem
                            icon={Icons.Help}
                            text={strings.help}
                            iconSize={IconSize.Large}
                            style={MenuComponentSlots.Menu}
                        >
                            <MenuItem disabled text={uiVersionLine} className="ui-version-line" />
                            <If
                                condition={
                                    config.externalLinks.connectGuide &&
                                    !onPremises &&
                                    auth.federated &&
                                    auth.issuer.includes("account.mbed.com")
                                }
                            >
                                <LinkMenuItem
                                    icon={Icons.Documentation}
                                    iconSize={IconSize.Large}
                                    text={strings.connectGuideLink}
                                    to={config.externalLinks.connectGuide}
                                />
                            </If>
                            <If condition={!onPremises && config.externalLinks.mbedForum}>
                                <LinkMenuItem
                                    icon={Icons.Forum}
                                    iconSize={IconSize.Large}
                                    text={strings.forumLink}
                                    to={config.externalLinks.mbedForum}
                                />
                            </If>
                            <If condition={config.externalLinks.documentation}>
                                <LinkMenuItem
                                    icon={Icons.Documentation}
                                    iconSize={IconSize.Large}
                                    text={strings.docsLink}
                                    to={config.externalLinks.documentation}
                                />
                            </If>
                            <LinkMenuItem
                                icon={Icons.ContactUs}
                                iconSize={IconSize.Large}
                                text={strings.contactLink}
                                to="/contact"
                            />
                        </MenuItem>
                        <If
                            condition={
                                !onPremises &&
                                auth.federated &&
                                auth.issuer.includes("account.mbed.com") &&
                                config.externalLinks.connectGuide
                            }
                        >
                            <SearchableFeature
                                group={strings.help}
                                icon={Icons.Help}
                                text={strings.connectGuideLink}
                                description={config.externalLinks.connectGuide}
                                to={config.externalLinks.connectGuide}
                            />
                        </If>
                        <If condition={!onPremises && config.externalLinks.mbedForum}>
                            <SearchableFeature
                                group={strings.help}
                                icon={Icons.Help}
                                text={strings.forumLink}
                                description={config.externalLinks.mbedForum}
                                to={config.externalLinks.mbedForum}
                            />
                        </If>
                        <If condition={config.externalLinks.documentation}>
                            <SearchableFeature
                                group={strings.help}
                                icon={Icons.Help}
                                text={strings.docsLink}
                                description={config.externalLinks.documentation}
                                to={config.externalLinks.documentation}
                            />
                        </If>
                        <SearchableFeature
                            group={strings.help}
                            icon={Icons.Help}
                            text={strings.contactLink}
                            to="/contact"
                        />
                    </If>
                    <MenuItem
                        icon={Icons.Language}
                        iconSize={IconSize.Large}
                        text={strings.language}
                        style={MenuComponentSlots.Menu}
                    >
                        <MenuItem text="English (US)" tag="en_us" onClick={this.handleChangeLanguage} />
                        <MenuItem text="日本語" tag="ja" onClick={this.handleChangeLanguage} />
                        <MenuItem text="中文(简体)" tag="zh" onClick={this.handleChangeLanguage} />
                        <MenuItem
                            text="Þšéûðô"
                            tag="pseudo"
                            when={!!config.i18nPseudo}
                            onClick={this.handleChangeLanguage}
                        />
                    </MenuItem>
                    <div style={MenuComponentSlots.Bottom} />
                    <SearchableFeature
                        disabled={!privacyPolicyUrl}
                        group={strings.identity}
                        icon={Icons.User}
                        text={strings.privacy}
                        description={`${strings.account} → ${strings.privacy}`}
                        to={privacyPolicyUrl}
                    />
                    <LinkMenuItem
                        when={privacyPolicyUrl}
                        to={privacyPolicyUrl}
                        icon={Icons.UserPrivacy}
                        iconSize={IconSize.Large}
                        text={strings.privacy}
                        style={MenuComponentSlots.Menu}
                    />
                </If>
            </SiteNavigationBar>
        );
    }

    private isAuthorized(rule: AuthorizationRule): boolean {
        return isAuthorized(this.props.config, this.access, rule);
    }

    private handleChangeLanguage(e: React.MouseEvent<HTMLElement>) {
        const language = getCustomTag(e.currentTarget);
        if (language) {
            this.props.dispatch(createLanguageAction(language));
        }
    }
}

export function mapStateToProps(state: AppState) {
    return {
        config: state.config,
        auth: state.auth,
        currentUser: state.currentuser,
        currentAccount: state.currentaccount,
    };
}

export default withRouter(connect(mapStateToProps)(translate("MenuComponent")(MenuComponent)));
