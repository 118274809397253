import { Theme } from "../features/organization";

/**
 * Application feature toggles
 */
export interface FeatureToggle {
    captcha: boolean;
    chunkedFirmwareUpload: boolean;
    consoleAPI: boolean;
    consoleFeatureToggles: boolean;
    consoleNotifications: boolean;
    customDashboard: boolean;
    detailed_campaign_metrics: boolean;
    edgeThick: boolean;
    externalCA: boolean;
    externalCACFSSL: boolean;
    externalCACFSSLAuth: boolean;
    factory_configurator_utility: boolean;
    googleAnalytics: boolean;
    idp_management: boolean;
    jobManagement: boolean;
    manifestBytes: boolean;
    mbedLogin: boolean;
    mfa: boolean;
    miniclient: boolean;
    notifications_websockets: boolean;
    notifications_websockets_temp_override: boolean;
    pelion_insight: boolean;
    sda: boolean;
    selfEnrolment: boolean;
    showTermsOfService: boolean;
    uiCustomization: boolean;
    update: boolean;
    uploadCertificate: boolean;
    newLogin: boolean;
    fleetManagement: boolean;
    manufacturing_statistics: boolean;
    useNewMenu: boolean;
}

/** Represents the level of an event in the system state. */
export enum SystemStateLevel {
    /** Unknown level, possibly operational or fetch in progress. */
    Unknown = 0,

    /** The (sub)system is operational. */
    Operational = 100,

    /** The (sub)system is operational but with degraded performance. */
    DegradedPerformance = 300,

    /** The (sub)system is partially operational, check `affectedComponents`. */
    PartialServiceDisruption = 400,

    /** The (sub)system is not available, check `affectedComponents`. */
    ServiceDisruption = 500,

    /** The (sub)system is operational but an unresolved security event occurred. */
    SecurityEvent = 600,
}

/** Represents the state of an incident `SystemStateIncident` described in `SystemState.incidents`. */
export enum SystemStateIncidentState {
    /** The investigation is currently in progress. */
    Investigating = 100,

    /** The cause has been identified, resolution (if required) in progress. */
    Identified = 200,

    /**
     * The incident has been resolved (or simply the cause has been identified and no resolution was required)
     * and it is currently monitored.
     */
    Monitoring = 300,
}

/** Base interface implemented by all the system state related events (and messages). */
export interface SystemStateEvent {
    /**
     * Optional id of this event. ID is required to let the user dismiss the notification (if
     * applicable and if `dismissable` is not `false`).
     */
    id?: string;

    /** ISO timestamp of the event or its creation time. */
    timestamp?: string;

    /**
     * Indicates whether this event can be dismissed by the user. Set this property
     * to `false` to mark it as undismissable. Note that an event, to be permanently dismissed,
     * must have a valid an unique `id` property. If the property is missing then - when available -
     * the `SystemStateEventWithDetails.details` property is hashed and used as ID for the current
     * user session only.
     */
    dismissable?: boolean;
}

/** Base interface implemented by all the events with an optional textual description. */
export interface SystemStateEventWithDetails extends SystemStateEvent {
    /** An optional description of this event. */
    details?: string;

    /** Additional message describing this event. */
    messages?: string[];

    /** The optional list of components affected by this event. */
    affectedComponents?: string[];
}

/** Represents a system message. */
export interface SystemMessage extends SystemStateEventWithDetails {
    /** The text of the message. */
    details: string;
}

/** Represents a maintenance event. */
export interface SystemStateMaintenance extends SystemStateEventWithDetails {
    /** The (planned) beginning of the maintenance. */
    from: string;

    /** The (planned) end of the maintenance. */
    to?: string;
}

/** Represents an incident. */
export interface SystemStateIncident extends SystemStateEvent {
    /** The current investigation/resolution state of this incident. */
    state: SystemStateIncidentState;
}

/** Represents the status of a system component. */
export interface SystemStateComponentStatus {
    /** The name of the component. */
    name: string;

    /** The current status of the component. */
    status: SystemStateLevel;

    /** An optional textual description of the status */
    details?: string;
}

/** Represents the overall system status. */
export interface SystemStateStatus {
    /** The overall status of the system. */
    overall: SystemStateLevel;

    /** An optional textual description of the system status. */
    details?: string;

    /**
     * An optional list of all the components in the system and
     * their specific status.
     */
    components?: SystemStateComponentStatus[];
}

/** Represents the system state. */
export interface SystemState {
    /** The optional list of administrator defined informational messages. */
    messages?: SystemMessage[];

    /** The optional list of historical, scheduled and active maintenances. */
    maintenances?: SystemStateMaintenance[];

    /** The optional list of historical and active incidents. */
    incidents?: SystemStateIncident[];

    /**
     * The overall system status. Note that system status might be affected
     * by active maintenances and incidents, if one of them is specified then
     * this property _should_ have a value.
     */
    status?: SystemStateStatus;
}

/**
 * Type of the application config (intersection of appConfig and siteConfig.
 */
export interface Config {
    trackingID?: string;
    idpLogoutRedirectUri: string;
    docs_base: string;
    docs_version: string;
    onPremises: boolean;
    history_strategy: string;
    mbedIdpIssuer: string;
    i18nPseudo?: boolean;
    apiHost: string;
    polling_interval: number;
    systemState?: SystemState;
    api: {
        api_base: string;
        auth_base: string;
        download_base: string;
        thick_edge_ws_base: string;
        v2_base: string;
        v3_alpha: string;
        v3_base: string;
        v3_preview1: string;
        version: string;
    };
    externalLinks: {
        contact: string;
        cookiePolicy: string;
        documentation: string;
        genericSupport: string;
        LWM2M_base: string;
        mbed_client_base: string;
        connectGuide: string;
        mbedForum: string;
        privacyPolicy: string;
        registration: string;
        supportLink: string;
        subscriptionCenter: string;
        supportEmailAddress: string;
        termsOfService: string;
        upgradeLink: string;
        adminConsole: string;
    };
    featureToggle: FeatureToggle;
    featureToggleOverwrites?: FeatureToggle;
    brandLoginBackground?: string;
    brandLogo?: string;
    brandLogos?: {
        application: string;
        login: string;
    };
    brandingBase?: Theme;
    brandingColors?: { [key: string]: string };
    brandingColorsDark?: { [key: string]: string };
    brandingColorsLight?: { [key: string]: string };
    brandingImagesDark?: { [key: string]: string };
    brandingImagesLight?: { [key: string]: string };
    defaultTheme?: Theme;
    allowUserTheme?: boolean;
    userTheme?: Theme;
    showPoweredBy?: boolean;
    excludeCountryCodes?: string[];
    endpoint: string;
    firmwareUploadChunkBytes?: number;
    firmwareUploadChunkedThresholdBytes?: number;
    theme?: {
        images: {
            loginBackground: () => string;
            loginLogo: () => string;
        };
    };
    totpRecommendedApps: { [key: string]: RecommendedApp };
    catalog_custom_attribute_limit: number;
    mbedReferred: boolean;
    contactUsMarkup: { [key: string]: string };
    phoneNumbers: string[];
}

export interface RecommendedApp {
    enabled: boolean;
    name: string;
    link: string;
}

export interface Sort {
    id: string;
    dir?: number;
}

export enum UserStatus {
    Initalizing = 0,
    Reset = 1,
    Active = 2,
}
