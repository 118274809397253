import moment from "moment";
import { AnyAction } from "redux";
import { getMomentLocale, getUserUiLanguage } from "../script/utility";
import { CHANGE_LANG_ACTION, LanguageAction, RECEIVE_LANG_ACTION } from "./creator";
import { Translations } from "./translate";

export interface TranslateState {
    lang: string;
    strings: Translations;
}

export const i18nReducer = (state: TranslateState = { lang: getUserUiLanguage(), strings: {} }, action: AnyAction) => {
    const { lang, strings = {} } = action as LanguageAction;
    switch (action.type) {
        case CHANGE_LANG_ACTION:
            window.localStorage.setItem("lang", lang);
            moment.locale(getMomentLocale(lang));
            return { ...state, lang };
        case RECEIVE_LANG_ACTION:
            window.localStorage.setItem("lang", lang);
            moment.locale(getMomentLocale(lang));
            return { ...state, strings: { ...state.strings, [lang]: strings }, lang };
        default:
            return state;
    }
};
