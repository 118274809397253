import { Config } from "../../../typings/interfaces";
import { addAuth } from "../../../creators/AjaxApiCreator";
import { configAjax, ConfigAjaxOptions } from "../../../script/ajax";
import { CurrentAccount } from "../../account";

const apiAjax = (baseKey: keyof Config["api"]) => (options: ConfigAjaxOptions) =>
    configAjax(baseKey)({
        ...options,
        headers: addAuth(options.headers),
    });

const iamAjax = apiAjax("v3_base");
const authAjax = configAjax("auth_base");
const authenticatedAuthAjax = apiAjax("auth_base");

interface BaseInput {
    getState: () => { config: Config };
}

export interface LogoutResponse {
    redirect_uri?: string;
}

/**
 * Make a logout request to IAM for the token stored in the token cookie.
 */
export const teamLogout = ({ getState }: BaseInput): Promise<LogoutResponse> =>
    authenticatedAuthAjax({
        getState,
        param: "logout",
        method: "POST",
    }).then(({ body }) => body || {});

/**
 * Load the account details for the token stored in the token cookie.
 */
export const loadCurrentAccount = ({ getState }: BaseInput): Promise<CurrentAccount> =>
    iamAjax({
        getState,
        param: "accounts/me",
        queryParameters: { include: "limits,policies,sub_accounts" },
    }).then(({ body }) => body);

export interface CurrentTeam {
    id: string;
    alias: string;
}

/**
 * Load the teams available via impersonate for the token stored in the token cookie.
 */
export const loadCurrentTeams = ({ getState }: BaseInput): Promise<CurrentTeam[]> =>
    iamAjax({
        getState,
        param: "users/me/team-accounts",
    }).then(({ body }) => body.data || []);

export type IdpStatus = "ACTIVE" | "SUSPENDED";

export interface OidcProperties {
    authorization_endpoint?: string;
    auto_enrollment?: boolean;
    client_id?: string;
    end_session_endpoint?: string;
    enrollment_resp_endpoint?: string;
    revocation_endpoint?: string;
    jwks_uri?: string;
    redirect_uri?: string;
    logout_redirect_uri?: string;
    token_endpoint?: string;
    userinfo_endpoint?: string;
}

export interface Saml2Properties {
    sso_endpoint: string;
    slo_endpoint: string;
    idp_x509cert: string[];
    assertion_endpoint: string;
    client_x509cert: string | null;
    client_id: string;
}

type IdpSpecificAttributes =
    | { type: "MBED" | "OIDC"; oidc: OidcProperties }
    | { type: "SAML2"; saml2: Saml2Properties }
    | { type: "NATIVE" };

export type Idp = IdpSpecificAttributes & {
    id: string;
    name: string;
    description: string;
    issuer: string;
    status: IdpStatus;
    created_at: string;
    updated_at: string;
    account_id: string;
    is_default: boolean;
};

type IdpsForUserOptions = BaseInput & { email?: string; team: string };

/**
 * Load the IdPs for the given team+user. Does not require auth.
 */
export const getIdpsForUser = ({ getState, team, email }: IdpsForUserOptions): Promise<Idp[]> =>
    authAjax({
        getState,
        param: "identity-providers",
        queryParameters: email ? { alias__eq: team, email__eq: email } : { alias__eq: team },
    }).then(({ body }) => body.data);

export interface LoginCredentials {
    idp_id?: string;
    account?: string;
    saml_response?: string;
    relay_state?: string;
    code?: string;
    issuer?: string;
    username?: string;
    password?: string;
    captcha?: string;
    captcha_id?: string;
    otp?: string;
}

export interface LoginResponse {
    redirect_uri?: string;
    token?: string;
    role?: string;
}

/**
 * Make a login request to IAM.
 */
export const postLogin = ({
    getState,
    credentials,
}: BaseInput & { credentials: LoginCredentials }): Promise<LoginResponse> =>
    authAjax({
        getState,
        method: "POST",
        param: "login",
        data: credentials,
    }).then(({ body }) => body);

interface ImpersonateResponse {
    roles: string[];
    token: string;
}

/**
 * Make a switch teams request to IAM.
 */
export const impersonate = ({
    getState,
    accountId,
}: BaseInput & { accountId: string }): Promise<ImpersonateResponse> => {
    return apiAjax("auth_base")({
        getState,
        param: "impersonate",
        method: "POST",
        data: { account_id: accountId },
    }).then(({ body }) => body);
};
