import React from "react";
import { connect } from "react-redux";
import { AjaxState } from "../../creators/AjaxCreator";
import { AppState } from "../../creators/reducers";
import ClientBatchOperation from "../asyncJob/clientBatchOperation";
import { Job } from "../asyncJobList/creator";
import { resumeDevice } from "./creator";
import { DeviceBlock } from "./types";

interface Device {
    id: string;
    last_operator_suspended_category?: string;
}

export interface ResumeDevicesJobProps {
    devices: Device[];
    blockData: DeviceBlock;
    onCompleted: () => void;
    job: Job;
    deviceResume: {
        [key: string]: AjaxState<void>;
    };
}

export class ResumeDevicesJob extends React.PureComponent<ResumeDevicesJobProps> {
    constructor(props: Readonly<ResumeDevicesJobProps>) {
        super(props);

        this.handleResumeDevice = this.handleResumeDevice.bind(this);
        this.handleCompleted = this.handleCompleted.bind(this);
    }

    handleResumeDevice(device: Device) {
        // Device is resumed when the blocking reason is resolved then we repeat
        // the same category.
        return resumeDevice({
            id: this.props.job.id,
            deviceId: device.id,
            data: {
                ...this.props.blockData,
                category: device.last_operator_suspended_category ?? "",
            },
        });
    }

    handleCompleted() {
        this.props.onCompleted && this.props.onCompleted();
    }

    render() {
        return (
            <ClientBatchOperation
                job={this.props.job}
                list={this.props.devices}
                response={(this.props.deviceResume as any)[this.props.job.id]} // Keep any for now, legacy component
                onProcess={this.handleResumeDevice}
                onCompleted={this.handleCompleted}
            />
        );
    }
}

export function mapStateToProps(state: AppState) {
    return {
        deviceResume: state.resumeDevice,
    };
}

export default connect(mapStateToProps)(ResumeDevicesJob);
