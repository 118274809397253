import { Page } from "portal-components";
import React from "react";
import translate, { DefaultStrings } from "../../i18n/translate";
import { isObject } from "../../script/utility";
import Footer from "./footer";
import FreeAccount from "./freeAccount";
import OnPremisesCustomMarkUp from "./onPremisesCustomMarkUp";
import Subtenant from "./subtenant";
import Tenant from "./tenant";
import { ParentAccount } from "../account/creator";

const defaultStrings = {
    contactUs: "Contact us",
    support: "Support",
};

interface SupportComponentProps {
    strings: DefaultStrings<typeof defaultStrings>;
    documentation: string;
    mbedForum: string;
    supportLink: string;
    supportEmailAddress: string;
    isFreeAccount: boolean;
    upgradeLink: string;
    phoneNumbers: string[];
    onPremises: boolean;
    contactUsMarkup: { [key: string]: string };
    currentLanguage?: string;
    parentAccount?: ParentAccount;
}

export class SupportComponent extends React.PureComponent<SupportComponentProps> {
    public static defaultProps = {
        strings: defaultStrings,
        supportLink: "https://arm.service-now.com/mbed-cloud/",
        emailAddress: "support@izumanetworks.com",
    };

    renderBody() {
        const {
            documentation,
            mbedForum,
            supportLink,
            supportEmailAddress,
            isFreeAccount,
            upgradeLink,
            phoneNumbers,
            onPremises,
            contactUsMarkup,
            currentLanguage,
            parentAccount,
        } = this.props;

        // On premises installations might have a custom content for this page
        const hasMarkupString =
            isObject(contactUsMarkup) && Object.entries(contactUsMarkup).filter((entry) => !!entry[1]).length > 0;

        if (onPremises && hasMarkupString) {
            return <OnPremisesCustomMarkUp translatedContent={contactUsMarkup} language={currentLanguage || "en_us"} />;
        }

        // Free accounts have a simpler ad-hoc page
        if (isFreeAccount) {
            return (
                <React.Fragment>
                    <FreeAccount link={upgradeLink} supportEmailAddress={supportEmailAddress} />
                    <Footer documentation={documentation} mbedForum={mbedForum} />
                </React.Fragment>
            );
        }

        // For subtenants we should redirect users to contact their reseller
        if (parentAccount && Object.keys(parentAccount).length) {
            return (
                <React.Fragment>
                    <Subtenant parentAccount={parentAccount} />
                    <Footer documentation={documentation} mbedForum={mbedForum} />
                </React.Fragment>
            );
        }

        // If we are here then this is a normal tenant
        return (
            <React.Fragment>
                <Tenant
                    phoneNumbers={phoneNumbers}
                    supportLink={supportLink}
                    supportEmailAddress={supportEmailAddress}
                />
                <Footer documentation={documentation} mbedForum={mbedForum} />
            </React.Fragment>
        );
    }

    render() {
        const { strings, parentAccount } = this.props;
        const isTenantAccount = parentAccount && Object.keys(parentAccount).length;

        return (
            <Page id="support" title={isTenantAccount ? strings.support : strings.contactUs}>
                {this.renderBody()}
            </Page>
        );
    }
}

export default translate(["SupportComponent"])(SupportComponent);
