import {
    Button,
    ButtonVariant,
    DialogButtons,
    Else,
    ErrorMessage,
    Form,
    If,
    TextBox,
    ValueEvent,
    NameValueTable,
    NameValueItem,
    ButtonSet,
} from "portal-components";
import React from "react";
import { redirectTo } from "../../../script/routing";
import translate, { DefaultStrings } from "../../../i18n/translate";
import BaseLoginComponent from "../loginComponent";
import { InviteAccept, InviteStatus } from "./creator";

const defaultStrings = {
    acceptButton: "Create account",
    emailLabel: "Email",
    teamLabel: "Team",
    fullNameLabel: "Full name",
    loginPage: "Go back to login page",
    passwordLabel: "Password",
    loginHeader: "Welcome! Tell us a bit about yourself.",
    passwordShow: "Show password",
    passwordHide: "Hide password",
};

interface InviteAcceptComponentProps {
    invite?: InviteStatus;
    badInvitationID?: string;
    message: string;
    validationMessage?: string;
    onSubmit: (accept: InviteAccept) => void;
    strings: DefaultStrings<typeof defaultStrings>;
    working: boolean;
}

interface State {
    fullName: string;
    password: string;
    hidePassword: boolean;
}

export class InviteAcceptComponent extends React.PureComponent<InviteAcceptComponentProps, State> {
    public static defaultProps = {
        strings: defaultStrings,
    };

    constructor(props: InviteAcceptComponentProps) {
        super(props);

        this.state = {
            password: "",
            hidePassword: true,
            fullName: "",
        };

        this.createForm = this.createForm.bind(this);
        this.createInvalid = this.createInvalid.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleShowHidePasswordClick = this.handleShowHidePasswordClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleFieldChange(e: ValueEvent<string>) {
        switch (e.name) {
            case "fullName": {
                this.setState({
                    fullName: e.value,
                });
                break;
            }
            case "password": {
                this.setState({
                    password: e.value,
                });
                break;
            }
        }
    }

    handleShowHidePasswordClick() {
        this.setState({ hidePassword: !this.state.hidePassword });
    }

    handleSubmit() {
        const { password, fullName } = this.state;

        this.props.onSubmit({ password, full_name: fullName });
    }

    createForm() {
        const { invite: account, strings, validationMessage } = this.props;
        const { password, fullName, hidePassword } = this.state;
        const { handleSubmit, handleFieldChange, handleShowHidePasswordClick } = this;

        return (
            <Form id="login-form" onSubmit={handleSubmit}>
                <p className="lead">{strings.loginHeader}</p>
                <NameValueTable>
                    <NameValueItem id="invite-team" text={strings.teamLabel}>
                        {account?.team || ""}
                    </NameValueItem>
                    <NameValueItem id="invite-team" text={strings.emailLabel}>
                        {account?.email || ""}
                    </NameValueItem>
                </NameValueTable>
                <TextBox
                    id="invite-full-name"
                    label={strings.fullNameLabel}
                    name="fullName"
                    value={fullName}
                    onValueChange={handleFieldChange}
                    required
                />
                <TextBox
                    id="invite-password"
                    label={strings.passwordLabel}
                    name="password"
                    value={password}
                    onValueChange={handleFieldChange}
                    type={hidePassword ? "password" : "text"}
                    required
                />
                <p className="help-text">
                    <span className="float-right">
                        <a className="passwordTips" onClick={handleShowHidePasswordClick}>
                            {hidePassword ? strings.passwordShow : strings.passwordHide}
                        </a>
                    </span>
                </p>
                <ErrorMessage value={validationMessage} />
                <DialogButtons
                    primary
                    confirmTitle={strings.acceptButton}
                    buttonSet={ButtonSet.SaveCancel}
                    hideCancel
                />
            </Form>
        );
    }

    createInvalid(message: string) {
        const { strings } = this.props;

        return (
            <p className="lead">
                {message}
                <br />
                <Button
                    variant={ButtonVariant.Inline}
                    text={strings.loginPage}
                    onClick={() => redirectTo({ path: "/login", replace: true })}
                />
            </p>
        );
    }

    render() {
        const { message, working } = this.props;
        const { createForm, createInvalid } = this;

        return (
            <BaseLoginComponent id="invite-accept-component" loading={working}>
                <If condition={message}>
                    {createInvalid(message)}
                    <Else>{createForm()}</Else>
                </If>
            </BaseLoginComponent>
        );
    }
}

export default translate("InviteAcceptComponent")(InviteAcceptComponent);
