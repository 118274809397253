import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from "react-router";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { CalloutState } from "../../creators/CalloutCreator";
import ContactUs from "../../features/contactus/container";
import Access from "../../routes/access";
import Account from "../../routes/account";
import Billing from "../../routes/billing";
import Dashboard from "../../routes/dashboard";
import Firmware from "../../routes/firmware";
import Identity from "../../routes/identity";
import Fleet from "../../routes/fleet";
import Jobs from "../../routes/jobs";
import Team from "../../routes/organization";
import { AppState } from "../../creators/reducers";
import { shallowEqual } from "../../script/utility";
import NotFound from "../404";
import { ackCallout, clearCallout } from "../../creators/callouts";
import Requires from "../requires";
import Directory from "../../routes/devices";
import { MainContext, MainContextProps } from "./mainContext";
import Notifications from "./notifications";

interface MainComponentProps {
    dispatch: ThunkDispatch<AppState, never, Action>;
    callout: CalloutState;
}

interface MainComponentState {
    isCalloutHidden: boolean;
}

export interface PropagatedProps {
    enableCallout: () => void;
    disableCallout: () => void;
    resetCallout: (callout: CalloutState) => void;
    callout: CalloutState;
    title?: string;
}

type MainComponentWithRoutingProps = MainComponentProps & RouteComponentProps<Record<string, string>>;

export class MainComponent extends React.PureComponent<MainComponentWithRoutingProps, MainComponentState> {
    private contextValue: MainContextProps;
    private propagatedProps: PropagatedProps;
    public readonly state: MainComponentState = {
        isCalloutHidden: false,
    };

    public constructor(props: Readonly<MainComponentWithRoutingProps>) {
        super(props);

        this.enableCallout = this.enableCallout.bind(this);
        this.disableCallout = this.disableCallout.bind(this);
        this.resetCallout = this.resetCallout.bind(this);

        this.contextValue = {
            enableCallout: this.enableCallout,
            disableCallout: this.disableCallout,
            resetCallout: this.resetCallout,
        };

        this.propagatedProps = {
            enableCallout: this.enableCallout,
            disableCallout: this.disableCallout,
            resetCallout: this.resetCallout,
            callout: props.callout,
        };
    }

    public componentDidUpdate(prevProps: Readonly<MainComponentWithRoutingProps>) {
        if (!shallowEqual(prevProps.location, this.props.location)) {
            this.resetCallout(this.props.callout);
        }
    }

    public render() {
        return (
            <React.Fragment>
                <Notifications hidden={this.state.isCalloutHidden} callout={this.props.callout} />

                <MainContext.Provider value={this.contextValue}>
                    <Switch>
                        <Redirect exact from="/" to="/dashboard" />
                        <Route
                            path="/access"
                            render={(props) => (
                                <Access {...props} componentProps={this.propagatedProps} title="access" />
                            )}
                        />
                        <Route
                            path="/account"
                            render={(props) => <Account {...props} componentProps={this.propagatedProps} />}
                        />
                        <Route
                            path="/organization"
                            render={(props) => <Team {...props} componentProps={this.propagatedProps} />}
                        />
                        <Route path="/contact" component={ContactUs} />
                        <Route
                            path="/dashboard"
                            render={(props) => <Dashboard {...props} componentProps={this.propagatedProps} />}
                        />
                        <Route
                            path="/devices"
                            render={(props) => <Directory {...props} componentProps={this.propagatedProps} />}
                        />
                        <Route
                            path="/firmware"
                            render={(props) => <Firmware {...props} componentProps={this.propagatedProps} />}
                        />
                        <Route
                            path="/identity"
                            render={(props) => <Identity {...props} componentProps={this.propagatedProps} />}
                        />
                        <Route
                            path="/fleet"
                            render={(props) => <Fleet {...props} componentProps={this.propagatedProps} />}
                        />
                        <Route
                            path="/billing"
                            render={(props) => (
                                <Requires rule={{ tierCommercial: true }} redirectIfUnsupported>
                                    <Billing {...props} componentProps={this.propagatedProps} />
                                </Requires>
                            )}
                        />

                        <Route
                            path="/jobs"
                            render={(props) => (
                                <Requires rule={{ feature: "jobManagement" }} redirectIfUnsupported>
                                    <Jobs {...props} componentProps={this.propagatedProps} />
                                </Requires>
                            )}
                        />

                        <Route component={NotFound} />
                    </Switch>
                </MainContext.Provider>
            </React.Fragment>
        );
    }

    private resetCallout(callout: CalloutState) {
        if (callout.acked) {
            this.props.dispatch(clearCallout());
        } else {
            this.props.dispatch(ackCallout());
        }
    }

    private disableCallout() {
        this.setState({ isCalloutHidden: true });
    }

    private enableCallout() {
        this.setState({ isCalloutHidden: false });
    }
}

export function mapStateToProps(state: AppState) {
    return {
        callout: state.callout,
    };
}

export default withRouter(connect(mapStateToProps)(MainComponent));
