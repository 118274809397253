import { makeConfigApiCreator } from "../../../creators";
import { AccountDetails } from "../../organization";
import { CurrentUser } from "../creator";

// Reset password
const {
    reducer: resetPasswordReducer,
    createThunk: resetPasswordThunk,
    createResetAction: resetPasswordReset,
} = makeConfigApiCreator<{ password_policy: { minimum_length: number } }>("v3_base")("IAM_RESET_PASSWORD");

const resetPassword = (data: {}) =>
    resetPasswordThunk({
        param: "users/me/password",
        data,
        method: "PUT",
        errorCallout: false,
    });

// Update user info
const {
    reducer: updateUserReducer,
    createThunk: updateUserThunk,
    createResetAction: updateUserReset,
} = makeConfigApiCreator<CurrentUser>("v3_base")("IAM_UPDATE_CURRENT_USER");

const updateUser = (data: {}) => updateUserThunk({ param: "users/me", data: data, method: "PUT" });

// Create a tenant account
const {
    reducer: createTenantAccountReducer,
    createThunk: createTenantAccountThunk,
    createResetAction: createTenantAccountReset,
} = makeConfigApiCreator<AccountDetails>("v3_base")("IAM_CREATE_TENANT_ACCOUNT");

const createTenantAccount = (data: {}, action = "enroll") =>
    createTenantAccountThunk({
        param: `accounts?action=${action}`,
        data,
        method: "POST",
        errorCallout: false,
    });

// Update a tenant account
const { reducer: updateTenantAccountReducer, createThunk: updateTenantAccountThunk } = makeConfigApiCreator<
    AccountDetails
>("v3_base")("IAM_UPDATE_TENANT_ACCOUNT");

const updateTenantAccount = (id: string, data: {}) =>
    updateTenantAccountThunk({
        param: `accounts/${id}`,
        data,
        method: "PUT",
        errorCallout: false,
    });

// Update account info
const {
    reducer: updateCurrentAccountReducer,
    createThunk: updateCurrentAccountThunk,
    createResetAction: updateCurrentAccountReset,
} = makeConfigApiCreator<unknown>("v3_base")("IAM_UPDATE_CURRENT_ACCOUNT");

const updateCurrentAccount = (data: {}) => {
    const dataNormalized = { ...data, custom_fields: undefined };
    return updateCurrentAccountThunk({
        param: "accounts/me",
        data: dataNormalized,
        method: "PUT",
    });
};

// Setup two-factor otp for activation
const { reducer: totpActivationReducer, createThunk: totpActivationThunk } = makeConfigApiCreator<CurrentUser>(
    "v3_base"
)("IAM_TOTP_ACTIVATION");

const totpActivate = (data: {}) =>
    totpActivationThunk({
        param: "users/me/password",
        data: data,
        method: "POST",
        errorCallout: false,
    });

// Generate new scrate codes
const { reducer: totpGenerateScratchCodesReducer, createThunk: totpGenerateScratchCodesThunk } = makeConfigApiCreator<
    CurrentUser
>("v3_base")("IAM_TOTP_GENERATE_SCRATCH_CODES");

const totpGenerateScratchCodes = () =>
    totpGenerateScratchCodesThunk({
        param: "users/me?scratch_codes",
        errorCallout: false,
    });

export {
    updateUser,
    updateUserReset,
    updateUserReducer,
    resetPassword,
    resetPasswordReset,
    resetPasswordReducer,
    updateCurrentAccount,
    updateCurrentAccountReset,
    updateCurrentAccountReducer,
    totpActivate,
    totpActivationReducer,
    totpGenerateScratchCodes,
    totpGenerateScratchCodesReducer,
    createTenantAccount,
    createTenantAccountReset,
    createTenantAccountReducer,
    updateTenantAccount,
    updateTenantAccountReducer,
};
