import React from "react";
import { Prompt } from "react-router-dom";
import translate, { DefaultStrings } from "../i18n/translate";

export const defaultStrings = {
    message: "Proceeding will cause any unsaved changes to be lost.",
};

export interface LeavePageConfirmationProps {
    ignoreRouting?: boolean;
    ignorePageLeave?: boolean;
    message?: string;
    strings: DefaultStrings<typeof defaultStrings>;
    when: boolean;
}

export class LeavePageConfirmation extends React.PureComponent<LeavePageConfirmationProps> {
    public static readonly defaultProps = {
        strings: defaultStrings,
        when: false,
    };

    constructor(props: LeavePageConfirmationProps) {
        super(props);

        this.resolveMessage = this.resolveMessage.bind(this);
        this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
    }

    componentDidMount() {
        window.addEventListener("beforeunload", this.handleBeforeUnload);
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.handleBeforeUnload);
    }

    resolveMessage() {
        return this.props.message || this.props.strings.message;
    }

    handleBeforeUnload(e: BeforeUnloadEvent) {
        if (!this.props.when || this.props.ignorePageLeave) {
            return;
        }

        const message = this.resolveMessage();

        e.preventDefault(); // Older browsers and Safari
        e.returnValue = message; // Newer browsers and Opera

        return message; // Anything else not covered with preventDefault()
    }

    render() {
        // We do not want to render anything when doing this server side (for example
        // during tests or when rendering on the server - just in case).
        if (!(process && (process as any).browser) || this.props.ignoreRouting) {
            return null;
        }

        return <Prompt when={this.props.when} message={this.resolveMessage()} />;
    }
}

export default translate("LeavePageConfirmation")(LeavePageConfirmation);
