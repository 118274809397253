import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AppDispatch, AppState } from "../../../../creators/reducers";
import { isLoaded, isLoading } from "../../../../creators/AjaxCreator";
import { DefaultStrings } from "../../../../i18n/translate";
import BaseLoginComponent from "../../loginComponent";
import { AuthState } from "../../../../layout/reducer";
import { ListResponse } from "../../../../script/types";
import { AuthorizationEndpoint, getAuthorizationEndpoint, IdentityProvider, identityProviderAction } from "../creator";
import * as OIDC from "../oidc";

export const FLOW_STORAGE_KEY = "oidc-flow";
export const LOGIN_FLOW = "login";
export const REAUTHENTICATION = "reauthentication";

const defaultStrings = {
    anErrorOccurred: "An error occurred",
    stateParameterMismatch: "State parameter did not match",
    unauthorized: "You are not authorized to access this service.",
};

export interface LoginFederatedReAuthenticateContainerProps {
    identityProvider: ListResponse<IdentityProvider> | null;
    startFederatedLogin: AuthorizationEndpoint | null;
    startFederatedLoginLoading: boolean;
    auth: AuthState;
    strings: DefaultStrings<typeof defaultStrings>;
    actions: {
        identityProviderAction: typeof identityProviderAction;
        getAuthorizationEndpoint: typeof getAuthorizationEndpoint;
    };
}

export class LoginFederatedReAuthenticateContainer extends React.Component<LoginFederatedReAuthenticateContainerProps> {
    public static defaultProps = {
        strings: defaultStrings,
    };

    componentDidMount() {
        const { auth } = this.props;

        if (auth.federated) {
            // start the flow
            this.props.actions.identityProviderAction({ issuer: auth.issuer });
        }
    }

    componentDidUpdate() {
        const { auth, actions, identityProvider, startFederatedLogin, startFederatedLoginLoading } = this.props;

        if (identityProvider && !startFederatedLogin && !startFederatedLoginLoading) {
            const idp = identityProvider.data.find((i) => i.issuer === auth.issuer);
            // get the redirect uri from IAM
            idp && actions.getAuthorizationEndpoint({ idp_id: idp.id });
        }

        if (!startFederatedLoginLoading && startFederatedLogin && startFederatedLogin.redirect_uri) {
            // initiate the reauth
            sessionStorage.setItem(FLOW_STORAGE_KEY, REAUTHENTICATION);
            OIDC.goToIdP(startFederatedLogin.redirect_uri);
        }
    }

    render() {
        return <BaseLoginComponent loading id="federated-login-reauthenticate" />;
    }
}

export const mapStateToProps = ({ auth, identityProvider, startFederatedLogin }: AppState) => {
    return {
        auth,
        identityProvider: isLoaded(identityProvider) ? identityProvider.data : null,
        startFederatedLogin: isLoaded(startFederatedLogin) ? startFederatedLogin.data : null,
        startFederatedLoginLoading: isLoading(startFederatedLogin),
    };
};

export const mapDispatchToProps = (dispatch: AppDispatch) => ({
    actions: bindActionCreators({ identityProviderAction, getAuthorizationEndpoint }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginFederatedReAuthenticateContainer);
