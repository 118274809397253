import React from "react";

interface ControlGroupsProps {
    disabled?: boolean;
    className?: string;
}

const ControlGroup: React.FunctionComponent<ControlGroupsProps> = (props) => (
    <div className={`${props.className || ""} control-group ${props.disabled ? "disabled" : ""}`}>{props.children}</div>
);

export default ControlGroup;
