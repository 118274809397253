import { makeConfigApiCreator } from "../../../creators";
import { loadCookie } from "../../../script/utility";

const { createThunk: logoutThunk } = makeConfigApiCreator<
    unknown,
    { endIdpSession: boolean; overlayMessage: string | null }
>("auth_base", { useAuth: false, errorCallout: false, errorRedirect: false })("logout");

const logout = ({ overlayMessage = null, endIdpSession = false } = {}) => {
    localStorage.setItem("logout-event", "logout" + Math.random());
    const headers = { Authorization: "Bearer " + loadCookie("token") };
    return logoutThunk({
        param: "logout",
        headers,
        method: "POST",
        requestData: {
            endIdpSession,
            overlayMessage: overlayMessage || null,
        },
        errorCallout: false,
    });
};

const { createThunk: idpInitiatedLogoutThunk } = makeConfigApiCreator("auth_base", {
    useAuth: false,
    errorCallout: false,
    errorRedirect: false,
})("idp_logout");

const completeIdpInitiatedLogout = (params: string) =>
    idpInitiatedLogoutThunk({
        param: "saml2" + params,
        method: "GET",
    });

export { logout, completeIdpInitiatedLogout };
