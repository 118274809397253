import React, { ReactNode } from "react";
import ErrorHandler from "../../controls/errorHandler";
import { Column, GridWrap } from "../../controls/grid";
import LogLevelComponent from "../../controls/logLevelComponent";
import { CalloutState } from "../../creators/CalloutCreator";
import translate, { DefaultStrings } from "../../i18n/translate";
import { MaxHistoryEntries } from "./creator";

export const defaultStrings = {
    message: "Message",
    severity: "Severity",
    timestamp: "Time",
};

export interface ConsoleNotifcationsProps {
    data: CalloutState[];
    strings: DefaultStrings<typeof defaultStrings>;
}

interface ConsoleNotifcationsState {
    columns: Column<CalloutState>[];
}

export class ConsoleNotifcations extends React.Component<ConsoleNotifcationsProps, ConsoleNotifcationsState> {
    public static readonly defaultProps = {
        data: [],
        strings: defaultStrings,
    };

    constructor(props: ConsoleNotifcationsProps) {
        super(props);
        this.parseNotificationBody = this.parseNotificationBody.bind(this);
        this.state = {
            columns: [
                {
                    id: 0,
                    name: "timestamp",
                    title: props.strings.timestamp,
                    isSortable: true,
                    width: 200,
                    type: "date",
                    ellipsis: "end",
                },
                {
                    id: 1,
                    name: "severity",
                    title: props.strings.severity,
                    isSortable: false,
                    renderFunction: (level: unknown) => <LogLevelComponent level={level as string} />,
                    width: 150,
                },
                {
                    id: 1,
                    name: "message",
                    title: props.strings.message,
                    isSortable: false,
                    renderFunction: (row: unknown) => {
                        const { header, body } = row as CalloutState;
                        return (
                            <React.Fragment>
                                <strong>{header}</strong>
                                <div>{this.parseNotificationBody(body as Pick<CalloutState, "body">)}</div>
                            </React.Fragment>
                        );
                    },
                },
            ],
        };
    }

    parseNotificationBody(body: Pick<CalloutState, "body">) {
        if (Array.isArray(body) && body.find((elem) => elem.isError)) {
            return body
                .filter((elem) => elem.isError)
                .map((elem, index) => (
                    <ErrorHandler key={`errorCompo${index}`} responseBody={elem.response} message={elem.message} />
                ));
        }

        return Array.isArray(body) ? body.map((text, index) => <div key={`message-${index}`}>{text}</div>) : body;
    }

    public render(): ReactNode {
        const { data = [] } = this.props;
        const { columns } = this.state;
        return (
            <GridWrap<CalloutState>
                id="console-notifications"
                columns={columns}
                data={data}
                defaultPageSize={MaxHistoryEntries}
                pagingPageSizeSmall={[{ value: MaxHistoryEntries }]}
                noSelection
            />
        );
    }
}

export default translate("ConsoleNotifcations")(ConsoleNotifcations);
