import { Action, AnyAction, Reducer } from "redux";
import { ThunkAction } from "redux-thunk";
import { Config } from "../typings/interfaces";
import { ApiCreatorOptions } from "./AjaxApiCreator";
import { AjaxAction, AjaxCreator, AjaxState, RequestState } from "./AjaxCreator";

export interface CollectionReducerOptions<I> {
    typePrefix: string;
    itemReducer: Reducer<I, Action>;
}

/**
 * Creates a reducer that manages a map from string to I, where I is the type managed by
 * the itemReducer. Action types handled by the reducer must start with the same prefix, and
 * actions must include an ID.
 * @returns {Function}
 */
export const collectionReducer = <I>({
    typePrefix,
    itemReducer,
}: CollectionReducerOptions<I>): Reducer<{ [id: string]: I }, AnyAction> => (state = {}, action) => {
    const { id, type } = action;
    const hasId = id !== null && id !== undefined;

    if (hasId && type.startsWith(typePrefix)) {
        return { ...state, [id]: itemReducer(state[id], action) };
    } else {
        return state;
    }
};

export interface AjaxCollectionReducerOptions<I> {
    prefix: string;
    ajaxReducer: Reducer<AjaxState<I>, AnyAction>;
}

const initialState: AjaxState<unknown> = { requestState: RequestState.INITIALIZING, id: "" };

/**
 * Creates a reducer that manages a map from string to I, where I is the type managed by
 * the ajaxReducer. Action types handled by the reducer must start with the same prefix, and
 * actions must include an ID.
 * @returns {Function}
 */
export const ajaxCollectionReducer = <I>({
    prefix,
    ajaxReducer,
}: AjaxCollectionReducerOptions<I>): Reducer<{ [id: string]: AjaxState<I> }, AnyAction> => (
    state: { [id: string]: AjaxState<I> } = { [prefix]: initialState },
    action: AnyAction
) => {
    const { id, type } = action;
    const hasId = id !== null && id !== undefined;

    if (hasId && type.startsWith(prefix)) {
        return { ...state, [id]: ajaxReducer(state[id], action) };
    } else {
        return state;
    }
};

export interface AjaxCollectionCreator<A, D = undefined> {
    createResetAction: (id?: string) => AjaxAction<D>;
    createThunk: (options: ApiCreatorOptions<D>) => ThunkAction<Promise<void>, { config: Config }, undefined, Action>;
    reducer: Reducer<{ [id: string]: AjaxState<A> }, AnyAction>;
    prefix: string;
}

/**
 * Creator that manages a map from string to T in the redux state, where T is the
 * type of the state managed by the ajaxCreator`.
 */
export const makeCollectionCreator = <A, D = undefined>(ajaxCreator: AjaxCreator<A, D>) => {
    const { createResetAction, createThunk, reducer: itemReducer, prefix } = ajaxCreator;

    const reducer = ajaxCollectionReducer({
        prefix: prefix.toUpperCase(),
        ajaxReducer: itemReducer,
    });

    return { createResetAction, createThunk, reducer, prefix } as AjaxCollectionCreator<A, D>;
};
