import { ErrorAction, makeConfigApiCreator, ParseFunction } from "../../../creators";
import history from "../../../script/history";
import { ListResponse } from "../../../script/types";

const makeDeviceFiltersCreator = <T>(type: string, parseFunction?: ParseFunction<T>) =>
    makeConfigApiCreator<T>("v3_base", {
        errorCallout: true,
        errorRedirect: () => history.push("/devices/list"),
    })(type, parseFunction);

const { createThunk: createGetFiltersThunk, reducer: filtersReducer } = makeDeviceFiltersCreator<
    ListResponse<DeviceQuery>
>("device_filter", (untypedResponse: unknown) => {
    const response = untypedResponse as ListResponse<DeviceQuery>;
    response.data = response.data.map((device) => Object.assign(device, { selected: false }));
    if (response.data?.length > 1) {
        response.last = response.data[response.data.length - 1].id;
    }
    return response;
});

export interface DeviceQuery {
    created_at: Date;
    description: string;
    id: string;
    name: string;
    query: string;
    updated_at: Date;
    object: string;
}

interface DeviceQueryParameters {
    pageSize?: number;
    last?: string;
    order?: string;
    query?: string;
    errorAction?: ErrorAction;
}

const getFilters = ({
    pageSize = 1000,
    last = undefined,
    order = "DESC",
    query = undefined,
    errorAction = undefined,
}: DeviceQueryParameters = {}) => {
    const queryParameters = {
        order,
        limit: pageSize?.toString(),
        include: "total_count",
        after: last,
        filter: query,
    };

    return createGetFiltersThunk({
        param: "device-queries",
        queryParameters,
        errorAction,
    });
};

// create filter
const { createThunk: createSaveFilterThunk, reducer: saveFilterReducer } = makeDeviceFiltersCreator<void>(
    "save_filter"
);
const saveFilter = (data: DeviceQuery) =>
    createSaveFilterThunk({
        param: "device-queries",
        data,
        method: "POST",
        errorCallout: false,
    });

// update filter
const { createThunk: createUpdateFilterThunk, reducer: updateFilterReducer } = makeDeviceFiltersCreator<DeviceQuery>(
    "update_device_filter"
);
const updateFilter = (filterID: string, data: DeviceQuery, errorCallout = true) =>
    createUpdateFilterThunk({
        param: `device-queries/${filterID}`,
        data,
        method: "PUT",
        errorCallout,
    });

const { createThunk: createDeleteFilterThunk, reducer: deleteFilterReducer } = makeDeviceFiltersCreator<void>(
    "delete_device_filter"
);
const deleteFilter = (filterID: string) =>
    createDeleteFilterThunk({
        param: `device-queries/${filterID}`,
        method: "DELETE",
    });

export {
    DeviceQueryParameters,
    deleteFilter,
    deleteFilterReducer,
    getFilters,
    filtersReducer,
    saveFilter,
    saveFilterReducer,
    updateFilter,
    updateFilterReducer,
};
