import { makeConfigApiCreator } from "../../../creators";
import { ListResponse } from "../../../script";
import { PreSharedKey } from "./upload/creator";

const { reducer, createThunk: getPskListThunk } = makeConfigApiCreator<ListResponse<PreSharedKey>>("v2_base")(
    "psk_list",
    (response) => {
        const typedResponse = response as ListResponse<PreSharedKey>;
        if (typedResponse.data) {
            const data = typedResponse.data.map((item, index) => ({ ...item, index, selected: false }));
            typedResponse.last = typedResponse.continuation_marker;
            return { ...typedResponse, data };
        }
        return { ...typedResponse, data: [] };
    }
);

const getAction = ({
    pageSize = 100,
    last = undefined,
    order = "DESC",
    errorCallout = undefined,
}: { pageSize?: number; last?: string; order?: string; errorCallout?: boolean } = {}) =>
    getPskListThunk({
        param: "device-shared-keys",
        queryParameters: {
            order,
            limit: `${pageSize}`,
            include: "total_count",
            last,
        },
        errorCallout,
    });

const { reducer: deletePSKReducer, createThunk: deletePSKsThunk } = makeConfigApiCreator<void>("v2_base")("psk_delete");
const deletePSKs = (id: string) =>
    deletePSKsThunk({
        param: `device-shared-keys/${id}`,
        method: "DELETE",
    });

export { getAction, reducer, deletePSKs, deletePSKReducer };
