import CalloutCreator from "./CalloutCreator";
import { logout as logoutAction } from "../features/login/logout/creator";

const logout = logoutAction;

// Setup for exporting
const ackCallout = CalloutCreator.ackCallout;
const clearCallout = CalloutCreator.clearCallout;
const staleCallout = CalloutCreator.staleCallout;

export { ackCallout, clearCallout, logout, staleCallout };
