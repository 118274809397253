import { makeCollectionCreator, makeConfigApiCreator } from "../../../creators";
import { ParseFunction } from "../../../creators/AjaxCreator";
import history from "../../../script/history";
import { ListResponse } from "../../../script/types";
import { LwM2MObject } from "./types";

const makeLwm2mObjectCreator = <T>(type: string, parseFunction?: ParseFunction<T>) =>
    makeConfigApiCreator<T>("v3_base", {
        errorRedirect: () => history.push("/devices/list"),
        errorCallout: true,
    })(type, parseFunction);

const { createThunk: downloadLwm2mObjectThunk, reducer: Lwm2mObjectDownloadReducer } = makeCollectionCreator(
    makeLwm2mObjectCreator<LwM2MObject>("lwm2m_object_download")
);

const downloadLwm2mObject = (id: string, objectId: string) =>
    downloadLwm2mObjectThunk({
        id,
        param: `lwm2m-objects/download/${objectId}`,
        method: "GET",
        errorRedirect: false as any, // To work around the js creator "typing" till we sort collection creator
    });

const { createThunk: createLwm2mObjectThunk, reducer: Lwm2mObjectReducer } = makeCollectionCreator(
    makeLwm2mObjectCreator<LwM2MObject>("lwm2m_object")
);

const getLwm2mObject = (id: string, objectId: string) =>
    createLwm2mObjectThunk({
        id,
        param: `lwm2m-objects/${objectId}`,
        method: "GET",
        errorRedirect: false as any, // To work around the js creator "typing" till we sort collection creator
    });

const makeLwm2mObjectsFiltersCreator = <T>(type: string, parseFunction?: ParseFunction<T>) =>
    makeConfigApiCreator<T>("v3_base", {
        errorCallout: true,
        errorRedirect: () => history.push("/devices/list"),
    })(type, parseFunction);

const { createThunk: createLwm2mObjectsThunk, reducer: lwm2mObjectsReducer } = makeLwm2mObjectsFiltersCreator<
    ListResponse<LwM2MObject>
>("lwm2m_catalog", (untypedResponse: unknown) => {
    const response = untypedResponse as ListResponse<LwM2MObject>;
    response.data = response.data.map((resource) => Object.assign(resource, { selected: false }));
    if (response.data?.length > 1) {
        response.last = response.data[response.data.length - 1].id;
    }
    return response;
});

const {
    createThunk: createCustomLwm2mObjectsThunk,
    reducer: lwm2mCustomObjectsReducer,
} = makeLwm2mObjectsFiltersCreator<ListResponse<LwM2MObject>>("lwm2m_custom_catalog", (untypedResponse: unknown) => {
    const response = untypedResponse as ListResponse<LwM2MObject>;
    response.data = response.data.map((resource) => Object.assign(resource, { selected: false }));
    if (response.data?.length > 1) {
        response.last = response.data[response.data.length - 1].id;
    }
    return response;
});

const {
    createThunk: createSystemLwm2mObjectsThunk,
    reducer: lwm2mSystemObjectsReducer,
} = makeLwm2mObjectsFiltersCreator<ListResponse<LwM2MObject>>("lwm2m_system_catalog", (untypedResponse: unknown) => {
    const response = untypedResponse as ListResponse<LwM2MObject>;
    response.data = response.data.map((resource) => Object.assign(resource, { selected: false }));
    if (response.data?.length > 1) {
        response.last = response.data[response.data.length - 1].id;
    }
    return response;
});

const getLwm2mObjects = ({
    pageSize = 1000,
    last,
    order = "ASC",
    source,
    errorAction,
    errorCallout = true,
}: {
    pageSize?: number;
    last?: string;
    order?: string;
    source?: string;
    query?: string;
    errorAction?: () => void;
    errorCallout?: boolean;
}) => {
    const queryParameters = {
        order,
        limit: pageSize?.toString(),
        include: "total_count",
        ...(last && { after: last }),
        ...(source && { source__eq: source }),
    };

    return createLwm2mObjectsThunk({
        param: "lwm2m-objects",
        queryParameters,
        errorAction,
        errorCallout,
    });
};

const getLwm2mCustomObjects = ({
    pageSize = 1000,
    last,
    order = "ASC",
    source,
    errorAction,
    errorCallout = true,
}: {
    pageSize?: number;
    last?: string;
    order?: string;
    source?: string;
    query?: string;
    errorAction?: () => void;
    errorCallout?: boolean;
}) => {
    const queryParameters = {
        order,
        limit: pageSize?.toString(),
        include: "total_count",
        ...(last && { after: last }),
        ...(source && { source__eq: source }),
    };

    return createCustomLwm2mObjectsThunk({
        param: "lwm2m-objects",
        queryParameters,
        errorAction,
        errorCallout,
    });
};

const getLwm2mSystemObjects = ({
    pageSize = 1000,
    last,
    order = "ASC",
    source,
    errorAction,
    errorCallout = true,
}: {
    pageSize?: number;
    last?: string;
    order?: string;
    source?: string;
    query?: string;
    errorAction?: () => void;
    errorCallout?: boolean;
}) => {
    const queryParameters = {
        order,
        limit: pageSize?.toString(),
        include: "total_count",
        ...(last && { after: last }),
        ...(source && { source__eq: source }),
    };

    return createSystemLwm2mObjectsThunk({
        param: "lwm2m-objects",
        queryParameters,
        errorAction,
        errorCallout,
    });
};

const { reducer: deleteLwm2mObjectReducer, createThunk: deleteLwm2mObjectsThunk } = makeConfigApiCreator<void>(
    "v3_base"
)("delete_lwm2m_object");

const deleteLwm2mObject = (lwm2mObjectId: string) =>
    deleteLwm2mObjectsThunk({
        param: `lwm2m-objects/${lwm2mObjectId}/`,
        method: "DELETE",
    });

export {
    getLwm2mObject,
    Lwm2mObjectReducer,
    getLwm2mObjects,
    lwm2mObjectsReducer,
    getLwm2mCustomObjects,
    lwm2mCustomObjectsReducer,
    getLwm2mSystemObjects,
    lwm2mSystemObjectsReducer,
    downloadLwm2mObject,
    Lwm2mObjectDownloadReducer,
    deleteLwm2mObject,
    deleteLwm2mObjectReducer,
};
