import React from "react";
import translate, { DefaultStrings } from "../../i18n/translate";

const defaultStrings = {
    bootstrapped: "Bootstrapped",
    deregistered: "Deregistered",
    cloud_enrolling: "Cloud enrolling",
    registered: "Registered",
    unenrolled: "Unenrolled",
};

export interface DeviceStateProps {
    strings: DefaultStrings<typeof defaultStrings>;
    state: string;
}

export class DeviceState extends React.PureComponent<DeviceStateProps> {
    public static readonly defaultProps = {
        strings: defaultStrings,
    };

    public render() {
        const { state, strings } = this.props;
        return <option value={state}>{(strings as any)[state] || state}</option>;
    }
}

export default translate("DeviceState")(DeviceState);
