import { makeConfigApiCreator } from "../../../../creators";

export interface PreSharedKey {
    endpoint_name: string;
    secret_hex: string;
}

const {
    reducer: uploadPskReducer,
    createThunk: uploadPskThunk,
    createResetAction: uploadPskReset,
} = makeConfigApiCreator<void>("v2_base")("psk_upload");
const uploadPsk = (data: PreSharedKey) =>
    uploadPskThunk({
        param: "device-shared-keys",
        data,
        method: "POST",
        errorCallout: false,
    });

export { uploadPsk, uploadPskReset, uploadPskReducer };
