import { makeConfigApiCreator } from "../../../creators";
import { ListResponse } from "../../../script/types";

export interface Agreement {
    accepted_at: Date;
    accepted_by: string;
    account_id: string;
    created_at: Date;
    etag: string;
    id: string;
    legal_id: string;
    required: boolean;
    text?: string;
    title: string;
    type: string;
    updated_at?: Date;
    version: string;
}

// Update Account custom fields
const { reducer, createThunk } = makeConfigApiCreator<ListResponse<Agreement>>("v3_base")("iam_agreement");

interface GetActionProps {
    required?: boolean | null;
    type?: string;
    version?: string;
}

const getAction = ({ required = true, type, version }: GetActionProps = {}) => {
    const params = [
        required !== null ? `required__eq=${required}` : null,
        type ? `type__eq=${type}` : null,
        version ? `version__eq=${version}` : null,
    ]
        .filter((i) => i !== null)
        .join("&");

    return createThunk({
        param: `agreements?${params}`,
    });
};

const { reducer: agreementContentReducer, createThunk: createTextThunk } = makeConfigApiCreator<Agreement>("v3_base")(
    "iam_agreement_text"
);

const getAgreementContent = (id: string) => {
    return createTextThunk({
        param: `agreements/${id}?include=text`,
    });
};

const { reducer: acceptAgreementReducer, createThunk: createPostThunk } = makeConfigApiCreator<void>("v3_base")(
    "iam_agreement_accept"
);

const postAction = (data: { [key: string]: unknown }, errorCallout = true) => {
    return createPostThunk({
        param: "signed-agreements",
        data,
        method: "POST",
        errorCallout,
    });
};

export { getAction, getAgreementContent, postAction, reducer, agreementContentReducer, acceptAgreementReducer };
