// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3rC7LhAtLPmuqyNzWFeDT5:not(:first-child){padding-left:.5em}._3rC7LhAtLPmuqyNzWFeDT5.vertical{-webkit-align-items:flex-start;align-items:flex-start}._3rC7LhAtLPmuqyNzWFeDT5.vertical .filter-expand{-webkit-align-items:flex-start;align-items:flex-start}._3K-b5u355NKxD5g2pvb3Bn .bare-popover-trigger>.button,._3VCQehbKsbxWT38lDj9xV .bare-popover-trigger>.button{margin:0;color:var(--workspace-font-color, #333e48)}._1iVHdWkeyNVaYNvAtwyLEj{font-size:.93333rem;padding:1em;line-height:1.5em;color:var(--workspace-font-color, #333e48)}._1iVHdWkeyNVaYNvAtwyLEj span.switch-filter-label{color:var(--workspace-font-color, #333e48)}._2lX4SsVurxWVjyKrSJA8l4:not(.active)>.text-with-icon>.icon{opacity:0}._2lX4SsVurxWVjyKrSJA8l4>.text-with-icon>.text{margin-left:.5em}\n", "",{"version":3,"sources":["style.scss"],"names":[],"mappings":"AAAA,2CAAqC,iBAAiB,CAAC,kCAA4B,8BAAqB,CAArB,sBAAsB,CAAC,iDAA2C,8BAAqB,CAArB,sBAAsB,CAAC,6GAA4G,QAAQ,CAAC,0CAA0C,CAAC,yBAAsB,mBAAmB,CAAC,WAAW,CAAC,iBAAiB,CAAC,0CAA0C,CAAC,kDAA+C,0CAA0C,CAAC,4DAA+D,SAAS,CAAC,+CAAkD,gBAAgB","file":"style.scss","sourcesContent":[":local(.filterBar):not(:first-child){padding-left:.5em}:local(.filterBar).vertical{align-items:flex-start}:local(.filterBar).vertical .filter-expand{align-items:flex-start}:local(.dropdownFilter) .bare-popover-trigger>.button,:local(.dateTimeFilter) .bare-popover-trigger>.button{margin:0;color:var(--workspace-font-color, #333e48)}:local(.switchFilter){font-size:.93333rem;padding:1em;line-height:1.5em;color:var(--workspace-font-color, #333e48)}:local(.switchFilter) span.switch-filter-label{color:var(--workspace-font-color, #333e48)}:local(.dropdownFilterItem):not(.active)>.text-with-icon>.icon{opacity:0}:local(.dropdownFilterItem)>.text-with-icon>.text{margin-left:.5em}\n"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterBar": "_3rC7LhAtLPmuqyNzWFeDT5",
	"dropdownFilter": "_3K-b5u355NKxD5g2pvb3Bn",
	"dateTimeFilter": "_3VCQehbKsbxWT38lDj9xV",
	"switchFilter": "_1iVHdWkeyNVaYNvAtwyLEj",
	"dropdownFilterItem": "_2lX4SsVurxWVjyKrSJA8l4"
};
export default ___CSS_LOADER_EXPORT___;
