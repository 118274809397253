import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { Config } from "../../../typings/interfaces";
import { makeApiCreator, makeConfigApiCreator } from "../../../creators";
import { AjaxState, RequestState, SuccessAction } from "../../../creators/AjaxCreator";
import { ListResponse } from "../../../script/types";
import { getFeatureToggle } from "../../../script/utility";
import { Idp, IdpStatus } from "../../login";

export const SecurityDefaults = {
    DEFAULT_PASS_MIN: 8,
    DEFAULT_TIMEOUT_PERIOD: 30,
};

const makeIdpCreator = <T>(type: string) =>
    makeConfigApiCreator<T>("v3_base", {
        errorCallout: false,
    })(type);

export interface OidcKey {
    key?: string;
    kid?: string;
}

export interface OidcConfiguration {
    issuer?: string;
    client_id?: string;
    client_secret?: string;
    redirect_uri?: string;
    logout_redirect_uri?: string;
    authorization_endpoint?: string;
    token_endpoint?: string;
    userinfo_endpoint?: string;
    revocation_endpoint?: string;
    end_session_endpoint?: string;
    jwks_uri?: string;
    auto_enrollment?: boolean;
    keys?: OidcKey[];
    use_pkce?: boolean;
}

export interface Saml2Configuration {
    sso_endpoint?: string;
    slo_endpoint?: string;
    assertion_endpoint?: string;
    idp_entity_id?: string;
    sp_entity_id?: string;
    client_x509cert?: string;
    idp_x509_certs?: string[];
    sp_x509_cert?: string;
    entity_descriptor?: string;
    auto_enrollment?: boolean;
}

export interface IDPData {
    id?: string;
    issuer: string;
    type: "NATIVE" | "MBED" | "SAML2" | "OIDC";
    name: string;
    description?: string;
    profile_endpoint?: string;
    status?: "ACTIVE" | "SUSPENDED";
    oidc_attributes?: OidcConfiguration;
    saml2_attributes?: Saml2Configuration;
}

/**
 * IdP details as returned by the /v3/identity-providers API. Note: This is a different
 * structure from that returned by the /auth/identity-providers API.
 */
export type IdpInfo = Required<Pick<IDPData, "id">> &
    IDPData & {
        status: IdpStatus;
        created_at: string;
        updated_at: string;
        account_id: string;
        is_default: boolean;
    };

const idpTypeOrder: IdpInfo["type"][] = ["SAML2", "OIDC", "MBED", "NATIVE"];

export const compareIdps = (idp1: IdpInfo, idp2: IdpInfo): number => {
    const index1 = idpTypeOrder.findIndex((type) => idp1.type === type);
    const index2 = idpTypeOrder.findIndex((type) => idp2.type === type);
    return index1 === index2 ? idp1.name.localeCompare(idp2.name) : index1 - index2;
};

interface XmlDescriptorSaml2Attributes {
    entity_descriptor: string;
}

export type CreateIdpOptions = Pick<IDPData, "name" | "description" | "oidc_attributes" | "issuer" | "type"> & {
    saml2_attributes?: Saml2Configuration | XmlDescriptorSaml2Attributes;
};

export interface EditIdpOptions extends CreateIdpOptions {
    id: string;
}

interface ListActionData {
    idpManagementEnabled: boolean;
}

export interface ListIdpsOptions {
    accountId?: string;
}

export interface SetIdpStatusOptions {
    id: string;
    status: IdpStatus;
}

const {
    reducer: createIdpReducer,
    createThunk: createIdpCreateThunk,
    actionTypes: createActionTypes,
    createResetAction: createResetCreateAction,
} = makeIdpCreator<IdpInfo>("idp_create");

export { createIdpReducer };
export { editIdpReducer };
export { setIdpStatusReducer };
export { deleteIdpReducer };
export { renewIdpReducer };

export const createIdp = (options: IDPData, discovery = false) =>
    createIdpCreateThunk({
        param: `identity-providers${discovery ? "?discovery" : ""}`,
        method: "POST",
        data: { ...options },
    });

export const resetCreateIdp = () => createResetCreateAction();

const {
    reducer: editIdpReducer,
    createThunk: editIdpCreateThunk,
    actionTypes: editActionTypes,
    createResetAction: createResetEditAction,
} = makeIdpCreator<IdpInfo>("idp_edit");

export const editIdp = ({ id, ...options }: IDPData, discovery = false) =>
    editIdpCreateThunk({
        param: `identity-providers/${id}${discovery ? "?discovery" : ""}`,
        method: "PUT",
        data: { ...options },
    });

export const resetEditIdp = () => createResetEditAction();

const {
    reducer: setIdpStatusReducer,
    createThunk: setIdpStatusCreateThunk,
    actionTypes: setStatusActionTypes,
} = makeIdpCreator<IdpInfo>("idp_set_status");

export const setIdpStatus = ({ id, status }: SetIdpStatusOptions) =>
    setIdpStatusCreateThunk({
        param: `identity-providers/${id}`,
        method: "PUT",
        data: { status },
        errorCallout: true,
        id,
    });

const { createThunk: deleteIdpCreateThunk, actionTypes: deleteActionTypes, reducer: deleteIdpReducer } = makeIdpCreator<
    void
>("idp_delete");

export const deleteIdp = (id: string) =>
    deleteIdpCreateThunk({
        param: `identity-providers/${id}`,
        method: "DELETE",
        id,
        errorCallout: true,
    });

const { createThunk: renewIdpCreateThunk, reducer: renewIdpReducer } = makeIdpCreator<void>("idp_renew");

export const renewIdp = (id: string) =>
    renewIdpCreateThunk({
        param: `identity-providers/${id}/generate-sp-certificate`,
        method: "POST",
        id,
        errorCallout: true,
    });

const { reducer: listReducer, createThunk: listIdpCreateThunk } = makeApiCreator<IdpInfo[], ListActionData>(
    // If idp_management is not enabled, fall back to using unauthenticated endpoint
    (config: Config): string =>
        getFeatureToggle("idp_management", { config }) ? config.api.v3_base : config.api.auth_base
)("idp_list", (response, _, requestData): IdpInfo[] => {
    const idpManagementEnabled = !requestData || requestData.idpManagementEnabled;

    if (idpManagementEnabled) {
        return (response as ListResponse<IdpInfo>).data;
    } else {
        const { data } = response as ListResponse<Idp>;

        // IdPs returned from /auth/identity-providers have a different structure, convert them here
        return data.reduce((parsedIdps: IdpInfo[], idp: Idp): IdpInfo[] => {
            if (idp.type === "MBED" || idp.type === "NATIVE") {
                const idpInfo: IdpInfo = {
                    id: idp.id,
                    name: idp.name,
                    issuer: idp.issuer,
                    status: idp.status,
                    description: idp.description,
                    type: idp.type,
                    created_at: idp.created_at,
                    updated_at: idp.updated_at,
                    account_id: idp.account_id,
                    is_default: idp.is_default,
                };

                return [...parsedIdps, idpInfo];
            }

            return parsedIdps;
        }, []);
    }
});

export const idpListReducer = (
    state: AjaxState<IdpInfo[]> = { requestState: RequestState.INITIALIZING, id: "" },
    action: Action
): AjaxState<IdpInfo[]> => {
    let outputState = state;

    if (state.requestState === RequestState.LOADED) {
        if (action.type === createActionTypes.successType) {
            const createAction = action as SuccessAction<unknown>;
            const newIdp = createAction.info as IdpInfo;
            const existingIdp = state.data.find(({ id }) => id === newIdp.id);

            if (!existingIdp) {
                // Update list on create
                outputState = { ...state, data: [...state.data, newIdp] };
            }
        } else if (action.type === editActionTypes.successType || action.type === setStatusActionTypes.successType) {
            const editAction = action as SuccessAction<unknown>;
            const updatedIdp = editAction.info as IdpInfo;

            // Update list on edit
            const updatedIdpList = state.data.map((idp) => (idp.id === updatedIdp.id ? updatedIdp : idp));

            outputState = { ...state, data: updatedIdpList };
        } else if (action.type === deleteActionTypes.successType) {
            const deleteAction = action as SuccessAction<unknown>;

            // Update list on delete
            const updatedIdpList = state.data.filter((idp) => idp.id !== deleteAction.id);
            outputState = { ...state, data: updatedIdpList };
        }
    }

    return listReducer(outputState, action);
};

export const listIdps = ({ accountId }: ListIdpsOptions) => (
    dispatch: ThunkDispatch<{ config: Config; identityProviders: AjaxState<IdpInfo[]> }, never, Action>,
    getState: () => { config: Config; identityProviders: AjaxState<IdpInfo[]> }
) => {
    const { config, identityProviders } = getState();

    if (
        identityProviders &&
        (identityProviders.requestState === RequestState.LOADED ||
            identityProviders.requestState === RequestState.LOADING)
    ) {
        return null;
    }

    const idpManagementEnabled = getFeatureToggle("idp_management", { config });

    return dispatch(
        listIdpCreateThunk({
            param: "identity-providers",
            // Track if we're using the feature-toggled endpoint in the request data so we can use it when parsing the response
            requestData: { idpManagementEnabled },
            useAuth: idpManagementEnabled,
            queryParameters:
                idpManagementEnabled || !accountId
                    ? {}
                    : {
                          account_id__eq: accountId,
                      },
        })
    );
};
